<template>
	<div
		class="offer-duration"
		:class="{ 'duration-error': maxTravelDurationError }"
	>
		<DropdownFilterType
			title="Zeitraum"
			:with-menu-header="false"
			icon="calendar"
			class="offer-duration__dropdown"
			:selected-value="selectedDatesLabel"
			cancel-button-text="Verwerfen"
			ok-button-text="Übernehmen"
			:show-buttons="true"
			:show-toggle-icon="false"
			:emit-on-ok="true"
			:button-disabled="maxTravelDurationError"
			:has-error="maxTravelDurationError"
			:apply-button-text="'Übernehmen'"
			@DropdownFilterType:apply="onOk"
			@DropdownFilterType:clear="onClear"
			@DropdownFilterType:abort="onCancel"
		>
			<div class="offer-duration__time-container">
				<div class="offer-duration__time-box">
					<h4 class="offer-duration__time-title">Früheste Anreise</h4>
					<p class="offer-duration__time-zone">
						{{ extendSelectedDatesLabel.from }}
					</p>
				</div>
				<div class="offer-duration__time-box">
					<h4 class="offer-duration__time-title">Späteste Abreise</h4>
					<p class="offer-duration__time-zone">
						{{ extendSelectedDatesLabel.to }}
					</p>
				</div>
			</div>

			<Calendar
				ref="calendar"
				v-model="selection"
				:max-months="24"
				:horizontal="isDesktop"
				:is-exact="isExact"
				@Calendar:AlertOkClick="onOk"
				@Calendar:AlertCancelClick="onCancel"
				@update:modelValue="checkDateRange"
			/>

			<p
				v-if="maxTravelDurationError"
				class="offer-duration__warning"
			>
				{{ maxDurationErrorMessage }}
			</p>
		</DropdownFilterType>
	</div>
</template>

<script lang="ts" setup>
import Calendar from '@lmt-rpb/Calendar/Calendar.vue';
import { dateDiff, formatDate, offsetDate } from '@utils/dateUtils';
import { computed, ref, watch } from 'vue';
import { EventBus } from '@global-js/event-bus';
import type { SelectionType } from '@/components/common/types';
import { useStore } from '@/components/common/store';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

const store = useStore();

const maxDurationErrorMessage = 'Die gewählte Dauer kann nicht länger als 56 Tage sein. Bitte ändern Sie die Reisedaten.';

const maxTravelDurationError = computed({
	get() {
		return store.state.searchMask.maxTravelDurationError;
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			maxTravelDurationError: value,
		});
	},
});

const calendar = ref<InstanceType<typeof Calendar> | null>(null);

const okFirstTime = ref(true);
const isDirty = ref(false);

const { isDesktop } = storeToRefs(useBreakpointStore());

const offerDuration = computed({
	get() {
		return store.state.searchMask.offerDuration as SelectionType;
	},
	set(value: SelectionType) {
		if (!isDirty.value) {
			isDirty.value = JSON.stringify(value) !== JSON.stringify(store.state.searchMask.offerDuration);
		}
		store.commit('searchMask/updateFormData', { offerDuration: value });
	},
});

const selection = ref<SelectionType>(offerDuration.value);

const travelDuration = computed({
	get() {
		return store.state.searchMask.travelDuration as number[];
	},
	set(value: number[]) {
		store.commit('searchMask/updateFormData', { travelDuration: value });
	},
});

const isExact = computed({
	get() {
		return store.state.searchMask.isTravelDurationExactSelected;
	},
	set(newValue: boolean) {
		store.commit('searchMask/updateFormData', { isTravelDurationExactSelected: newValue });
	},
});

const selectionDiffers = computed((): boolean => {
	const { from: offerFrom, to: offerTo } = offerDuration.value;
	const { from: selectionFrom, to: selectionTo } = selection.value;

	return offerFrom !== selectionFrom || offerTo !== selectionTo;
});

const selectedDatesLabel = computed((): string => {
	if (!selection.value.from) {
		return '';
	}
	const from = formatDate(new Date(selection.value.from));
	const to = (selection.value.to && formatDate(new Date(selection.value.to))) || 'Beliebig';

	return `${from} - ${to}`;
});

const extendSelectedDatesLabel = computed((): { from: string; to: string } => {
	let [from, to] = selectedDatesLabel.value.split(' - ');

	if (to !== 'Beliebig') {
		to += new Date(selection.value.to).getFullYear();
	}

	from += new Date(selection.value.from).getFullYear();
	if (from === '1970') {
		from = '';
		to = '';
	}
	return { from, to };
});

const checkDateRange = (selectionValues: { from: number; to: number }) => {
	if (selectionValues.from === 0 || selectionValues.to === 0) {
		return;
	}

	const dayDifference = dateDiff(selectionValues.to, selectionValues.from);
	if (isExact.value) {
		maxTravelDurationError.value = Math.abs(dayDifference) > 56;
	}
};

const closeDropdown = () => {
	if (isDesktop.value) {
		// If dropdown closes, always set calendarDateChanged to false
		store.commit('updateCalendarDateChanged', false);
	}
};

const updateTravelDuration = () => {
	const totalDiffInDay = dateDiff(offerDuration.value.from, offerDuration.value.to);

	if (totalDiffInDay < travelDuration.value[0] || isExact.value) {
		travelDuration.value = [totalDiffInDay];
		isExact.value = true;
	}

	maxTravelDurationError.value = travelDuration.value[0] > 56;
};

const handleTravelDuration = () => {
	if (okFirstTime.value && travelDuration.value[0] <= 56 && isDirty.value) {
		EventBus.$emit('OfferDurationField:OpenTravelDuration');
	}
	okFirstTime.value = false;
	updateTravelDuration();
};

const onOk = () => {
	// Clearing relative offerDuration if user changes the offerDuration via the calendar

	store.commit('searchMask/updateFormData', { offerDurationRelative: { relativeFrom: '', relativeTo: '' }, filter: '' });

	if (!selection.value.from) {
		selection.value.from = offerDuration.value.from;
	}

	if (selection.value.from && !selection.value.to) {
		// If offer duration to is 'Beliebig', a date 4 weeks in the future is choosen
		selection.value.to = offsetDate(selection.value.from, 28).getTime();
	}

	offerDuration.value = selection.value;
	handleTravelDuration();

	EventBus.$emit('Calendar:Scroll');
};

const onCancel = () => {
	selection.value = offerDuration.value;
	checkDateRange(offerDuration.value);
	closeDropdown();
};

const onBeforeClose = () => {
	if (!isDesktop.value) {
		selection.value = offerDuration.value;
		maxTravelDurationError.value = false;
	} else if (!maxTravelDurationError.value) {
		if (selectionDiffers.value && calendar.value) {
			calendar.value.showDismiss = !calendar.value.showConfirm;
		} else {
			selection.value = offerDuration.value;
			closeDropdown();
		}
		EventBus.$emit('Calendar:Scroll');
	}
};

const onClear = () => {
	if (!maxTravelDurationError.value) {
		offerDuration.value = { from: 0, to: 0 };
		EventBus.$emit('Calendar:Scroll');
	}
};

watch(
	() => offerDuration.value,
	() => {
		selection.value = offerDuration.value;
	},
	{ immediate: true }
);

watch(
	() => isDesktop.value,
	() => {
		onBeforeClose();
	}
);

defineExpose({
	selectedDatesLabel,
	offerDuration,
});
</script>

<style lang="scss" scoped>
:deep() {
	.calendar {
		overflow: auto;
		min-height: unset;
	}

	.search-modal__main {
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.dropdown {
		.dropdown__box {
			left: -27rem !important;
		}

		&:not(.is-open) .dropdown__box {
			min-height: unset;
		}

		.is-open .dropdown__box {
			min-height: 50rem !important;
		}
	}
}

.offer-duration {
	&__time-container {
		display: flex;
		padding: 0;
		margin: 0;
		border-bottom: 0.1rem solid $color-primary-l4;

		@media (min-width: $breakpoint-small) {
			margin: 0 auto;
			width: 60%;
		}

		@media (min-width: $breakpoint-extralarge) {
			border-bottom: none;
			width: 100%;
			justify-content: center;
		}
	}

	&__time-box {
		display: flex;
		flex-direction: column;
		align-items: center;

		&:nth-child(1) {
			width: 43%;
		}

		&:nth-child(2) {
			width: 45%;
		}
	}

	&__time-title {
		padding: 0;
		margin: 0;
		margin-top: 1.4rem;
		font-family: $font-family-special;
		font-size: clamp($font-small-1, 1vw + 0.8rem, $font-medium-3);
		font-weight: $font-weight-semibold;
		margin-left: 1rem;
	}

	&__time-zone {
		margin: 0;
		padding: 0;
		margin-top: 0.7rem;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		font-family: 'Open Sans', sans-serif;
	}

	:deep(.form-field__icon) {
		@include visible-from($breakpoint-verysmall);

		& {
			width: 3rem;
			height: 3rem;
			margin-right: 1rem;
			fill: $color-primary;
		}
	}

	.offer-duration-modal {
		.modal__content-top {
			border-bottom: 0.1rem solid $color-light-gray;
			padding-bottom: 0.2rem;
		}
	}

	.offer-duration__dropdown {
		:deep(.dropdown__box) {
			width: 80rem;
			max-width: none;
		}
	}

	.offer-duration__day-names {
		position: relative;
		display: flex;
		max-width: 50rem;
		margin: 0 auto;
		padding: 0 1.3rem;
		flex-wrap: wrap;
		border-bottom: 1px solid #f9f9f9;
	}

	.offer-duration__day {
		padding: 1rem;
		flex: 1 1 auto;
		text-align: center;
		font-weight: $font-weight-bold;
	}

	&__warning {
		text-align: center;
		color: $color-warning;
		font-size: $font-small-2;
		margin: 1rem 4.5rem;
	}

	&.duration-error .is-open {
		:deep(.offer-duration__field) {
			border-color: $color-warning;
		}

		:deep(.dropdown__inner) {
			border-color: $color-warning;
		}

		:deep(.form-field::after) {
			border-color: $color-warning;
		}
	}
}
</style>
