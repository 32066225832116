<template>
	<div class="travelers">
		<DropdownFilterType
			:selected-value="fieldLabel"
			class="travelers__dropdown"
			title="Reisende"
			:with-menu-header="false"
			icon="group"
			cancel-button-text="Verwerfen"
			ok-button-text="Übernehmen"
			:apply-button-text="'Übernehmen'"
			:show-buttons="true"
			:show-toggle-icon="false"
			:emit-on-ok="true"
			@DropdownFilterType:apply="onOk"
			@DropdownFilterType:clear="onClear"
			@DropdownFilterType:abort="onCancel"
		>
			<TravelersFieldContent v-model="travelerData" />
		</DropdownFilterType>
	</div>
</template>

<script lang="ts" setup>
import { cloneDeep, pluralize } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import type { Travelers } from '@/components/common/types';
import TravelersFieldContent from './TravelersFieldContent.vue';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

const store = useStore();

const travelers = computed({
	get() {
		return store.state.searchMask.travelers;
	},
	set(value: Travelers) {
		store.commit('searchMask/updateFormData', { travelers: value, filter: '' });
	},
});
const travelerData = ref<Travelers>(cloneDeep(travelers.value));

const { isDesktop } = storeToRefs(useBreakpointStore());

const fieldLabel = computed((): string => {
	const adultLabel = isDesktop.value
		? `${travelerData.value.adult} Erw.`
		: pluralize(travelerData.value.adult, 'Erwachsener', 'Erwachsene');
	const childLabel = pluralize(travelerData.value.children.length, 'Kind', 'Kinder');

	return `${adultLabel} ${childLabel}`;
});

const onOk = () => {
	travelers.value = cloneDeep(travelerData.value);
};

const onCancel = () => {
	travelerData.value = cloneDeep(travelers.value);
};

const onClear = () => {
	travelers.value = { adult: 2, children: [] };
};

watch(
	() => travelers.value,
	() => {
		if (JSON.stringify(travelers.value) !== JSON.stringify(travelerData.value)) {
			travelerData.value = cloneDeep(travelers.value);
		}
	},
	{ immediate: true }
);

defineExpose({
	travelerData,
	travelers,
});
</script>

<style lang="scss" scoped>
.travelers {
	:deep() {
		.form-field__icon {
			flex-shrink: 0;
			width: 2.5rem;
			height: 3rem;
			fill: $color-primary;
		}
		.dropdown__box {
			width: 44rem;
			left: -12rem;
		}
	}
}
</style>
