import type { SearchFormDataType } from '@/interfaces/search-form';

/** returns true if hotel page */
export function isHotelPage(): boolean {
	if (document.querySelector('.page_hotel')) return true;
	return false;
}

/** returns true if hotel page */
export function isHotelList(): boolean {
	if (document.querySelector('.page_hotel_list')) return true;
	return false;
}

/**  Returns number of all search filters */
export function getFilterCount(data: SearchFormDataType): number {
	let counter = 0;

	counter += !isHotelPage() && data?.destinationTypeName && data?.destinationTypeName !== '' ? 1 : 0;
	counter += !data.onlyHotel && data?.directFlight ? 1 : 0;
	counter += (!data.onlyHotel && data?.transferTypes?.length) || 0;
	counter += data.flex ? 1 : 0;
	counter += isHotelList() && data.deal ? 1 : 0;
	counter += data?.boardTypes?.length || 0;
	counter += data?.roomTypes?.length || 0;
	counter += data?.roomViews?.length > 0 ? 1 : 0;
	counter += !isHotelPage() && data?.hotelCategory && data?.hotelCategory > 0 ? 1 : 0;
	counter += !isHotelPage() && data?.minMeanRecommendationRate && data?.minMeanRecommendationRate > 0 ? 1 : 0;
	counter += !isHotelPage() && data?.maxPrice && data?.maxPrice <= 1500 ? 1 : 0;
	counter += (!isHotelPage() && data?.mostPopularFilters?.hotelAttributes?.length) || 0;
	counter += (!isHotelPage() && data?.mostPopularFilters?.ratingAttributes?.length) || 0;
	counter += (!isHotelPage() && data?.additionalCheckboxFilters?.ratingAttributes?.length) || 0;
	counter += data?.operatorTypes && data?.operatorTypes?.length > 0 ? 1 : 0;
	if (
		!data.onlyHotel &&
		(data?.minArrivalTime > 0 || data?.minDepartureTime > 0 || data?.maxDepartureTime < 24 || data?.maxArrivalTime < 24)
	) {
		counter += 1;
	}

	return counter;
}
