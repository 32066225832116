const base = {
	searchBarMain: {
		searchButton: {
			label: 'Suchen',
		},
		travelType: {
			hotelOnly: 'Nur Hotel',
			package: 'Pauschalreise',
		},
	},
	close: 'Schließen',
	filtersSection: {
		dealsOnly: {
			title: 'Restplätze',
			label: 'Angebote mit Preissenkung',
		},
	},
	flightDetail: {
		header: 'Voraussichtliche Flugdaten',
		legalText:
			'Der Reiseveranstalter behält sich Änderungen der geplanten Flugzeiten im Rahmen der gesetzlichen und vertraglichen Möglichkeiten vor.',
	},
} as const;

export type BaseStructure = typeof base;

export default base;
