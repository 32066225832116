import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import type { Feature } from './offer-feature';

type Props = {
	feature: Feature;
	featureBold?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'OfferFeature',
  props: {
    feature: {},
    featureBold: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["offer-feature", { 'font-weight-bold': _ctx.featureBold }])
  }, [
    _createVNode(FaIcon, {
      icon: _ctx.feature.icon,
      class: _normalizeClass(["offer-feature__list-icon", {
				'offer-feature__list-icon--available': _ctx.feature.checked,
				'offer-feature__list-icon--unavailable': !_ctx.feature.checked,
			}])
    }, null, 8 /* PROPS */, ["icon", "class"]),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}
}

})