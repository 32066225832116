<template>
	<div :class="[blockClassModifiers]">
		<div class="flight-details__header">
			<FaIcon
				name="planeGo"
				:icon="['fa-solid', 'fa-plane']"
				:class="iconClass"
			/>
			<h3 class="flight-details__headline">
				{{ flightWording }}
			</h3>
		</div>
		<div>
			<span
				v-if="flights[0]?.Departure?.Date"
				class="flight-details__date"
			>
				{{ getDate(flights[0]?.Departure?.Date) }}
			</span>
			<div
				v-for="segment in flights"
				:key="`${segment?.Departure?.Date}`"
			>
				<CheckoutFlightDetailsSegment
					:segment="segment"
					:is-right="!isOutbound"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import CheckoutFlightDetailsSegment from '@lmt-rpb/CheckoutFlightDetailsSegment/CheckoutFlightDetailsSegment.vue';
import { computed } from 'vue';
import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import { formatDateLong } from '@utils/dateUtils';

const props = defineProps<{
	isOutbound: boolean;
	flights: FlightSegment[];
}>();

const flightWording = computed((): string => (props.isOutbound ? 'Hinflug' : 'Rückflug'));

const iconClass = computed((): string => {
	const baseClass = 'flight-details__';

	return props.isOutbound ? `${baseClass}outbound-icon` : `${baseClass}inbound-icon`;
});

const getDate = (date: Date | string): Date | string => {
	if (!date) {
		return '';
	}

	return formatDateLong(date);
};

const blockClassModifiers = computed(() => ({
	['flight-details--outbound']: props.isOutbound === true,
	['flight-details--inbound']: props.isOutbound === false,
}));
</script>

<style lang="scss" scoped>
.flight-details {
	max-width: 76rem;
	margin: 0 auto;

	&__header {
		margin: 0 auto;
		display: flex;
		align-items: center;
		margin-bottom: 0.8rem;
		font-weight: $font-weight-semibold;
		line-height: 1.56;
	}

	&__headline {
		text-align: left;
		padding: 0;
		font-size: $font-medium-3;
		margin-left: 0.8rem;
		font-weight: $font-weight-bold;
	}

	&__sub-headline {
		margin: 0 auto;
		max-width: 30rem;
		font-style: italic;
		font-size: $font-small-2;
		font-weight: $font-weight-semibold;
		margin-bottom: 2rem;
	}

	&__date {
		padding-left: 2.5rem;
		font-size: $font-small-1;
		font-weight: $font-weight-regular;
	}

	&__outbound-icon,
	&__inbound-icon {
		width: 1.6rem;
	}

	&__inbound-icon {
		transform: rotate(180deg);
	}

	&--inbound {
		margin-top: 4.8rem;
	}
}

@media (min-width: $breakpoint-extralarge) {
	.flight-details {
		&__headline {
			font-size: $font-medium-2;
		}
	}
}
</style>
