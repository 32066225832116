import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-currency", "data-price"]
const _hoisted_2 = ["href", "title", "target", "data-ga-item_id"]
const _hoisted_3 = { class: "quickpill__headline" }

import type { ClientCurrency } from '@global-js/constants';
import BasePrice from '@lmt-rpb/Atoms/BasePrice/BasePrice.vue';

interface Props {
	destination: string;
	url?: string;
	linkTarget?: string;
	price?: string;
	currency?: ClientCurrency;
	titleTag?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'QuickPill',
  props: {
    destination: {},
    url: { default: '' },
    linkTarget: { default: '' },
    price: { default: '' },
    currency: { default: 'EUR' },
    titleTag: { default: '' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    "data-currency": _ctx.currency,
    "data-price": _ctx.price,
    class: "quickpill"
  }, [
    _createElementVNode("a", {
      href: _ctx.url,
      class: "quickpill__link",
      title: _ctx.titleTag,
      target: _ctx.linkTarget,
      "data-ga-event": "select_content",
      "data-ga-content_type": "Quickpill",
      "data-ga-item_id": _ctx.titleTag
    }, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.destination), 1 /* TEXT */),
      (_ctx.price)
        ? (_openBlock(), _createBlock(BasePrice, {
            key: 0,
            class: "quickpill__price",
            prefix: "ab",
            price: Number(_ctx.price),
            currency: _ctx.currency
          }, null, 8 /* PROPS */, ["price", "currency"]))
        : _createCommentVNode("v-if", true)
    ], 8 /* PROPS */, _hoisted_2)
  ], 8 /* PROPS */, _hoisted_1))
}
}

})