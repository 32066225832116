import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

type FontAwesomeUtils = 'fa-solid' | 'fa-regular' | 'fa-brand' | 'fa-kit';

export type FontAwesomeIcon = [FontAwesomeUtils, FontAwesomeUtils, `fa-${string}`] | [FontAwesomeUtils, `fa-${string}`];

interface Props {
	icon: FontAwesomeIcon;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FaIcon',
  props: {
    icon: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("i", {
    "aria-hidden": "true",
    class: _normalizeClass([_ctx.icon])
  }, null, 2 /* CLASS */))
}
}

})