<template>
	<div class="flight-data">
		<h4 class="flight-data__title">
			{{ title }}
		</h4>
		<p class="flight-data__departure-date">
			{{ flightDates }}
		</p>
		<div class="flight-data__time-row">
			<p class="flight-data__time">
				{{ formatTime(firstFlightSegmentDeparture) }}
			</p>
			<div class="flight-data__divider"></div>
			<BaseIcon
				class="flight-data__plane"
				:class="{ 'flight-data__plane--outbound': !outbound }"
				name="planeNew"
			/>
			<div class="flight-data__divider"></div>
			<p class="flight-data__time">
				{{ formatTime(lastFlightSegmentArrival) }}
			</p>
		</div>
		<div class="flight-data__airport-row">
			<p class="flight-data__departure-airport">
				{{ firstFlightSegment.Departure.Airport }}
				<span class="flight-data__airport-code">({{ firstFlightSegment.Departure.AirportCode }})</span>
			</p>
			<p class="flight-data__stops">
				{{ stopsLabel }}
			</p>
			<p class="flight-data__arrival-airport">
				{{ lastFlightSegment.Arrival.Airport }}
				<span class="flight-data__airport-code">({{ lastFlightSegment.Arrival.AirportCode }})</span>
			</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import { pluralize } from '@/js/utils/utils';
import { formatDate, dateDiff } from '@utils/dateUtils';
import { NDASH } from '@global-js/constants';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed, ref } from 'vue';

type Props = {
	flightSegment: FlightSegment[];
	outbound: boolean;
};

const formatTime = (date: string | Date | undefined) =>
	date ? formatDate(date, { hour: '2-digit', minute: '2-digit' }) : `--${NDASH}-- Uhr`;
const formatDateLong = (date: string | Date | undefined) =>
	date
		? formatDate(date, {
				weekday: 'short',
				month: '2-digit',
				day: '2-digit',
				year: '2-digit',
			}).replace(',', '')
		: '.';

const props = withDefaults(defineProps<Props>(), {});

const stopsCount = ref(props.flightSegment.length - 1);

const firstFlightSegment = ref(props.flightSegment[0]);

const lastFlightSegment = ref(props.flightSegment[stopsCount.value]);

const firstFlightSegmentDeparture = ref(firstFlightSegment.value.Departure.Date);

const lastFlightSegmentArrival = ref(lastFlightSegment.value.Arrival.Date);

const stopsLabel = ref(stopsCount.value > 0 ? pluralize(stopsCount.value, 'Stop', 'Stops') : 'Direkt');

const title = ref(props.outbound ? 'Hinflug' : 'Rückflug');

const flightDates = computed(() => {
	// if only one value is set fill it with this value
	let result = formatDateLong(firstFlightSegmentDeparture.value || lastFlightSegmentArrival.value);
	if (firstFlightSegmentDeparture.value && lastFlightSegmentArrival.value) {
		const datediff = dateDiff(firstFlightSegmentDeparture.value, lastFlightSegmentArrival.value!);
		if (datediff > 0) {
			result += ` ${NDASH} ${formatDateLong(lastFlightSegmentArrival.value)}`;
		}
	}
	return result;
});
</script>
<style lang="scss" scoped>
.flight-data {
	* {
		margin: 0;
		padding: 0;
	}

	&__title {
		font-weight: $font-weight-bold;
		font-size: $font-medium-3;
		color: $color-filter-type-header-text;
	}

	&__divider {
		border-bottom: solid 0.1rem $color-border;
		margin-top: 0.1rem;
		flex-grow: 1;
	}

	&__time-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 1.8rem;
	}

	&__time {
		font-weight: $font-weight-semibold;
		font-size: $font-medium-3;
	}

	&__departure-date {
		color: $color-text;
		font-weight: $font-weight-semibold;
		font-size: $font-small-3;
		text-align: center;
		margin-top: 0.4rem;
	}

	&__plane {
		color: $color-primary;
		width: 3rem;
		height: 3rem;
		flex-shrink: 0;

		&--outbound {
			transform: rotate(180deg);
			transform-origin: center center;
		}
	}

	&__airport-row {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	&__stops {
		justify-self: center;
	}

	&__arrival-airport {
		text-align: right;
	}

	&__arrival-airport,
	&__departure-airport {
		font-size: $font-small-3;
		font-weight: $font-weight-semibold;
	}

	&__airport-code {
		font-weight: $font-weight-regular;
	}

	@media screen and (min-width: $breakpoint-small) {
		.flight-data {
			&__time-row {
				column-gap: 3.2rem;
			}
		}
	}

	@media screen and (min-width: $breakpoint-extralarge) {
		.flight-data {
			&__time-row {
				column-gap: 1.8rem;
			}
		}
	}
}
</style>
