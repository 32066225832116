import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "select-field__list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick", "onKeydown"]
const _hoisted_5 = {
  key: 1,
  style: {"float":"right"}
}

import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import { pluralize } from '@/js/utils/utils';
import { formatPrice } from '@/js/utils/priceUtils';
import type { CountLabel } from '@global-js/constants';
import { computed, ref, watch } from 'vue';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	modelValue?: string | number | null;
	label?: string;
	icon?: string;
	valueProp?: string;
	labelProp?: string;
	countLabel?: CountLabel;
	countProp?: string;
	items: any[];
	board?: boolean;
	showFooter?: boolean;
	disabled?: boolean;
	manual?: boolean;
	placeholder?: string;
	offerCount?: boolean;
	offerCountClass?: string;
	noNullOption?: boolean;
	allowClear?: boolean;
	buttonWording?: string;
	itemAny?: boolean;
	labelForAny?: string;
	totalCount?: number | string;
	loading?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectField',
  props: {
    modelValue: { default: null },
    label: { default: '' },
    icon: { default: '' },
    valueProp: { default: 'id' },
    labelProp: { default: 'label' },
    countLabel: { default: () => ({
		singular: '',
		plural: '',
	}) },
    countProp: { default: 'Count' },
    items: {},
    board: { type: Boolean, default: false },
    showFooter: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    manual: { type: Boolean, default: undefined },
    placeholder: { default: 'Beliebig' },
    offerCount: { type: Boolean, default: false },
    offerCountClass: { default: '' },
    noNullOption: { type: Boolean, default: false },
    allowClear: { type: Boolean, default: false },
    buttonWording: { default: 'Übernehmen' },
    itemAny: { type: Boolean, default: false },
    labelForAny: { default: 'Beliebig' },
    totalCount: { default: '' },
    loading: { type: Boolean, default: false }
  },
  emits: ['update:modelValue', 'SelectField:Clear', 'SelectField:Toggle'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const { isDesktop } = storeToRefs(useBreakpointStore());

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	},
});

const proxy = ref<string | number | null>(model.value || null);

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const fieldValue = computed((): string => {
	if (!model.value) {
		return '';
	}

	const selectedItem = props.items.find((item) => item[props.valueProp] === model.value);

	if (!selectedItem) {
		return '';
	}

	return selectedItem[props.labelProp];
});

const select = (item: any) => {
	// prevent click if offerCount true and count is zero
	if (props.offerCount && !item[props.countProp]) {
		return;
	}

	if (model.value === item[props.valueProp] && !props.noNullOption) {
		model.value = null;
	} else {
		model.value = item[props.valueProp];
	}
};

const onOk = () => {
	model.value = proxy.value;
	dropdown.value?.closeDropdown();
};

const onCancel = () => {
	proxy.value = model.value;
	dropdown.value?.closeDropdown();
};

const itemAnyClickHandler = (event: Event): void => {
	if (props.offerCount && props.totalCount === '0') {
		return;
	}

	emit('SelectField:Clear', event);
};

const pluralizedLabel = (num: number): string => {
	if (props.countLabel) return pluralize(num, props.countLabel.singular, props.countLabel.plural);
	return String(num);
};

watch(
	() => model.value,
	() => {
		proxy.value = model.value;
	},
	{ immediate: true }
);

__expose({
	fieldValue,
	select,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, { class: "select-field" }), [
    _createVNode(DropdownField, {
      ref_key: "dropdown",
      ref: dropdown,
      modelValue: fieldValue.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((fieldValue).value = $event)),
      label: _ctx.label,
      board: _ctx.board,
      "show-footer": _ctx.showFooter,
      manual: _ctx.manual,
      icon: _ctx.icon,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      "allow-clear": _ctx.allowClear,
      "button-wording": _ctx.buttonWording,
      "onDropdownField:Ok": onOk,
      "onDropdownField:Cancel": onCancel,
      "onDropdownField:Clear": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('SelectField:Clear', $event))),
      "onDropdownField:OutsideClick": onOk,
      "onDropdownField:Toggle": _cache[2] || (_cache[2] = ($event) => _ctx.$emit('SelectField:Toggle', $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_1, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                _createVNode(Loading, { class: "select-field__loading" })
              ]))
            : _createCommentVNode("v-if", true),
          (!_ctx.loading && _ctx.items)
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                (_unref(isDesktop) && _ctx.itemAny)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "#",
                      class: _normalizeClass(["select-field__list-item", { 'is-selected': !proxy.value, 'is-disabled': _ctx.offerCount && _ctx.totalCount === '0' }]),
                      onClick: _withModifiers(itemAnyClickHandler, ["prevent"]),
                      onKeydown: _withKeys(itemAnyClickHandler, ["enter"])
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.labelForAny), 1 /* TEXT */)
                    ], 34 /* CLASS, NEED_HYDRATION */))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true),
          (!_ctx.loading && _ctx.items)
            ? _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["select-field__list-item", { 'is-selected': proxy.value === item[_ctx.valueProp], 'is-disabled': _ctx.offerCount && !item[_ctx.countProp] }]),
                      onClick: _withModifiers(() => select(item), ["prevent"]),
                      onKeydown: _withKeys(() => select(item), ["enter"])
                    }, [
                      _createTextVNode(_toDisplayString(item[_ctx.labelProp]) + " ", 1 /* TEXT */),
                      (_ctx.offerCount && item[_ctx.countProp] !== null)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.offerCountClass)
                          }, " (" + _toDisplayString(pluralizedLabel(item[_ctx.countProp])) + ") ", 3 /* TEXT, CLASS */))
                        : _createCommentVNode("v-if", true),
                      (item.price)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, " ab " + _toDisplayString(_unref(formatPrice)(item.price.Amount, item.price.Currency, false)), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_4)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue", "label", "board", "show-footer", "manual", "icon", "placeholder", "disabled", "allow-clear", "button-wording"])
  ], 16 /* FULL_PROPS */))
}
}

})