<template>
	<transition
		name="expand"
		@enter="enter"
		@after-enter="afterEnter"
		@leave="leave"
	>
		<slot />
	</transition>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
	direction?: 'height' | 'width';
}

const props = withDefaults(defineProps<Props>(), {
	direction: 'height',
});

const isHtmlELement = (element: Element | null): element is HTMLElement => element instanceof HTMLElement;
const isHeight = computed(() => props.direction === 'height');
const maxProp = computed(() => isHeight.value ? 'maxHeight' : 'maxWidth');
const dimension = computed(() => isHeight.value ? 'scrollHeight' : 'scrollWidth');

const setElementStyle = <T extends HTMLElement>(element: T, size: string | number, overflow: string, maxPropValue: 'maxWidth' | 'maxHeight') => {
	element.style.overflow = overflow;
	element.style[maxPropValue] = String(size);
	if (isHeight.value) {
		element.offsetHeight;
	} else {
		element.offsetWidth;
	}
};

const enter = (element: Element) => {
	const size = element[dimension.value];
	if (!isHtmlELement(element)) return;
	setElementStyle(element, 0, 'hidden', maxProp.value);

	element.style[maxProp.value] = `${size + 5}px`;
};

const afterEnter = (element: Element) => {
	if (!isHtmlELement(element)) return;
	setElementStyle(element, '', '', maxProp.value);
};

const leave = (element: Element) => {
	const size = element[dimension.value];
	if (!isHtmlELement(element)) return;
	setElementStyle(element, `${size}px`, 'hidden', maxProp.value);

	element.style[maxProp.value] = '0';
};
</script>

<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
	transition: all 330ms ease-in-out;
}

.expand-enter-from,
.expand-leave-to {
	opacity: 0;
	visibility: hidden;
}
</style>