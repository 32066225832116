import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info-tooltip" }
const _hoisted_2 = ["data-show"]

import { onMounted, ref, watch } from 'vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { createPopper } from '@popperjs/core';

interface Props {
	disabled?: boolean;
	showTooltipParent?: boolean;
	placement?: 'top' | 'left' | 'right' | 'bottom';
	modifierClass?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoTooltip',
  props: {
    disabled: { type: Boolean, default: false },
    showTooltipParent: { type: Boolean, default: false },
    placement: { default: 'top' },
    modifierClass: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const icon = ref<HTMLElement>();
const tooltip = ref<HTMLElement>();
const showTooltip = ref(false);
const scrollDispatched = ref(false);

watch(showTooltip, () => {
	if (showTooltip.value && !scrollDispatched.value) {
		window.dispatchEvent(new CustomEvent('scroll'));
		scrollDispatched.value = true;
	}
});

onMounted(async () => {
	setTimeout(() => {
		if (icon.value && tooltip.value) {
			createPopper(icon.value, tooltip.value, {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 10],
						},
					},
					{
						name: 'preventOverflow',
						options: {
							boundary: document.querySelector('.list-item.is-verified'),
						},
					},
				],
				placement: props.placement,
			});
		}
	}, 30);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      ref_key: "icon",
      ref: icon,
      class: "info-tooltip__icon-container",
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (showTooltip.value = true)),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (showTooltip.value = false))
    }, [
      _renderSlot(_ctx.$slots, "custom", {}, () => [
        _createVNode(FaIcon, {
          icon: ['fa-solid', 'fa-circle-info'],
          class: "info-tooltip__icon"
        })
      ])
    ], 544 /* NEED_HYDRATION, NEED_PATCH */),
    _createElementVNode("div", {
      id: "tooltip",
      ref_key: "tooltip",
      ref: tooltip,
      class: _normalizeClass(["info-tooltip__tooltip", _ctx.modifierClass]),
      "data-show": (showTooltip.value || _ctx.showTooltipParent) && !_ctx.disabled
    }, [
      _renderSlot(_ctx.$slots, "default"),
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "info-tooltip__arrow",
        "data-popper-arrow": ""
      }, null, -1 /* HOISTED */))
    ], 10 /* CLASS, PROPS */, _hoisted_2)
  ]))
}
}

})