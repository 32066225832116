import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "filter-radio-button" }
const _hoisted_2 = ["id", "disabled", "aria-checked", "aria-label", "value", "checked"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "filter-radio-button__bold-label"
}

import { computed } from 'vue';

interface Props {
	id: string;
	value: T;
	disabled?: boolean;
	label?: string;
	boldLabel?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterRadioButton',
  props: /*@__PURE__*/_mergeModels({
    id: { default: 'default' },
    value: {},
    disabled: { type: Boolean, default: false },
    label: { default: '' },
    boldLabel: { default: undefined }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const model = _useModel<T>(__props, "modelValue");

const isChecked = computed(() => model.value === props.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: `${_ctx.id}-${_ctx.value}`,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      disabled: _ctx.disabled,
      type: "radio",
      class: "filter-radio-button__input",
      role: "radio",
      "aria-checked": isChecked.value,
      "aria-label": _ctx.label,
      value: _ctx.value,
      checked: isChecked.value
    }, null, 8 /* PROPS */, _hoisted_2), [
      [_vModelRadio, model.value]
    ]),
    _createElementVNode("span", null, [
      _createElementVNode("label", {
        class: "filter-radio-button__label",
        for: `${_ctx.id}-${_ctx.value}`
      }, [
        (_ctx.boldLabel)
          ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_ctx.boldLabel), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createTextVNode(" " + _toDisplayString(_ctx.label), 1 /* TEXT */)
      ], 8 /* PROPS */, _hoisted_3),
      _renderSlot(_ctx.$slots, "tooltip")
    ])
  ]))
}
}

})