import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-iff", "data-ga-name", "data-ga-category"]

import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import {
	computed, onBeforeUnmount, onBeforeMount, ref,
} from 'vue';
import {
	addEntryToFavoritePersistence,
	removeEntryFromFavoritePersistence,
	isFavorite as isFavoriteCookie,
} from '@modules/bookmark';
import { EventBus } from '@global-js/event-bus';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	item: ItemType;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BookmarkHotelButton',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props;

const offer = computed(() => props.item.PackageOffer || props.item.AccommodationOffer!);

const bookmarked = ref(false);

onBeforeMount(() => {
	EventBus.$on('bookmark:favoriteChange', updateBookmark);
	EventBus.$on('bookmark:hotelsUpdated', (hotels: FavoritedHotel[]) => {
		bookmarked.value = hotels.some((h) => h.IffCode === props.item.Hotel.IffCode);
	});
	checkForFavorites();
});

onBeforeUnmount((): void => {
	EventBus.$off('bookmark:favoriteChange', updateBookmark);
	EventBus.$off('bookmark:hotelsUpdated');
});

function toggleBookmark() {
	if (props.item) {
		const item = BookmarkAnalytics.fromItemType(props.item.Hotel, props.item.Location, offer.value);
		if (!bookmarked.value) {
			addEntryToFavoritePersistence(item, props.item.Hotel.Category);
		} else {
			removeEntryFromFavoritePersistence(item);
		}
	}
}

function updateBookmark(iffCode: number, favorite: boolean) {
	if (iffCode === props.item.Hotel.IffCode) {
		bookmarked.value = favorite;
	}
}

async function checkForFavorites() {
	if (props.item?.Hotel.IffCode) {
		bookmarked.value = await isFavoriteCookie(props.item.Hotel.IffCode);
	}
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    tabindex: "0",
    class: _normalizeClass(["bookmark-hotel-button", {'bookmark-hotel-button--active': bookmarked.value}]),
    "data-iff": _ctx.item.Hotel.IffCode,
    "data-ga-name": _ctx.item.Hotel.Name,
    "data-ga-category": _ctx.item.Location.Country.CountryName + ' / ' + _ctx.item.Location.Region.RegionName + ' / ' + _ctx.item.Location.City.CityName,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (toggleBookmark()), ["prevent","stop"])),
    onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent","stop"]))
  }, [
    _createVNode(BaseIcon, {
      class: "bookmark-hotel-button__heart-icon",
      name: "heart",
      viewBox: "-2 -2 32 32"
    })
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})