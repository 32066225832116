import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, unref as _unref } from "vue"

const _hoisted_1 = { class: "flight-details__segment" }
const _hoisted_2 = { class: "flight-details__info" }
const _hoisted_3 = { class: "flight-details__flight" }
const _hoisted_4 = { class: "flight-details__departure" }
const _hoisted_5 = { class: "flight-details__time flight-time" }
const _hoisted_6 = { class: "flight-city" }
const _hoisted_7 = { class: "flight-city__code" }
const _hoisted_8 = { class: "flight-details__airline" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "flight-details__arrival" }
const _hoisted_12 = { class: "flight-details__time flight-time" }
const _hoisted_13 = {
  key: 0,
  class: "update-date"
}
const _hoisted_14 = { class: "flight-city" }
const _hoisted_15 = { class: "flight-city__code" }
const _hoisted_16 = { class: "flight-details__baggage" }
const _hoisted_17 = { class: "flight-details__baggage-tooltip" }
const _hoisted_18 = {
  key: 0,
  class: "flight-details__stopover"
}
const _hoisted_19 = {
  key: 1,
  class: "flight-details__newdate"
}

import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { convertSeconds } from '@utils/utils';
import { formatDate, formatDateLong } from '@utils/dateUtils';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import type { BaggageAllowance, Juncture } from '@/interfaces/common';
import type { AirportData } from '@/interfaces/offer';
import { computed } from 'vue';
interface Props {
	segment: FlightSegment;
	isRight?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutFlightDetailsSegment',
  props: {
    segment: {},
    isRight: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const isAiportData = (aiport: Juncture | AirportData): aiport is AirportData => 'time' in aiport;

const getAllowanceText = (baggage: BaggageAllowance | undefined): string => {
	if (!baggage || !(baggage.MaxWeight || baggage.MaxBags)) {
		return 'Gepäck inklusive';
	}
	const { MaxBags, MaxWeight } = baggage;
	if (MaxBags && MaxWeight) {
		return baggage.MaxBags + ' x ' + baggage.MaxWeight + 'kg inklusive';
	}

	if (MaxWeight) {
		return '1 x ' + baggage.MaxWeight + 'kg inklusive';
	}

	return MaxBags + ' Gepäck inkl.';
};

const getTooltipText = (baggae: BaggageAllowance | undefined) => {
	if (!baggae || !baggae.MaxWeight) {
		return 'Der Veranstalter liefert uns leider keine Informationen zum Freigepäck. Dieses beträgt ca. 20kg und kann je nach Airline auch weniger oder mehr betragen.';
	}
	return 'Bitte beachten Sie, dass die Angaben zu Freigepäckmengen in Ausnahmefällen abweichen können.';
};

const updateDate = computed((): string | null => {
	if (props.segment.StopOver && props.segment.Departure.Date) {
		const departureDate = new Date(props.segment.Departure.Date).toDateString();
		const stopoverEndDate = new Date(props.segment.StopOver.StopoverEnd).toDateString();
		if (departureDate !== stopoverEndDate) {
			return formatDateLong(props.segment.StopOver.StopoverEnd);
		}
	}
	return null;
});

const getTime = (airport: Juncture | AirportData): string => {
	let time = '';
	if (isAiportData(airport)) {
		if (airport.time) {
			time = airport.time;
		}
	}

	if (!time && airport.Date) {
		time = `${formatDate(airport.Date, { hour: '2-digit', minute: '2-digit' })}`;
	}

	return time;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flight-details__timeline-start" }, null, -1 /* HOISTED */)),
            _createElementVNode("p", _hoisted_5, _toDisplayString(getTime(_ctx.segment.Departure)), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.segment.Departure.Airport) + " ", 1 /* TEXT */),
              _createElementVNode("span", _hoisted_7, " (" + _toDisplayString(_ctx.segment.Departure.AirportCode) + ") ", 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              class: _normalizeClass(["flight-details__timeline-track", { 'flight-details__timeline-track--verified': _ctx.segment.Departure.Date }])
            }, null, 2 /* CLASS */),
            (_ctx.segment.Airline)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.segment.Airline), 1 /* TEXT */),
                  (_ctx.segment.FlightNumber)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, " (" + _toDisplayString(_ctx.segment.FlightNumber) + ")", 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _cache[1] || (_cache[1] = _createTextVNode(", "))
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.segment.CabinClass), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flight-details__timeline-end" }, null, -1 /* HOISTED */)),
            _createElementVNode("p", _hoisted_12, [
              _createTextVNode(_toDisplayString(getTime(_ctx.segment.Arrival)) + " ", 1 /* TEXT */),
              (updateDate.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, " +1 Tag "))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("p", _hoisted_14, [
              _createTextVNode(_toDisplayString(_ctx.segment.Arrival.Airport) + " ", 1 /* TEXT */),
              _createElementVNode("span", _hoisted_15, " (" + _toDisplayString(_ctx.segment.Arrival.AirportCode) + ") ", 1 /* TEXT */)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", null, [
            _createVNode(FaIcon, {
              icon: ['fa-solid', 'fa-suitcase'],
              name: "briefcase",
              class: "icon"
            }),
            _cache[3] || (_cache[3] = _createTextVNode(" Freigepäck pro Person "))
          ]),
          _createElementVNode("span", _hoisted_17, [
            _createTextVNode(_toDisplayString(getAllowanceText(_ctx.segment.BaggageAllowance)) + " ", 1 /* TEXT */),
            _createVNode(InfoTooltip, { placement: "bottom" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(getTooltipText(_ctx.segment.BaggageAllowance)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ])
    ]),
    (_ctx.segment.StopOver)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_unref(convertSeconds)(_ctx.segment.StopOver.StopoverDuration)) + " Aufenthalt ", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (updateDate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(updateDate.value), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})