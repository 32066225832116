<template>
	<div class="checkout-payment-info">
		<h3
			v-if="(!shouldShowFallback && !hideHeadline)"
			class="checkout-payment-info__headline"
		>
			{{ paymentHeadline }}
		</h3>
		<div class="checkout-payment-info__content">
			<p
				v-if="depositAmount && priceInfo?.FinalPayment"
				class="checkout-payment-info__payment-due-text"
				v-html="formattedDepositText"
			></p>
			<p
				class="checkout-payment-info__payment-due-text"
				v-html="formattedFinalPaymentText"
			></p>
			<span
				v-if="finalPaymentConverted && !shouldShowFallback"
				class="checkout-payment-info__chf-info"
			>
				{{ chfInfoText }}
			</span>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { toRef } from 'vue';
import { usePaymentInfo } from '../../composables/usePaymentInfo';
import { PriceInformation, SimplePrice } from '@/interfaces/checkout/offer-data';

interface Props {
    priceInfo: PriceInformation;
    flexStornoAmount?: SimplePrice;
    startDate?: string;
    hidePaymentInfo?: string;
    hideHeadline?: boolean;
}

const paymentHeadline = 'Zahlungsmodalitäten für Zahlung der Reise:';
const chfInfoText = 'Der Vertrag wird in EURO geschlossen. Die Angabe in CHF dient lediglich Ihrer Information und ist ein Circa-Wert.';

const props = withDefaults(defineProps<Props>(), {
    hideHeadline: false
});

const priceInfoRef = toRef(props, 'priceInfo');
const flexStornoRef = toRef(props, 'flexStornoAmount');
const startDateRef = toRef(props, 'startDate');
const hidePaymentInfoRef = toRef(props, 'hidePaymentInfo');

const {
    depositAmount,
    finalPaymentConverted,
    formattedDepositText,
    formattedFinalPaymentText,
    shouldShowFallback,
} = usePaymentInfo(
    priceInfoRef,
    flexStornoRef,
    startDateRef,
    hidePaymentInfoRef
);
</script>

<style lang="scss" scoped>
.checkout-payment-info {
    &__headline {
        padding: 0;
        text-align: left;
        font-weight: bold;
        margin-bottom: 0.8rem;
        font-size: $font-small-1;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    &__payment-due-text {
        font-size: $font-small-2;
        margin-bottom: 0.3rem;

        @media (min-width: $breakpoint-large) {
            font-size: $font-small-1;
        }
    }

    &__chf-info {
        font-size: $font-small-1;
    }
}
</style>
