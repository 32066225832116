import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "fa-package-icon",
  "aria-hidden": "true"
}

import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PackageIcon',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(FaIcon, { icon: ['fa-solid', 'fa-plane-departure'] }),
    _createVNode(FaIcon, { icon: ['fa-kit', 'fa-plus-strong'] }),
    _createVNode(FaIcon, { icon: ['fa-solid', 'fa-bed'] })
  ]))
}
}

})