import { NBSP, NDASH, MINIMUM_HOTEL_RATING, RATING_MAX_VALUE } from '@global-js/constants';
import type { OfferFeatures, SingleFeature } from '@/interfaces/common';
import type OfferFlex from '@/interfaces/checkout/offer-flex';
import type { Storno } from '@/components/common/types';
import { scrollIt as scrollItLib } from '../../../../all/js/libs/scrollIt_module';
import { numberFormat } from '@/js/utils/numberUtils';

export const scrollIt = (
	htmlElement: HTMLElement | number,
	animationTime?: number,
	animationName?: string,
	offset = 0,
	callback: any = null
) => {
	// https://dev.to/natclark/checking-for-reduced-motion-preference-in-javascript-4lp9
	const noAnimation = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

	if (noAnimation) {
		scrollItLib(htmlElement, 0, animationName, offset, callback);
	} else {
		scrollItLib(htmlElement, animationTime, animationName, offset, callback);
	}
};

/**
 * @param storeState creates a deep copy of an store state obj with an initial state.
 */

export const createStoreCopy = <T extends object>(storeState: T): T => JSON.parse(JSON.stringify(storeState));
/**
 * @param store The store object to be restored(original store object).
 * @param copy deep copy for initial state from store obtained using 'createStoreCopy'.
 */

export const restoreStore = <T extends object>(store: T, copy: T): void => {
	Object.keys(store).forEach((key) => {
		store[key as keyof T] = copy[key as keyof T];
	});
};

export const groupBy = (arr: any[], key: string): any => {
	return arr.reduce(
		(acc, curr) => ({
			...acc,
			[curr[key]]: (acc[curr[key]] || []).concat(curr),
		}),
		{}
	);
};

/**
 * Converts seconds to string with hours and minutes.
 * Output will be "2 h 30min"
 * @param seconds number
 */
export const convertSeconds = (seconds: number): string => {
	if (!seconds) {
		return '';
	}

	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);

	return `${h}h ${m} min`;
};

export const isEqual = (...objects: Record<string, any>[]): boolean =>
	objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));

export const cloneDeep = <T>(target: T): T => JSON.parse(JSON.stringify(target));

// helper to get a value from a deep object

export const get = (path: string, obj: Record<string, any>): any => {
	return new Function('_', 'return _.' + path)(obj);
};

export const isPlainObject = (obj: any): boolean => {
	return Object.prototype.toString.call(obj) === '[object Object]';
};

export const deepMerge = (target: Record<string, any>, ...source: { [key: string]: any }[]): Record<string, any> => {
	return source.reduce(
		(acc, current: { [key: string]: any }) => {
			Object.keys(current).forEach((key) => {
				if (Array.isArray(acc[key]) && Array.isArray(current[key])) {
					acc[key] = acc[key]
						.concat(current[key])
						.filter((item: any, index: number, srcArray: any[]) => srcArray.indexOf(item) === index);
				} else if (isPlainObject(acc[key]) && isPlainObject(current[key])) {
					acc[key] = deepMerge(acc[key], current[key]);
				} else if ([null, undefined].indexOf(current[key]) === -1) {
					acc[key] = current[key];
				}
			});

			return { ...acc };
		},
		cloneDeep(target) as { [key: string]: any }
	);
};

export const throttle = (fn: Function, wait: number): any => {
	let isCalled = false;
	let timer: any;

	return (...args: any[]): any => {
		if (!isCalled) {
			fn(...args);
			isCalled = true;
			setTimeout(() => {
				isCalled = false;
			}, wait);
		} else {
			if (timer) {
				clearTimeout(timer);
			}

			// call event last time because it may blocked
			timer = setTimeout(() => {
				fn(...args);
			}, wait);
		}
	};
};

export const debounce = (fn: (args: any[]) => void, delay: number): any => {
	let timeoutID: null | ReturnType<typeof setTimeout> = null;

	return (...args: any[]): any => {
		if (timeoutID) {
			clearTimeout(timeoutID);
		}
		timeoutID = setTimeout(() => {
			fn(args);
		}, delay);
	};
};

type PageTypes = 'hotelPage' | 'regionPage' | 'searchLandingPage' | 'homePage' | 'regionList' | 'hotelList' | 'servicePage' | 'themePage';
export const determinePageType = (): PageTypes => {
	const bodyClassList = document?.body?.classList;
	const searchIcon = document.getElementById('rpb_header-icon-search');

	if (bodyClassList.contains('page_hotel')) return 'hotelPage';
	if (bodyClassList.contains('page_country') || bodyClassList.contains('page_region') || bodyClassList.contains('page_city')) {
		return 'regionPage';
	}
	if (bodyClassList.contains('page_search_landing')) return 'searchLandingPage';
	if (bodyClassList.contains('page_home')) return 'homePage';
	if (bodyClassList.contains('page_region_list')) return 'regionList';
	if (bodyClassList.contains('page_hotel_list')) return 'hotelList';

	// workaround - body tag for service page needed
	if (searchIcon && searchIcon.childElementCount === 0) return 'servicePage';
	// workaround - body tag for theme page needed
	return 'themePage';
};

export const getpaymentTypesUrl = (client: string): string => `https://booking.${client}.at/ibecustomer/whitelabel/img/icons`;

/**
 * Helper function for defining offerlist pages
 * Returns true if current page is offerlist
 * Add additional offerlist pages to const offerlistPages
 *
 * @return boolean
 */
export const isOfferlistPage = (): boolean => {
	const offerlistPages = ['hotelPage', 'hotelList', 'regionList', 'searchLandingPage']; // For additional offerlist pages in the future
	const offerlistPage = offerlistPages.indexOf(determinePageType()) > -1;

	return offerlistPage;
};

/**
 * Scrolling to offerlist and open specific tab
 * @param dataTarget string of the data-target attribute
 * @param contentId string of the content id
 */
export const showOfferlist = (selector: string, offset = 0, time = 500): void => {
	setTimeout(() => {
		scrollIt(document.querySelector(selector) as HTMLElement, time, 'easeOutQuad', offset);
	}, 50);
};

/**
 * Get the flux ibe base URL ("https://<domain>/") from
 * the project environment "fluxIbeUrl" variable.
 */
export const getFluxIbeUrl = (): string => {
	let fluxIbeUrl = '';
	if (!process.env.fluxIbeUrl) {
		throw new Error('fluxIbeUrl is not defined!');
	} else {
		fluxIbeUrl = process.env.fluxIbeUrl;
	}

	return fluxIbeUrl;
};

const flexCodeToStornoMap: Record<string, Storno> = {
	1: 'Cancel',
	2: 'Rebooking',
};

export const getStornoValueByFlexCode = (flexCode: string): Storno | undefined => flexCodeToStornoMap?.[flexCode];

/**
 * Get the flux image base URL ("https://<domain>/") from
 * the project environment "fluxImagesUrl" variable.
 */
export const getFluxImageUrl = (): string => {
	let fluxImagesUrl = '';
	if (!process.env.fluxImagesUrl) {
		throw new Error('fluxImagesUrl is not defined!');
	} else {
		fluxImagesUrl = process.env.fluxImagesUrl;
	}

	return fluxImagesUrl;
};

/* Get the flux api base URL ("https://<domain>/") from
 * the project environment "fluxRestApiUrl" variable.
 */
export const getFluxApiUrl = (): string => {
	let fluxRestApiUrl = '';
	if (!process.env.fluxRestApiUrl) {
		throw new Error('fluxRestApiUrl is not defined!');
	} else {
		fluxRestApiUrl = process.env.fluxRestApiUrl;
	}

	return fluxRestApiUrl;
};

export const isMinimumRating = (rating: number): boolean => rating >= MINIMUM_HOTEL_RATING;

export const ratingOverall = (rating: number): string => {
	return `${numberFormat(rating)}/${RATING_MAX_VALUE}`;
};

export const getRatingCombined = (rating: number): string => {
	return `${ratingText(rating)} – ${ratingOverall(rating)}`;
};

/**
 * Determines rating text.
 */
export const ratingText = (rating: number): string => {
	let ratingString = '';
	// eslint-disable-next-line valid-typeof
	if (typeof rating === null) {
		return '';
	}
	if (rating >= 5.4) {
		ratingString = 'Exzellent';
	} else if (rating >= 4.8) {
		ratingString = 'Sehr Gut';
	} else if (isMinimumRating(rating)) {
		ratingString = 'Gut';
	} else {
		ratingString = '';
	}

	return ratingString;
};

/**
 * Capitalizes the first letter of each word in a string with certain exceptions
 * @param {string} the text to be capitalized
 * @return {string} the capitalized text
 */
export const capitalizeText = (text: string): string | boolean => {
	let textString: string[] = [];
	const convertedText: string[] = [];
	const termExceptions = ['mit', 'ohne', 'und'];

	if (typeof text !== 'string') {
		return false;
	}
	textString = text.split(' ');
	textString.forEach(function (word) {
		let convertedWord = word.toLowerCase();

		if (convertedWord && termExceptions.indexOf(convertedWord) === -1) {
			convertedWord = convertedWord[0].toUpperCase() + convertedWord.slice(1);
		}

		convertedWord = convertedWord.replace(/[/|-]./g, function (str) {
			return str.toUpperCase();
		});

		convertedText.push(convertedWord);
	});

	return convertedText.join(' ');
};

/**
 * Capitalizes the first letter of given string without conditions
 *
 * @param {string} text The string to convert first letter to uppercase without conditions
 * @return {string} The converted string with first letter uppercase
 */
export const firstLetterUppercase = (text: string): string => {
	if (!text) {
		return '';
	}

	return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Truncates string.
 */
export const truncate = (text: string, stop: number, clamp: string): string => {
	if (text != null && typeof text === 'string') {
		return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
	}
	return text;
};

/**
 * Convert price to other currency.
 * @param {float} price The source price.
 * @param {float} conversionRate A number between 0.00 and 1.00.
 * @returns {float} Returns price or false in error case.
 */
export const convertPrice = (price: any, conversionRate: any): number | boolean => {
	const conversionRateFloat = parseFloat(conversionRate) || 1;
	const priceFloat = parseFloat(price);

	if (price === priceFloat) {
		return price / conversionRateFloat;
	}

	return false;
};

/**
 * Determines symbol for currency.
 * @param {string} currencyISO currency in ISO4217 format. (EUR, USD)
 * @return {string} Currency symbol if available or input string.
 */
export const currencyISOToSymbol = (currencyISO: string): string => {
	switch (currencyISO) {
		case 'EUR':
			return '€';
		case 'USD':
			return '$';
		default:
			return currencyISO;
	}
};

/**
 * Returns The rating string (e.g. 'Hervorragend')
 * @param {number} The rating
 * @return {string} The rating string
 */
export const determineRatingString = (rating: number): string => {
	let ratingString = '';

	if (rating <= 3) {
		ratingString = 'Akzeptabel';
	} else if (rating <= 4) {
		ratingString = 'Gut';
	} else {
		ratingString = 'Hervorragend';
	}

	return ratingString;
};

/**
 * Returns the given counts & pluralizes the label if necessary.
 * @param {string} single singular form of the label
 * @param {string} plural plural form of the label
 * @return {string} count & label
 */
export const pluralize = (count: number, single: string, plural: string): string => {
	const label = count === 1 ? single : plural;
	return count + NBSP + label;
};

/**
 * Converts and formats a number into a language sensitive string
 * @param {number} num the number to be formated/converted
 * @param {Intl.NumberFormatOptions} format style options, currency, digits,...
 * @param {string} locale locale identifier string
 */
export const formatNumber = (
	num: number,
	format: Intl.NumberFormatOptions = { style: 'currency', currency: 'EUR' },
	locale = 'de-AT'
): string => {
	const intl = new Intl.NumberFormat(locale, format);
	return intl.format(num);
};

export const getTravelDuration = (travelDuration: number[] | null): string => {
	let duration = '';
	if (travelDuration && travelDuration.length && travelDuration.length > 1) {
		duration = `${travelDuration.join(NDASH)} Tage`;
	} else if (travelDuration && travelDuration.length && travelDuration[0]) {
		duration = pluralize(travelDuration[0], 'Tag', 'Tage');
	}
	return duration;
};

/**
 * Conversts the aid to the amadeus image group
 *
 * '100099702' should be '100095000'
 * '100084929' should be '100080000'
 */
export const amadeusImageGroup = (aid: string): string => {
	const imageGroup = parseInt(aid.substr(5, 1), 10) >= 5 ? '5000' : '0000';
	return `${aid.substr(0, 5)}${imageGroup}`;
};

/**
 * Add top margin class to element if on top of page
 * @param {string} element
 */
export const addTopMarginToFirstElement = (element: HTMLElement): void => {
	if (!element || !element.classList) {
		return;
	}

	const previousElement = element.previousElementSibling;
	const className = element.classList[0] || '';

	if (previousElement?.id === 'search-form-container' && window.matchMedia('(min-width: 1300px)').matches) {
		element.classList.add(`${className}--margin-top`);
	}
};

export const pascalToKebabCase = (pascalCaseString: string): string =>
	pascalCaseString.replace(/([a-z0–9])([A-Z])/g, '$1-$2').toLowerCase();

export const extendUrlParams = (hash: string): void => {
	const currentWindowHash = window.location.href?.split('#')[1];

	if (currentWindowHash === hash) {
		return;
	}

	const pathname = window.location.pathname;
	const search = window.location.search.split('#')[0];

	window.history.replaceState(null, '', `${pathname}${search}#${hash}`);
};

export const getDirectionToElement = (elementId: string): 'down' | 'up' | '' => {
	const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
	const targetElement = document.getElementById(elementId);

	if (!targetElement) return '';

	const targetTop = targetElement.getBoundingClientRect().top + currentScrollTop;

	if (targetTop > currentScrollTop) return 'down';

	if (targetTop < currentScrollTop) return 'up';

	return '';
};

const getElementHeight = (selector: string): number => document.querySelector(selector)?.getBoundingClientRect().height ?? 0;

const getNavbarHeightAfterScrollingToElement = (targetId: string): number => {
	const scrollDirection = getDirectionToElement(targetId);

	if (!scrollDirection) return 0;

	const navbarHeight = getElementHeight('.rpb_header');
	const anchorBarHeight = getElementHeight('.internal-navigation-bar');

	// Case mobile
	return scrollDirection === 'up' ? navbarHeight + anchorBarHeight : anchorBarHeight;
};

const getHashFromEvent = (event: MouseEvent): string => {
	const href = (event.target as HTMLElement).closest('a')?.getAttribute('href');

	if (!href) return '';

	return href.split('#')[1];
};

export const scrollToHash = (hash: string, scrollTime = 1000, offset = 200, callback: null | (() => void) = null) => {
	extendUrlParams(hash);
	setTimeout(() => {
		const scrollToSelector = document.querySelector(`#${hash}`) as HTMLElement;
		if (scrollToSelector) {
			scrollIt(scrollToSelector, scrollTime, 'easeOutQuad', offset, callback);
		}
	}, 0);
};

export const scrollToAnchor = (event: MouseEvent, scrollTime = 1000, offset = 200) => {
	const hash = getHashFromEvent(event);
	if (hash) {
		scrollToHash(hash, scrollTime, offset);
	}
};

export const scrollToHashWithDynamicOffset = (hash: string, callback?: () => void): void => {
	const navBarHeightAfterScrolling = getNavbarHeightAfterScrollingToElement(hash);
	scrollToHash(hash, undefined, navBarHeightAfterScrolling, callback);
};

export const scrollToAnchorWithDynamicOffset = (event: MouseEvent, callback?: () => void): void => {
	const targetId = getHashFromEvent(event);
	scrollToHashWithDynamicOffset(targetId, callback);
};

export const convertFeaturesIntoArray = (
	offerFeatures?: OfferFeatures,
	flexStorno?: OfferFlex | null,
	isFlexChecked: boolean = false,
	isThankYouPage = false
): Partial<SingleFeature>[] => {
	const result = [];

	if (offerFeatures && offerFeatures.FreeCancellation) {
		result.push({
			title: 'Flexible Stornierung',
			description: offerFeatures.FreeCancellation,
		});
	}
	if (offerFeatures && offerFeatures.PaidCancellation) {
		result.push({
			title: 'Flexible Stornierung',
			description: offerFeatures.PaidCancellation,
		});
	}
	if (offerFeatures && offerFeatures.FreeRebooking) {
		result.push({
			title: 'Flexible Umbuchung',
			description: offerFeatures.FreeRebooking,
		});
	}

	if (offerFeatures && offerFeatures.OptionalFlexRate) {
		result.push({
			title: isFlexChecked || isThankYouPage ? 'Flexible Stornierung zugebucht' : 'Flexible Stornierung zubuchbar',
			description: flexStorno?.FlexRate.Description || offerFeatures.OptionalFlexRate,
		});
	}

	return result;
};

export const reportErrorToSentry = (error: any, contexts: { name: string; data: any }[]) => {
	if (window.Sentry && window.Sentry.captureException) {
		contexts.forEach(({ name, data }) => {
			window.Sentry.setContext(name, data);
		});

		window.Sentry.captureException(error);
	}
};

/**
 *  Use this function to add information readable in sentry
 * @param message helps to find the location of the error in the code
 * @param error what error happened
 * @param logParams additional information added to the error to reproduce it easier
 */
export function logError(
	message: string,
	error: unknown,
	...logParams: {
		key: string;
		value: unknown;
	}[]
): void {
	const errorMessage = error instanceof Error ? error.message : String(error);

	const logData = logParams.reduce(
		(acc, { key, value }) => {
			acc[key] = JSON.stringify(value);
			return acc;
		},
		{} as Record<string, unknown>
	);

	console.error(message, ',Error: ' + errorMessage, logData);
}

/**
 *  Use this function to throw an error related to an api response
 * @param message helps to find the location of the error in the code
 * @param error what error happened
 * @param request request object
 * @param response response object
 */
export function logErrorResponse(message: string, error: unknown, request: unknown, response: unknown): void {
	logError(message, error, { key: 'request', value: request }, { key: 'response', value: response });
}
