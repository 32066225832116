import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "aria-label", "aria-description"]
const _hoisted_3 = ["src", "alt"]

import { computed } from 'vue';
import { getBaseUrl } from '@utils/environmentUtils';
import type { RadioButtonCommonProps } from '@/interfaces/components/inputs';
import type { CardPaymentProviders } from '@/interfaces/checkout/checkoutTypes';
import { PAYMENT_TYPES } from '@global-js/constants';

type Props = {
	value: CardPaymentProviders,
	label: RadioButtonCommonProps['label'],
	selected?: RadioButtonCommonProps['selected'],
};

export default /*@__PURE__*/_defineComponent({
  __name: 'CreditCardRadioButton',
  props: {
    value: {},
    label: {},
    selected: { type: Boolean }
  },
  emits: ["CreditRadio:Change"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const onChange = (event: Event) => {
	emit('CreditRadio:Change', (event?.target as HTMLInputElement).value as CardPaymentProviders);
};

const logoName = computed<string>(() => {
	const cardValue = props.value as CardPaymentProviders;
	const cardTypeMap: Record<CardPaymentProviders, string> = {
		AX: 'amex',
		MC: 'mc',
		VI: 'visa',
	};
	return cardTypeMap[cardValue] || '';
});

const logoUrl = computed<string>(() => `${getBaseUrl()}/fileadmin/2/restplatzboerse/all/img/payment/${logoName.value}.svg`);

const logoAlt = computed<string>(() => PAYMENT_TYPES[props.value].label || 'Kreditkarte');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.value,
    class: _normalizeClass(["credit-card-radio-button", {'selected': _ctx.selected }])
  }, [
    _createElementVNode("input", {
      id: _ctx.value,
      type: "radio",
      class: "credit-card-radio-button__input",
      value: _ctx.value,
      name: "credit-card-radio-input",
      "aria-label": `Zahlungsmethode: ${_ctx.label}`,
      "aria-description": `Wählen Sie ${_ctx.label}, um diese Zahlungsmethode mit der Karte ${logoAlt.value} zu verwenden.`,
      onChange: onChange
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(["credit-card-radio-button__label", {'selected': _ctx.selected }])
    }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(["credit-card-radio-button__logo", {'selected': _ctx.selected }])
    }, [
      _createElementVNode("img", {
        src: logoUrl.value,
        alt: logoAlt.value
      }, null, 8 /* PROPS */, _hoisted_3)
    ], 2 /* CLASS */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})