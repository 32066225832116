<template>
	<div
		class="filter-container"
		:class="{ 'filter-container--with-divider': withDivider }"
	>
		<FilterHeader
			v-if="title"
			:title="title"
			:icon="icon"
			:delete-button-string="deleteButtonString"
			:is-checked="isChecked"
			@delete="handleDelete"
		/>
		<slot />
	</div>
</template>

<script setup lang="ts">
import FilterHeader from '@lmt-rpb/FilterHeader/FilterHeader.vue';
import type { FontAwesomeIcon } from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';

type Props = {
	title?: string;
	withDivider?: boolean;
	icon?: FontAwesomeIcon;
	isChecked?: boolean;
	deleteButtonString?: string;
};

const props = withDefaults(defineProps<Props>(), {
	title: undefined,
	withDivider: true,
	icon: undefined,
	isChecked: false,
	deleteButtonString: '',
});

const store = useStore();

const resetInput = (value: string): void => {
	store.dispatch('resetItem', value);

	if (props.deleteButtonString === 'directFlight') {
		store.dispatch('resetItem', 'flight');
	}
};

const handleDelete = (value: string): void => {
	resetInput(value);
	EventBus.$emit('SearchMask:MobileSubmit');
};
</script>

<style scoped lang="scss">
.filter-container {
	margin-top: 2.4rem;
	width: 100%;
	padding-inline: 1.6rem;

	&--with-divider {
		border-top: 0.1rem solid $filter-border-color;
		padding-block: 2.4rem 0;
	}

	&__header {
		text-align: left;
		color: $color-text;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		cursor: pointer;
		margin-top: 0;
		padding-top: 0;
		padding-bottom: 2.4rem;
	}

	&[delete-value] {
		.filter-header {
			margin-bottom: 1.6rem;
		}
}
}

@media (min-width: $breakpoint-extralarge) {
	.filter-container {
		margin-top: 0;
		padding-inline: 0;

		&--with-divider {
			margin-top: 1.6rem;
			padding-top: 1.6rem;
		}

		&__header {
			font-size: $font-medium-2;
			padding-bottom: 1.6rem;
		}
	}
}
</style>
