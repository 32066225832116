import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flight-data" }
const _hoisted_2 = { class: "flight-data__title" }
const _hoisted_3 = { class: "flight-data__departure-date" }
const _hoisted_4 = { class: "flight-data__time-row" }
const _hoisted_5 = { class: "flight-data__time" }
const _hoisted_6 = { class: "flight-data__time" }
const _hoisted_7 = { class: "flight-data__airport-row" }
const _hoisted_8 = { class: "flight-data__departure-airport" }
const _hoisted_9 = { class: "flight-data__airport-code" }
const _hoisted_10 = { class: "flight-data__stops" }
const _hoisted_11 = { class: "flight-data__arrival-airport" }
const _hoisted_12 = { class: "flight-data__airport-code" }

import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import { pluralize } from '@/js/utils/utils';
import { formatDate, dateDiff } from '@utils/dateUtils';
import { NDASH } from '@global-js/constants';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed, ref } from 'vue';

type Props = {
	flightSegment: FlightSegment[];
	outbound: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FlightData',
  props: {
    flightSegment: {},
    outbound: { type: Boolean }
  },
  setup(__props: any) {

const formatTime = (date: string | Date | undefined) =>
	date ? formatDate(date, { hour: '2-digit', minute: '2-digit' }) : `--${NDASH}-- Uhr`;
const formatDateLong = (date: string | Date | undefined) =>
	date
		? formatDate(date, {
				weekday: 'short',
				month: '2-digit',
				day: '2-digit',
				year: '2-digit',
			}).replace(',', '')
		: '.';

const props = __props;

const stopsCount = ref(props.flightSegment.length - 1);

const firstFlightSegment = ref(props.flightSegment[0]);

const lastFlightSegment = ref(props.flightSegment[stopsCount.value]);

const firstFlightSegmentDeparture = ref(firstFlightSegment.value.Departure.Date);

const lastFlightSegmentArrival = ref(lastFlightSegment.value.Arrival.Date);

const stopsLabel = ref(stopsCount.value > 0 ? pluralize(stopsCount.value, 'Stop', 'Stops') : 'Direkt');

const title = ref(props.outbound ? 'Hinflug' : 'Rückflug');

const flightDates = computed(() => {
	// if only one value is set fill it with this value
	let result = formatDateLong(firstFlightSegmentDeparture.value || lastFlightSegmentArrival.value);
	if (firstFlightSegmentDeparture.value && lastFlightSegmentArrival.value) {
		const datediff = dateDiff(firstFlightSegmentDeparture.value, lastFlightSegmentArrival.value!);
		if (datediff > 0) {
			result += ` ${NDASH} ${formatDateLong(lastFlightSegmentArrival.value)}`;
		}
	}
	return result;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(title.value), 1 /* TEXT */),
    _createElementVNode("p", _hoisted_3, _toDisplayString(flightDates.value), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(formatTime(firstFlightSegmentDeparture.value)), 1 /* TEXT */),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flight-data__divider" }, null, -1 /* HOISTED */)),
      _createVNode(BaseIcon, {
        class: _normalizeClass(["flight-data__plane", { 'flight-data__plane--outbound': !_ctx.outbound }]),
        name: "planeNew"
      }, null, 8 /* PROPS */, ["class"]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flight-data__divider" }, null, -1 /* HOISTED */)),
      _createElementVNode("p", _hoisted_6, _toDisplayString(formatTime(lastFlightSegmentArrival.value)), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, [
        _createTextVNode(_toDisplayString(firstFlightSegment.value.Departure.Airport) + " ", 1 /* TEXT */),
        _createElementVNode("span", _hoisted_9, "(" + _toDisplayString(firstFlightSegment.value.Departure.AirportCode) + ")", 1 /* TEXT */)
      ]),
      _createElementVNode("p", _hoisted_10, _toDisplayString(stopsLabel.value), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_11, [
        _createTextVNode(_toDisplayString(lastFlightSegment.value.Arrival.Airport) + " ", 1 /* TEXT */),
        _createElementVNode("span", _hoisted_12, "(" + _toDisplayString(lastFlightSegment.value.Arrival.AirportCode) + ")", 1 /* TEXT */)
      ])
    ])
  ]))
}
}

})