import AnalyticsEvent from './AnalyticsEvent';
import type GaItemList from './GaItemList';

export default class FilterAnalytics extends AnalyticsEvent<Pick<GaItemList, 'content_type' | 'content_id'>> {
	static readonly CONTENT_TYPE = 'ux_filter';

	fireFilterSelected(): void {
		super.fireGoogleAnalyticsEventOrAddListener('select_content');
	}

	static createInstance(contentId: string) {
		return new FilterAnalytics({
			content_type: FilterAnalytics.CONTENT_TYPE,
			content_id: contentId,
		});
	}
}
