export const hotelCategory = [
	{ label: 'Beliebig', value: '0' },
	{ label: 'mind. 1 Stern', value: '1' },
	{ label: 'mind. 2 Sterne', value: '2' },
	{ label: 'mind. 3 Sterne', value: '3' },
	{ label: 'mind. 4 Sterne', value: '4' },
	{ label: '5 Sterne', value: '5' },
] as const;

export const hotelCategoryCom = [
	{ label: 'Beliebig', value: '0' },
	{ label: 'mind. 1 Sonne', value: '1' },
	{ label: 'mind. 2 Sonnen', value: '2' },
	{ label: 'mind. 3 Sonnen', value: '3' },
	{ label: 'mind. 4 Sonnen', value: '4' },
	{ label: '5 Sonnen', value: '5' },
] as const;
