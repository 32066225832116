<template>
	<menu class="show-more-container__list">
		<slot name="visibleContent"></slot>
		<TransitionExpand :show-content="showMore">
			<slot name="hiddenContent"></slot>
		</TransitionExpand>
	</menu>
	<button
		class="show-more-container__show-more"
		@click="handleShowMore"
	>
		{{ buttonText }}
	</button>
</template>

<script setup lang="ts">
import TransitionExpand from '@lmt-rpb/TransitionExpand/TransitionExpand.vue';
import { ref, computed } from 'vue';

type Props = {
	itemCount: number;
};

const props = defineProps<Props>();
const showMore = ref(false);
const handleShowMore = () => {
	showMore.value = !showMore.value;
};
const buttonText = computed(() => (showMore.value ? 'Weniger anzeigen' : `Alle ${props.itemCount} anzeigen`));
</script>

<style lang="scss" scoped>
$content-left-margin: 0.8rem;

.show-more-container__list {
	margin: 0;
	list-style: none;
	padding: 0;
	margin-left: $content-left-margin;

	@media (max-width: $breakpoint-extralarge) {
		margin-bottom: 1rem;
	}
}

.show-more-container__show-more {
	box-shadow: none;
	border: none;
	background: none;
	appearance: none;
	color: $color-show-more;
	font-weight: $font-weight-semibold;
	margin: 0;
	margin-bottom: 0;
	margin-left: $content-left-margin;
	padding-left: 0;

	@media (max-width: $breakpoint-extralarge) {
		font-size: $font-small-2;
		margin-left: 0;
		margin-top: 0;
		padding-left: 0.7rem;
	}
}
</style>
