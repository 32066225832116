import type { AirportData, FlightData, MergedOfferItemData, UnverifiedFlight, VerifiedFlightSegment } from '@interfaces/offer';
import { cloneDeep } from '@utils/utils';
import { dateDiff, offsetDate } from '@utils/dateUtils';
import { TRAVEL_DURATION_EXACT, timeZoneOffsetInHours } from '@global-js/constants';
import type { SearchFormDataType } from '@interfaces/search-form';
import {
	mostPopularTypes,
	additionalCheckboxFilterTypes,
	boardTypes as boardItemTypes,
	roomTypes as roomItemTypes,
	transferTypes as transferItemTypes,
} from '@/js/data/index';
import type { AdditionalCheckboxFilters, MostPopularFilters } from '@/interfaces/checkbox-filter-types';
import { viewTypeValues } from '@/js/data/view-types';
import type { UrlQueryParams } from '@/interfaces/urlQueryParams';
import { filterDuplicates } from '@/js/utils/objectUtils';
import { dashedDate } from '@/js/utils/dateUtils';
import type { EnabledParams } from './url';

type Objectish = { [key: string]: unknown };
type DataType = { id: number; value: string; label: string };
type ParamValue = string | number | string[] | number[];

export const mapper = <T extends DataType>(val: ParamValue, lookup: readonly T[], filterCallback = (_: T) => true) => {
	if (Array.isArray(val)) {
		return val.map((prop) => {
			const item = lookup
				.filter((a) => filterCallback(a))
				.find((type: DataType) => (typeof prop === 'string' ? type.value === prop : type.id === prop));
			return typeof prop === 'string' ? item?.id : item?.value;
		});
	}
	const item = lookup.find((type) => type.id === val);
	return item?.value;
};

const getMostPopularFilters = (
	ratingAttributeValues: number[],
	beachValues: number[],
	targetGroupValues: number[],
	hotelAttributeValues: number[]
): MostPopularFilters => {
	const ratingAttributes: string[] = [];
	const hotelAttributes: string[] = [];

	mostPopularTypes.forEach((type) => {
		if (type.attrType === 'HotelAttributes' && type.paramKey === 'beach' && beachValues && beachValues.includes(type.id)) {
			hotelAttributes.push(type.value);
		}
		if (type.attrType === 'HotelAttributes' && type.paramKey === 'trgrp' && targetGroupValues && targetGroupValues.includes(type.id)) {
			hotelAttributes.push(type.value);
		}
		if (
			type.attrType === 'RatingAttributes' &&
			type.paramKey === 'raatt' &&
			ratingAttributeValues &&
			ratingAttributeValues.includes(type.id)
		) {
			ratingAttributes.push(type.value);
		}
		if (
			type.attrType === 'HotelAttributes' &&
			type.paramKey === 'hotat' &&
			hotelAttributeValues &&
			hotelAttributeValues.includes(type.id)
		) {
			hotelAttributes.push(type.value);
		}
	});
	return { ratingAttributes, hotelAttributes };
};

const getAdditionalCheckboxFilters = (ratingAttributeValues: number[]): AdditionalCheckboxFilters => {
	const ratingAttributes: string[] = [];

	additionalCheckboxFilterTypes.forEach((type) => {
		if (type.attrType === 'RatingAttributes' && ratingAttributeValues && ratingAttributeValues.includes(type.id)) {
			ratingAttributes.push(type.value);
		}
	});
	return { ratingAttributes };
};

export const typeFormat = (data: any, type = ''): any =>
	(
		({
			number: Number(data),
			array: Array.isArray(data) ? data : data.split(','),
			bool: !!Number(data),
			date: new Date(data).setUTCHours(timeZoneOffsetInHours, 0, 0, 0), // set hour to 11 to prevent timezone error
		}) as Objectish
	)[type] || data;

export const objectToQuery = (params: any): string => {
	const qs = new URLSearchParams(params);
	if (qs.toString() === '%5Bobject+Object%5D=') return ''; // Fix for older browsers to keep the url clean instead of showing [object: Object]
	return decodeURIComponent(qs.toString());
};

/**
 * Adds relative days in the form of 'n_days' to current date
 * and returns ISO date string in the form of YYYY-MM-DD
 *
 * @param {string} relativeDate
 * @returns {string}
 */
const relativeToAbsoluteDate = (relativeDate: string): string => {
	const dayOffset = Number(relativeDate.split('_')[0]);
	const newDate = offsetDate(Date.now(), dayOffset);
	return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];
};

export const isValidParam = (paramKey: EnabledParams | undefined, paramValue: string | undefined): boolean => {
	if (!paramValue) return false;

	const currentYear = new Date().getFullYear();
	const mmDdRegexString = '[0-9]{2}-[0-9]{2}';
	const yyyyMmDdRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
	const dynamicYearRegex = new RegExp(
		`(^${currentYear}-` +
			mmDdRegexString +
			'$)|' +
			`(^${currentYear + 1}-` +
			mmDdRegexString +
			'$)|' +
			`(^${currentYear + 2}-` +
			mmDdRegexString +
			'$)'
	);
	const relativeDateRegex = /^[0-9]{1,3}_days$/;

	const enabledParams: Partial<Record<EnabledParams, RegExp>> = {
		ddate: new RegExp(dynamicYearRegex.source + '|' + relativeDateRegex.source),
		rdate: new RegExp(dynamicYearRegex.source + '|' + relativeDateRegex.source),
		adult: /^[1-4]{1}$/,
		child: /^(?:-?([1-9]|1[0-7])){1,3}?$/,
		board: /^[1-5]$/,
		room: /^(?:[0-9]{1,2},)*([0-9]{1,2})$/,
		aid: /^[0-9]{1,7}$/,
		depap: /^(?:[A-Z]{3},?)+$/,
		dur: /^(?:,?([1-9]|1[0-9]|2[0-8])){1,2}?$|^exact$/,
		dfl: /^1$/,
		sea: /^1$/,
		ibe: /^package$|^hotel$/,
		filter: yyyyMmDdRegex,
		trans: /^[1-3]$/,
		srtHot: /^101$|^5$/,
		stars: /^[1-6]$/,
		rarec: /^[0-9]{2}$/,
		price: /^[0-9]{3,4}$/,
		trgrp: /^(?:,?\d\d?\d?)+$/,
		raatt: /^(?:,?\d\d?\d?)+$/,
		beach: /^(?:,?\d\d?\d?)+$/,
		hotat: /^(?:,?\d\d?\d?)+$/,
		brand: /^(?:,?[A-Z0-9]{2,5})+$/,
		rid: /^[0-9]{1,7}$/,
		cyid: /^[0-9]{1,7}$/,
		cancel: /^[1-2]$/,
		deal: /^1$/,
	};

	if (paramValue !== undefined && paramKey !== undefined) {
		if (!paramValue.match(enabledParams[paramKey])) {
			return false;
		}
	}
	return true;
};

const isValidFlightTime = (flightTime?: string): boolean => {
	if (!flightTime) return false;
	const parsedTime = Number.parseInt(flightTime, 10);
	if (Number.isNaN(parsedTime)) {
		return false;
	}
	return parsedTime >= 0 && parsedTime <= 24;
};

export const paramsToForm = (params: Partial<UrlQueryParams>): Partial<SearchFormDataType> => {
	const {
		aid,
		depap,
		cancel,
		ibe,
		dur,
		ddate,
		rdate,
		adult,
		child,
		board,
		room,
		dfl,
		sea,
		filter,
		coname,
		trname,
		cyname,
		hotelname,
		trans,
		srtHot,
		stars,
		rarec,
		price,
		trgrp,
		raatt,
		beach,
		hotat,
		brand,
		rid,
		cyid,
		departureFlightTimeFrom,
		departureFlightTimeUntil,
		returnFlightTimeFrom,
		returnFlightTimeUntil,
		deal,
	} = params;

	const hotelId = isValidParam('aid', aid) ? typeFormat(aid, 'number') : undefined;
	const flex = isValidParam('cancel', cancel) ? typeFormat(cancel, 'string') : undefined;
	const onlyDeals = isValidParam('deal', deal) ? typeFormat(deal, 'number') : undefined;
	const departure = isValidParam('depap', depap) ? typeFormat(depap, 'array') : undefined;
	const onlyHotel = isValidParam('ibe', ibe) ? ibe === 'hotel' : undefined;
	const boardVals = isValidParam('board', board) ? typeFormat(board, 'array').map(Number) : undefined;
	const roomVals = isValidParam('room', room) ? typeFormat(room, 'array').map(Number) : undefined;
	const transferVals = isValidParam('trans', trans) ? typeFormat(trans, 'array').map(Number) : undefined;
	const directFlight = isValidParam('dfl', dfl) ? typeFormat(dfl, 'bool') : undefined;
	const viewVals = isValidParam('sea', sea) ? typeFormat(sea, 'array').map(Number) : undefined;
	const from = isValidParam('ddate', ddate)
		? typeFormat(ddate!.includes('_days') ? relativeToAbsoluteDate(ddate!) : ddate, 'date')
		: undefined;
	const to = isValidParam('rdate', rdate)
		? typeFormat(rdate!.includes('_days') ? relativeToAbsoluteDate(rdate!) : rdate, 'date')
		: undefined;
	const relativeFrom = isValidParam('ddate', ddate) && ddate!.includes('_days') ? ddate : '';
	const relativeTo = isValidParam('rdate', rdate) && rdate!.includes('_days') ? rdate : '';

	const travelDuration = travelDurationParamToForm(dur, from, to);

	const isTravelDurationExactSelected = dur === 'exact';

	const adults = isValidParam('adult', adult) ? typeFormat(adult, 'number') : undefined;

	const childTransform = child && child.includes(',') ? child.replaceAll(',', '-') : child; // legacy fallback
	const children = isValidParam('child', childTransform) ? childTransform.split('-').map(Number) : undefined;
	const hotelAttributeVals = isValidParam('hotat', hotat) ? typeFormat(hotat, 'array').map(Number) : undefined;
	const ratingAttributeVals = isValidParam('raatt', raatt) ? typeFormat(raatt, 'array').map(Number) : undefined;
	const targetGroupVals = isValidParam('trgrp', trgrp) ? typeFormat(trgrp, 'array').map(Number) : undefined;
	const beachVals = isValidParam('beach', beach) ? typeFormat(beach, 'array').map(Number) : undefined;
	const operatorTypes = isValidParam('brand', brand) ? typeFormat(brand, 'array') : undefined;
	const regionId = isValidParam('rid', rid) ? typeFormat(rid, 'array').map(Number) : undefined;
	const cityId = isValidParam('cyid', cyid) ? typeFormat(cyid, 'array').map(Number) : undefined;

	const boardTypes = mapper(boardVals, boardItemTypes, (obj) => obj.showInMenu);
	const roomTypes = mapper(roomVals, roomItemTypes);
	const roomViews = viewVals?.includes(1) ? viewTypeValues : [];
	const transferTypes = mapper(transferVals, transferItemTypes);
	const sortingPricePopular = isValidParam('srtHot', srtHot) ? srtHot === '101' : undefined;
	const hotelCategory = isValidParam('stars', stars) ? typeFormat(stars, 'number') : undefined;
	const minMeanRecommendationRate = isValidParam('rarec', rarec) ? typeFormat(rarec, 'number') : undefined;
	const maxPrice = isValidParam('price', price) ? typeFormat(price, 'number') : undefined;
	const mostPopularFilters = getMostPopularFilters(ratingAttributeVals, beachVals, targetGroupVals, hotelAttributeVals);
	const additionalCheckboxFilters = getAdditionalCheckboxFilters(ratingAttributeVals);
	const destinationTypes = cityId && cityId.length > 0 ? cityId : regionId;

	const minDepartureTime = isValidFlightTime(departureFlightTimeFrom) ? typeFormat(departureFlightTimeFrom, 'number') : undefined;
	const maxDepartureTime = isValidFlightTime(departureFlightTimeUntil) ? typeFormat(departureFlightTimeUntil, 'number') : undefined;
	const minArrivalTime = isValidFlightTime(returnFlightTimeFrom) ? typeFormat(returnFlightTimeFrom, 'number') : undefined;
	const maxArrivalTime = isValidFlightTime(returnFlightTimeUntil) ? typeFormat(returnFlightTimeUntil, 'number') : undefined;

	let dest = '';
	if (coname) {
		dest = coname;
	} else if (trname) {
		dest = trname;
	} else if (cyname) {
		dest = cyname;
	} else if (hotelname) {
		dest = hotelname;
	}

	const formData: Partial<SearchFormDataType> = {
		hotelId,
		departure,
		onlyHotel,
		travelDuration,
		boardTypes,
		roomTypes,
		transferTypes,
		directFlight,
		roomViews,
		filter,
		sortingPricePopular,
		hotelCategory,
		minMeanRecommendationRate,
		maxPrice,
		mostPopularFilters,
		additionalCheckboxFilters,
		operatorTypes,
		rid: regionId,
		cyid: cityId,
		flex,
		deal: Boolean(onlyDeals),
		destinationTypes,
		offerDuration: { from, to },
		offerDurationRelative: { relativeFrom, relativeTo },
		travelers: { adult: adults, children },
		destination: { label: dest },
		isTravelDurationExactSelected,
		minDepartureTime,
		maxDepartureTime,
		minArrivalTime,
		maxArrivalTime,
	};

	return JSON.parse(JSON.stringify(formData)) as Partial<SearchFormDataType>;
};

const getParamString = (setFilters: string[]): Record<string, string> => {
	const params = {
		hotat: '',
		raatt: '',
		beach: '',
		trgrp: '',
	};
	const possibleFilters = [...mostPopularTypes, ...additionalCheckboxFilterTypes];

	setFilters.forEach((setFilter) => {
		const foundFilter = possibleFilters.find((possibleFilter) => possibleFilter.value === setFilter);
		if (foundFilter) {
			params[foundFilter.paramKey as 'hotat' | 'raatt' | 'beach' | 'trgrp'] += `${foundFilter.id},`;
		}
	});
	return params;
};
type FlightParams = { minArrivalTime?: number; maxArrivalTime?: number; minDepartureTime?: number; maxDepartureTime?: number };
const flightParams: Array<keyof FlightParams> = ['minArrivalTime', 'maxArrivalTime', 'minDepartureTime', 'maxDepartureTime'];
export const needFlightParams = function (data: FlightParams) {
	return (
		flightParams.every((param) => data[param] !== undefined) &&
		(data.minArrivalTime! > 0 || data.minDepartureTime! > 0 || data.maxDepartureTime! < 24 || data.maxArrivalTime! < 24)
	);
};

export const formToParams = (data: { [key: string]: any }, component: string): Record<string, any> => {
	const setFilters = [];

	if (data.mostPopularFilters.ratingAttributes) {
		setFilters.push(...data.mostPopularFilters.ratingAttributes);
	}
	if (data.mostPopularFilters.hotelAttributes) {
		setFilters.push(...data.mostPopularFilters.hotelAttributes);
	}
	if (data.additionalCheckboxFilters.ratingAttributes) {
		setFilters.push(...data.additionalCheckboxFilters.ratingAttributes);
	}

	const paramStrings = getParamString(setFilters);

	// If the offerDuration params were relative & the calendar input wasn't changed by
	// the user we keep on using the relative dates in the url.
	let offerDurationFrom;
	if (data.offerDurationRelative?.relativeFrom) {
		offerDurationFrom = { ddate: data.offerDurationRelative.relativeFrom };
	} else if (data.offerDuration.from) {
		offerDurationFrom = { ddate: dashedDate(data.offerDuration.from) };
	} else {
		offerDurationFrom = {};
	}

	let offerDurationTo;
	if (data.offerDurationRelative?.relativeTo) {
		offerDurationTo = { rdate: data.offerDurationRelative.relativeTo };
	} else if (data.offerDuration.to) {
		offerDurationTo = { rdate: dashedDate(data.offerDuration.to) };
	} else {
		offerDurationTo = {};
	}

	const dur = data.isTravelDurationExactSelected ? TRAVEL_DURATION_EXACT : data.travelDuration;
	const params = {
		ibe: data.onlyHotel ? 'hotel' : 'package',
		adult: data?.travelers?.adult,
		// TODO: REMOVE component === 'offerList' evaluation. Probably?
		...(data.directFlight ? { dfl: 1 } : {}),
		...(data.roomViews && data.roomViews.length > 0 ? { sea: 1 } : {}),
		...(data.hotelId && component === 'offerList' ? { aid: data.hotelId } : {}),
		...(data.boardTypes?.length ? { board: mapper(data.boardTypes, boardItemTypes, (obj) => obj.showInMenu) } : {}),

		...(data.roomTypes?.length ? { room: mapper(data.roomTypes, roomItemTypes) } : {}),
		...(data.transferTypes?.length ? { trans: mapper(data.transferTypes, transferItemTypes) } : {}),
		...(data.filter && component === 'offerList' ? { filter: dashedDate(data.filter) } : {}),
		...(data.departure && data.departure?.length ? { depap: data.departure } : {}),
		...(dur ? { dur } : {}),
		...offerDurationFrom,
		...offerDurationTo,
		...(data.travelers?.children?.length ? { child: data.travelers.children.join('-') } : {}),
		srtHot: data.sortingPricePopular ? '101' : '5',
		...(data.flex ? { cancel: data.flex } : {}),
		...(data.deal ? { deal: 1 } : {}),
		...(data.hotelCategory ? { stars: data.hotelCategory } : {}),
		...(data.minMeanRecommendationRate ? { rarec: data.minMeanRecommendationRate } : {}),
		...(data.maxPrice && data.maxPrice !== 1600 ? { price: data.maxPrice } : {}),
		...(paramStrings.raatt.length ? { raatt: paramStrings.raatt.slice(0, -1) } : {}),
		...(paramStrings.hotat.length ? { hotat: paramStrings.hotat.slice(0, -1) } : {}),
		...(paramStrings.beach.length ? { beach: paramStrings.beach.slice(0, -1) } : {}),
		...(paramStrings.trgrp.length ? { trgrp: paramStrings.trgrp.slice(0, -1) } : {}),
		...(data.operatorTypes && data.operatorTypes.length ? { brand: data.operatorTypes } : {}),
		...(data.rid && data.rid.length ? { rid: data.rid } : {}),
		...(data.cyid && data.cyid.length ? { cyid: data.cyid } : {}),
		...(needFlightParams(data) ? { departureFlightTimeFrom: data.minDepartureTime } : {}),
		...(needFlightParams(data) ? { departureFlightTimeUntil: data.maxDepartureTime } : {}),
		...(needFlightParams(data) ? { returnFlightTimeFrom: data.minArrivalTime } : {}),
		...(needFlightParams(data) ? { returnFlightTimeUntil: data.maxArrivalTime } : {}),
	};
	return JSON.parse(JSON.stringify(params));
};

export const offerItemDecor = (item: any, searchType: 'hotel' | 'package'): MergedOfferItemData => {
	const formatDate = (unformatted: string) => {
		const [dateString, hours] = unformatted.split(' ');
		const date = new Date(dateString);

		if (hours) {
			const [hour, mins] = hours.split(':').map(Number);
			date.setHours(hour, mins);
		}

		return date;
	};

	const formatTime = (unformatted: string | Date) => {
		if (typeof unformatted === 'object') return '';
		let [_, time] = unformatted.split(' ');
		if (!time) {
			[_, time] = unformatted.split('T');
			if (time) {
				const [hours, mins] = time.split(':');
				time = `${hours}:${mins}`;
			}
		}
		return time;
	};

	const updatedOfferItem: MergedOfferItemData = {
		...cloneDeep(item),
		type: searchType,
		available: null,
		availableChecked: false,
		loading: false,
		duration: searchType === 'hotel' ? item.OvernightStays : item.BetweenDeparturesDuration,
	};

	(['OutboundFlight', 'InboundFlight'] as (keyof MergedOfferItemData)[]).forEach((flightType) => {
		const flight = updatedOfferItem[flightType] as UnverifiedFlight | undefined;
		if (flight) {
			(['Departure', 'Arrival'] as (keyof FlightData)[]).forEach((type) => {
				const data: AirportData = flight[type];
				data.Date = formatDate(item[flightType][type].Date);
				// yes twice 'Date' seems to be correct
				data.time = formatTime(item[flightType][type].Date);
			});
		}
	});

	(['OutboundFlightSegments', 'InboundFlightSegments'] as (keyof MergedOfferItemData)[]).forEach((segmentType) => {
		const flightSegment = updatedOfferItem[segmentType] as VerifiedFlightSegment[] | undefined;
		if (item[segmentType] && flightSegment) {
			flightSegment.forEach((segment: VerifiedFlightSegment, index: number) => {
				(['Departure', 'Arrival'] as (keyof FlightData)[]).forEach((type) => {
					const data: AirportData = segment[type];
					flightSegment[index][type].time = data.Date && formatTime(data.Date);
					// yes twice 'Date' seems to be correct
					flightSegment[index][type].Date = data.Date && new Date(data.Date);
				});
			});
		}
	});

	return updatedOfferItem;
};

export interface ParsedData extends Partial<SearchFormDataType> {
	urlParams?: SearchFormDataType;
}

/**
 * Parse the data from the editorial and auto generated topic pages settings
 * @param dataSet DOMStringMap
 * @return ParsedData modified dataSet
 */
export const parseSearchMaskAndFilterSettings = (dataSet: DOMStringMap): ParsedData => {
	const parsedData: ParsedData = {};

	(Object.keys(dataSet) as Array<keyof typeof dataSet>).forEach((key) => {
		const transformToBooleans = ['isEditorial', 'onlyHotel'];

		if (dataSet[key] && typeof dataSet[key] === 'string') {
			const parsedDataChunk = JSON.parse(dataSet[key] as string);

			// Don't add falsy values
			if (parsedDataChunk) {
				parsedData[key as keyof typeof parsedData] = transformToBooleans.includes(key as string)
					? !!parsedDataChunk
					: parsedDataChunk;
			}
		}
	});

	return parsedData;
};

export const parsedDataToQuery = (parsedData: ParsedData) => {
	const params = formToParams(parsedData, 'HotelGrid');
	return objectToQuery(params);
};

export function getTravelDurationByQuery(query: { [key: string]: string }): number[] {
	let queryTravelDuration;
	if (query.dur === 'exact' && query.ddate && query.rdate) {
		queryTravelDuration = [dateDiff(query.ddate, query.rdate)];
	} else if (query.dur) {
		queryTravelDuration = typeFormat(query.dur, 'array').map(Number);
	}
	return queryTravelDuration;
}

function travelDurationParamToForm(dur: string | undefined, from: any, to: any) {
	const travelDurationParam = isValidParam('dur', dur) ? dur : undefined;
	let result;
	if (travelDurationParam === 'exact') {
		result = [dateDiff(from, to)];
	} else if (travelDurationParam !== undefined) {
		result = filterDuplicates(typeFormat(dur, 'array')).map(Number);
	}
	return result;
}
