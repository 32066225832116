import { requestCancelable } from '@/components/common/services/request';
import type { UpsellingBoard, UpsellingBoardResponse } from '@/interfaces/search-form';
import { useStore } from '@/components/common/store';
import type { ApiDataTypes, ApiDataTypesKeys } from '@/components/common/types';
import { boardTypes } from '@/js/data';
import { reportErrorToSentry } from '@/js/utils/utils';
import { createPriceMap } from './priceMapService';
import type { BoardTypeValue } from '@/js/data/board-types';

const url = '/v1/best-offer-for-board-type';
const store = useStore();
const apiData = (override?: any, exclude?: ApiDataTypesKeys[]): ApiDataTypes => store.getters['searchMask/apiData'](override, exclude);

const isTypeOfferUpsellingBoard = (isOfferUpsellingBoard: UpsellingBoardResponse | []): isOfferUpsellingBoard is UpsellingBoardResponse => {
	return typeof (isOfferUpsellingBoard as any).Status === 'string';
};

export const getOfferUpsellingBoard = async (excludes: ApiDataTypesKeys[] = []) => {
	const hotelOnly = store.state.searchMask.onlyHotel;
	const exclude: ApiDataTypesKeys[] = hotelOnly ? ['DepartureAirport', 'DirectFlight', 'DepartureTime', 'ArrivalTime'] : [];
	// Removing params which are at the moment specific to the hotellist request
	exclude.push(
		...excludes,
		'Regions',
		'Cities',
		'MaxPrice',
		'SortOrder',
		'HotelCategory',
		'MinMeanRecommendationRate',
		'RatingAttributes',
		'HotelAttributes',
		'Countries'
	);
	const bodyParams = {
		travelType: hotelOnly ? 'Accommodation' : 'Package',
	};
	const body = apiData(bodyParams, exclude);

	let result: UpsellingBoard[] = [];
	let error: null | unknown = null;

	try {
		const response = await requestCancelable<UpsellingBoardResponse | []>(
			{
				url,
				data: body,
			},
			'best-offer-for-board-type'
		);
		if (response && isTypeOfferUpsellingBoard(response) && response.Status === 'OK') {
			result = response.Offers;
		}
	} catch (err) {
		error = err;
		reportErrorToSentry(err, [
			{ name: 'Request Body', data: body },
			{ name: 'Error Details', data: err },
		]);
	}

	return { result, error };
};

export async function updateBoardTypePrices() {
	await store.commit('searchMask/updateBoard', {
		enabledFilters: new Set(boardTypes.filter((board) => board.showInMenu).map((board) => board.value)),
		isFetchLoading: true,
		priceMap: new Map(),
		fetchError: null,
	});

	const { result, error } = await getOfferUpsellingBoard();

	if (error) {
		await store.commit('searchMask/updateBoard', {
			enabledFilters: new Set(boardTypes.filter((board) => board.showInMenu).map((board) => board.value)),
			isFetchLoading: false,
			priceMap: new Map(),
			fetchError: error as Error,
		});
		return;
	}

	const { priceMap, enabledFilters } = createPriceMap<UpsellingBoard>(result, (response) => response.Board.Type as BoardTypeValue);

	await store.commit('searchMask/updateBoard', {
		enabledFilters: enabledFilters as Set<BoardTypeValue>,
		priceMap,
		isFetchLoading: false,
		fetchError: null,
	});
}
