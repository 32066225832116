import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

import { NBSP, type ClientCurrency } from '@global-js/constants';
import { formatPrice, separatePrice } from '@utils/priceUtils';
import { getDefaultCurrency } from '@utils/environmentUtils';
import { computed } from 'vue';

interface Props {
	strikeThrough?: boolean;
	price: number;
	currency?: ClientCurrency;
	showDecimals?: boolean;
	theme?: 'primary' | 'accent' | 'inherit';
	prefix?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BasePrice',
  props: {
    strikeThrough: { type: Boolean, default: false },
    price: {},
    currency: { default: getDefaultCurrency() || 'EUR' },
    showDecimals: { type: Boolean, default: false },
    theme: { default: 'inherit' },
    prefix: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const formattedPrice = computed(() => separatePrice(formatPrice(props.price, props.currency, props.showDecimals)));
const component = computed(() => (props.strikeThrough ? 'del' : 'ins'));
const ariaLabel = computed(() => `${props.prefix}${NBSP}${formattedPrice.value.amount}${NBSP}${formattedPrice.value.symbol}`);

const isLongCurrency = computed(() => ['CHF'].includes(props.currency));

const blockClassModifiers = computed(() => ({
	[`base-price--${props.theme}`]: !!props.theme,
	'base-price--strike-through': !!props.strikeThrough,
	'base-price--long-currency': isLongCurrency.value,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(component.value), {
    class: _normalizeClass(['base-price', blockClassModifiers.value]),
    "aria-label": ariaLabel.value
  }, {
    default: _withCtx(() => [
      (_ctx.prefix)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "base-price__prefix",
            textContent: _toDisplayString(_ctx.prefix + _unref(NBSP))
          }, null, 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true),
      _createElementVNode("span", {
        textContent: _toDisplayString(formattedPrice.value.amount)
      }, null, 8 /* PROPS */, _hoisted_2),
      _createElementVNode("span", {
        class: "base-price__symbol",
        textContent: _toDisplayString(_unref(NBSP) + formattedPrice.value.symbol)
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "aria-label"]))
}
}

})