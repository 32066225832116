import { type App } from 'vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import { determineLocales, type LocalesCodes } from './locales';

/*
 * Vue3 doesn't replace the mount point like Vue2 did.
 * Instead Vue3 adds the vue app as a child.
 * This function mimics the Vue2 behaviour,
 * so we don't have to clean up the extra container for now.
 *
 * WARNING: not yet tested with multiroot components.
 *
 * Further information:
 * https://v3-migration.vuejs.org/breaking-changes/mount-changes.html
 */
const { messages, locale } = determineLocales();

type MessageSchema = typeof messages;

const i18n = createI18n<[MessageSchema], LocalesCodes>({
	locale,
	fallbackLocale: 'de_AT',
	messages: {
		...messages,
	},
});

const pinia = createPinia();

export const mountReplace = (app: App, target: Element) => {
	const fragment = document.createDocumentFragment();
	app.use(pinia);
	app.use(i18n);
	// Ignore TS warning by casting to Element.
	app.mount(fragment as unknown as Element);

	target.parentNode?.replaceChild(fragment, target);
};
