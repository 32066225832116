<template>
	<span class="info-tooltip">
		<span
			ref="icon"
			class="info-tooltip__icon-container"
			@mouseover="showTooltip = true"
			@mouseleave="showTooltip = false"
		>
			<slot name="custom">
				<FaIcon
					:icon="['fa-solid', 'fa-circle-info']"
					class="info-tooltip__icon"
				/>
			</slot>
		</span>
		<div
			id="tooltip"
			ref="tooltip"
			class="info-tooltip__tooltip"
			:class="modifierClass"
			:data-show="(showTooltip || showTooltipParent) && !disabled"
		>
			<slot></slot>
			<div
				class="info-tooltip__arrow"
				data-popper-arrow
			></div>
		</div>
	</span>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { createPopper } from '@popperjs/core';

interface Props {
	disabled?: boolean;
	showTooltipParent?: boolean;
	placement?: 'top' | 'left' | 'right' | 'bottom';
	modifierClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
	showTooltipParent: false,
	placement: 'top',
	modifierClass: '',
});

const icon = ref<HTMLElement>();
const tooltip = ref<HTMLElement>();
const showTooltip = ref(false);
const scrollDispatched = ref(false);

watch(showTooltip, () => {
	if (showTooltip.value && !scrollDispatched.value) {
		window.dispatchEvent(new CustomEvent('scroll'));
		scrollDispatched.value = true;
	}
});

onMounted(async () => {
	setTimeout(() => {
		if (icon.value && tooltip.value) {
			createPopper(icon.value, tooltip.value, {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 10],
						},
					},
					{
						name: 'preventOverflow',
						options: {
							boundary: document.querySelector('.list-item.is-verified'),
						},
					},
				],
				placement: props.placement,
			});
		}
	}, 30);
});
</script>

<style lang="scss" scoped>
.info-tooltip {
	fill: $color-primary;

	&__tooltip {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		background: $color-primary;
		color: white;
		padding: 4px 8px;
		font-size: 13px;
		border-radius: $border-radius-small;
		transition:
			visibility 0.2s,
			opacity 0.2s linear;
		z-index: 999;
		isolation: isolate;
	}

	&__tooltip[data-show='true'] {
		visibility: visible;
		opacity: 1;
	}

	&__icon {
		color: $color-primary;
		width: 1.1rem;
		height: 1.1rem;
	}

	&__arrow,
	&__arrow::before {
		position: absolute;
		left: 0;
		width: 8px;
		height: 8px;
		background: inherit;
	}

	&__arrow {
		visibility: hidden;
	}

	&__arrow::before {
		visibility: visible;
		content: '';
		transform: rotate(45deg);
	}

	&__tooltip[data-popper-placement^='top'] > &__arrow {
		bottom: -4px;
	}

	&__tooltip[data-popper-placement^='bottom'] > &__arrow {
		top: -4px;
	}

	&__tooltip[data-popper-placement^='left'] > &__arrow {
		right: -4px;
	}

	&__tooltip[data-popper-placement^='right'] > &__arrow {
		left: -4px;
	}
}
</style>
