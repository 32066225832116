<template>
	<FilterType class="recommendation-rate-filter">
		<FilterRadioButtonList
			id="recommendation-rate"
			v-model="selectedRating"
			:items="recommendationRate"
			class="recommendation-rate-filter__list"
		/>
	</FilterType>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import FilterRadioButtonList from '@lmt-rpb/FilterRadioButtonList/FilterRadioButtonList.vue';
import recommendationRate from '@/js/data/recommendation-rate-types';
import { useStore } from '@/components/common/store';

const store = useStore();

const selectedRating = computed({
	get: () => store.state.searchMask.minMeanRecommendationRate?.toString(),
	set: (newValue: string) => store.commit('searchMask/updateFormData', { minMeanRecommendationRate: Number.parseInt(newValue) }),
});
</script>

<style lang="scss" scoped>
.recommendation-rate-filter {
	&__list {
		margin-left: 0.8rem;
	}
}
</style>
