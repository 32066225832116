<template>
	<div
		class="form-field"
		:class="fieldClasses"
		v-bind="$attrs"
		@mousedown="mousedownHandler"
	>
		<BaseIcon
			v-if="icon"
			:name="icon"
			class="form-field__icon"
		/>
		<label class="form-field__label">
			<span
				v-if="label"
				class="form-field__label-text"
			>
				{{ label }}
				<span
					v-if="board"
					class="form-field__label-text--small-text"
				>
					(mind.)</span
				>
			</span>
			<input
				ref="input"
				:value="model"
				@input="model = $event.target.value"
				type="text"
				class="form-field__input"
				data-lpignore="true"
				maxlength="100"
				:disabled="disabled"
				:tabindex="-1"
				:placeholder="placeholder"
				:readonly="readonly"
				:aria-label="model"
				@focus="onFocus"
				@blur="onBlur"
				@keydown.enter.stop="emit('key:enter')"
			/>
		</label>
		<template v-if="allowClear && model">
			<div
				class="form-field__close"
				@click.stop="$emit('FormField:Clear')"
			>
				<BaseIcon
					:name="customClearIcon ?? 'close'"
					class="form-field__clear-icon"
				/>
			</div>
		</template>
		<template v-else-if="showToggleIcon">
			<BaseIcon
				name="chevron"
				class="form-field__chevron-icon"
				:class="{ 'form-field__chevron-icon--open': open }"
				@click.stop="$emit('toggle', $event)"
			/>
		</template>
	</div>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';

interface Props {
	selected?: string;
	readonly?: boolean;
	showModal?: boolean;
	label?: string;
	icon: string;
	placeholder?: string;
	board?: string | boolean;
	open?: boolean;
	allowClear?: boolean;
	showToggleIcon?: boolean;
	preventMousedown?: boolean;
	disabled?: boolean;
	preventCloseOpen?: boolean;
	customClearIcon?: string;
}

const props = withDefaults(defineProps<Props>(), {
	selected: '',
	readonly: true,
	showModal: false,
	label: '',
	placeholder: 'Beliebig',
	board: false,
	open: false,
	allowClear: false,
	showToggleIcon: true,
	preventMousedown: true,
	disabled: false,
	customClearIcon: undefined,
});

const emit = defineEmits(['update:selected', 'FormField:Focus', 'FormField:Blur', 'FormField:Clear', 'toggle', 'key:enter']);

const model = computed({
	get() {
		return props.selected;
	},
	set(newValue) {
		emit('update:selected', newValue);
	},
});

const isFocused = ref(false);

const isClosing = ref(false);

const input = ref<HTMLInputElement>();

const fieldClasses = computed(
	(): Record<string, any> => ({
		'is-readonly': props.readonly,
		'is-focused': isFocused.value,
		'is-modal-closing': isClosing.value,
		'is-modal-open': props.showModal,
	})
);

const onFocus = (): void => {
	isFocused.value = true;
	emit('FormField:Focus');
};

const onBlur = (): void => {
	isFocused.value = false;
	emit('FormField:Blur');
};

const mousedownHandler = (event: MouseEvent): void => {
	if (props.preventMousedown) {
		event.preventDefault();
	}
};

onMounted(() => {
	input.value = getCurrentInstance()?.proxy?.$refs.input as HTMLInputElement;
});

watch(
	() => props.showModal,
	() => {
		if (!props.showModal) {
			setTimeout(() => {
				isClosing.value = false;
			}, 300);
			isClosing.value = true;
		}
	}
);

defineExpose({
	input,
	model,
});
</script>

<style lang="scss" scoped>
::input-placeholder {
	color: $color-placeholder-text !important;
}

::placeholder {
	color: $color-placeholder-text !important;
}

:input-placeholder {
	color: $color-placeholder-text !important;
}

.form-field {
	display: flex;
	align-items: center;
	justify-content: left;
	width: 100%;
	height: 6.5rem;
	padding: 1rem 1.2rem 0.75rem;
	border: 0.1rem solid $color-primary-l4;
	border-radius: $border-radius-large;
	background: $color-white;
	text-align: left;
	cursor: pointer;

	&__close {
		display: flex;
	}

	.form-field__icon {
		width: 3rem;
		height: 3.5rem;
		margin-right: 1rem;
		fill: $color-primary;
	}

	.form-field__clear-icon,
	.form-field__chevron-icon {
		width: 2.3rem;
		height: 2.3rem;
		margin-top: 0;
		fill: $color-primary;
	}

	.form-field__chevron-icon {
		transform: rotate(180deg);
		transition: transform 0.1s ease-out;
		flex-shrink: 0;
	}

	.form-field__chevron-icon--open {
		transform: rotate(0deg);
	}

	.form-field__label {
		width: 100%;
		min-width: 0;
		margin-right: 0.2rem;
	}

	.form-field__label-text {
		@include textEllipsis(100%);

		display: block;
		color: $color-text;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		line-height: 2rem;
	}

	.form-field__label-text--small-text {
		font-size: $font-small-2;
		font-weight: normal;
	}

	.form-field__input {
		@include textEllipsis(100%);

		width: 100%;
		padding: 0;
		border: none;
		color: $color-primary;
		font-size: $font-medium-2;
		font-weight: $font-weight-semibold;
		line-height: 1.5;

		&:focus,
		&:active {
			outline: none;
		}
	}

	.form-field__input:disabled {
		background-color: initial;
	}

	&.is-readonly {
		.form-field__label {
			pointer-events: none;
		}
	}

	&.is-modal-closing {
		position: relative;
	}

	&.is-modal-open {
		position: relative;

		.form-field__mask {
			z-index: 3;
		}
	}

	@include media-query-up($breakpoint-mobilelarge) {
		height: 7.5rem;
	}

	@include media-query-up($breakpoint-large) {
		height: 7rem;
	}
}
</style>
