<template>
	<FilterType class="board-type-filter">
		<menu class="board-type-filter__list">
			<li class="board-type-filter__list-item">
				<FilterRadioButton
					id="board-type"
					:key="0"
					v-model="selectedFilter"
					label="Beliebig"
					value="Any"
					:disabled="isFilterDisabled(ANY)"
				/>
			</li>
			<li
				v-for="board in actualBoardTypes"
				:key="board.id"
				class="board-type-filter__list-item"
			>
				<FilterRadioButton
					id="board-type"
					v-model="selectedFilter"
					:label="board.upsellingLabel"
					:value="board.value"
					:disabled="isFilterDisabled(board.value)"
				/>
				<template v-if="isHotelPage">
					<FilterPrice
						:is-loading="isFetchLoading"
						:value="priceMap.get(board.value)"
					></FilterPrice>
				</template>
			</li>
		</menu>
	</FilterType>
</template>

<script setup lang="ts">
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import FilterRadioButton from '@lmt-rpb/FilterRadioButton/FilterRadioButton.vue';
import { boardTypes } from '@/js/data';
import { computed } from 'vue';
import { useStore } from '@/components/common/store';
import type { BoardTypeValue } from '@/js/data/board-types';
import { ANY } from '@global-js/constants';
import FilterPrice from '@lmt-rpb/FilterPrice/FilterPrice.vue';

type Props = {
	isHotelPage?: boolean;
};

const props = withDefaults(defineProps<Props>(), { isHotelPage: false });

const actualBoardTypes = computed(() => boardTypes.filter((board) => board.showInMenu));

const store = useStore();

const selectedFilter = computed({
	get() {
		return store.state.searchMask.boardTypes[0] ?? ANY;
	},
	set(value) {
		store.commit('searchMask/updateFormData', { boardTypes: value === ANY ? [] : [value] });
	},
});

const priceMap = computed(() => {
	return store.state.searchMask.board.priceMap;
});

const activeFilters = computed(() => store.state.searchMask.board.enabledFilters);
const isFetchLoading = computed(() => store.state.searchMask.board.isFetchLoading);

const isFilterDisabled = function (filterValue: BoardTypeValue | typeof ANY) {
	if (!props.isHotelPage) {
		return false;
	}
	if (isFetchLoading.value) {
		return true;
	}
	if (filterValue === ANY) {
		return activeFilters.value.size === 0;
	}
	return !activeFilters.value.has(filterValue);
};
</script>

<style lang="scss" scoped>
.board-type-filter {
	&__list {
		display: flex;
		flex-direction: column;
		row-gap: 1.6rem;
		list-style: none;
		padding: 0;
		margin: 0;
		margin-left: 0.8rem;
	}

	&__list-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__price {
		display: inline-block;
		margin-left: auto;
		color: $color-extra;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
	}

	&__price-loader {
		display: inline-block;
		margin-left: auto;
		margin-bottom: 0;
	}
}
</style>
