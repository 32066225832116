import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { pluralize } from '@utils/utils';
import { dateDiff, offsetDate } from '@utils/dateUtils';
import { useStore } from '@/components/common/store';
import { EventBus } from '@global-js/event-bus';
import { TRAVEL_DURATION_EXACT } from '@global-js/constants';
import TravelDurationContent from './TravelDurationContent.vue';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { useBreakpointStore } from 'src/store/breakpointsStore';
import { storeToRefs } from 'pinia';

const maxDurationErrorMessage = 'Die gewählte Dauer kann nicht länger als 56 Tage sein. Bitte ändern Sie die Reisedaten.';


export default /*@__PURE__*/_defineComponent({
  __name: 'TravelDurationField',
  setup(__props, { expose: __expose }) {

const store = useStore();

const { isDesktop } = storeToRefs(useBreakpointStore());

const dropdownFilterType = ref<InstanceType<typeof DropdownFilterType>>();

const selection = ref({
	isExactSelected: false,
	travelDuration: [] as number[],
});

const maxTravelDurationError = computed({
	get() {
		return store.state.searchMask.maxTravelDurationError;
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			maxTravelDurationError: value,
		});
	},
});

const offerDuration = computed({
	get() {
		const dur = store.state.searchMask.offerDuration;
		return {
			from: dur.from || 0,
			to: dur.to || 0,
		};
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			offerDuration: value,
			offerDurationRelative: { relativeFrom: '', relativeTo: '' },
		});
	},
});

const totalDiffInDay = computed((): number => dateDiff(offerDuration.value.from, offerDuration.value.to));

const isExact = computed({
	get() {
		return store.state.searchMask.isTravelDurationExactSelected;
	},
	set(newValue: boolean) {
		store.commit('searchMask/updateFormData', { isTravelDurationExactSelected: newValue });
		selection.value.isExactSelected = newValue;
	},
});

const travelDuration = computed({
	get() {
		return store.state.searchMask.travelDuration || [];
	},
	set(newValue: number[]) {
		store.commit('searchMask/updateFormData', { travelDuration: newValue });
		selection.value.travelDuration = newValue;
	},
});

const travelDurationModel = computed({
	get() {
		return {
			exact: selection.value.isExactSelected,
			duration: selection.value.travelDuration,
		};
	},
	set(newValue: { exact: boolean; duration: number[] }) {
		selection.value = {
			isExactSelected: newValue.exact,
			travelDuration: newValue.duration,
		};
	},
});

const getDaysDurationLabel = (duration: number[]) => {
	let result: string;
	if (duration.length === 1) {
		result = pluralize(Number(duration[0]), 'Tag', 'Tage');
	} else {
		result = `${duration.join('-')} Tage`;
	}
	return result;
};

const durationLabel = computed((): string => {
	let result = '';
	const duration = travelDurationModel.value.duration;
	const isBeliebig = duration.length === 0 || (duration[0] === 1 && duration[1] === 14);
	if (travelDurationModel.value.exact) {
		result = 'Exakt';
	} else if (!isBeliebig) {
		result = getDaysDurationLabel(duration);
	}
	return result;
});

const updateOfferDuration = (): void => {
	const offerFrom = offerDuration.value.from!;
	const min = travelDuration.value[0];
	if (totalDiffInDay.value < min) {
		const max = Math.max(...travelDuration.value);
		offerDuration.value = {
			from: offerDuration.value.from,
			to: offsetDate(offerFrom, max).getTime(),
		};
	}
};
const onOk = () => {
	if (maxTravelDurationError.value) {
		return;
	}
	isExact.value = selection.value.isExactSelected;
	travelDuration.value = selection.value.travelDuration;
	EventBus.$emit('TravelDuration:Changed');
	updateOfferDuration();
	store.commit('searchMask/updateFormData', { filter: '' });
};

const onCancel = () => {
	selection.value = {
		travelDuration: travelDuration.value,
		isExactSelected: isExact.value,
	};
	maxTravelDurationError.value = travelDuration.value[0] > 56 && isExact.value;
};

const handleDurationError = (pillValue: string, travelDurationDays: number) => {
	if (pillValue !== '') {
		maxTravelDurationError.value = pillValue === TRAVEL_DURATION_EXACT && travelDurationDays > 56;
	}
};

watch(travelDuration, (newValue) => {
	travelDuration.value = newValue;
});

watch(isExact, (newValue) => {
	isExact.value = newValue;
});

watch(isDesktop, () => {
	onCancel();
});

const openModalOrDropdown = () => {
	if (!maxTravelDurationError.value) {
		if (isDesktop.value && !dropdownFilterType.value?.desktopField?.open) {
			dropdownFilterType.value?.desktopField?.toggleDropdown();
		} else {
			setTimeout(() => dropdownFilterType.value?.open(), 500);
		}
	}
};
// we need this unfortunately because of multiple instances
const listenToBus = computed({
	get: () => store.state.config.listenToBus,
	set: (value: boolean) => store.commit('updateConfig', { listenToBus: value }),
});
onMounted(() => {
	if (!listenToBus.value) {
		listenToBus.value = true;
		EventBus.$on('OfferDurationField:OpenTravelDuration', openModalOrDropdown);
	}
	selection.value = {
		isExactSelected: isExact.value,
		travelDuration: travelDuration.value,
	};
});

onBeforeUnmount(() => {
	EventBus.$off('OfferDurationField:OpenTravelDuration', openModalOrDropdown);
	listenToBus.value = false;
});

__expose({
	selection,
	durationLabel,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["travel-duration-field", { 'duration-error': maxTravelDurationError.value }])
  }, [
    _createVNode(DropdownFilterType, {
      ref_key: "dropdownFilterType",
      ref: dropdownFilterType,
      title: "Dauer",
      "with-menu-header": false,
      icon: "clock",
      "selected-value": durationLabel.value,
      "cancel-button-text": "Verwerfen",
      "ok-button-text": "Übernehmen",
      "show-buttons": true,
      "emit-on-ok": true,
      "button-disabled": maxTravelDurationError.value,
      "has-error": maxTravelDurationError.value,
      "apply-button-text": 'Übernehmen',
      "show-toggle-icon": false,
      "onDropdownFilterType:apply": onOk,
      "onDropdownFilterType:clear": onCancel,
      "onDropdownFilterType:abort": onCancel
    }, {
      default: _withCtx(() => [
        _createVNode(TravelDurationContent, {
          modelValue: travelDurationModel.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((travelDurationModel).value = $event)),
          "offer-duration-diff": totalDiffInDay.value,
          "error-message": maxTravelDurationError.value ? maxDurationErrorMessage : '',
          "onTravelDurationContent:PillChanged": handleDurationError
        }, null, 8 /* PROPS */, ["modelValue", "offer-duration-diff", "error-message"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["selected-value", "button-disabled", "has-error"])
  ], 2 /* CLASS */))
}
}

})