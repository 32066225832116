import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "checkout-hotel-infos" }
const _hoisted_2 = { class: "checkout-hotel-infos__content" }
const _hoisted_3 = { class: "checkout-hotel-infos__list" }
const _hoisted_4 = { class: "checkout-hotel-infos__detail-name" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "checkout-hotel-infos__copyright" }

import { computed } from 'vue';
import type { HotelCatalogueType } from '@/interfaces/hotel-catalogue-types';
import CheckoutHotelInfoGallery from '../CheckoutHotelInfoGallery/CheckoutHotelInfoGallery.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogueInfoContent',
  props: {
    hotelInfo: {},
    client: { default: 'at' }
  },
  setup(__props: any) {

const props = __props;

const details = computed(() => ({
	basicInfo: {
		Katalog: props.hotelInfo.CatalogName,
		Veranstalter: props.hotelInfo.TourOperatorName,
		Objekt: props.hotelInfo.HotelName,
		Ort: props.hotelInfo.CityName,
		Kategorie: props.hotelInfo.HotelCategory,
	},
	description: props.hotelInfo.Description,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hotelInfo.Photos)
      ? (_openBlock(), _createBlock(CheckoutHotelInfoGallery, {
          key: 0,
          images: _ctx.hotelInfo.Photos,
          client: _ctx.client,
          class: "checkout-hotel-infos__gallery"
        }, null, 8 /* PROPS */, ["images", "client"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(details.value.basicInfo, (value, detail) => {
          return (_openBlock(), _createElementBlock("li", {
            key: detail,
            class: "checkout-hotel-infos__detail"
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(detail), 1 /* TEXT */),
            _createTextVNode(": " + _toDisplayString(value), 1 /* TEXT */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", {
        class: "checkout-hotel-infos__description",
        innerHTML: details.value.description
      }, null, 8 /* PROPS */, _hoisted_5),
      _createElementVNode("p", _hoisted_6, "© GIATA GmbH 1996 - " + _toDisplayString(new Date().getFullYear()), 1 /* TEXT */),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})