export default [
	{
		id: 11, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Tolle Lage', value: 'location',
	},
	{
		id: 22, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Schöner Strand', value: 'beach',
	},
	{
		id: 33, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Schöne Zimmer', value: 'room',
	},
	{
		id: 40, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Gutes All Inclusive', value: 'mealsAndDrinksAllInclusive',
	},
	{
		id: 73, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Gutes Sportangebot', value: 'sport',
	},
	{
		id: 2, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Sauberkeit', value: 'complexCleanness',
	},
	{
		id: 120, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Top Wellness', value: 'wellness',
	},
	{
		id: 7, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Top für Singles', value: 'objectTravelAsSingle',
	},
	{
		id: 12, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Top Freizeitaktivitäten', value: 'locationRecreationalActivities',
	},
] as const;
