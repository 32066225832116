import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-mobile" }

import FilterTagList from '@lmt-rpb/FilterTagList/FilterTagList.vue';
import ListFilters from '@lmt-rpb/ListFilters/ListFilters.vue';

type Props = {
	listType: 'hotel' | 'region' | 'offer';
	destinationLabel?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterMobile',
  props: {
    listType: {},
    destinationLabel: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FilterTagList, { "emit-analytics": false }),
    _createVNode(ListFilters, {
      "list-type": _ctx.listType,
      "destination-label": _ctx.destinationLabel
    }, null, 8 /* PROPS */, ["list-type", "destination-label"])
  ]))
}
}

})