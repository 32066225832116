import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "travelers" }

import { cloneDeep, pluralize } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import type { Travelers } from '@/components/common/types';
import TravelersFieldContent from './TravelersFieldContent.vue';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'TravelersField',
  setup(__props, { expose: __expose }) {

const store = useStore();

const travelers = computed({
	get() {
		return store.state.searchMask.travelers;
	},
	set(value: Travelers) {
		store.commit('searchMask/updateFormData', { travelers: value, filter: '' });
	},
});
const travelerData = ref<Travelers>(cloneDeep(travelers.value));

const { isDesktop } = storeToRefs(useBreakpointStore());

const fieldLabel = computed((): string => {
	const adultLabel = isDesktop.value
		? `${travelerData.value.adult} Erw.`
		: pluralize(travelerData.value.adult, 'Erwachsener', 'Erwachsene');
	const childLabel = pluralize(travelerData.value.children.length, 'Kind', 'Kinder');

	return `${adultLabel} ${childLabel}`;
});

const onOk = () => {
	travelers.value = cloneDeep(travelerData.value);
};

const onCancel = () => {
	travelerData.value = cloneDeep(travelers.value);
};

const onClear = () => {
	travelers.value = { adult: 2, children: [] };
};

watch(
	() => travelers.value,
	() => {
		if (JSON.stringify(travelers.value) !== JSON.stringify(travelerData.value)) {
			travelerData.value = cloneDeep(travelers.value);
		}
	},
	{ immediate: true }
);

__expose({
	travelerData,
	travelers,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(DropdownFilterType, {
      "selected-value": fieldLabel.value,
      class: "travelers__dropdown",
      title: "Reisende",
      "with-menu-header": false,
      icon: "group",
      "cancel-button-text": "Verwerfen",
      "ok-button-text": "Übernehmen",
      "apply-button-text": 'Übernehmen',
      "show-buttons": true,
      "show-toggle-icon": false,
      "emit-on-ok": true,
      "onDropdownFilterType:apply": onOk,
      "onDropdownFilterType:clear": onClear,
      "onDropdownFilterType:abort": onCancel
    }, {
      default: _withCtx(() => [
        _createVNode(TravelersFieldContent, {
          modelValue: travelerData.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((travelerData).value = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["selected-value"])
  ]))
}
}

})