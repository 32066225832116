<template>
	<div
		class="gradient"
		:style="gradientStyle"
	></div>
	<div
		v-if="reflex"
		class="gradient--reflex"
		:style="{ ...gradientStyle, left: '0', right: 'auto' }"
	></div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, computed } from 'vue';

interface Props {
	width: string;
	height: string;
	top: string;
	reflex?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	width: '',
	height: '',
	top: '',
	reflex: false,
});

const gradientStyle = computed(() => ({
	height: `${props.height}rem`,
	width: `${props.width}rem`,
	top: `${props.top}rem`,
}));
</script>

<style lang="scss" scoped>
.gradient {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0%, rgb(255 255 255 / 0%)),
		color-stop(100%, rgb(255 255 255 / 100%))
	);
	position: absolute;
	right: 0;

	&--reflex {
		position: absolute;
		background: -webkit-gradient(
			linear,
			right top,
			left top,
			color-stop(0%, rgb(255 255 255 / 0%)),
			color-stop(100%, rgb(255 255 255 / 100%))
		);
	}
}
</style>
