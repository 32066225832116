import {
	AUSTRIAN_AIRPORTS,
	AUSTRIAN_DEFAULT_AIRPORTS,
	GERMAN_AIRPORTS,
	GERMAN_DEFAULT_AIRPORTS,
	SWISS_AIRPORTS,
	SWISS_DEFAULT_AIRPORTS,
} from '@global-js/constants';
import type { ClientType } from '@/interfaces/common';

// These airports are used for the user interface in the search mask
export const airportCodes: Record<ClientType, string> = {
	at: AUSTRIAN_AIRPORTS,
	kuat: AUSTRIAN_AIRPORTS,
	lmat: AUSTRIAN_AIRPORTS,
	com: GERMAN_AIRPORTS,
	ch: SWISS_AIRPORTS,
	tbde: GERMAN_AIRPORTS,
} as const;
// These airports are used as defaults for package-offer requests with no airport selected
export const defaultAirportCodes: Record<ClientType, string> = {
	at: AUSTRIAN_DEFAULT_AIRPORTS,
	kuat: AUSTRIAN_DEFAULT_AIRPORTS,
	lmat: AUSTRIAN_DEFAULT_AIRPORTS,
	com: GERMAN_DEFAULT_AIRPORTS,
	ch: SWISS_DEFAULT_AIRPORTS,
	tbde: GERMAN_DEFAULT_AIRPORTS,
} as const;

// Grouped airports by country
const germanAirports = [
	{ label: 'Berlin-Brandenburg', additional: ['BER'], value: 'BER' },
	{ label: 'Bremen', additional: ['BRE'], value: 'BRE' },
	{ label: 'Dortmund', additional: ['DTM'], value: 'DTM' },
	{ label: 'Dresden', additional: ['DRS'], value: 'DRS' },
	{ label: 'Düsseldorf', additional: ['DUS'], value: 'DUS' },
	{ label: 'Erfurt', additional: ['ERF'], value: 'ERF' },
	{ label: 'Frankfurt', additional: ['FRA'], value: 'FRA' },
	{ label: 'Frankfurt-Hahn', additional: ['HHN'], value: 'HHN' },
	{ label: 'Friedrichshafen', additional: ['FDH'], value: 'FDH' },
	{ label: 'Hamburg', additional: ['HAM'], value: 'HAM' },
	{ label: 'Hannover', additional: ['HAJ'], value: 'HAJ' },
	{ label: 'Karlsruhe', additional: ['FKB'], value: 'FKB' },
	{ label: 'Kassel-Calden', additional: ['KSF'], value: 'KSF' },
	{ label: 'Köln-Bonn', additional: ['CGN'], value: 'CGN' },
	{ label: 'Leipzig/Halle', additional: ['LEJ'], value: 'LEJ' },
	{ label: 'Lübeck', additional: ['LBC'], value: 'LBC' },
	{ label: 'Memmingen', additional: ['FMM'], value: 'FMM' },
	{ label: 'München', additional: ['MUC'], value: 'MUC' },
	{ label: 'Münster-Osnabrück', additional: ['FMO'], value: 'FMO' },
	{ label: 'Niederrhein/Weeze', additional: ['NRN'], value: 'NRN' },
	{ label: 'Nürnberg', additional: ['NUE'], value: 'NUE' },
	{ label: 'Paderborn', additional: ['PAD'], value: 'PAD' },
	{ label: 'Rostock', additional: ['RLG'], value: 'RLG' },
	{ label: 'Saarbrücken', additional: ['SCN'], value: 'SCN' },
	{ label: 'Stuttgart', additional: ['STR'], value: 'STR' },
];

const austrianAirports = [
	{ label: 'Graz', additional: ['GRZ'], value: 'GRZ' },
	{ label: 'Innsbruck', additional: ['INN'], value: 'INN' },
	{ label: 'Klagenfurt', additional: ['KLU'], value: 'KLU' },
	{ label: 'Linz', additional: ['LNZ'], value: 'LNZ' },
	{ label: 'Salzburg', additional: ['SZG'], value: 'SZG' },
	{ label: 'Wien', additional: ['VIE'], value: 'VIE' },
];

const swissAirports = [
	{ label: 'Basel', additional: ['BSL'], value: 'BSL' },
	{ label: 'Bern', additional: ['BRN'], value: 'BRN' },
	{ label: 'Genf', additional: ['GVA'], value: 'GVA' },
	{ label: 'St. Gallen', additional: ['ACH'], value: 'ACH' },
	{ label: 'Zürich', additional: ['ZRH'], value: 'ZRH' },
];

const otherAirports = [
	{ label: 'Amsterdam', additional: ['AMS'], value: 'AMS' },
	{ label: 'Billund', additional: ['BLL'], value: 'BLL' },
	{ label: 'Bratislava', additional: ['BTS'], value: 'BTS' },
	{ label: 'Brüssel', additional: ['BRU'], value: 'BRU' },
	{ label: 'Budapest', additional: ['BUD'], value: 'BUD' },
	{ label: 'Charleroi', additional: ['CRL'], value: 'CRL' },
	{ label: 'Eindhoven', additional: ['EIN'], value: 'EIN' },
	{ label: 'Groningen', additional: ['GRQ'], value: 'GRQ' },
	{ label: 'Krakau', additional: ['KRK'], value: 'KRK' },
	{ label: 'Ljubljana', additional: ['LJU'], value: 'LJU' },
	{ label: 'Luxemburg', additional: ['LUX'], value: 'LUX' },
	{ label: 'Mailand-Malpensa', additional: ['MXP'], value: 'MXP' },
	{ label: 'Maastricht', additional: ['MST'], value: 'MST' },
	{ label: 'Prag', additional: ['PRG'], value: 'PRG' },
	{ label: 'Warschau', additional: ['WAW'], value: 'WAW' },
	{ label: 'Brünn', additional: ['BRQ'], value: 'BRQ' },
	{ label: 'Straßburg', additional: ['SXB'], value: 'SXB' },
];

const allAirports = [...germanAirports, ...austrianAirports, ...swissAirports, ...otherAirports];

export default allAirports;
