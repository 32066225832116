import { enabled } from '../../components/common/services/url';

// Removes all params not in enabled list including aid.
export const getSearchParams = (excludeParams: string[] = []): string => {
	const { search } = window.location;
	const searchParams = new URLSearchParams(search);
	const searchArr: string[] = [];

	searchParams.forEach((value, key) => {
		searchArr.push(key);
	});

	searchArr.forEach((param) => {
		if (enabled.indexOf(param) === -1 || param === 'aid') {
			searchParams.delete(param);
		}
	});

	excludeParams.forEach((param) => searchParams.delete(param));

	return searchParams.toString();
};

export const updateBreadcrumbUrl = (): void => {
	const searchParams = getSearchParams();

	if (!searchParams) {
		return;
	}
	const searchCrumb = document.getElementById('rpb_breadcrumb--search') as HTMLAnchorElement;
	const allRegionsCrumb = document.getElementById('rpb_breadcrumb--all-regions') as HTMLAnchorElement;
	const regionGroupCrumb = document.getElementById('rpb_breadcrumb--regiongroup') as HTMLAnchorElement;
	const topregionCrumb = document.getElementById('rpb_breadcrumb--topregion') as HTMLAnchorElement;
	const cityCrumb = document.getElementById('rpb_breadcrumb--city') as HTMLAnchorElement;

	if (searchCrumb) {
		const searchBCParams = getSearchParams(['cyid', 'rid']);
		const searchUrl = new URL(searchCrumb.href);
		searchCrumb.href = `${searchUrl.origin}${searchUrl.pathname}?${searchBCParams}`;
	}

	if (allRegionsCrumb) {
		const allRegionsParams = getSearchParams(['cyid', 'rid']);
		const allRegionsUrl = new URL(allRegionsCrumb.href);
		allRegionsCrumb.href = `${allRegionsUrl.origin}${allRegionsUrl.pathname}?${allRegionsParams}`;
	}

	if (regionGroupCrumb) {
		const regionParams = getSearchParams(['cyid', 'rid']);
		const regionGroupUrl = new URL(regionGroupCrumb.href);
		regionGroupCrumb.href = `${regionGroupUrl.origin}${regionGroupUrl.pathname}?${regionParams}`;
	}

	if (topregionCrumb) {
		const topregionUrl = new URL(topregionCrumb.href);
		topregionCrumb.href = `${topregionUrl.origin}${topregionUrl.pathname}?${searchParams}`;
	}

	if (cityCrumb) {
		const cityUrl = new URL(cityCrumb.href);
		cityCrumb.href = `${cityUrl.origin}${cityUrl.pathname}?${searchParams}`;
	}
};

export const updateSearchAndBookUrls = (newSearchParams: string = getSearchParams()): void => {
	if (!newSearchParams) {
		return;
	}

	const mainMenuAnchor: HTMLAnchorElement | null = document.querySelector('.search-book-main-anchor');
	const subMenuAnchors: NodeList | undefined = document.querySelectorAll('.search-book-sublink');

	if (mainMenuAnchor) {
		const mainMenuAnchorUrl = new URL(mainMenuAnchor.href);
		mainMenuAnchor.href = `${mainMenuAnchorUrl.origin}${mainMenuAnchor.pathname}?${newSearchParams}`;
	}

	if (subMenuAnchors) {
		subMenuAnchors.forEach((anchorTag) => {
			const anchorTagUrl = new URL((anchorTag as HTMLAnchorElement).href);

			const urlSearchParams = new URLSearchParams(newSearchParams);

			if ((anchorTag as HTMLAnchorElement).classList.contains('search-book-hotel')) {
				urlSearchParams.set('ibe', 'hotel');
			}

			if ((anchorTag as HTMLAnchorElement).classList.contains('search-book-package')) {
				urlSearchParams.set('ibe', 'package');
			}

			// eslint-disable-next-line no-param-reassign
			(anchorTag as HTMLAnchorElement).href = `${anchorTagUrl.origin}${anchorTagUrl.pathname}?${urlSearchParams}`;
		});
	}
};

export const updateHotelPageUrls = (): void => {
	const searchParams = getSearchParams();

	if (!searchParams) {
		return;
	}

	const quickPills = document.querySelectorAll('.quickpill-list .quickpill a');

	if (quickPills) {
		quickPills.forEach((quickpill: Element) => {
			const updatedUrl = new URL((quickpill as HTMLAnchorElement).href);
			// eslint-disable-next-line no-param-reassign
			(quickpill as HTMLAnchorElement).href = `${updatedUrl.origin}${updatedUrl.pathname}?${searchParams}`;
		});
	}
};
