import { computed, ComputedRef, isRef } from 'vue';
import { NBSP } from '@global-js/constants';
import { pluralize } from '@utils/utils';

interface TravelersInfo {
	adult: number;
	children: number[] | number;
}

export const useTravelers = (travelers: TravelersInfo | ComputedRef<TravelersInfo>) => {
	const travelersLabel = computed((): string => {
		const data = isRef(travelers) ? travelers.value : travelers;
		const adultLabel = `${data.adult}${NBSP}Erw.`;
		let label = `${adultLabel}`;
		
		const childrenCount = Array.isArray(data.children) ? data.children.length : data.children;
		if (childrenCount > 0) {
			label += ', ' + pluralize(childrenCount, 'Kind', 'Kinder');
		}
		
		return label + `${NBSP}ab`;
	});

	return {
		travelersLabel
	};
};