<template>
	<div class="payment-method">
		<div class="payment-method__header">
			<h3 class="payment-method__headline">Bevorzugte Zahlungsart:</h3>
			<CheckoutPaymentMethodInfo v-if="!isSmallMobile" />
		</div>
		<span
			v-if="!paymentRadioValid && paymentRadioValidated"
			class="payment-radio-group__error"
		>
			<BaseIcon
				name="exclamationMarkFill"
				class="payment-radio-group__exclamation"
			/>
			Zahlungsart auswählen
		</span>
		<PaymentRadioButtonGroup
			id="payment-type"
			v-model="paymentInfo.selectedPayment"
			:class="{ 'payment-radio-group__error': !paymentRadioValid && paymentRadioValidated }"
			:options="overallPaymentMethods"
		/>

		<div
			v-if="isCreditCardSelected"
			class="payment-method__info-container"
		>
			<h3 class="payment-method__headline-add">Kreditkartenanbieter</h3>
			<span
				v-if="!creditCardValid && creditCardValidated"
				class="payment-radio-group__error"
			>
				Kreditkarte auswählen
			</span>
			<CreditCardRadioButtonGroup
				id="credit-card-radio-group"
				v-model="paymentInfo.selectedCreditCard"
				:class="{ 'payment-radio-group__error': !creditCardValid && creditCardValidated }"
				:options="creditCards"
			/>

			<p
				v-if="creditCardServiceText"
				class="payment-method__credit-card-service-text"
			>
				{{ creditCardServiceText }}
			</p>
			<div class="payment-method__credit-card-info">
				<p class="payment-method__credit-card-info-text">
					Wählen Sie hier Ihre Kreditkarte,
					<strong> die Eingabe Ihrer Kreditkartendaten erfolgt nach Abschluss der Buchung. </strong>
				</p>
				<BaseIcon
					class="payment-method__question-icon"
					name="questionCircle"
					@keypress.enter.native="showInfoText = !showInfoText"
					@mouseover.native="showInfoText = true"
					@mouseleave.native="showInfoText = false"
				/>
				<transition name="info-text">
					<div
						v-if="showInfoText"
						class="payment-method__info-bubble"
					>
						<span>
							Die <strong>Eingabe Ihrer Kreditkartendaten</strong> erfolgt im nächsten Schritt,
							<strong>nach Abschluß der Buchung.</strong> Dabei werden Sie, wie im Rahmen der neuen Zahlungsdiensterichtlinie
							(PSD2) festgelegt,
							<strong>
								für die Authentifizierung zu Ihrer Bank weitergeleitet, um die Zahlung des Gesamtbetrags zu bestätigen.
							</strong>
							Danach werden Sie wieder zu uns zurückgeleitet.
						</span>
					</div>
				</transition>
			</div>
		</div>
		<div
			v-show="selectedPaymentIsSepa"
			class="payment-method__info-container"
		>
			<h3 class="payment-method__headline-add sepa">
				{{ paymentOptions['DirectDebitInternational']?.header ?? 'Zahlung per Lastschriftmandat' }}
			</h3>
			<p
				v-if="selectedPaymentIsSepa && paymentOptions['DirectDebitInternational'].serviceText"
				class="payment-method__sepa-info"
			>
				{{ paymentOptions['DirectDebitInternational'].serviceText }}
			</p>

			<CheckoutSepaFormFields
				unique-key="sepa"
				:validate-form="selectedPaymentIsSepa"
			/>
		</div>
		<h3
			v-if="selectedPaymentOption?.header"
			class="payment-method__headline-add"
		>
			{{ selectedPaymentOption.header }}
		</h3>
		<p
			v-if="selectedPaymentOption?.serviceText && !selectedPaymentIsSepa"
			class="payment-method__payment-due-text"
		>
			{{ selectedPaymentOption?.serviceText }}
		</p>
		<CheckoutPaymentInfo
			v-if="!isPaymentPage"
			:price-info="priceInfoFromStore"
			:flex-storno-amount="flexStornoAmount"
			:start-date="startDate"
		/>
		<CheckoutPaymentMethodInfo v-if="isSmallMobile" />
	</div>
</template>

<script lang="ts" setup>
import PaymentRadioButtonGroup from '@lmt-rpb/PaymentRadioButtonGroup/PaymentRadioButtonGroup.vue';
import CreditCardRadioButtonGroup from '@lmt-rpb/CreditCardRadioButtonGroup/CreditCardRadioButtonGroup.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import CheckoutSepaFormFields from '@lmt-rpb/CheckoutSepaFormFields/CheckoutSepaFormFields.vue';
import CheckoutPaymentMethodInfo from '@lmt-rpb/CheckoutPaymentMethodInfo/CheckoutPaymentMethodInfo.vue';
import CheckoutPaymentInfo from '@lmt-rpb/CheckoutPaymentInfo/CheckoutPaymentInfo.vue';
import { PAYMENT_TYPES, PAYMENT_TYPES_CONSTANTS } from '@global-js/constants';
import { computed, ref, watch } from 'vue';
import type { PriceInformation, SimplePrice } from '@/interfaces/checkout/offer-data';
import type { CardPaymentProviders, PaymentOptions, PaymentTypesKey } from '@/interfaces/checkout/checkoutTypes';
import { useStore } from '@/components/common/store';
import type { RadioButtonCommonProps } from '@/interfaces/components/inputs';
import { CheckoutValidationState } from '@/components/common/store/checkout';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

const store = useStore();
interface Props {
	paymentOptions: PaymentOptions;
	priceInfo: PriceInformation;
	flexStornoAmount?: SimplePrice;
	isPaymentPage?: boolean;
	startDate?: string;
}

const props = withDefaults(defineProps<Props>(), {
	isPaymentPage: false,
});

const emit = defineEmits(['CheckoutPaymentMethod:PaymentInfoChanged']);

const paymentInfo = ref<{ selectedPayment: keyof PaymentOptions; selectedCreditCard: string }>({
	selectedPayment: '' as keyof PaymentOptions,
	selectedCreditCard: '',
});

const selectedPaymentOption = computed(() => props.paymentOptions?.[paymentInfo.value.selectedPayment]);
const priceInfoFromStore = computed((): PriceInformation => store.state.checkout?.OfferData?.Offer?.PriceInformation || props.priceInfo);

initCheckoutValidation();

function initCheckoutValidation() {
	CheckoutValidationState.value['payment-type'] = {
		valid: false,
		validated: false,
		hasBackendError: false,
	};
	CheckoutValidationState.value['credit-card-radio-group'] = {
		valid: false,
		validated: false,
		hasBackendError: false,
	};
}

const { isSmallMobile } = storeToRefs(useBreakpointStore());
const paymentRadioValid = computed((): boolean => CheckoutValidationState.value['payment-type'].valid);
const paymentRadioValidated = computed((): boolean => CheckoutValidationState.value['payment-type'].validated);
const creditCardValid = computed((): boolean => CheckoutValidationState.value['credit-card-radio-group'].valid);
const creditCardValidated = computed((): boolean => CheckoutValidationState.value['credit-card-radio-group'].validated);
const showInfoText = ref(false);
const selectedPaymentIsSepa = computed(() => paymentInfo.value.selectedPayment === PAYMENT_TYPES_CONSTANTS.SEPA);

const creditCards = computed(() => {
	const cards: RadioButtonCommonProps<CardPaymentProviders>[] = [];
	Object.entries(props.paymentOptions).forEach(([key, value]) => {
		if (value.type === 'CreditCard') cards.push({ label: value.label, value: key as CardPaymentProviders });
	});
	return cards;
});

const overallPaymentMethods = computed(() => {
	const methods: RadioButtonCommonProps[] = [];
	Object.entries(props.paymentOptions).forEach(([key, value]) => {
		if (value.type !== 'CreditCard') {
			methods.push({ label: value.label, value: key });
		}
	});

	if (creditCards.value.length) {
		methods.push({ label: 'Kreditkarte', value: PAYMENT_TYPES_CONSTANTS.CREDIT_CARD });
	}
	return methods;
});

const creditCardServiceText = computed((): string => {
	let serviceText = '';
	Object.values(props.paymentOptions).forEach((value) => {
		if (value.label === paymentInfo.value.selectedCreditCard && value.serviceText) {
			serviceText = value.serviceText;
		}
	});
	return serviceText;
});

const selectBankTransfer = () => {
	const paymentType: PaymentTypesKey = 'BankTransfer';
	store.dispatch('checkout/updateTravel', { PaymentType: paymentType });
	emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentType);
};

const isCreditCardSelected = computed(() => paymentInfo.value.selectedPayment === PAYMENT_TYPES_CONSTANTS.CREDIT_CARD);

watch(
	() => paymentInfo.value,
	() => {
		const payment = paymentInfo.value;
		if (isCreditCardSelected.value) {
			let paymentMethodSelected: PaymentTypesKey | '' = '';
			(Object.keys(PAYMENT_TYPES) as Array<PaymentTypesKey>).forEach((paymentMethod) => {
				if (paymentMethod === payment.selectedCreditCard) {
					paymentMethodSelected = paymentMethod;
					CheckoutValidationState.value['credit-card-radio-group'].valid = true;
					CheckoutValidationState.value['credit-card-radio-group'].validated = true;
				}
			});
			CheckoutValidationState.value['payment-type'].valid = true;
			CheckoutValidationState.value['payment-type'].validated = true;
			emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentMethodSelected);
			store.dispatch('checkout/updateTravel', { PaymentType: paymentMethodSelected });
			return;
		}
		if (payment.selectedPayment === 'BankTransfer') {
			selectBankTransfer();
		}
		if (selectedPaymentIsSepa.value) {
			const paymentType: PaymentTypesKey = 'DirectDebitInternational';
			emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentType);
			store.dispatch('checkout/updateTravel', { PaymentType: paymentType });
		}

		CheckoutValidationState.value['payment-type'].valid = true;
		CheckoutValidationState.value['payment-type'].validated = true;
	},
	{ deep: true }
);
</script>
<style lang="scss" scoped>
:deep(.payment-radio-button-group) {
	margin: 1.6rem 0;
}

:deep(.payment-method-info__container) {
	margin-top: 1.6rem;

	@media (min-width: $breakpoint-verysmall) {
		margin-top: -6.4rem;
		margin-bottom: unset;
	}
}

#payment-radio-group,
#credit-card-radio-group {
	scroll-margin-top: $scroll-margin-top;
}

.payment-radio-group__error {
	color: $color-warning-2 !important;
	border: none;
	font-size: $font-small-3;
	width: unset;
	margin-bottom: 0.5rem;
	font-weight: $font-weight-semibold;

	:deep(label) {
		border: 0.2rem solid $color-warning-2;
	}

	:deep(input) {
		border: 0.2rem solid $color-warning-2;
	}

	@media (min-width: $breakpoint-large) {
		font-size: $font-small-2;
	}
}

.payment-radio-group__exclamation {
	fill: $color-warning-2 !important;
	margin-right: 0.5rem;
}

.payment-method {
	padding: 0 1.8rem 1.6rem;

	&__card__chf-info {
		font-size: $font-small-1;
	}

	&__header {
		display: flex;
		justify-content: space-between;
	}

	&__headline {
		min-width: 24.5rem;
		padding: 0;
		text-align: left;
		font-weight: $font-weight-semibold;
		font-size: $font-medium-3;
		margin-bottom: 1.7rem;

		&-add {
			// we have servicetext above the headline
			p + & {
				margin-top: 1.6rem;
			}

			padding: 0;
			text-align: left;
			font-weight: $font-weight-semibold;
			margin-bottom: 0.8rem;
			font-size: $font-small-1;
		}
	}

	&__headline-without-buttons {
		font-size: $font-medium-3;
	}

	&__sub-headline {
		display: block;
		font-weight: $font-weight-regular;
		color: $color-green-text;
		font-size: $font-small-2;
	}

	&__credit-card-info {
		margin-top: 1.5rem;
		position: relative;
		display: flex;
		justify-content: space-between;
		border: 0.1rem solid $color-primary-l4;
		border-radius: $border-radius-small;
		padding: 0.6rem 1.4rem;
	}

	&__credit-card-info-text,
	&__credit-card-service-text {
		font-size: $font-small-2;
		margin: 0;
		line-height: 1.7;
		max-width: 90%;
	}

	&__credit-card-service-text {
		margin-bottom: 1.6rem;
	}

	&__question-icon {
		align-self: center;
		flex-shrink: 0;
	}

	&__info-bubble {
		pointer-events: none;
		position: absolute;
		display: inline-block;
		bottom: 11rem;
		left: 0;
		font-size: $font-small-1;
		padding: 1.6rem;
		background: #44a678;
		color: $color-white;
		border-radius: $border-radius-small;
	}

	&__info-bubble::after {
		content: '';
		bottom: -3rem;
		right: 1rem;
		position: absolute;
		border: 2rem solid transparent;
		border-top-color: #44a678;
	}

	// Vue Transitionsbase
	.info-text-enter-from,
	.info-text-leave-to {
		visibility: hidden;
		opacity: 0;
	}

	.info-text-enter-active,
	.info-text-leave-active {
		transition: all 0.3s;
	}

	&__bank-transfer-info,
	&__sepa-info {
		font-size: $font-small-1;
		line-height: 1.7;
	}

	&__info-fti {
		margin-top: 1.8rem;
	}

	&__info-container {
		margin-bottom: 1.6rem;
	}

	&__payment-due-text {
		font-size: $font-small-2;
		margin-bottom: 0.3rem;
	}

	@media screen and (min-width: $breakpoint-mobilelarge) {
		&__info-bubble {
			bottom: 9rem;
		}
	}

	@media screen and (min-width: $breakpoint-verysmall) {
		padding: 1.6rem 0.8rem;

		&__info-bubble {
			bottom: 8rem;
		}
	}

	@media screen and (min-width: $breakpoint-small) {
		&__info-container {
			margin-bottom: 2.4rem;
		}

		&__info-bubble {
			bottom: 7rem;
		}
	}

	@media (min-width: $breakpoint-large) {
		padding: 2.4rem 3.6rem;

		&__payment-due-text {
			font-size: $font-small-1;
		}

		&__headline {
			font-size: $font-medium-2;
		}
	}
}
</style>
