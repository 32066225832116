<template>
	<FilterType class="most-popular-filter">
		<ShowMoreContainer :item-count="listOfAvailableFilters.length">
			<template #visibleContent>
				<template
					v-for="(availableFilter, index) in visibleFilters"
					:key="index"
				>
					<li class="most-popular-filter__list-item">
						<Checkbox
							v-if="availableFilter.attrType === 'HotelAttributes'"
							v-model="hotelFilters"
							:label="availableFilter.label"
							:custom-value="availableFilter.value"
						/>
						<Checkbox
							v-else
							v-model="ratingFilters"
							:label="availableFilter.label"
							:custom-value="availableFilter.value"
						/>
					</li>
				</template>
			</template>
			<template #hiddenContent>
				<template
					v-for="(availableFilter, index) in hiddenFilters"
					:key="index"
				>
					<li class="most-popular-filter__list-item">
						<Checkbox
							v-if="availableFilter.attrType === 'HotelAttributes'"
							v-model="hotelFilters"
							:label="availableFilter.label"
							:custom-value="availableFilter.value"
						/>
						<Checkbox
							v-else
							v-model="ratingFilters"
							:label="availableFilter.label"
							:custom-value="availableFilter.value"
						/>
					</li>
				</template>
			</template>
		</ShowMoreContainer>
	</FilterType>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import ShowMoreContainer from '@lmt-rpb/ShowMoreContainer/ShowMoreContainer.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { useStore } from '@/components/common/store';
import { mostPopularTypes } from '@/js/data/index';
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';

const store = useStore();
const listOfAvailableFilters = ref(mostPopularTypes);

const showAllFilters = ref(false);

const visibleFilters = computed(() => (showAllFilters.value ? listOfAvailableFilters.value : listOfAvailableFilters.value.slice(0, 4)));

const hiddenFilters = computed(() => (showAllFilters.value ? [] : listOfAvailableFilters.value.slice(4)));

const hotelFilters = computed({
	get() {
		return store.state.searchMask.mostPopularFilters?.hotelAttributes || [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData', {
			mostPopularFilters: { ratingAttributes: ratingFilters.value, hotelAttributes: value },
		});
	},
});

const ratingFilters = computed({
	get() {
		return store.state.searchMask.mostPopularFilters?.ratingAttributes || [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData', {
			mostPopularFilters: { ratingAttributes: value, hotelAttributes: hotelFilters.value },
		});
	},
});

const isChecked = computed(() => hotelFilters.value.length > 0 || ratingFilters.value.length > 0);

const emit = defineEmits(['update:isChecked']);

watch(isChecked, (newVal) => {
	emit('update:isChecked', newVal);
});

onMounted(() => {
	if (isChecked.value) emit('update:isChecked', isChecked.value);
});
</script>

<style lang="scss" scoped>
.most-popular-filter {
	&__list-item {
		padding: 0;
		margin-bottom: 1.6rem;

		:deep(.checkbox .checkbox__label) {
			font-weight: $font-weight-regular;
			font-size: $font-small-1;
		}
	}
}
</style>
