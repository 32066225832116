import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flying-time-filter" }
const _hoisted_2 = { class: "flying-time-filter__sliders" }

import { computed } from 'vue';
import { useStore } from '@/components/common/store';
import FlyingTimeSlider from '@lmt-rpb/FlyingTimeSlider/FlyingTimeSlider.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FlyingTimeFilter',
  setup(__props) {

const store = useStore();

const departureTime = computed({
	get() {
		return {
			from: store.state.searchMask.minDepartureTime ?? 0,
			to: store.state.searchMask.maxDepartureTime ?? 24,
		};
	},
	set(value) {
		store.commit('searchMask/updateFormData', { minDepartureTime: value.from, maxDepartureTime: value.to });
	},
});

const arrivalTime = computed({
	get() {
		return {
			from: store.state.searchMask.minArrivalTime ?? 0,
			to: store.state.searchMask.maxArrivalTime ?? 24,
		};
	},
	set(value) {
		store.commit('searchMask/updateFormData', { minArrivalTime: value.from, maxArrivalTime: value.to });
	},
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(FlyingTimeSlider, {
        modelValue: departureTime.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((departureTime).value = $event)),
        title: "Hinflug"
      }, null, 8 /* PROPS */, ["modelValue"]),
      _createVNode(FlyingTimeSlider, {
        modelValue: arrivalTime.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((arrivalTime).value = $event)),
        title: "Rückflug"
      }, null, 8 /* PROPS */, ["modelValue"])
    ])
  ]))
}
}

})