import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "travelers-field-content__content" }
const _hoisted_2 = { class: "travelers-field-content__helper" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "travelers-field-content__slider" }
const _hoisted_5 = { class: "travelers-field-content__age-value" }
const _hoisted_6 = {
  key: 0,
  class: "travelers-field-content__chilren-index"
}
const _hoisted_7 = { class: "travelers-field-content__footer" }
const _hoisted_8 = {
  key: 0,
  class: "travelers-field-content__infants-warning"
}

import RowList from '@lmt-rpb/RowList/RowList.vue';
import Counter from '@lmt-rpb/Counter/Counter.vue';
import RangeSlider from '@lmt-rpb/RangeSlider/RangeSlider.vue';
import { pluralize } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import type { Travelers } from '@/components/common/types';

interface Props {
	defaultAge?: number,
	modelValue?: Travelers,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TravelersFieldContent',
  props: {
    defaultAge: { default: 12 },
    modelValue: { default: () => ({ adult: 2, children: [] }) }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;
const store = useStore();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue: Travelers) {
		emit('update:modelValue', newValue);
	}
});

const childrenCount = ref(0);

watch(() => props.modelValue, () => {
	childrenCount.value = model.value.children.length;
}, { immediate: true });

watch(() => childrenCount.value, () => {
	model.value.children = model.value.children.slice(0, childrenCount.value);
}, { immediate: true });

const phoneNumber = computed((): string => store.state.config.phone);

const phoneNumberLink = computed((): string => store.state.config.phoneLink);

const isInfants = computed((): boolean => model.value.children
	.slice(0, childrenCount.value)
	.some((value: number) => value < 2));

const childrenAgeValue = (age: number) => (age < 2 && 'jünger als 2 Jahre') || pluralize(age, 'Jahr', 'Jahre');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RowList, { class: "travelers-field-content" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(Counter, {
          modelValue: model.value.adult,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model.value.adult) = $event)),
          class: "travelers-field-content__counter",
          "single-label": "Erwachsener",
          "plural-label": "Erwachsene",
          "is-adult": true
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(Counter, {
          modelValue: childrenCount.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((childrenCount).value = $event)),
          class: "travelers-field-content__counter",
          "single-label": "Kind",
          "plural-label": "Kinder",
          min: 0,
          max: 3,
          "is-adult": false
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("p", _hoisted_2, [
          _cache[2] || (_cache[2] = _createTextVNode(" Standardmäßig wird nur 1 Zimmer gebucht. Wünschen Sie mehrere Zimmer, rufen Sie uns bitte unter ")),
          _createElementVNode("a", {
            href: `tel:${phoneNumberLink.value}`,
            class: "travelers-field-content__phone"
          }, _toDisplayString(phoneNumber.value), 9 /* TEXT, PROPS */, _hoisted_3),
          _cache[3] || (_cache[3] = _createTextVNode(" an. "))
        ]),
        (childrenCount.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "travelers-field-content__alter" }, " Alter der Kinder bei der Rückreise ", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(childrenCount.value, (child, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "travelers-field-content__age-row"
                  }, [
                    _createElementVNode("p", _hoisted_5, [
                      (childrenCount.value > 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(index + 1) + ". Kind", 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      _createTextVNode(" " + _toDisplayString(childrenAgeValue(model.value.children[index])), 1 /* TEXT */)
                    ]),
                    _createVNode(RangeSlider, {
                      modelValue: model.value.children[index],
                      "onUpdate:modelValue": ($event: any) => ((model.value.children[index]) = $event),
                      class: "travelers-content__age",
                      "default-value": _ctx.defaultAge,
                      min: 1,
                      max: 17
                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "default-value"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (isInfants.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, " Kinder unter 2 Jahren haben keinen Anspruch auf einen eigenen Sitzplatz im Flieger. "))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})