import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-filter" }
const _hoisted_2 = { class: "flex-filter__container" }
const _hoisted_3 = { class: "flex-filter__tooltip" }
const _hoisted_4 = ["innerHTML"]

import { computed } from 'vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'FlexFilter',
  setup(__props) {

const store = useStore();

const flexItem = {
	Name: 'Flexible Stornierung & <br>Umbuchung',
	Tooltip:
		'Angebote, die günstig oder kostenlos stornierbar und umbuchbar sind oder bei denen ein solcher flexibler Tarif zubuchbar ist.',
};

const flexFilters = computed({
	get() {
		return store.getters['searchMask/flex'];
	},
	set(value: boolean) {
		store.commit('searchMask/updateFlex', value);
	},
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Checkbox, {
        modelValue: flexFilters.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((flexFilters).value = $event)),
        class: "flex-filter__checkbox",
        label: "Flexible Stornierung & Umbuchung"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", {
              innerHTML: flexItem.Name
            }, null, 8 /* PROPS */, _hoisted_4),
            _createVNode(InfoTooltip, {
              placement: "bottom",
              "modifier-class": "info-tooltip__body",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(flexItem.Tooltip), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ])
  ]))
}
}

})