import type { ClientType } from '@/interfaces/common';
import { type Airport as SearchFormAirport } from '@interfaces/search-form';
import airportData from '@/js/data/airports';

import type { GERMAN_COUNTRY_NAMES } from '@global-js/constants';
import { AUSTRIAN_AIRPORTS_FULL, GERMAN_AIRPORTS_FULL, SWISS_AIRPORTS_FULL, EUROPE_AIRPORTS_FULL } from '@global-js/constants';
import { AUSTRIAN_CLIENTS, GERMAN_CLIENTS, SWISS_CLIENTS } from '@global-js/constants';

export type Airport = Omit<SearchFormAirport, 'additional'>;

export const findAirportByKey = (code: string): Airport => airportData.find((a) => a.value === code) as Airport;

export const getMappedAirportOptions = (client: ClientType): Map<GERMAN_COUNTRY_NAMES, Airport[]> => {
	const airports = new Map<GERMAN_COUNTRY_NAMES, string[]>();

	if (AUSTRIAN_CLIENTS.includes(client)) {
		airports.set('Österreich', AUSTRIAN_AIRPORTS_FULL);
	} else if (GERMAN_CLIENTS.includes(client)) {
		airports.set('Deutschland', GERMAN_AIRPORTS_FULL);
	} else if (SWISS_CLIENTS.includes(client)) {
		airports.set('Schweiz', SWISS_AIRPORTS_FULL);
	}

	airports.set('Deutschland', GERMAN_AIRPORTS_FULL);
	airports.set('Österreich', AUSTRIAN_AIRPORTS_FULL);
	airports.set('Schweiz', SWISS_AIRPORTS_FULL);

	airports.set('Europa', EUROPE_AIRPORTS_FULL);

	return new Map(
		Array.from(airports).map(([key, value]) => [
			key,
			value.map((code) => {
				const airport = findAirportByKey(code);
				return { label: airport?.label ?? '', value: airport?.value ?? '' };
			}),
		])
	);
};
