import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, isRef as _isRef, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "destination-filter__not-found"
}
const _hoisted_2 = {
  key: 1,
  class: "destination-filter__list"
}

import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import FilterRow from './internals/FilterRow.vue';
import { computed } from 'vue';
import { pluralize } from '@/js/utils/utils';

import { useStore } from '@/components/common/store';
import { useFilterArray } from '../../composables/filterArray';
import type { LocationType } from '@/interfaces/components/hotelListData';
import { NBSP } from '@global-js/constants';

interface Props {
	title: string;
	locationType: LocationType;
	disabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DestinationFilter',
  props: {
    title: {},
    locationType: {},
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const destinations = computed(() => store.state.types.destination);
const dealsFilterActive = computed(() => store.state.searchMask.deal);

const isRegion = computed(() => props.locationType === 'REGIONGROUP');

const locationIdName = computed((): 'RegionID' | 'CityID' => (isRegion.value ? 'RegionID' : 'CityID'));
const destinationTotal = computed(() => destinations.value.reduce((sum, curr) => sum + curr.Count, 0));
const radioOptionList = computed(() => {
	if (!destinations.value) {
		return [];
	}
	return [
		{
			boldLabel: undefined,
			label: dealsFilterActive.value
				? `${placeholderText.value}`
				: `${placeholderText.value}${NBSP}(${pluralize(destinationTotal.value, 'Hotel', 'Hotels')})`,
			value: undefined,
			disabled: false,
		},
		...destinations.value.map((destination) => ({
			boldLabel: destination.Name,
			label: dealsFilterActive.value ? '' : `(${pluralize(destination.Count, 'Hotel', 'Hotels')})`,
			value: destination[locationIdName.value]!,
			disabled: !dealsFilterActive.value && destination.Count === 0,
		})),
	];
});

const { filterExpression, filteredItems, notFoundMessage } = useFilterArray(radioOptionList, ['boldLabel', 'label']);

const selectedDestinationName = computed(() => store.state.searchMask.destinationTypeName);

const selectedValue = computed({
	get() {
		return store.state.searchMask.destinationTypes?.[0] as number;
	},
	set(value: number) {
		const foundDestination = store.state.types.destination.find((type) => type[locationIdName.value] === value);

		const destinationTypes = foundDestination ? [value] : [];
		const destinationTypeName = foundDestination?.Name ?? '';
		const selectedGeoInventory = foundDestination ?? {
			RegionID: undefined,
			CityID: undefined,
			Name: '',
			Count: 0,
		};

		store.state.searchMask.destinationTypes = destinationTypes;
		store.commit('searchMask/updateFormData', {
			destinationTypes,
			destinationTypeName,
			selectedGeoInventory,
		});
	},
});

const placeholderText = computed((): string => {
	if (props.disabled) {
		return 'Keine Treffer';
	}
	if (isRegion.value) {
		return 'Alle Regionen';
	}
	return 'Alle Orte';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownFilterType, {
    "search-term": _unref(filterExpression),
    "onUpdate:searchTerm": _cache[1] || (_cache[1] = ($event: any) => (_isRef(filterExpression) ? (filterExpression).value = $event : null)),
    class: _normalizeClass(['destination-filter', { 'destination-filter--disabled': _ctx.disabled }]),
    title: _ctx.title,
    icon: "marker2",
    "selected-value": selectedDestinationName.value,
    placeholder: placeholderText.value,
    disabled: _ctx.disabled,
    searchable: true,
    "show-toggle-icon": false,
    "with-menu-header": false,
    "show-buttons": false,
    "modal-header": isRegion.value ? 'Regionen' : 'Orte'
  }, {
    default: _withCtx(({ close }) => [
      (_unref(notFoundMessage))
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_unref(notFoundMessage)), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredItems), (item) => {
              return (_openBlock(), _createBlock(FilterRow, {
                id: `destination-filter-${item.value}`,
                key: `${item.boldLabel ?? ''}${item.label}`,
                modelValue: selectedValue.value,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((selectedValue).value = $event)),
                  close
                ],
                tabindex: _ctx.disabled ? -1 : 0,
                label: item.label,
                "bold-label": item.boldLabel,
                value: item.value,
                disabled: item.disabled
              }, null, 8 /* PROPS */, ["id", "modelValue", "tabindex", "label", "bold-label", "value", "disabled", "onUpdate:modelValue"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["search-term", "class", "title", "selected-value", "placeholder", "disabled", "modal-header"]))
}
}

})