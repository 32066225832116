<template>
	<component
		:is="iconComponent"
		class="base-icon"
	>
		<slot></slot>
	</component>
</template>

<!-- If the slot is used, it is also necessary to add the slot tag to the svg, so it gets correctly passed on. -->
<!-- See star-icon.vue for reference. -->

<script lang="ts" setup>
/* eslint-disable global-require */
import { computed } from 'vue';
import { type IconNames, icons } from './svg';

interface Props {
	name: IconNames;
}

const { name } = defineProps<Props>();

const iconComponent = computed(() => icons[name]);

</script>
