<template>
	<div
		class="counter"
		aria-live="polite"
	>
		<div
			class="counter__label"
			:id="`counter-display-${uuid}`"
		>
			{{ props.pluralLabel }}
		</div>
		<div class="counter__controls">
			<RoundedButton
				class="counter__button is-decrease"
				:disabled="model === min"
				@click="decrease"
				@keydown.enter.prevent="decrease"
				:aria-label="labels.decrease"
			>
				<BaseIcon
					name="minus"
					class="counter__icon"
				/>
			</RoundedButton>
			<div
				class="counter__value"
				:aria-labelledby="`counter-value-${uuid}`"
			>
				{{ model }}
			</div>
			<RoundedButton
				class="counter__button is-increase"
				:disabled="model === max"
				@click="increase"
				@keydown.enter.prevent="increase"
				:aria-label="labels.increase"
			>
				<BaseIcon
					name="plus"
					class="counter__icon"
				/>
			</RoundedButton>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';

interface Props {
	min?: number,
	max?: number,
	pluralLabel: string,
	modelValue: number,
	adultDecreaseLabel?: string,
	adultIncreaseLabel?: string,
	childDecreaseLabel?: string,
	childIncreaseLabel?: string,
	isAdult?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	min: 1,
	max: 4,
	pluralLabel: '',
	adultDecreaseLabel: 'Einen Erwachsenen entfernen',
	adultIncreaseLabel: 'Einen Erwachsenen hinzufügen',
	childDecreaseLabel: 'Ein Kind entfernen',
	childIncreaseLabel: 'Ein Kind hinzufügen',
	isAdult: true
});

const emit = defineEmits(['update:modelValue']);

const uuid = uuidv4();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newCount) {
		emit('update:modelValue', newCount);
	}
});

const increase = (): void => {
	model.value = Math.max(props.min, Math.min(props.modelValue + 1, props.max));
};

const decrease = (): void => {
	model.value = Math.max(props.min, Math.min(props.modelValue - 1, props.max));
};

const labels = computed(() => ({
  decrease: props.isAdult ? props.adultDecreaseLabel : props.childDecreaseLabel,
  increase: props.isAdult ? props.adultIncreaseLabel : props.childIncreaseLabel
}));

</script>

<style lang="scss" scoped>
.counter {
	display: flex;
	justify-content: space-between;

	.counter__icon {
		vertical-align: middle;
		:deep(path) {
			fill: $color-primary;
		}
	}

	.counter__button {
		padding: 0 1.6rem;
		background: none;

		&:disabled .counter__icon {
			:deep(path) {
				fill: $color-state-disabled;
			}
		}
	}

	.counter__label {
		color: $color-primary;
		font-size: $font-medium-2;
		font-weight: $font-weight-semibold;

		&::-ms-expand {
			display: none;
		}
	}

	.counter__controls {
		display: flex;
		box-sizing: border-box;
		flex-direction: row;
		height: 38px;
		border: 1px solid $color-search-mask-border;
		border-radius: $border-radius-button;
	}

	.counter__value {
		font-size: 2rem;
		font-weight: $font-weight-semibold;
		align-content: center;
		color: $color-black;
	}
}
</style>
