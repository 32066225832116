import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-header" }
const _hoisted_2 = {
  key: 1,
  class: "filter-header__title"
}

import DeleteButtonFilter from '@lmt-rpb/Atoms/DeleteButtonFilter/DeleteButtonFilter.vue';
import type { FontAwesomeIcon } from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';

interface Props {
	title: string;
	icon?: FontAwesomeIcon;
	deleteButtonString?: string;
	isChecked?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FilterHeader',
  props: {
    title: { default: '' },
    icon: { default: undefined },
    deleteButtonString: { default: '' },
    isChecked: { type: Boolean }
  },
  emits: ["delete"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(FaIcon, {
          key: 0,
          class: "filter-header__icon",
          icon: _ctx.icon
        }, null, 8 /* PROPS */, ["icon"]))
      : _createCommentVNode("v-if", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_ctx.deleteButtonString !== '')
      ? (_openBlock(), _createBlock(DeleteButtonFilter, {
          key: 2,
          "button-text": "Löschen",
          "aria-label": 'Filter löschen',
          "is-checked": _ctx.isChecked,
          onDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete', _ctx.deleteButtonString)))
        }, null, 8 /* PROPS */, ["is-checked"]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})