<template>
	<component
		:is="as"
		class="button"
		:class="classes"
		:disabled="disabled"
		:title="titleValue"
		v-bind="{ ...defaults, ...$attrs }"
	>
		<slot />
	</component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { ButtonType } from '@helper/types/commonProps';
import type { RoundedButtonTheme } from './defaults/theme';

interface Props {
	as?: 'a' | 'button';
	theme?: RoundedButtonTheme;
	type?: ButtonType;
	disabled?: boolean;
	titleValue?: string;
}

const { theme, disabled = false, titleValue = '', type = 'button', as = 'button' } = defineProps<Props>();

const classes = computed(() => ({
	[`button--${theme}`]: Boolean(theme),
	'is-disabled': disabled,
}));

const defaults = computed(() => (as === 'button' ? { type } : {}));
</script>

<style lang="scss" scoped>
.button {
	padding: 1rem 3rem;
	border: none;
	border-radius: $border-radius-button;
	outline: none;
	background: $color-primary;
	color: $color-white;
	font-size: $font-small-1;
	font-weight: $font-weight-semibold;
	font-family: $font-family;
	line-height: $line-height-default;
	cursor: pointer;

	&:hover,
	&:focus-visible {
		border-color: $color-primary-l0;
		background: $color-primary-l0;
		color: $color-white;
	}

	&.button--outlined {
		border: 0.1rem solid $color-primary;
		background: $color-white;
		color: $color-primary;
		transition: all ease 0.3s;

		&:focus-visible,
		&:hover {
			background: $color-primary-l0;
			color: $color-white;
		}
	}

	&.button--transparent-no-outline {
		border: 0.1rem solid transparent;
		background: transparent;
		color: $color-primary;
		font-weight: $font-weight-regular;

		&:focus-visible,
		&:hover {
			opacity: 0.8;
		}
	}

	&.button--no-outline {
		background: transparent;
		color: $color-primary;
		font-weight: $font-weight-semibold;
	}

	&.button--gradient {
		background: linear-gradient($color-primary, $color-primary-darker);
		box-shadow: $box-shadow;
	}

	&.button--extra {
		background: $color-extra;
		color: $color-white;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;

		&:focus-visible,
		&:hover {
			background: $color-extra-t8;
			color: $color-white;
		}
	}

	&.button--checkout-next {
		background: linear-gradient($color-checkout-button-next-1, $color-checkout-button-next-2);
		box-shadow: $box-shadow;
		color: $color-white;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
	}

	&.is-disabled {
		background: $color-dark-gray;
		color: $color-light-gray;
		pointer-events: none;

		&.button--outlined {
			border: 0.2rem solid $color-dark-gray;
			background: $color-light-gray;
			color: $color-text;
		}

		&.button--extra {
			background: $color-dark-gray;
			color: $color-light-gray;

			&:focus-visible,
			&:hover {
				background: $color-dark-gray;
				color: $color-light-gray;
			}
		}
	}
}
</style>
