import { ShoppingCart } from '@/interfaces/checkout/shopping-cart';
import request from '@components/common/services/request';

const url = 'v1/checkout/shopping-cart';

export async function get(token: string, isPaymentPage = true): Promise<ShoppingCart> {
	try {
		const resp = await request<ShoppingCart>({ method: 'post',	url, data: { Token: token } }, 'checkout-shopping-cart', 1, true);
		return getShoppingCart(resp, isPaymentPage);
	} catch (error) {
		console.error('Shopping Cart error: ', error);
		throw error;
	}
}

function getShoppingCart(resp: ShoppingCart | null, isPaymentPage: boolean) {
	if (!resp || Object.keys(resp).length === 0) {
		throw new Error('Shopping Cart response is empty');
	}
	if (resp.Errors && resp.Errors[0]?.Message === 'Expired token!') {
		throw new Error('Expired Token');
	}
	if (resp.Errors && resp.Errors.length > 0) {
		throw new Error(resp.Errors[0].Message);
	}
	if (isPaymentPage && (!resp.l3pId || !resp.DataCollectionURL)) {
		throw new Error('Cannot initialze payment provider. id or url missing.');
	}
	if (isPaymentPage && (!resp.PriceInformation || !resp.AvailablePaymentTypes)) {
		throw new Error('Price information or payment types missing.');
	}
	return resp;
}
