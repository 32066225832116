import { ApiDataTypes, Travelers } from '@components/common/types/index';
import request from './request';
import * as NeustaService from './neustaService';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { isBestPackageOfferForHotelList } from '@/js/utils/typeUtils';
import { BaseOfferForHotelListResponse } from '@/interfaces/common';

export const getUniqueKey = ({ offerId, iffCode }: { offerId: string, iffCode: number }) => `${offerId}_${iffCode}`;
export const NO_RESPONSE_OR_EMPTY_RESPONSE = 'No response or empty response'

export async function getHotelListOffer(apiUrl: string, bodyParams: ApiDataTypes, uniqueKey: string): Promise<BaseOfferForHotelListResponse<ItemType>> {
	const response = await request<BaseOfferForHotelListResponse<ItemType>>(
		{ method: 'post', url: apiUrl, data: bodyParams },
		uniqueKey
	);

	if (!response || Object.keys(response).length === 0) {
		throw new Error(NO_RESPONSE_OR_EMPTY_RESPONSE);
	}

	return response;
}

export async function getDiscounts(rawOffers: ItemType[], travellerInfo: Travelers): Promise<ItemType[]> {
	const neustaRequest = NeustaService.createDiscountRequest(rawOffers, travellerInfo.adult, travellerInfo.children);
	const neustaDeals = await NeustaService.getAsMap(neustaRequest);
	return rawOffers.map((offer) => {
		let uniqueKey;
		if (isBestPackageOfferForHotelList(offer)) {
			uniqueKey = getUniqueKey({ offerId: offer.PackageOffer.OfferID, iffCode: offer.Hotel.IffCode });
		} else {
			uniqueKey = getUniqueKey({ offerId: offer.AccommodationOffer.OfferID, iffCode: offer.Hotel.IffCode });
		}
		return {
			...offer,
			WasPrice: neustaDeals.get(uniqueKey),
			DiscountedPrice: neustaDeals.get(uniqueKey),
		} as ItemType;
	});
}
