<template>
	<SkeletonLoader
		v-if="isLoading"
		class="filter-price filter-price__loader"
		:loader-width="7"
		:loader-height="1.6"
		border-radius="0.5rem"
		width-unit="rem"
	/>
	<label
		v-else-if="price"
		class="filter-price filter-price__value"
	>
		{{ price }}
	</label>
</template>

<script setup lang="ts">
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import type { Price } from '@/interfaces/checkout/offer-data';
import { getPriceCorrectCurrency } from '@/js/utils/priceUtils';
import { computed } from 'vue';

type Props = {
	isLoading: boolean
	value?: Price
}

const props = defineProps<Props>();

const price = computed(() => props.value ? `ab ${getPriceCorrectCurrency(props.value)}` : null)

</script>

<style lang="scss" scoped>
.filter-price {
	display: inline-block;
	margin-left: auto;
	margin-bottom: 0;
	color: $color-extra;
	font-size: $font-small-1;
	font-weight: $font-weight-semibold;
}
</style>
