import type { BaseStructure } from '@helper/locales/translations/base';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import type { LocalesCodes } from '@/js/utils/locales';
import type { FlattenedObject } from '@/js/utils/typeUtils';

export const useTranslations = () => {
	const { t, locale, availableLocales } = useI18n();

	return {
		setLocale: (lang: LocalesCodes) => {
			locale.value = lang;
		},
		getAvailableLocales: () => availableLocales,
		locale: computed(() => locale.value),
		translate: t as unknown as TranslationsParameters,
	};
};

// Type definitions
type Replaceable = string | number | boolean;
type NamedValues = Record<string, Replaceable>;

type TranslationsMap = FlattenedObject<BaseStructure>;
type ExtractPlaceholders<T extends string> = T extends `${string}{${infer Var}}${infer Rest}` ? Var | ExtractPlaceholders<Rest> : '';

type TranslationsParameters = {
	<K extends keyof TranslationsMap>(key: K, defaultValue?: string): TranslationsMap[K];
	<K extends keyof TranslationsMap>(key: K, n?: number): TranslationsMap[K];
	<K extends keyof TranslationsMap>(
		key: K,
		namedValues?: Record<ExtractPlaceholders<TranslationsMap[K]>, Replaceable> | NamedValues
	): TranslationsMap[K];
	<K extends keyof TranslationsMap>(
		key: K,
		namedValues?: Record<ExtractPlaceholders<TranslationsMap[K]>, Replaceable> | NamedValues,
		n?: number
	): TranslationsMap[K];
};
