import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "transition-expand" }

interface Props {
	showContent: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransitionExpand',
  props: {
    showContent: { type: Boolean, default: false }
  },
  setup(__props: any) {

// source: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/

const props = __props;

const forceRepaintTriggerAnimation = (element: HTMLElement, height: string): void => {
	void getComputedStyle(element).height;
	requestAnimationFrame(() => {
		element.style.height = height;
	});
};

const setElementStyles = (element: HTMLElement, styles: Partial<CSSStyleDeclaration>): void => {
	Object.assign(element.style, styles);
};

const enter = (element: HTMLElement): void => {
	const width = getComputedStyle(element).width;

	setElementStyles(element, {
		width,
		position: 'absolute',
		visibility: 'hidden',
		height: 'auto',
	});

	const height = getComputedStyle(element).height;

	setElementStyles(element, {
		width: '',
		position: '',
		visibility: '',
		height: '0',
	});

	forceRepaintTriggerAnimation(element, height);
};

const afterEnter = (element: HTMLElement): void => {
	element.style.height = 'auto';
};

const leave = (element: HTMLElement): void => {
	const height = getComputedStyle(element).height;
	element.style.height = height;
	forceRepaintTriggerAnimation(element, '0');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "expand",
    onEnter: enter,
    onAfterEnter: afterEnter,
    onLeave: leave,
    persisted: ""
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ], 512 /* NEED_PATCH */), [
        [_vShow, _ctx.showContent]
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})