import base, { type BaseStructure } from './base';
import { deepOverride } from '@/js/utils/objectUtils';

const swissWordings: BaseStructure = {
	...deepOverride(base, {
		close: 'Schliessen',
		searchBarMain: {
			travelType: {
				package: 'Badeferien',
			},
		},
	}),
} as const;
export default swissWordings;
