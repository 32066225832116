import type { SearchFormDataType } from '@/interfaces/search-form';
import { useStore } from '@/components/common/store';
import { cloneDeep } from '@utils/utils';
import { dateDiff } from '@utils/dateUtils';
import { storage } from './localStorageUtils';
import * as hotelListService from '../hotelListService';

const store = useStore();
const formData = store.state.searchMask;

const checkOfferDurationToDate = (item: SearchFormDataType) => item.offerDuration.to && dateDiff(item.offerDuration.to, new Date()) < 30;

const removeDisabledHotels = async (items: SearchFormDataType[]) => {
	const hotelurls = items.filter((i) => i.destination?.url?.includes('/hotel/')).map((i) => i.destination?.url);
	const hotelIds = hotelurls.map((url) => Number(url?.split('/')[2]));
	const existingHotels = await hotelListService.get(hotelIds);
	const newHistory = items.filter((i) => {
		const isNotHotel = !i.destination?.url?.includes('/hotel/');
		const hotelExists = existingHotels.find((hotel) => i.destination?.url?.includes(hotel.IffCode.toString()));
		return isNotHotel || hotelExists;
	});
	storage.setItem('searchHistory', newHistory);
	return newHistory;
};

export const get = () => {
	let items = [] as SearchFormDataType[];
	items = storage.getItem<SearchFormDataType>('searchHistory');
	items = items.filter(checkOfferDurationToDate);
	return items;
};

export const getThreeReversed = async (checkDisabledHotels = false) => {
	let searchHistory = await get();
	if (checkDisabledHotels && searchHistory.length > 0) {
		searchHistory = await removeDisabledHotels(searchHistory);
	}
	return searchHistory.slice(Math.max(searchHistory.length - 3, 0)).reverse();
};

const updateSearchHistoryEntry = (newEntry: SearchFormDataType, oldEntry: SearchFormDataType) => {
	const currentLabel = newEntry.destination?.label;
	const updatedEntry = newEntry;
	updatedEntry.destination = oldEntry.destination;
	updatedEntry.destination!.label = currentLabel;
	return updatedEntry;
};

const updateSearchHistory = (searchHistory: SearchFormDataType[]) => {
	const selectedDestinationUrl = formData.destination?.url || window.location.pathname;
	const currentEntryIndex = searchHistory.findIndex((item) => item.destination?.url === selectedDestinationUrl);
	let newEntry = cloneDeep(formData);
	if (currentEntryIndex !== -1) {
		const oldEntry = searchHistory.splice(currentEntryIndex, 1)[0];
		newEntry = updateSearchHistoryEntry(newEntry, oldEntry);
	} else if (newEntry.destination && !newEntry.destination.url) {
		newEntry.destination.url = window.location.pathname;
	}
	searchHistory.push(newEntry);
	return searchHistory.slice(Math.max(searchHistory.length - 4, 0));
};

export const update = () => {
	if (formData.destination?.label) {
		let searchHistory = get();
		searchHistory = updateSearchHistory(searchHistory);
		storage.setItem('searchHistory', searchHistory);
	}
};
