<template>
	<div
		class="autocomplete"
		@focus.capture="onFocus"
		@focusout.capture="onFocusOut"
	>
		<DropdownFilterType
			ref="dropdown"
			:selected-value="model?.label"
			tabindex="-1"
			class="autocomplete__dropdown"
			:title="label"
			:searchable="true"
			placeholder="Beliebig"
			:icon="icon"
			:allow-clear="true"
			apply-button-text="Übernehmen"
			:search-term="searchTerm"
			:with-menu-header="false"
			:emit-on-ok="true"
			:is-focused="focus"
			:show-toggle-icon="false"
			@DropdownFilterType:clear="clearInput"
			@DropdownFilterType:abort="onAbort"
			@DropdownFilterType:KeyEnter="selectFirst"
			@DropdownFilterType:focusedOut="onFocus"
			@DropdownFilterType:focusedIn="onFocusOut"
			@update:searchTerm="onInput"
		>
			<div
				ref="list"
				class="autocomplete__list"
				role="group"
				aria-label="Ergebnisse"
				tabindex="-1"
			>
				<template v-if="loading">
					<!-- Loading -->
					<slot name="loading">
						<div class="autocomplete__load-box">
							<Loading
								size="small"
								class="autocomplete__loader"
							/>
						</div>
					</slot>
				</template>
				<template v-else-if="error">
					<!-- Error -->
					<slot
						name="error"
						:error="error"
						:term="term"
					>
						<div class="autocomplete__error">
							<p>Der Server ist im Augenblick nicht erreichbar.</p>
							Bitte versuchen Sie es in Kürze erneut.
						</div>
					</slot>
				</template>
				<template v-else>
					<template v-if="minCharCount && !Object.keys(items).length">
						<!-- No result -->
						<slot name="no-result">
							<div class="autocomplete__helper">Keine Ergebnisse gefunden.</div>
						</slot>
					</template>
					<template v-else>
						<slot name="result">
							<!-- Result list -->
							<ul
								ref="itemList"
								class="autocomplete__item-list"
							>
								<template
									v-for="(category, key) in items"
									:key="key"
								>
									<!-- Category name -->
									<li
										class="autocomplete__item-header"
										aria-hidden="true"
									>
										{{
											minCharCount || key === 'searchHistory'
												? getCategoryName(`${key}`)
												: 'Beliebteste Destinationen'
										}}
									</li>

									<!-- Result item -->
									<template
										v-for="(destination, index) in category"
										:key="`${key}-${index}`"
									>
										<li
											v-if="isSearchFormDataType(destination)"
											ref="item"
											:key="`${key}-${index}`"
											role="button"
											class="autocomplete__item"
											:class="{ 'is-active': flattenedItemsIndex(key, index) === 0 }"
											@click="selectItem(destination, index)"
										>
											<div class="autocomplete__search-history-destination">
												{{ destination.destination?.label }}
											</div>
											<div class="autocomplete__search-history-departure">
												{{ getAirportLabels(destination) }}
											</div>
											<div class="autocomplete__search-history-person">
												{{ pluralize(destination.travelers.adult, 'Erwachsener', 'Erwachsene') }}
												{{
													destination.travelers.children.length
														? ', ' + pluralize(destination.travelers.children.length, 'Kind', 'Kinder')
														: ''
												}}
											</div>
											<div class="autocomplete__search-history-duration">
												{{ formatDateInterval(destination.offerDuration.from, destination.offerDuration.to) }},
												{{ getTravelDuration(destination.travelDuration) }}
											</div>
										</li>
										<li
											v-else
											ref="item"
											role="button"
											class="autocomplete__item"
											:class="{ 'is-active': flattenedItemsIndex(key, index) === 0 }"
											@click="selectItem(destination, index, key)"
										>
											{{ destination.Label }}
											<span class="autocomplete__item-sublabel">{{ destination.SubLabel }}</span>
										</li>
									</template>
								</template>
							</ul>
						</slot>
					</template>
				</template>
			</div>
		</DropdownFilterType>
	</div>
</template>

<script lang="ts" setup>
import topPlacesData from '@json/topPlaces.json';
import axios, { type AxiosError, type Canceler, type AxiosResponse } from 'axios';
import { isOfferlistPage, pluralize, getTravelDuration } from '@utils/utils';
import { formatDateInterval } from '@utils/dateUtils';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import type { SuggestionDataType, FullSuggest, SearchFormDataType } from '@interfaces/search-form';
import { EventBus } from '@global-js/event-bus';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import * as searchHistoryService from '@/components/common/services/localStorage/searchHistoryService';
import airportData from '@/js/data/airports';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

const store = useStore();

interface Props {
	url: string;
	label: string;
	filter?: any;
	minLength?: number;
	icon: string;
	modelValue: SuggestionDataType | null;
	errorDuration?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	filter: (data: any) => data,
	minLength: 2,
	errorDuration: false,
});

const term = ref<string>('');

const dropdown = ref<InstanceType<typeof DropdownFilterType> | null>(null);

const searchTerm = ref<string>(props.modelValue?.label ?? '');

const { isDesktop } = storeToRefs(useBreakpointStore());

const items = ref<FullSuggest>({});

const loading = ref(false);

const error = ref<AxiosError | null>(null);

const abort = ref<Canceler>();

const activeIndex = ref(0);

const focus = ref(false);

const itemList = ref<HTMLElement>();

const defaultAutosuggestsLoaded = ref(false);

const initialLoad = ref(true);

const emit = defineEmits(['update:modelValue']);

const minCharCount = computed((): boolean => !!term.value && term.value.length >= props.minLength);

const pageType = computed((): string => store.state.config.pageType);

const itemsArray = computed((): SuggestionDataType[] =>
	Object.keys(items.value).reduce((acc: SuggestionDataType[], cat: string) => {
		const category = (items.value as { [key: string]: SuggestionDataType[] })[cat];
		return [...acc, ...category];
	}, [])
);

let requestAvailableHotels = true;

const getSearchHistory = async () => {
	loading.value = true;
	const searchHistory = await searchHistoryService.getThreeReversed(requestAvailableHotels);
	loading.value = false;
	requestAvailableHotels = false;
	return searchHistory;
};

const getDefaultItems = async (): Promise<FullSuggest> => {
	const searchHistory = await getSearchHistory();
	const defaultItems: FullSuggest = {
		searchHistory,
		RegionGroups: topPlacesData.RegionGroups as SuggestionDataType[],
	};
	if (!searchHistory.length) {
		delete defaultItems.searchHistory;
	}
	return defaultItems;
};

const getAirportLabels = (formData: SearchFormDataType): string => {
	let result = 'Eigene Anreise';
	if (!formData.onlyHotel && formData.departure.length) {
		result =
			'Flug ab ' +
			airportData
				.filter((airport) => formData.departure.includes(airport.value))
				.map((a) => a.label)
				.join(', ');
	} else if (!formData.onlyHotel) {
		result = 'Inkl. Flug';
	}
	return result;
};

const onFocus = (): void => {
	focus.value = true;
};
const onFocusOut = (): void => {
	focus.value = false;
};

const flattenedItemsIndex = (actualKey: string, actualIndex: number): number => {
	let destinationCount = 0;

	for (const [key, destinationArray] of Object.entries(items.value)) {
		if (key === actualKey) {
			destinationCount += actualIndex;
			break;
		}
		destinationCount += destinationArray ? destinationArray.length : 0;
	}

	return destinationCount;
};

const buildUrlWithId = (item: SuggestionDataType): string => {
	const type = item.Type || item.type;
	const id = item.ID || item.id;

	if (!id || !type) {
		return '';
	}

	let url = `/hotel/${id}/`;

	if (type === 'region_group') {
		url = `/region/g/${id}/`;
	} else if (type === 'region') {
		url = `/hotels/r/${id}/`;
	} else if (type === 'city') {
		url = `/hotels/o/${id}/`;
	}
	return url;
};

const itemKeysToLowerCase = (item: SuggestionDataType): SuggestionDataType => {
	const lowerCaseItemKeys: SuggestionDataType = {};

	for (const [key, value] of Object.entries(item)) {
		lowerCaseItemKeys[key.toLowerCase()] = value;
	}

	return lowerCaseItemKeys;
};

const getIdFromUrl = (): number => {
	if (!isOfferlistPage()) {
		return 0;
	}

	let id = null;

	// get id from location path
	const pathArray = window.location.pathname.split('/').filter((fraction) => fraction);
	const onlyNumbers = new RegExp('^[0-9]+$');

	pathArray.forEach((path) => {
		if (path.match(onlyNumbers)) {
			id = +path;
		}
	});

	return id || 0;
};

const getIdFromStore = (): number | false => {
	const regionTypeIds = {
		page_country: 'rgid',
		page_region: 'rid',
		page_city: 'cyid',
	};
	let destinationIdSelector = '',
		destinationId = 0;

	for (const [key, value] of Object.entries(regionTypeIds)) {
		if (document.body.classList.contains(key)) {
			destinationIdSelector = value;
		}
	}

	const regionString = store.state.config[destinationIdSelector];

	if (regionString) {
		destinationId = parseInt(regionString, 10);
		destinationId += destinationIdSelector === 'rgid' ? 40000 : 0; // add 40000 for region groups
	}

	return destinationId || false;
};

const updateBySearchHistoryEntry = (searchParams: SearchFormDataType) => {
	store.commit('searchMask/updateFormData', {
		destination: searchParams.destination,
		departure: searchParams.departure,
		travelDuration: searchParams.travelDuration,
		travelers: searchParams.travelers,
		offerDuration: searchParams.offerDuration,
		onlyHotel: searchParams.onlyHotel,
	});
};

const model = computed({
	get: () => store.state.searchMask.destination,
	set: (newValue) => emit('update:modelValue', newValue),
});

const commitTerm = (): void => {
	store.commit('searchMask/updateFormData', {
		searchTerm: searchTerm.value,
	});
};

const isSearchFormDataType = (item: SuggestionDataType | SearchFormDataType): item is SearchFormDataType =>
	(item as SearchFormDataType).destination !== undefined;

const selectItem = (item: SuggestionDataType | SearchFormDataType, i?: number, key?: string): void => {
	let label;
	if (isSearchFormDataType(item)) {
		updateBySearchHistoryEntry(item);
		label = item.destination?.label ?? '';
	} else {
		label = item.label ?? item.Label ?? '';

		item.url = buildUrlWithId(item);
		model.value = itemKeysToLowerCase(item);
	}
	let index = i;
	if (key) {
		index = flattenedItemsIndex(key, i);
	}
	if (label) {
		searchTerm.value = label;
		term.value = label;
		search();
	}
	if (index !== undefined && index >= 0) {
		activeIndex.value = index;
		selectItemBy(index);
	}
	dropdown.value?.close();

	if (isOfferlistPage() && isDesktop.value) {
		EventBus.$emit('search:submit');
	}
	if (!isDesktop.value) {
		commitTerm();
	}
};

const clearTerm = (): void => {
	term.value = model.value?.label ?? '';
	searchTerm.value = model.value?.label ?? '';
};

const selectItemBy = (index: number): void => {
	if (Object.keys(items.value).length) {
		selectItem(itemsArray.value[index] || itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

const selectFirst = (): void => {
	selectItemBy(activeIndex.value);
};

const selectItemByLabel = (label: string | undefined): void => {
	if (!label) {
		return;
	}

	if (Object.keys(items.value).length) {
		const active = itemsArray.value.find((item) => item.Label === label);
		selectItem(active || itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

const selectItemById = (id: number | false): void => {
	if (!id) {
		return;
	}

	if (Object.keys(items.value).length) {
		const currentDestination: SuggestionDataType | undefined = itemsArray.value.find((item) => item.ID === +id);
		if (currentDestination) {
			currentDestination.url = buildUrlWithId(currentDestination);
		}
	} else {
		clearTerm();
	}
};

const getCategoryName = (category: string): string =>
	(
		({
			Countries: 'Land',
			Hotels: 'Hotel',
			Cities: 'Stadt',
			Regions: 'Region',
			RegionGroups: 'Destinationen',
			searchHistory: 'Zuletzt gesucht',
		}) as { [key: string]: string }
	)[category];

const clearInput = async (): Promise<void> => {
	term.value = '';
	searchTerm.value = '';
	if (model.value) {
		model.value = null;
	}
	items.value = await getDefaultItems();
};
const onAbort = (): void => {
	term.value = model.value?.label ?? term.value;
	searchTerm.value = model.value?.label ?? searchTerm.value;
};
const search = (): void => {
	loading.value = true;
	error.value = null;

	const url = `${props.url}?term=${encodeURIComponent(term.value)}`;

	// if previous search still in progress abort it
	if (abort.value) {
		abort.value();
	}

	axios
		.get(url, {
			cancelToken: new axios.CancelToken((abortCanceler: Canceler) => {
				abort.value = abortCanceler;
			}),
		})
		.then(({ data }: AxiosResponse) => data)
		.then((data: FullSuggest) => {
			const filteredItems: FullSuggest = {};

			for (const [key, value] of Object.entries(data)) {
				if (value && value.length) {
					filteredItems[key] = value;
				}
			}
			items.value = filteredItems;
		})
		.catch((err: AxiosError) => {
			// ignore abort error
			if (err instanceof axios.Cancel) {
				return;
			}

			error.value = err;
		})
		.finally(() => {
			loading.value = false;
			if (initialLoad.value) {
				initialLoad.value = false;
				// Setting the correct item as target destination on initial load
				// to set the URL if user doesn't change anything (on region and city pages)
				if (['regionPage'].indexOf(pageType.value) !== -1 && !document.body.classList.contains('page_country')) {
					selectItemById(getIdFromStore());
				}

				// Getting wrong rgid from typo3 data on some countries, e.g. Spain
				if (document?.body.classList.contains('page_country')) {
					selectItemByLabel(term.value);
				}

				// Get the correct destination (not just the label)
				// for list pages on mobile to prevent unnecessary redirects
				if (['regionList', 'hotelList', 'hotelPage'].indexOf(pageType.value) !== -1) {
					selectItemById(getIdFromUrl());
				}
				if (model.value?.id) {
					store.commit('searchMask/updateFormData', {
						destination: model.value,
					});
					store.dispatch('updateProxies', { initialDestination: model.value });
					EventBus.$emit('Autocomplete:updatedActiveItem');
				}
			}
		});
};

const onTermChange = (): void => {
	error.value = null;
	if (!minCharCount.value) {
		getDefaultItems().then((defaultItems) => {
			items.value = defaultItems;
		});
	} else {
		search();
	}
};

let timeoutID: any = null;
const onInput = (value: string): void => {
	if (value === searchTerm.value) {
		return;
	}
	searchTerm.value = value;
	term.value = value;
	if (timeoutID) {
		clearTimeout(timeoutID);
	}
	timeoutID = setTimeout(() => {
		commitTerm();
		onTermChange();
		timeoutID = null;
	}, 400);
};

const isRegionPage = computed(() => pageType.value === 'regionPage');
const isWhitelistPage = computed(() => pageType.value === 'hotelPage' || isRegionPage.value);

watch(
	() => model.value,
	(newValue) => {
		if (newValue?.label) {
			term.value = newValue.label;
			searchTerm.value = newValue.label;
		}
	},
	{ immediate: true }
);
watch(
	() => items.value,
	() => {
		// we need this for "Urlaubsziele" like Spanien
		if (isRegionPage.value) {
			selectItemByLabel(model.value?.label);
			if (model.value?.id) {
				store.commit('searchMask/updateFormData', {
					destination: model.value,
				});
				store.dispatch('updateProxies', { initialDestination: model.value });
				EventBus.$emit('Autocomplete:updatedActiveItem');
			}
		}
	},
	{ once: true }
);
onMounted((): void => {
	if (isWhitelistPage.value) {
		if (!defaultAutosuggestsLoaded.value) {
			search();
			defaultAutosuggestsLoaded.value = true;
		}
		EventBus.$on('loadDefaultAutosuggests', search);
	}
	onTermChange();
});

onBeforeUnmount((): void => {
	if (isWhitelistPage.value) {
		EventBus.$off('loadDefaultAutosuggests', search);
	}
});

defineExpose({
	selectFirst,
	clearInput,
	dropdown,
});
</script>

<style lang="scss" scoped>
.autocomplete {
	&__search-history-destination {
		font-weight: $font-weight-semibold;
		font-size: $font-small-1;
		color: $color-text-light;
		margin-bottom: 0.2rem;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.is-active &__search-history-destination {
		color: white;
	}

	.is-active:hover &__search-history-destination {
		color: $color-text-light;
	}

	&__search-history-departure,
	&__search-history-duration,
	&__search-history-person {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: $font-small-2;
		font-weight: $font-weight-semibold;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.autocomplete__icon {
		flex-shrink: 0;
		margin-right: 1rem;
		fill: $color-primary-l1;
	}

	// dropdown style
	.autocomplete__dropdown {
		margin: 0 auto;

		.dropdown-field__field::after {
			display: none;
		}

		.dropdown-box {
			margin-top: 0;
			border-top: 0;
		}
	}

	.autocomplete__helper {
		position: relative;
		padding: 3rem 2rem;
	}

	.autocomplete__error {
		padding: 2rem;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
	}

	.autocomplete__load-box {
		position: relative;
		padding: 3.75rem 2rem;
	}

	.autocomplete__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-75%, -50%, 0);
	}

	.autocomplete__list {
		overflow: auto;
	}

	.autocomplete__item-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.autocomplete__item-header {
		margin: 2rem 0 1rem;
		padding-left: 3rem;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
	}

	.autocomplete__item {
		display: block;
		width: 100%;
		padding: 1.5rem 1.5rem 1.5rem 4.5rem;
		border: none;
		background: none;
		color: $color-text-l24;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
		text-decoration: none;

		&.is-active {
			background: $color-destion-field-active-background;
			color: $color-white;
		}

		&:hover {
			background: $color-primary-l6;
			color: $color-text-l24;
		}
	}

	.autocomplete__item-sublabel {
		display: block;
		margin-top: 0.5rem;
		font-size: $font-small-3;
		font-weight: normal;
	}

	.autocomplete__empty-item {
		@extend .autocomplete__item;

		padding: 1.5rem 1.5rem 1.5rem 4.5rem;
	}

	.clear-icon {
		position: absolute;
		width: 1.2rem;
		height: 1.2rem;
		margin-bottom: 1.1rem;
		fill: $color-dark-gray;
		top: 1.2rem;
		right: 0.6rem;
	}

	.clear-icon__container {
		position: absolute;
		width: 3rem;
		height: 90%;
		bottom: 0.1rem;
		right: 0.7rem;
		background-color: rgba($color-white, 0.5);
	}

	@include media-query-up($breakpoint-container) {
		.clear-icon {
			margin-top: 1.9rem;
			margin-bottom: 0;
			fill: $color-primary;
		}

		.autocomplete__dropdown {
			max-width: none;
			margin: 0;
		}
	}
}
</style>
