<template>
	<div class="checkout-info-flight-details">
		<FlightData
			:flight-segment="outbound"
			:outbound="true"
		/>
		<FlightData
			:flight-segment="inbound"
			:outbound="false"
		/>
		<div class="checkout-info-button checkout-info-flight-details__button">
			<button
				type="button"
				class="checkout-info-button__button"
				@click="openModal"
			>
				<p class="checkout-info-button__button-text">Flugdetails anzeigen</p>
				<FaIcon
					:icon="['fa-solid', 'fa-chevron-right']"
					class="checkout-info-button__button-arrow"
				/>
			</button>
			<BaseModal
				ref="modalRef"
				header-title="Flugdetails"
			>
				<CheckoutFlightDetails
					:inbound-flight="inbound"
					:outbound-flight="outbound"
				/>
			</BaseModal>
		</div>
	</div>
</template>
<script setup lang="ts">
import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import FlightData from '@lmt-rpb/FlightData/FlightData.vue';
import CheckoutFlightDetails from '@lmt-rpb/CheckoutFlightDetails/CheckoutFlightDetails.vue';
import BaseModal from '@lmt-rpb/BaseModal/BaseModal.vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { ref } from 'vue';

type Props = {
	outbound: FlightSegment[];
	inbound: FlightSegment[];
};

withDefaults(defineProps<Props>(), {});

const modalRef = ref<InstanceType<typeof BaseModal>>();
const openModal = (): void => {
	modalRef.value?.openModal();
};
</script>
<style lang="scss" scoped>
.checkout-info-flight-details {
	& > :not(:last-child) {
		margin-bottom: 1.6rem;
	}

	&__button {
		margin-top: 3.2rem;
	}
}

.checkout-info-button {
	&__button {
		appearance: none;
		background: none;
		border: none;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-weight: $font-weight-semibold;
		color: $color-link;
		font-size: $font-small-1;
		margin-top: 0.8rem;
		column-gap: 1.6rem;
	}

	&__button-text {
		margin: 0;
		flex-grow: 1;
		flex-shrink: 0;
		text-decoration: underline $color-link;
	}

	&__button-arrow {
		fill: currentcolor;
		width: $font-small-2;
		height: $font-small-2;
		font-size: 1.4rem;
		display: flex;
		align-items: baseline;
	}
}
</style>
