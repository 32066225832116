<template>
	<div class="checkout-sepa">
		<div class="checkout-sepa__name-info">
			<CheckoutTextField
				v-model.trim="firstName"
				label="Vorname"
				field-name="first-name"
				:required-field="true"
				:unique-key="uniqueKey"
				placeholder="z.B. Max"
				error-message="Vorname fehlt"
				:validation-schema="validateForm ? stringRequiredSchema : noSchema"
			/>
			<CheckoutTextField
				v-model.trim="lastName"
				label="Nachname"
				field-name="last-name"
				:required-field="true"
				:unique-key="uniqueKey"
				placeholder="z.B. Mustermann"
				error-message="Nachname fehlt"
				:validation-schema="validateForm ? stringRequiredSchema : noSchema"
			/>
		</div>
		<div class="checkout-sepa__payment-info">
			<CheckoutTextField
				ref="ibanInput"
				v-model.trim="iban"
				class="checkout-insurance__text-field--uppercase"
				label="IBAN"
				field-name="iban"
				:required-field="true"
				:unique-key="uniqueKey"
				placeholder="z.B. AT02 2011 1000 0342 9660"
				:error-message="errorMessage('IBAN')"
				:validation-schema="validateForm ? ibanRequiredSchema : noSchema"
			/>
			<CheckoutTextField
				v-model.trim="bic"
				class="checkout-insurance__text-field--uppercase"
				label="BIC"
				field-name="bic"
				:required-field="true"
				:unique-key="uniqueKey"
				placeholder="z.B. GIBAATWW"
				:error-message="errorMessage('BIC')"
				:validation-schema="validateForm ? bicRequiredSchema : noSchema"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import type { z } from 'zod';
import { MaskInput, Mask } from 'maska';
import { MASKA_IBAN_MASK } from '@global-js/constants';
import { useStore } from '@/components/common/store';
import { stringRequiredSchema, ibanRequiredSchema, bicRequiredSchema, noSchema } from '@/js/utils/validationSchemas';
import { CheckoutValidationState } from '@/components/common/store/checkout';
import type { Payment } from '@/interfaces/checkout/checkout-state';
import CheckoutTextField from '../CheckoutTextField/CheckoutTextField.vue';

const store = useStore();

const props = withDefaults(
	defineProps<{
		uniqueKey?: string;
		validateForm?: boolean;
	}>(),
	{
		uniqueKey: '',
		validateForm: true,
	}
);

let maskInput: InstanceType<typeof MaskInput>;
const mask = new Mask({ mask: MASKA_IBAN_MASK });

const updatePayment = (value: Partial<Payment>): void => {
	store.dispatch('checkout/updatePayment', value);
};
const ibanInput = ref<InstanceType<typeof CheckoutTextField> | null>(null);

const iban = computed({
	get() {
		return mask.masked(store.state.checkout.Payment.IBAN || '');
	},
	set(value) {
		updatePayment({ IBAN: mask.unmasked(value?.toUpperCase()) });
	},
});

const bic = computed({
	get() {
		return store.state.checkout.Payment.BIC;
	},
	set(value) {
		updatePayment({ BIC: value.toUpperCase() });
	},
});

const errorMessage = (type: 'IBAN' | 'BIC') => {
	const isIban = type === 'IBAN';
	const inputValue = isIban ? iban.value : bic.value;

	if (inputValue?.length === 0) {
		return `${type} fehlt`;
	}

	return `${type} ungültig`;
};

const firstName = computed({
	get() {
		return store.state.checkout.Payment.FirstName;
	},
	set(value: string) {
		updatePayment({ FirstName: value });
	},
});

const lastName = computed({
	get() {
		return store.state.checkout.Payment.LastName;
	},

	set(value: string) {
		updatePayment({ LastName: value });
	},
});

const setValidState = (inputValue: string, fieldName: string, validationSchema: z.ZodString | z.ZodPipeline) => {
	const validationResult = validationSchema.safeParse(inputValue);
	CheckoutValidationState.value[`${fieldName}-${props.uniqueKey}`].valid = !!inputValue && validationResult.success;
};

watch(
	() => store.getters['checkout/insuranceOffer'],
	() => {
		setValidState(firstName.value, 'first-name', stringRequiredSchema);
		setValidState(lastName.value, 'last-name', stringRequiredSchema);
		setValidState(iban.value, 'iban', ibanRequiredSchema);
		setValidState(bic.value, 'bic', bicRequiredSchema);
	}
);

onMounted(() => {
	if (ibanInput.value?.nativeInput) {
		maskInput = new MaskInput(ibanInput.value.nativeInput, {
			// https://beholdr.github.io/maska/v3/#/tokens?id=default-tokens
			mask: MASKA_IBAN_MASK,
		});
	}
});

onBeforeUnmount(() => {
	if (maskInput) {
		maskInput.destroy();
	}
});
</script>

<style lang="scss" scoped>
.checkout-sepa {
	@media (min-width: $breakpoint-verysmall) {
		&__name-info {
			display: flex;
			gap: 0.8rem;
		}

		&__payment-info {
			display: flex;
			gap: 0.8rem;
		}
	}

	@media (min-width: $breakpoint-small) {
		&__name-info {
			gap: 1.6rem;
		}

		&__payment-info {
			gap: 1.6rem;
		}
	}
}

:deep(::placeholder) {
	color: $color-checkout-disabled-text;
	opacity: 1;
	font-weight: $font-weight-semibold;
}

.checkout-insurance {
	&__text-field--uppercase {
		:deep(input) {
			text-transform: uppercase;
		}
	}
}
</style>
