import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, onMounted, onUnmounted, provide, reactive, ref } from 'vue';
import { type InternalNavigationBarProvide, NavigationBarProvideKey } from './provideTypes';

interface Props {
	centerAlignment?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InternalNavigationBar',
  props: {
    centerAlignment: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const blockModifiers = computed(() => ({
	'internal-navigation-bar--center': props.centerAlignment,
}));

const menu = ref<HTMLElement | null>(null);
let linksObserver: IntersectionObserver | null = null;
const currentIntersection = ref<string | undefined>(undefined);
const observeSections = ref<string[]>([]);
const observableSections = reactive<Record<string, boolean>>({});

const scrollToMenuEntry = (menuEntry: string) => {
	const htmlElementEntry = menu.value?.querySelector(`[data-id="${menuEntry}"]`);
	if (menu.value && htmlElementEntry) {
		const currentMenuScroll = menu.value?.scrollLeft as number;
		const menuPosition = menu.value.getBoundingClientRect().left as number;
		menu.value.scrollTo({
			top: 0,
			left: htmlElementEntry.getBoundingClientRect().left + (currentMenuScroll - menuPosition),
			behavior: 'smooth',
		});
	}
};

const addToObserveSections = (section: string) => {
	if (observeSections.value.includes(section)) return;
	observeSections.value.push(section);
	observableSections[section] = false;
};

provide<InternalNavigationBarProvide>(NavigationBarProvideKey, {
	addLinkToObserved: addToObserveSections,
	activeLink: computed(() => currentIntersection.value),
});

const createObserver = () => {
	linksObserver = new IntersectionObserver(
		(entries) => {
			entries.forEach((e) => {
				observeSections.value.forEach((o) => {
					if (e.target.id === o) {
						observableSections[o] = e.isIntersecting;
					}
				});
				currentIntersection.value = observeSections.value.find((s) => observableSections[s]);
				if (currentIntersection.value) {
					scrollToMenuEntry(currentIntersection.value);
				}
			});
		},
		{
			rootMargin: '-300px 0px 0px 0px',
		}
	);
	observeSections.value.forEach((o) => {
		const section = document.querySelector('#' + o);
		if (section) {
			linksObserver?.observe(section);
		}
	});
};

onMounted(() => {
	setTimeout(createObserver, 10);
});

onUnmounted(() => {
	if (linksObserver) {
		linksObserver.disconnect();
		linksObserver = null;
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(['internal-navigation-bar', blockModifiers.value])
  }, [
    _createElementVNode("ul", {
      ref_key: "menu",
      ref: menu
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 512 /* NEED_PATCH */)
  ], 2 /* CLASS */))
}
}

})