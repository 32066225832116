import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "aria-label", "aria-description"]

import type { RadioButtonCommonProps } from '@/interfaces/components/inputs';

type Props = {
	value: RadioButtonCommonProps['value'],
	label: RadioButtonCommonProps['label'],
	selected?: RadioButtonCommonProps['selected'],
};


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentRadioButton',
  props: {
    value: {},
    label: {},
    selected: { type: Boolean }
  },
  emits: ['PaymentRadio:Change'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;



const onChange = (event: Event) => {
	emit('PaymentRadio:Change', (event?.target as HTMLInputElement).value);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.value,
    class: _normalizeClass(["payment-radio-button", { 'selected': _ctx.selected }])
  }, [
    _createElementVNode("input", {
      id: _ctx.value,
      type: "radio",
      class: "payment-radio-button__input",
      value: _ctx.value,
      name: "payment-radio-input",
      enterkeyhint: "next",
      "aria-label": `Zahlungsmethode: ${_ctx.label}`,
      "aria-description": `Wählen Sie ${_ctx.label}, um diese Zahlungsmethode zu verwenden.`,
      onChange: onChange
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["payment-radio-button__label", { 'selected': _ctx.selected }])
    }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(["payment-radio-button__sub-label", { 'selected': _ctx.selected }])
    }, "gebührenfrei", 2 /* CLASS */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})