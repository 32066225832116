<template>
	<DropdownFilterType
		v-model:search-term="filterExpression"
		:class="['destination-filter', { 'destination-filter--disabled': disabled }]"
		:title="title"
		icon="marker2"
		:selected-value="selectedDestinationName"
		:placeholder="placeholderText"
		:disabled="disabled"
		:searchable="true"
		:show-toggle-icon="false"
		:with-menu-header="false"
		:show-buttons="false"
		:modal-header="isRegion ? 'Regionen' : 'Orte'"
	>
		<template #default="{ close }">
			<p
				v-if="notFoundMessage"
				class="destination-filter__not-found"
			>
				{{ notFoundMessage }}
			</p>
			<div
				v-else
				class="destination-filter__list"
			>
				<FilterRow
					v-for="item in filteredItems"
					:id="`destination-filter-${item.value}`"
					:key="`${item.boldLabel ?? ''}${item.label}`"
					v-model="selectedValue"
					:tabindex="disabled ? -1 : 0"
					:label="item.label"
					:bold-label="item.boldLabel"
					:value="item.value"
					:disabled="item.disabled"
					@update:model-value="close"
				/>
			</div>
		</template>
	</DropdownFilterType>
</template>

<script setup lang="ts">
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import FilterRow from './internals/FilterRow.vue';
import { computed } from 'vue';
import { pluralize } from '@/js/utils/utils';

import { useStore } from '@/components/common/store';
import { useFilterArray } from '../../composables/filterArray';
import type { LocationType } from '@/interfaces/components/hotelListData';
import { NBSP } from '@global-js/constants';

interface Props {
	title: string;
	locationType: LocationType;
	disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
});

const store = useStore();

const destinations = computed(() => store.state.types.destination);
const dealsFilterActive = computed(() => store.state.searchMask.deal);

const isRegion = computed(() => props.locationType === 'REGIONGROUP');

const locationIdName = computed((): 'RegionID' | 'CityID' => (isRegion.value ? 'RegionID' : 'CityID'));
const destinationTotal = computed(() => destinations.value.reduce((sum, curr) => sum + curr.Count, 0));
const radioOptionList = computed(() => {
	if (!destinations.value) {
		return [];
	}
	return [
		{
			boldLabel: undefined,
			label: dealsFilterActive.value
				? `${placeholderText.value}`
				: `${placeholderText.value}${NBSP}(${pluralize(destinationTotal.value, 'Hotel', 'Hotels')})`,
			value: undefined,
			disabled: false,
		},
		...destinations.value.map((destination) => ({
			boldLabel: destination.Name,
			label: dealsFilterActive.value ? '' : `(${pluralize(destination.Count, 'Hotel', 'Hotels')})`,
			value: destination[locationIdName.value]!,
			disabled: !dealsFilterActive.value && destination.Count === 0,
		})),
	];
});

const { filterExpression, filteredItems, notFoundMessage } = useFilterArray(radioOptionList, ['boldLabel', 'label']);

const selectedDestinationName = computed(() => store.state.searchMask.destinationTypeName);

const selectedValue = computed({
	get() {
		return store.state.searchMask.destinationTypes?.[0] as number;
	},
	set(value: number) {
		const foundDestination = store.state.types.destination.find((type) => type[locationIdName.value] === value);

		const destinationTypes = foundDestination ? [value] : [];
		const destinationTypeName = foundDestination?.Name ?? '';
		const selectedGeoInventory = foundDestination ?? {
			RegionID: undefined,
			CityID: undefined,
			Name: '',
			Count: 0,
		};

		store.state.searchMask.destinationTypes = destinationTypes;
		store.commit('searchMask/updateFormData', {
			destinationTypes,
			destinationTypeName,
			selectedGeoInventory,
		});
	},
});

const placeholderText = computed((): string => {
	if (props.disabled) {
		return 'Keine Treffer';
	}
	if (isRegion.value) {
		return 'Alle Regionen';
	}
	return 'Alle Orte';
});
</script>

<style lang="scss" scoped>
.destination-filter {
	:deep(.search-modal__main) {
		padding-inline: 0;

		.destination-filter__list {
			padding-top: 1.6rem;
		}
	}

	:deep(.dropdown__container:focus-visible) {
		outline: none;
	}

	&__not-found {
		font-size: $font-small-1;
		padding-right: 1.6rem;
		padding-left: 5.6rem;
	}

	:deep(.form-field__icon) {
		width: 3rem;
		height: 3rem;
		align-self: flex-end;
		margin-bottom: 0.75rem;
		flex-shrink: 0;
		color: $color-primary;
	}

	&--disabled {
		:deep(.form-field__icon),
		:deep(.form-field__chevron-icon) {
			fill: $color-state-disabled;
			color: $color-state-disabled;
		}

		:deep(.form-field) {
			cursor: default;
			border-color: $color-state-disabled;
		}

		:deep(.form-field__label-text) {
			color: $color-state-disabled;
		}

		:deep(.form-field__input) {
			color: $color-state-disabled;
		}

		:deep(.form-field__input::placeholder) {
			color: $color-state-disabled !important;
		}
	}
}
</style>
