import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "discount-price" }
const _hoisted_2 = { class: "discount-price__tag" }

import { computed, useSlots } from 'vue';
import type { ClientCurrency } from '@global-js/constants';
import BasePrice from '@lmt-rpb/Atoms/BasePrice/BasePrice.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';

interface Props {
	discount: number;
	currency: ClientCurrency;
	averagePrice?: number;
	tooltip?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DiscountPrice',
  props: {
    discount: {},
    currency: { default: 'EUR' },
    averagePrice: { default: undefined },
    tooltip: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const slots = useSlots();
const discountPrice = computed(() => `${props.discount ? '-' : ''}${props.discount}%`);
const withTooltip = computed(() => !!props.tooltip || !!slots?.tooltip);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(InfoTooltip, {
    disabled: !withTooltip.value
  }, {
    custom: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(discountPrice.value), 1 /* TEXT */),
        _createVNode(BasePrice, {
          currency: _ctx.currency,
          price: _ctx.averagePrice,
          "strike-through": "",
          class: "discount-price__price",
          theme: "primary"
        }, null, 8 /* PROPS */, ["currency", "price"]),
        (withTooltip.value)
          ? (_openBlock(), _createBlock(BaseIcon, {
              key: 0,
              class: "discount-price__icon",
              name: 'infoOutline',
              width: "16",
              height: "16"
            }))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    default: _withCtx(() => [
      (withTooltip.value)
        ? _renderSlot(_ctx.$slots, "tooltip", { key: 0 }, () => [
            _createTextVNode(_toDisplayString(_ctx.tooltip), 1 /* TEXT */)
          ])
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["disabled"]))
}
}

})