<template>
	<button
		type="button"
		aria-label="Close"
		:class="['close-button', blockClassModifiers]"
	>
		<FaIcon
			class="close-button__icon"
			:icon="['fa-solid', 'fa-xmark']"
			:style="{ fontSize: `${remSize}rem`, color: color }"
		>
		</FaIcon>
	</button>
</template>

<script lang="ts" setup>
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { computed } from 'vue';

interface Props {
	inheritStyles?: boolean;
	remSize?: number;
	color?: string;
}

const { inheritStyles = false, remSize = 2, color = 'currentcolor' } = defineProps<Props>();
const blockClassModifiers = computed(() => ({
	'close-button--inherit-styles': inheritStyles,
}));
</script>

<style lang="scss" scoped>
.close-button {
	padding: 0;
	height: auto;
	aspect-ratio: 1/1;
	border: none;
	background: none;
	color: $color-white;

	&:focus-within,
	&:focus {
		outline-color: $color-white;
	}

	&__icon {
		aspect-ratio: 1/1;
		font-size: 2rem;
	}

	&--inherit-styles {
		&:focus-within,
		&:focus {
			outline-color: currentcolor;
		}

		.close-button__icon {
			color: currentcolor;
			font-size: inherit;
		}
	}
}
</style>
