<template>
	<li class="internal-navigation-link">
		<a
			:data-id="anchorId"
			:href="href"
			class="internal-navigation-link__link"
			:aria-current="isAriaCurrent"
			v-bind="{ ...gaAttributes, ...$attrs }"
			@click.prevent="scrollToAnchorWithDynamicOffset($event)"
		>
			{{ label }}
		</a>
	</li>
</template>
<script lang="ts" setup>
import { scrollToAnchorWithDynamicOffset } from '@/js/utils/utils';
import { computed, inject, onBeforeMount } from 'vue';
import { type InternalNavigationBarProvide, NavigationBarProvideKey } from '../provideTypes';

export interface InternalNavigationLinkProps {
	anchorId: string;
	href?: string;
	label?: string;
	gaAttributes?: Record<string, string | number>;
}
const props = defineProps<InternalNavigationLinkProps>();

defineOptions({
	inheritAttrs: false,
});

const injectData = inject<InternalNavigationBarProvide>(NavigationBarProvideKey);

const isAriaCurrent = computed(() => {
	return injectData?.activeLink.value === props.anchorId;
});

onBeforeMount(() => {
	if (injectData) {
		injectData.addLinkToObserved(props.anchorId as string);
	}
});
</script>
<style lang="scss" scoped>
.internal-navigation-link {
	white-space: nowrap;
	list-style-type: none;

	&__link {
		display: inline-block;
		position: relative;
		color: $color-black;
		font-family: $font-family-special;
		font-size: $font-medium-3;
		text-decoration: none;
		font-weight: $font-weight-semibold;
		line-height: 2.8rem;

		&::before {
			content: '';
			position: absolute;
			display: block;
			bottom: 0;
			width: 0;
			height: 0.2rem;
			border-radius: 0.5rem;
			background: $color-link;
			transition: all 0.15s ease-in-out;
			opacity: 0;
		}

		&[aria-current='true'] {
			color: $color-link;

			&::before {
				width: 100%;
				opacity: 1;
			}
		}

		@media (min-width: $breakpoint-small) {
			& {
				font-size: $font-medium-3;
				line-height: 4rem;
			}
		}

		@media (min-width: $breakpoint-extralarge) {
			& {
				font-size: $font-medium-2;
				line-height: 4.6rem;
			}
		}
	}
}
</style>
