<template>
	<div class="flying-time-filter">
		<div class="flying-time-filter__sliders">
			<FlyingTimeSlider
				v-model="departureTime"
				title="Hinflug"
			/>
			<FlyingTimeSlider
				v-model="arrivalTime"
				title="Rückflug"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '@/components/common/store';
import FlyingTimeSlider from '@lmt-rpb/FlyingTimeSlider/FlyingTimeSlider.vue';

const store = useStore();

const departureTime = computed({
	get() {
		return {
			from: store.state.searchMask.minDepartureTime ?? 0,
			to: store.state.searchMask.maxDepartureTime ?? 24,
		};
	},
	set(value) {
		store.commit('searchMask/updateFormData', { minDepartureTime: value.from, maxDepartureTime: value.to });
	},
});

const arrivalTime = computed({
	get() {
		return {
			from: store.state.searchMask.minArrivalTime ?? 0,
			to: store.state.searchMask.maxArrivalTime ?? 24,
		};
	},
	set(value) {
		store.commit('searchMask/updateFormData', { minArrivalTime: value.from, maxArrivalTime: value.to });
	},
});
</script>

<style lang="scss" scoped>
.flying-time-filter {
	display: flex;
	flex-direction: column;
	gap: 0.8rem;

	&__sliders {
		padding: 0 2.4rem;
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
	}
}

@media (min-width: $breakpoint-extralarge) {
	.flying-time-filter {
		padding: 0 0.8rem;
		gap: 0;

		:deep(.direct-flight-filter__input-container) {
			margin-left: 0.8rem;
		}

		&__sliders {
			padding: 0 0.8rem;
		}
	}
}
</style>
