import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-page-search__mobile-filters" }
const _hoisted_2 = { class: "list-page-search__searchForm" }

import ThemeMinimal from '@lmt-rpb/ThemeMinimal/ThemeMinimal.vue';
import SearchBar from '@lmt-rpb/SearchBar/SearchBar.vue';
import FilterModal from '@lmt-rpb/FilterModal/FilterModal.vue';
import type SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import SearchForm from '@lmt-rpb/SearchForm/SearchForm.vue';
import { onMounted, ref } from 'vue';
import SearchFormModal from '@lmt-rpb/SearchFormModal/SearchFormModal.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	mobileTeleportTarget?: string;
	hasMap?: boolean;
	hasSort?: boolean;
	destinationLabel?: string;
	listType: 'hotel' | 'region' | 'offer';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ListPageSearch',
  props: {
    mobileTeleportTarget: {},
    hasMap: { type: Boolean, default: false },
    hasSort: { type: Boolean, default: false },
    destinationLabel: { default: '' },
    listType: {}
  },
  setup(__props: any, { expose: __expose }) {



const filterModal = ref<InstanceType<typeof FilterModal>>();
const searchFormModal = ref<InstanceType<typeof SearchModal>>();
const { isDesktop } = storeToRefs(useBreakpointStore());

onMounted(() => {
	document.body.classList.add('page--mobile-search-bar');
});

__expose({
	filterModal,
	searchFormModal,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isDesktop))
      ? (_openBlock(), _createBlock(SearchFormModal, {
          key: 0,
          ref_key: "searchFormModal",
          ref: searchFormModal,
          header: "Suche anpassen",
          "apply-button": "Suchen",
          "cancel-button": "Abbrechen"
        }, null, 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true),
    (!_unref(isDesktop))
      ? (_openBlock(), _createBlock(FilterModal, {
          key: 1,
          ref_key: "filterModal",
          ref: filterModal,
          "list-type": _ctx.listType,
          "destination-label": _ctx.destinationLabel,
          header: "Filter anpassen",
          "apply-button": "Suchen",
          "cancel-button": "Abbrechen"
        }, null, 8 /* PROPS */, ["list-type", "destination-label"]))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createBlock(_Teleport, {
      to: _ctx.mobileTeleportTarget,
      disabled: !_ctx.mobileTeleportTarget
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(ThemeMinimal, {
          class: "list-page-search__theme-minimal",
          onClick: _cache[0] || (_cache[0] = () => searchFormModal.value?.openModal())
        }),
        _createVNode(SearchBar, {
          class: "list-page-search__serach-bar",
          "has-map": _ctx.hasMap,
          "has-sort": _ctx.hasSort,
          "onSearchBar:openFilterModal": _cache[1] || (_cache[1] = () => filterModal.value?.openModal())
        }, null, 8 /* PROPS */, ["has-map", "has-sort"])
      ])
    ], 8 /* PROPS */, ["to", "disabled"])),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(SearchForm, { "search-bar-variant": 'inline' })
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
}

})