<template>
	<div class="price-summary">
		<p
			v-if="withOverviewHeader"
			class="price-summary__overview"
		>
			Preisübersicht
		</p>
		<div
			v-for="traveller in actualTravellerPrices"
			:key="traveller.Label"
			class="price-summary__row price-summary__traveller-price"
		>
			<p>{{ traveller.Label }}</p>
			<p>{{ traveller.Price }}</p>
		</div>
		<section v-if="discount">
			<div class="price-summary__row">
				<DiscountBadge class="price-summary__discount-badge" />
				<span class="price-summary__tooltip">
					<sup>
						<BaseIcon name="info" />
					</sup>
					<p class="price-summary__discount-price">
						{{ travelPriceFormatted }}
					</p>
				</span>
			</div>
		</section>
		<div
			v-if="storno"
			class="price-summary__row price-summary__storno-price"
		>
			<p>flexible Stornierungsoption</p>
			<p>{{ flexPrice }}</p>
		</div>
		<section v-if="showTravelPrice">
			<div class="price-summary__divider"></div>
			<div class="price-summary__row price-summary__row--travel-price">
				<p>
					Reisepreis
				</p>
				<p>
					{{ travelPriceWithDiscountFormatted }}
				</p>
			</div>
		</section>
		<div
			v-if="insurancePrice"
			class="price-summary__row price-summary__insurance-price"
		>
			<p>{{ insurance?.insuranceName }}</p>
			<p>{{ insurancePrice }}</p>
		</div>
		<div class="price-summary__divider price-summary__divider--total"></div>
		<div
			v-if="isPackage"
			class="price-summary__best-price-badge"
		>
			<BaseIcon name="bestPriceWarranty" />
			Bestpreisgarantie
		</div>

		<div class="price-summary__row price-summary__row--total">
			<p class="price-summary__total">
				Gesamtpreis
			</p>
			<div class="checkout-price-__sum-container">
				<p class="price-summary__sum">
					{{ totalFormatted }}
				</p>
				<p
					v-if="isChf"
					class="price-summary__sum-converted"
				>
					({{ finalPriceWithCurrency }})
				</p>
			</div>
		</div>
		<p class="price-summary__disclaimer">
			Gesamtpreis einschließlich Steuern und Gebühren. Eventuell erfolgt vor Ort die Berechnung einer zu
			bezahlenden Kurtaxe.
			<span
				v-if="isChf"
				class="price-summary__disclaimer price-summary__disclaimer--extra"
			>
				Der Vertrag wird in EURO geschlossen. Die Angabe in CHF dient lediglich Ihrer Information und ist ein
				Circa-Wert.
			</span>
		</p>
		<div
			v-if="isInfoBox"
			class="price-summary__payments"
		>
			<img
				v-for="(item, index) in paymentTypes"
				:key="index"
				class="price-summary__payment-img"
				:src="item.img"
				:title="item.name"
				:alt="item.name"
			/>
		</div>
	</div>
</template>
<script setup lang="ts">
import { FormattedTravelerPrice, SimplePrice } from '@/interfaces/checkout/offer-data';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import DiscountBadge from '@lmt-rpb/DiscountBadge/DiscountBadge.vue';
import { computed } from 'vue';

type Props = {
	paymentTypes?: Record<string, string>[],
	storno?: SimplePrice | undefined,
	isPackage?: boolean,
	isInfoBox?: boolean,
	isChf?: boolean,
	discount?: number,
	finalPriceWithCurrency: string,
	actualTravellerPrices: FormattedTravelerPrice[],
	withOverviewHeader?: boolean,
	totalFormatted: string,
	flexPrice: string,
	travelPriceWithDiscountFormatted: string,
	insurancePrice: string,
	insurance?: { insuranceName?: string, priceInformation?: SimplePrice }
	travelPriceFormatted: string
}
const props = defineProps<Props>();

const showTravelPrice = computed(() => {
	const entries = (props.actualTravellerPrices.length ?? 0) + (props.flexPrice ? 1 : 0);
	return props.insurancePrice && entries >= 2;
});


</script>

<style lang="scss" scoped>
.price-summary {
	* {
		margin: 0;
		padding: 0;
	}

	&__overview {
		font-size: $font-small-1;
		font-weight: $font-weight-bold;
		margin-bottom: 0.7rem;
	}

	&__best-price-badge + &__row--total {
		margin-top: -1rem;
	}

	&__best-price-badge {
		display: flex;
		gap: 0.7rem;
		font-weight: $font-weight-bold;
		font-size: 1.3rem;
		color: $color-bestprice-badge-text;
		justify-content: end;
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: $font-small-2;
		@media (min-width: $breakpoint-large) {
			font-size: $font-small-1;
		}

		&:not(:last-of-type) {
			margin-bottom: 0.6rem;
		}

		&--total {
			align-items: first baseline;
		}

		&--travel-price {
			font-weight: $font-weight-semibold;
			padding-bottom: 0.8rem;
		}
	}

	&__divider {
		padding-bottom: 0.8rem;
		margin-top: 0.8rem;
		border-top: 0.1rem solid $color-inactive;

		&--total {
			border-top-color: $color-checkout-highlight;
		}
	}

	&__discount-badge {
		font-weight: $font-weight-bold;
		padding: 0.4rem 0.8rem;
	}

	&__discount-price {
		font-weight: $font-weight-bold;
		text-decoration: line-through;
		text-decoration-thickness: 0.1rem;
	}

	&__total {
		font-weight: $font-weight-semibold;
		font-size: $font-medium-3;
	}

	&__sum-container {
		text-align: right;
	}

	&__sum {
		font-weight: $font-weight-semibold;
		font-size: $font-medium-3;
		color: $color-extra;
	}

	&__sum-converted {
		color: $color-text;
		text-align: right;
	}

	&__disclaimer {
		font-size: 1.1rem;
		color: $color-text;
		padding: 0;
		text-align: left;
		margin: 1rem 0;
	}

	&__tooltip {
		color: $color-price-text;
		display: inline-flex;
		justify-content: flex-end;
		align-items: center;
		column-gap: 0.2rem;
		cursor: default;

		& svg {
			width: 1rem;
			height: 1rem;
		}
	}

	&__payments {
		text-align: right;
	}

	&__payment-img {
		height: 2rem;
		margin-right: 0.5rem;

		&:last-of-type {
			margin-right: 0;
		}
	}

	@media (min-width: $breakpoint-small) {
		&__payment-img {
			height: 2.5rem;
		}
	}
}
</style>
