import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "filter-price filter-price__value"
}

import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import type { Price } from '@/interfaces/checkout/offer-data';
import { getPriceCorrectCurrency } from '@/js/utils/priceUtils';
import { computed } from 'vue';

type Props = {
	isLoading: boolean
	value?: Price
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterPrice',
  props: {
    isLoading: { type: Boolean },
    value: {}
  },
  setup(__props: any) {

const props = __props;

const price = computed(() => props.value ? `ab ${getPriceCorrectCurrency(props.value)}` : null)


return (_ctx: any,_cache: any) => {
  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(SkeletonLoader, {
        key: 0,
        class: "filter-price filter-price__loader",
        "loader-width": 7,
        "loader-height": 1.6,
        "border-radius": "0.5rem",
        "width-unit": "rem"
      }))
    : (price.value)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(price.value), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
}
}

})