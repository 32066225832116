import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flight-details__header" }
const _hoisted_2 = { class: "flight-details__headline" }
const _hoisted_3 = {
  key: 0,
  class: "flight-details__date"
}

import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import CheckoutFlightDetailsSegment from '@lmt-rpb/CheckoutFlightDetailsSegment/CheckoutFlightDetailsSegment.vue';
import { computed } from 'vue';
import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import { formatDateLong } from '@utils/dateUtils';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutFlightDetailsSegmentList',
  props: {
    isOutbound: { type: Boolean },
    flights: {}
  },
  setup(__props: any) {

const props = __props;

const flightWording = computed((): string => (props.isOutbound ? 'Hinflug' : 'Rückflug'));

const iconClass = computed((): string => {
	const baseClass = 'flight-details__';

	return props.isOutbound ? `${baseClass}outbound-icon` : `${baseClass}inbound-icon`;
});

const getDate = (date: Date | string): Date | string => {
	if (!date) {
		return '';
	}

	return formatDateLong(date);
};

const blockClassModifiers = computed(() => ({
	['flight-details--outbound']: props.isOutbound === true,
	['flight-details--inbound']: props.isOutbound === false,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([blockClassModifiers.value])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(FaIcon, {
        name: "planeGo",
        icon: ['fa-solid', 'fa-plane'],
        class: _normalizeClass(iconClass.value)
      }, null, 8 /* PROPS */, ["class"]),
      _createElementVNode("h3", _hoisted_2, _toDisplayString(flightWording.value), 1 /* TEXT */)
    ]),
    _createElementVNode("div", null, [
      (_ctx.flights[0]?.Departure?.Date)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(getDate(_ctx.flights[0]?.Departure?.Date)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flights, (segment) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `${segment?.Departure?.Date}`
        }, [
          _createVNode(CheckoutFlightDetailsSegment, {
            segment: segment,
            "is-right": !_ctx.isOutbound
          }, null, 8 /* PROPS */, ["segment", "is-right"])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 2 /* CLASS */))
}
}

})