import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "map-offer-box" }
const _hoisted_2 = { class: "map-offer-box__subline" }
const _hoisted_3 = { class: "map-offer-box__map-container" }
const _hoisted_4 = {
  key: 0,
  class: "map-offer-box__rating"
}
const _hoisted_5 = { class: "map-offer-box__text-container" }
const _hoisted_6 = { class: "map-offer-box__text" }

import {
	isMinimumRating,
} from '@utils/utils';
import Price from '@lmt-rpb/Price/Price.vue';
import HotelImageSlider from '@lmt-rpb/HotelImageSlider/HotelImageSlider.vue';
import BookmarkHotelButton from '@lmt-rpb/BookmarkHotelButton/BookmarkHotelButton.vue';
import { computed } from 'vue';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { Board as boardMapping } from '@/js/data/board-types';
import CircleButton from '@lmt-rpb/CircleButton/CircleButton.vue';
import HotelHeader from '@lmt-rpb/HotelHeader/HotelHeader.vue';
import { addEnabledParams } from '@/components/common/services/url';
import { getRatingCombined } from '@utils/utils';

interface Props {
	item: ItemType,
	showSuns: boolean,
	domain?: string,
	bestRating?: number,
	rating?: number,
	isActive?: boolean,
	isHotelPage?: boolean
	shouldNavigate?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MapOfferBox',
  props: {
    item: {},
    showSuns: { type: Boolean, default: false },
    domain: { default: 'AT' },
    bestRating: { default: 6 },
    rating: { default: 2 },
    isActive: { type: Boolean, default: true },
    isHotelPage: { type: Boolean },
    shouldNavigate: { type: Boolean }
  },
  emits: ['MapOfferBox:LinkOpened', 'MapOfferBox:Close'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;
const close = () => {
	emit('MapOfferBox:Close');
};

const offer = computed(() => props.item.PackageOffer || props.item.AccommodationOffer!);


const clickHandler = () => {
	if (props.shouldNavigate) {
		emit('MapOfferBox:LinkOpened');
	} else {
		close();
	}
};

const hotelUrl = computed(() => (props.isHotelPage ? addEnabledParams(props.item.Hotel.URL) : props.item.Hotel.URL));

const board = computed(() => {
	const boardMappingProp = offer.value.Board as keyof typeof boardMapping;
	return boardMapping[boardMappingProp];
});

const days = computed(() =>	(offer.value.TravelType === 'Package' ? offer.value.BetweenDeparturesDuration : offer.value.OvernightStays));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.item.NumberOfImages > 0 && _ctx.isActive)
      ? (_openBlock(), _createBlock(HotelImageSlider, {
          key: 0,
          "iff-code": _ctx.item.Hotel.IffCode,
          alt: _ctx.item.Hotel.Name,
          "number-of-images": Math.min(_ctx.item.NumberOfImages, 10)
        }, null, 8 /* PROPS */, ["iff-code", "alt", "number-of-images"]))
      : _createCommentVNode("v-if", true),
    _createVNode(BookmarkHotelButton, {
      tabindex: "-1",
      class: "map-offer-box__bookmark-button",
      item: _ctx.item
    }, null, 8 /* PROPS */, ["item"]),
    _createVNode(CircleButton, {
      class: "map-offer-box__close-button",
      icon: "crossRounded",
      viewBox: "-0.5 -0.5 14 14",
      onClick: close
    }),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.shouldNavigate ? 'a' : 'div'), {
      href: hotelUrl.value,
      title: _ctx.item.Hotel.Name,
      target: "_blank",
      onClick: clickHandler
    }, {
      default: _withCtx(() => [
        _createVNode(HotelHeader, {
          class: "map-offer-box__header",
          hotel: _ctx.item.Hotel,
          "show-suns": _ctx.showSuns,
          "show-address": false
        }, null, 8 /* PROPS */, ["hotel", "show-suns"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_unref(isMinimumRating)(_ctx.item.Ratings.Overall))
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(getRatingCombined)(_ctx.item.Ratings.Overall)), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("ul", _hoisted_6, [
            _createElementVNode("li", null, _toDisplayString(days.value) + " Tage", 1 /* TEXT */),
            _createElementVNode("li", null, _toDisplayString(board.value), 1 /* TEXT */),
            (_ctx.item.PackageOffer)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                  _cache[1] || (_cache[1] = _createElementVNode("li", null, "inkl. Flug ", -1 /* HOISTED */))
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]),
          _createVNode(Price, {
            price: _ctx.item.computedPrice,
            currency: offer.value.CurrencyCode,
            class: "map-offer-box__price"
          }, null, 8 /* PROPS */, ["price", "currency"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["href", "title"]))
  ]))
}
}

})