import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

import { computed } from 'vue';

interface Props {
	width: string;
	height: string;
	top: string;
	reflex?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Gradient',
  props: {
    width: { default: '' },
    height: { default: '' },
    top: { default: '' },
    reflex: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const gradientStyle = computed(() => ({
	height: `${props.height}rem`,
	width: `${props.width}rem`,
	top: `${props.top}rem`,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "gradient",
      style: _normalizeStyle(gradientStyle.value)
    }, null, 4 /* STYLE */),
    (_ctx.reflex)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "gradient--reflex",
          style: _normalizeStyle({ ...gradientStyle.value, left: '0', right: 'auto' })
        }, null, 4 /* STYLE */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})