<template>
	<nav :class="['internal-navigation-bar', blockModifiers]">
		<ul ref="menu">
			<slot />
		</ul>
	</nav>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, provide, reactive, ref } from 'vue';
import { type InternalNavigationBarProvide, NavigationBarProvideKey } from './provideTypes';

interface Props {
	centerAlignment?: boolean;
}

const props = defineProps<Props>();

const blockModifiers = computed(() => ({
	'internal-navigation-bar--center': props.centerAlignment,
}));

const menu = ref<HTMLElement | null>(null);
let linksObserver: IntersectionObserver | null = null;
const currentIntersection = ref<string | undefined>(undefined);
const observeSections = ref<string[]>([]);
const observableSections = reactive<Record<string, boolean>>({});

const scrollToMenuEntry = (menuEntry: string) => {
	const htmlElementEntry = menu.value?.querySelector(`[data-id="${menuEntry}"]`);
	if (menu.value && htmlElementEntry) {
		const currentMenuScroll = menu.value?.scrollLeft as number;
		const menuPosition = menu.value.getBoundingClientRect().left as number;
		menu.value.scrollTo({
			top: 0,
			left: htmlElementEntry.getBoundingClientRect().left + (currentMenuScroll - menuPosition),
			behavior: 'smooth',
		});
	}
};

const addToObserveSections = (section: string) => {
	if (observeSections.value.includes(section)) return;
	observeSections.value.push(section);
	observableSections[section] = false;
};

provide<InternalNavigationBarProvide>(NavigationBarProvideKey, {
	addLinkToObserved: addToObserveSections,
	activeLink: computed(() => currentIntersection.value),
});

const createObserver = () => {
	linksObserver = new IntersectionObserver(
		(entries) => {
			entries.forEach((e) => {
				observeSections.value.forEach((o) => {
					if (e.target.id === o) {
						observableSections[o] = e.isIntersecting;
					}
				});
				currentIntersection.value = observeSections.value.find((s) => observableSections[s]);
				if (currentIntersection.value) {
					scrollToMenuEntry(currentIntersection.value);
				}
			});
		},
		{
			rootMargin: '-300px 0px 0px 0px',
		}
	);
	observeSections.value.forEach((o) => {
		const section = document.querySelector('#' + o);
		if (section) {
			linksObserver?.observe(section);
		}
	});
};

onMounted(() => {
	setTimeout(createObserver, 10);
});

onUnmounted(() => {
	if (linksObserver) {
		linksObserver.disconnect();
		linksObserver = null;
	}
});
</script>
<style lang="scss" scoped>
.internal-navigation-bar {
	min-height: 4.2rem;
	background: $color-white;
	box-shadow: 0 0.2rem 0.2rem 0 $color-box-shadow;
	padding-right: 1.6rem;
	padding-left: 1.6rem;

	&--center {
		display: flex;
		justify-content: center;
	}

	ul {
		display: flex;
		list-style-type: none;
		gap: 1.6rem;
		margin: 0 auto;
		overflow: auto hidden;
		scrollbar-width: thin;
		padding: 0.8rem 0;
		max-width: $breakpoint-container;

		@include customHorizontalScrollbar;
	}

	@media (min-width: $breakpoint-small) {
		& {
			min-height: 5.6rem;

			ul {
				gap: 4rem;
			}
		}
	}

	@media (min-width: $breakpoint-extralarge) {
		& {
			min-height: 6.1rem;

			ul {
				gap: 4.8rem;
			}
		}
	}
}
</style>
