import { filterObjectArray } from '@/js/utils/objectUtils';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';

export function useFilterArray<T>(items: Ref<Array<T>>, fieldsToFilterBy: Array<keyof T>) {
	const filterExpression = ref('');
	const updateFilterExpression = (value: string) => {
		filterExpression.value = value;
	};
	const resetFilter = () => {
		updateFilterExpression('');
	};

	const filteredItems = computed(() => items.value.filter((item) => filterObjectArray(item, fieldsToFilterBy, filterExpression.value)));

	const notFoundMessage = computed(() =>
		filteredItems.value.length === 0 ? 'Für deine Suche gibt es leider keine passenden Filter.' : ''
	);

	return {
		filterExpression,
		filteredItems,
		notFoundMessage,
		updateFilterExpression,
		resetFilter,
	};
}
