/**
 * A function to format an int or a float number to a string with a specific number of decimals and a specific decimal delimiter.
 * @param value the number to format
 * @param decimals default is 1
 * @param decimalDelimiter default is ','
 * @returns
 */
export function numberFormat(value: number, decimals = 1, decimalDelimiter = ','): string {
	return value.toFixed(decimals).toString().replace('.', decimalDelimiter);
}
