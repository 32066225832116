<template>
	<div
		class="gallery-modal"
		:class="{'is-visible': model}"
	>
		<div class="gallery-modal__header">
			<img
				class="gallery-modal__logo"
				:alt="altTextForLogo"
				:src="modalLogo"
			/>

			<RoundedButton
				class="gallery-modal__close-button"
				theme="outlined"
				aria-label="Close"
				@click="close"
			>
				<BaseIcon
					name="close"
					class="gallery-modal__close-icon"
				/>
			</RoundedButton>
		</div>
		<div class="gallery-modal__body">
			<div
				ref="mainGalleryElement"
				class="swiper gallery-modal__main-slides"
			>
				<div class="swiper-wrapper">
					<div
						v-for="(element, index) in elements"
						:key="index"
						ref="mainSlideRefs"
						class="swiper-slide"
						:alt="element.alt"
					>
						<div class="gallery-modal__content-wrapper">
							<template v-if="element.type === 'image'">
								<ImageWithPlaceholder
									:src="element.content"
									:alt="`${element.alt} - ${index}`"
									class="gallery-modal__image"
									:title="`© GIATA GmbH 1996 - ${new Date().getFullYear()}`"
								/>
							</template>
							<template v-else>
								<div class="gallery-modal__video-wrapper">
									<embed
										class="gallery-modal__video"
										:src="getVideoSrc(element)"
									/>
								</div>
							</template>
							<span
								v-if="element.copyright"
								class="gallery-modal__copyright"
							>
								{{ formatCopyrightString(element.copyright) }}
							</span>
						</div>
					</div>
				</div>

				<div
					class="gallery-modal__main-gallery-prev swiper-button-prev"
					:class="{ 'hidden': activeIndex === 0 }"
					@click="animate(mainGallery, 'prev');"
				>
					<BaseIcon name="chevronLeft" />
				</div>
				<div
					class="gallery-modal__main-gallery-next swiper-button-next"
					:class="{ 'hidden': activeIndex === elements.length - 1 }"
					@click="animate(mainGallery, 'next');"
				>
					<BaseIcon name="chevronRight" />
				</div>
			</div>

			<div
				v-if="elements"
				ref="thumbnailGalleryElement"
				class="swiper gallery-modal__thumb-slides"
			>
				<div class="swiper-wrapper">
					<div
						v-for="(element, index) in elements"
						:key="index"
						class="swiper-slide"
						:alt="element.alt"
					>
						<template v-if="element.type === 'image'">
							<img
								:src="element.preview"
								:alt="`${element.alt} - ${index}`"
								class="gallery-modal__thumb-image"
								:class="{ 'gallery-modal__thumb-image--active': index === activeIndex}"
							/>
						</template>
						<template v-else>
							<div
								class="gallery-modal__thumb-video-preview"
								:class="{ 'gallery-modal__thumb-video-preview--active': index === activeIndex}"
							>
								<img
									class="gallery-modal__thumb-image"
									:class="{ 'gallery-modal__thumb-image--active': index === activeIndex}"
									:src="element.preview"
									:alt="`${element.alt} - ${index}`"
								/>
								<img
									src="/fileadmin/2/restplatzboerse/all/img/playbutton.svg"
									class="gallery-modal__thumb-video-icon"
								/>
							</div>
						</template>
					</div>
				</div>

				<div
					class="gallery-modal__thumb-gallery-prev swiper-button-prev"
					:class="{ 'hidden': activeIndex === 0 }"
					@click="slidePrevThumb"
				>
					<BaseIcon name="chevronLeft" />
				</div>
				<div
					class="gallery-modal__thumb-gallery-next swiper-button-next"
					:class="{ 'hidden': activeIndex === elements.length - 1 }"
					@click="slideNextThumb"
				>
					<BaseIcon name="chevronRight" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { getWebsiteLogoPath, getWebsiteName } from '@utils/environmentUtils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import {
	computed, nextTick, ref, watch, onBeforeUnmount,
	MaybeRef, unref, useTemplateRef,
} from 'vue';
import ImageWithPlaceholder from '@lmt-rpb/ImageWithPlaceholder/ImageWithPlaceholder.vue';
import { Swiper } from 'swiper/bundle';
import type { ClientType } from '@/interfaces/common';
import { GalleryElement } from '@/interfaces/components/galleryElement';
import 'swiper/scss';

type Props = {
	elements: GalleryElement[],
	index?: number,
	modelValue?: boolean,
	client?: ClientType,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: false,
	index: 0,
	client: 'at',
});

const emit = defineEmits(['slide', 'update:modelValue', 'close']);

const model = defineModel<boolean>();
const mainSlideRefs = useTemplateRef('mainSlideRefs');

const altTextForLogo = getWebsiteName(props.client);

const modalLogo = computed((): string => `${getWebsiteLogoPath(props.client)}/img/logo.svg`);

const mainGalleryElement = useTemplateRef('mainGalleryElement');
const thumbnailGalleryElement = useTemplateRef('thumbnailGalleryElement');

const animate = (galleryRef: MaybeRef<InstanceType<Swiper>>, button: 'next' | 'prev') => {
	if (unref(galleryRef)) {
		const buttonElement = unref(galleryRef).navigation[`${button}El`];
		buttonElement.style.animationName = '';
		buttonElement.offsetWidth;
		buttonElement.style.animationName = 'hotel-gallery-arrow-bounce';
	}
};

function formatCopyrightString(text: string) {
	if (text.includes('©')) {
		return text;
	}
	if (text.includes('&copy;')) {
		return text.replace('&copy;', '©');
	}
	return `© ${text}`;
}

const getVideoSrc = (element: GalleryElement) => {
	if (element.type === 'youtube' || element.type === 'ytube') {
		return `https://www.youtube.com/embed/${element.content}?rel=0`;
	}
	if (element.type === 'vimeo') {
		return `https://player.vimeo.com/video/${element.content}`;
	}
	return element.content;
};

const slideNext = () => {
	animate(mainGallery, 'next');
	mainGallery.value?.slideNext();
};

const slidePrev = () => {
	animate(mainGallery, 'prev');
	mainGallery.value?.slidePrev();
};

const slideNextThumb = () => {
	animate(thumbGallery, 'next');
	mainGallery.value?.slideNext();
};

const slidePrevThumb = () => {
	animate(thumbGallery, 'prev');
	mainGallery.value?.slidePrev();
};

const close = () => {
	stopVideo(activeIndex.value);
	emit('close');
};

const handleKeyup = (event: KeyboardEvent) => {
	if (event.key === 'ArrowRight') {
		slideNext();
	}
	if (event.key === 'ArrowLeft') {
		slidePrev();
	}
	if (event.key === 'Escape') {
		close();
		window.removeEventListener('keyup', handleKeyup);
	}
};

const mainGallery = ref<InstanceType<Swiper>>(null);
const thumbGallery = ref<InstanceType<Swiper>>(null);

const activeIndex = ref(mainGallery.value?.activeIndex ?? 0);
const elementCount = computed(() => props.elements.length)

watch(() => model.value, () => {
	if (model.value) {
		nextTick(() => { // one tick delay
			document.body.style.overflow = 'hidden';
			if (!thumbGallery.value) {
				thumbGallery.value = new Swiper(thumbnailGalleryElement.value, {
					slidesPerView: 3,
					freeMode: true,
					spaceBetween: 10,
					watchSlidesProgress: true,
					breakpoints: {
						544: {
							slidesPerView: 6,
						},
						992: {
							slidesPerView: 10,
						},
						1300: {
							slidesPerView: 12,
						}
					},
					navigation: {
						nextEl: thumbnailGalleryElement.value?.querySelector('.gallery-modal__thumb-gallery-next'),
						prevEl: thumbnailGalleryElement.value?.querySelector('.gallery-modal__thumb-gallery-prev'),
					},
				});
			}
			if (!mainGallery.value) {
				mainGallery.value = new Swiper(mainGalleryElement.value, {
					slidesPerView: 1,
					centeredSlides: true,
					navigation: {
						nextEl: mainGalleryElement.value?.querySelector('.gallery-modal__main-gallery-next'),
						prevEl: mainGalleryElement.value?.querySelector('.gallery-modal__main-gallery-prev'),
					},
					thumbs: {
						swiper: thumbGallery.value,
					},
					on: {
						activeIndexChange(swiper: Swiper) {
							activeIndex.value = swiper.activeIndex;
						},
					}
				});
			}
			if (mainGallery.value && props.index !== mainGallery.value?.activeIndex) {
				mainGallery.value.slideTo(props.index, 0, false);
			}
		});
		window.addEventListener('keyup', handleKeyup);
	} else {
		document.body.style.overflow = 'auto';
		window.removeEventListener('keyup', handleKeyup);
	}
});

function stopVideo(index: number) {
	if (!mainSlideRefs.value) {
		return;
	}
	const embedElement = mainSlideRefs.value[index].querySelector('embed');
	if (embedElement) {
		embedElement.src = embedElement.src;
	}
}

watch(activeIndex, (_, oldVal) => {
	if (!mainSlideRefs.value) {
		return;
	}
	const embedElement = mainSlideRefs.value[oldVal].querySelector('embed');
	if (embedElement) {
		embedElement.src = embedElement.src;
	}
});

onBeforeUnmount(() => {
	document.body.style.overflow = 'auto';
	window.removeEventListener('keyup', handleKeyup);
});

</script>

<style lang="scss">
@keyframes hotel-gallery-arrow-bounce {
	0% {
		transform: scaleX(1);
	}

	25% {
		transform: scaleX(1.1);
	}

	100% {
		transform: scaleX(1);
	}
}
</style>

<style lang="scss" scoped>
$main-viewport-width: max(75vw, 100rem);

.gallery-modal {
	position: fixed;
	z-index: 100000;
	inset: 0;
	display: none;
	flex-direction: column;
	background: $color-white;

	&.is-visible {
		display: grid;
		grid-template-rows: clamp(7rem, 9rem, 12%) minmax(0, 1fr);
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1.5rem 1rem;
		border-bottom: 0.1rem solid $color-border;
	}

	&__logo {
		width: auto;
		height: 100%;
	}

	&__close-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem;

		&:hover,
		&:active,
		&:focus {
			background: $color-white;
		}
	}

	&__close-icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: currentcolor;
	}

	&__body {
		display: grid;
		grid-template-rows: minmax(0, 5fr) minmax(0, auto);
		height: 100%;
	}

	&__aria-label {
		@include sr-only;
	}

	&__content-wrapper {
		width: 100%;
		position: relative;
		max-width: $main-viewport-width;
		min-height: 50%;
		aspect-ratio: 3/2;
	}

	&__copyright {
		position: absolute;
		right: 0;
		bottom: 1rem;
		padding: 0.5rem 1rem;
		background: $color-white;
		font-size: $font-small-3;
		color: $color-dark-gray;
	}

	&__main-slides {
		width: 100%;
		max-height: 100%;
		height: 100%;
		align-self: center;
		display: grid;
		grid-template-columns: minmax(0, 1fr) minmax(min($main-viewport-width, 100vw), 1fr) minmax(0, 1fr);
		grid-template-rows: minmax(0, 1fr);
		grid-template-areas: "prev image next";

		.swiper-slide {
			display: flex;
			align-items: center;
		}
	}

	&__thumb-slides {
		border-top: solid 0.1rem $color-border;
		width: 100%;
		height: 100%;
		display: grid;
		align-self: center;
		grid-template-columns: minmax(0, 6rem) minmax(0, 1fr) minmax(0, 6rem);
		grid-template-areas: "prev gallery next";

		.swiper-wrapper {
			padding: 1rem;
			box-sizing: border-box;
		}
	}

	.swiper-wrapper {
		width: 100%;
	}

	&__video-wrapper {
		height: calc(100% - 50px);
		position: absolute;
		inset: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&__video {
		width: 100%;
		height: 100%;
		max-width: $main-viewport-width;
	}

	:deep(.gallery-modal__image) {
		width: 100%;
		aspect-ratio: 3/2;
		object-fit: cover;
	}

	&__thumb-image {
		width: 100%;
		aspect-ratio: 3/2;
		object-fit: cover;
		opacity: 0.5;

		&--active {
			opacity: 1;
		}
	}

	&__thumb-video-preview {
		position: relative;
		opacity: 0.5;
		width: 100%;
		height: 100%;

		&--active {
			opacity: 1;
		}
	}

	&__thumb-video-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 3rem;
		height: 3rem;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
		align-items: center;
		justify-content: center;
		z-index: 1;
		background-color: hsl(0deg 0% 100% / 50%);
		animation-duration: 0.5s;
		cursor: pointer;

		svg {
			fill: $color-primary;
		}
	}

	.swiper-button-next {
		transform-origin: left;
	}

	.swiper-button-prev {
		transform-origin: right;
	}

	&__main-gallery-next,
	&__main-gallery-prev {
		width: 10rem;
		height: 100%;

		svg {
			width: 4.2rem;
			height: 4.2rem;
		}
	}

	&__main-gallery-next {
		justify-self: end;
		grid-area: next;
	}

	&__main-gallery-prev {
		justify-self: start;
		grid-area: prev;
	}

	&__thumb-gallery-next,
	&__thumb-gallery-prev {
		width: 4rem;
		height: 100%;

		svg {
			width: 2rem;
			height: 2rem;
		}
	}

	&__thumb-gallery-next {
		justify-self: end;
		grid-area: next;
	}

	&__thumb-gallery-prev {
		justify-self: start;
		grid-area: prev;
	}
}


@media screen and (orientation: 'landscape') {
	.gallery-modal {
		&__main-slides {
			max-width: 65%;
		}

		&__content-wrapper {
			min-height: 100%;
			aspect-ratio: unset;
		}
	}
}

@media screen and (min-width: $breakpoint-extralarge) {
	.gallery-modal {
		&__body {
			grid-template-rows: minmax(0, 7fr) minmax(0, auto);
		}

		&__main-slides {
			max-width: 100%;
		}

		.swiper-button-next,
		.swiper-button-prev {
			display: flex;
		}

		.hidden {
			visibility: hidden;
		}
	}
}

</style>
