import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filter-radio-button-list" }

import FilterRadioButton from '@lmt-rpb/FilterRadioButton/FilterRadioButton.vue';

type Props = {
	id: string;
	items: Array<{ label: string; value: T; boldLabel?: string, disabled?: boolean }>
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterRadioButtonList',
  props: /*@__PURE__*/_mergeModels({
    id: { default: 'default' },
    items: { default: () => [] }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const model = _useModel<T>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(FilterRadioButton, {
        id: _ctx.id,
        key: `${item.boldLabel ?? ''}${item.label}`,
        modelValue: model.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
        label: item.label,
        "bold-label": item.boldLabel,
        value: item.value,
        disabled: item.disabled
      }, null, 8 /* PROPS */, ["id", "modelValue", "label", "bold-label", "value", "disabled"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})