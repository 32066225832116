import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-checked", "aria-label"]
const _hoisted_2 = { class: "filter-row__label" }
const _hoisted_3 = {
  key: 0,
  class: "filter-row__bold-label"
}

import { computed } from 'vue';

interface Props {
	id: string;
	value: T;
	disabled?: boolean;
	label?: string;
	boldLabel?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterRow',
  props: /*@__PURE__*/_mergeModels({
    id: { default: 'default' },
    value: {},
    disabled: { type: Boolean, default: false },
    label: { default: '' },
    boldLabel: { default: undefined }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const containerClasses = computed(() => {
	return {
		'filter-row--disabled': props.disabled,
		'filter-row--selected': isSelected.value,
	};
});

const model = _useModel<T>(__props, "modelValue");
const isSelected = computed(() => model.value === props.value);
const updateModel = () => {
	if (props.disabled) return;
	model.value = props.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['filter-row', containerClasses.value]),
    role: "radio",
    "aria-checked": isSelected.value,
    "aria-label": _ctx.label,
    onClick: updateModel,
    onKeyup: _withKeys(updateModel, ["enter"])
  }, [
    _createElementVNode("p", _hoisted_2, [
      (_ctx.boldLabel)
        ? (_openBlock(), _createElementBlock("b", _hoisted_3, _toDisplayString(_ctx.boldLabel), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createTextVNode(" " + _toDisplayString(_ctx.label), 1 /* TEXT */)
    ])
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})