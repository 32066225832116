import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "quickpill-list rpb_main-content--no-sidepadding"
}
const _hoisted_2 = { class: "quickpill-list__container" }
const _hoisted_3 = { class: "quickpill-list__pills" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "quickpill-list__headline" }

import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import QuickPill from '@lmt-rpb/QuickPill/QuickPill.vue';
import { addTopMarginToFirstElement } from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import { computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from '@/components/common/store';
import type { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import type { QuickPillType } from './types';

interface Props {
	headerProps: HeaderProps;
	pills: QuickPillType[];
	limit?: number;
	expandText?: string;
	enableEventToggling?: boolean;
	currency?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuickPillList',
  props: {
    headerProps: {},
    pills: {},
    limit: { default: 7 },
    expandText: { default: 'Mehr anzeigen' },
    enableEventToggling: { type: Boolean, default: false },
    currency: { default: 'EUR' }
  },
  emits: ["pill-click"],
  setup(__props: any, { emit: __emit }) {

const store = useStore();

const props = __props;

const emit = __emit;

const showAll = ref(false);

const storeLoaded = ref(false);

const limitedList = computed((): QuickPillType[] => (showAll.value ? props.pills : props.pills.slice(0, props.limit)));

const showButton = computed((): boolean => props.pills.length > props.limit);

const isStoreInitialized = computed((): boolean => store?.state?.config?.storeInitialized);

const addMarginTop = (): void => {
	addTopMarginToFirstElement(getCurrentInstance()?.proxy?.$el as HTMLElement);
};

const showAllPills = () => {
	showAll.value = true;
};

onBeforeMount(() => {
	// Workaround for initializing quickpills before store
	if (!isStoreInitialized.value) {
		storeLoaded.value = true;
		addMarginTop();
	} else {
		storeLoaded.value = true;
	}

	if (props.enableEventToggling) {
		EventBus.$on('Destination:OpenQuickPills', showAllPills);
	}
});

onMounted(() => {
	if (storeLoaded.value) {
		addMarginTop();
	}
});

onBeforeUnmount(() => {
	EventBus.$off('Store:Initialized', () => {
		storeLoaded.value = false;
	});

	if (props.enableEventToggling) {
		EventBus.$off('Destination:OpenQuickPills', showAllPills);
	}
});

return (_ctx: any,_cache: any) => {
  return (storeLoaded.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(ComponentHeader, { "header-props": _ctx.headerProps }, null, 8 /* PROPS */, ["header-props"]),
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(limitedList.value, (pill, index) => {
              return (_openBlock(), _createBlock(QuickPill, {
                key: pill.destination,
                destination: pill.destination,
                price: pill.price,
                currency: _ctx.currency,
                url: pill.url,
                "title-tag": pill.titleTag,
                "link-target": pill.linkTarget,
                onClick: ($event: any) => (emit('pill-click', index))
              }, null, 8 /* PROPS */, ["destination", "price", "currency", "url", "title-tag", "link-target", "onClick"]))
            }), 128 /* KEYED_FRAGMENT */)),
            (showButton.value && !showAll.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                  _createElementVNode("button", {
                    class: "quickpill-list__button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showAll.value = true))
                  }, [
                    _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.expandText), 1 /* TEXT */)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}
}

})