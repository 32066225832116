<template>
	<div class="checkout-text-field">
		<label
			class="checkout-text-field__label"
			:class="{ 'checkout-text-field__label--invalid': fieldInvalid }"
			:for="computedFieldName"
		>
			{{ actualLabel }}<span v-if="requiredField && !fieldInvalid">*</span>
		</label>
		<p
			v-if="labelText"
			class="checkout-text-field__explanation"
		>
			{{ labelText }}
		</p>
		<div class="checkout-text-field__container">
			<slot></slot>
			<input
				:id="computedFieldName"
				ref="nativeInput"
				:class="{ 'error' : !valid && validated && !disabled}"
				class="checkout-text-field__container-input"
				:type="fieldType"
				:name="computedFieldName"
				:placeholder="placeholder"
				:value="modelValue"
				:disabled="disabled"
				:required="requiredField"
				:enterkeyhint="enterkeyhint"
				@input="inputHandler($event)"
				@change="inputHandler($event)"
				@blur="blurHandler($event)"
			/>
			<template v-if="requiredField || disabled">
				<template v-if="!disabled">
					<BaseIcon
						v-if="!valid && validated"
						name="exclamationMarkFill"
						class="checkout-text-field__exclamation"
					/>
				</template>
			</template>
		</div>
	</div>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { firstLetterUppercase } from '@utils/utils';
import { computed, ref } from 'vue';
import { z } from 'zod';
import { CheckoutValidationState } from '@/components/common/store/checkout';

interface Props {
	label: string,
	fieldName: string,
	disabled?: boolean,
	fieldType?: string,
	labelText?: string,
	requiredField?: boolean,
	uniqueKey?: string | number
	placeholder?: string,
	errorMessage?: string,
	capitalizeFirstWord?: boolean,
	validFromParentValidation?: boolean,
	hideError?: boolean,
	enterkeyhint?: string,
	validationSchema?: any,
}

const props = withDefaults(defineProps<Props>(), {
	fieldType: 'text',
	labelText: '',
	requiredField: false,
	uniqueKey: '',
	placeholder: '',
	errorMessage: '',
	capitalizeFirstWord: false,
	validFromParentValidation: undefined,
	hideError: false,
	enterkeyhint: 'next',
	validationSchema: z.any(),
	disabled: false,
});

const modelValue = defineModel<string>();

const nativeInput = ref<HTMLInputElement | null>(null);

const computedFieldName = computed((): string => {
	if (props.uniqueKey) return `${props.fieldName}-${props.uniqueKey}`;
	return props.fieldName;
});

// Init field in store
CheckoutValidationState.value[computedFieldName.value] = {
	valid: false, validated: false, hasBackendError: false
};

const valid = computed((): boolean => {
	if (typeof props.validFromParentValidation !== 'undefined') {
		CheckoutValidationState.value[computedFieldName.value].valid = props.validFromParentValidation;
		CheckoutValidationState.value[computedFieldName.value].hasBackendError = false;
		return props.validFromParentValidation;
	}

	if (CheckoutValidationState.value[computedFieldName.value].hasBackendError) {
		return false;
	}
	return CheckoutValidationState.value[computedFieldName.value].valid;
});

const fieldInvalid = computed(() => !valid.value && validated.value && !props.disabled);
const actualLabel = computed(() => (fieldInvalid.value ? props.errorMessage : props.label));

const validated = computed((): boolean => CheckoutValidationState.value[computedFieldName.value].validated);

const inputValidator = (inputValue: string): void => {
	if (props.capitalizeFirstWord) {
		// eslint-disable-next-line no-param-reassign
		inputValue = firstLetterUppercase(inputValue);
	}

	// if the validation happens in the parent validation inside the component is disabled
	if (typeof props.validFromParentValidation === 'undefined') {
		const validationResult = props.validationSchema.safeParse(inputValue);
		CheckoutValidationState.value[computedFieldName.value].valid = validationResult.success;
		if (CheckoutValidationState.value[computedFieldName.value].hasBackendError) {
			// Reset backend error flag if the input is valid
			CheckoutValidationState.value[computedFieldName.value].hasBackendError = !validationResult.success;
		}
	}

	modelValue.value = inputValue;
};

const inputHandler = (event: Event): void => {
	const inputValue = (event.target as HTMLInputElement).value;
	inputValidator(inputValue);
};

const blurHandler = (event: Event): void => {
	CheckoutValidationState.value[computedFieldName.value].validated = true;
	inputHandler(event);
};

defineExpose({ inputValidator, nativeInput });
</script>

<style lang="scss" scoped>
.checkout-text-field {
	margin-top: 1.5rem;
	width: 100%;

	&__container {
		position: relative;
		gap: 1rem;
	}

	&__error {
		position: absolute;
		background-color: $color-warning;
		width: 100%;
		border: 0.1rem solid $color-warning;
		color: $color-white;
		border-bottom-left-radius: $border-radius-small;
		border-bottom-right-radius: $border-radius-small;
		padding: 0.5rem 0.8rem;
		top: 5.4rem;
		font-style: italic;
	}

	&__valid {
		position: absolute;
		width: 100%;
		top: 50%;
		text-align: end;
		padding: 0 2rem;
		transform: translateY(-35%);
		pointer-events: none;
	}

	&__label {
		color: $color-text;
		font-size: $font-small-3;
		font-weight: $font-weight-semibold;
		margin-bottom: 0.3rem;
		width: 100%;

		&--invalid {
			color: $color-warning-2;
		}
	}

	&__explanation {
		font-size: $font-small-4;
		font-weight: $font-weight-regular;
		color: $color-checkout-explanation-text;
		margin-top: -0.6rem;
		margin-bottom: 0.5rem;
	}

	input {
		width: 100%;
		border: 0.1rem solid $color-checkout-forms-border;
		border-radius: $border-radius-small;
		height: 5.4rem;
		padding: 0 2rem;
		font-size: $font-small-1;
		color: $color-checkout-font;
		font-weight: $font-weight-semibold;
		background: $color-white;
		box-shadow: inset 2px 3px 2px #0000000A;
		text-overflow: ellipsis;
		padding-right: 4rem;
		scroll-margin-top: $scroll-margin-top;
		display: block;

		&::placeholder {
			font-size: $font-small-1;
			font-weight: $font-weight-semibold;
			max-width: 95%;
			text-overflow: ellipsis;
		}

		&:placeholder-shown {
			text-overflow: ellipsis;
		}

		&:focus,
		&:active,
		&:focus-visible {
			outline: 0.1rem solid $color-checkout-forms-focus-border;
			border: 0.1rem solid $color-checkout-forms-focus-border;

			&::placeholder {
				opacity: 0.2;
			}
		}

		&.error {
			border: 0.2rem solid $color-warning-2;

			&:focus,
			&:active,
			&:focus-visible {
				outline: 0.1rem solid $color-checkout-forms-focus-border;
				border: 0.1rem solid $color-checkout-forms-focus-border;

				&::placeholder {
					opacity: 0.2;
				}
			}

			&:focus::placeholder {
				opacity: 0.2;
			}

			&.error {
				border: 0.2rem solid $color-warning-2;

				&:focus,
				&:active,
				&:focus-visible {
					outline: 0.1rem solid $color-warning-2;
				}

				&:placeholder-shown {
					text-overflow: ellipsis;
				}
			}
		}

		&:disabled {
			border: none;
		}
	}

	&__exclamation {
		fill: $color-warning-2;
		position: absolute;
		right: 1.6rem;
		top: 50%;
		transform: translateY(-50%);
	}

	&__check-icon {
		z-index: 1;
	}

	@media (min-width: $breakpoint-small) {
		&__container {
			gap: 1.6rem;
		}
	}

	@media (min-width: $breakpoint-large) {
		&__label {
			font-size: $font-small-2;
		}

		&__explanation {
			font-size: 1.1rem;
		}

		input {
			font-size: $font-medium-3;
		}
	}
}
</style>
