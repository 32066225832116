export default [
	{
		id: 1, paramKey: 'beach', attrType: 'HotelAttributes', label: 'Direkte Strandlage', value: 'direct_beach_access',
	},
	{
		id: 2, paramKey: 'beach', attrType: 'HotelAttributes', label: 'Sandstrand', value: 'sandy_beach',
	},
	{
		id: 3, paramKey: 'beach', attrType: 'HotelAttributes', label: 'Strandnah', value: 'hotel_near_beach',
	},
	{
		id: 5, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Top für Familien', value: 'objectTravelAsFamily',
	},
	{
		id: 4, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Top für Paare', value: 'objectTravelAsCouple',
	},
	{
		id: 7, paramKey: 'trgrp', attrType: 'HotelAttributes', label: 'Nur für Erwachsene', value: 'adults_only',
	},
	{
		id: 31, paramKey: 'hotat', attrType: 'HotelAttributes', label: 'Nur mit Gratis W-Lan', value: 'free_wlan',
	},
	{
		id: 43, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Gutes Essen', value: 'mealsAndDrinks',
	},
	{
		id: 52, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Schöner Pool', value: 'pool',
	},
	{
		id: 53, paramKey: 'raatt', attrType: 'RatingAttributes', label: 'Top Wasserrutschen', value: 'poolWaterSlide',
	},
] as const;
