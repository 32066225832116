import type { InitializedStore } from '.';

export function initBreakpoints(store: InitializedStore) {
	const mediaQueryDesktop = window.matchMedia('(min-width: 1300px)');
	const updateIsDesktop = (media: MediaQueryListEvent | MediaQueryList) => store.commit('updateConfig', { isDesktop: media.matches });
	updateIsDesktop(mediaQueryDesktop);
	mediaQueryDesktop.onchange = updateIsDesktop;

	const mediaQueryIsMinTablet = window.matchMedia('(min-width: 768px)');
	const updateIsMinTablet = (media: MediaQueryListEvent | MediaQueryList) => store.commit('updateConfig', { isMinTablet: media.matches });
	updateIsMinTablet(mediaQueryIsMinTablet);
	mediaQueryIsMinTablet.onchange = updateIsMinTablet;

	const mediaQueryIsMinTabletLandscape = window.matchMedia('(min-width: 992px)');
	const updateIsMinTabletLandscape = (media: MediaQueryListEvent | MediaQueryList) => store.commit('updateConfig', { isMinTabletLandscape: media.matches });
	updateIsMinTabletLandscape(mediaQueryIsMinTabletLandscape);
	mediaQueryIsMinTabletLandscape.onchange = updateIsMinTabletLandscape;

	const mediaQueryisMobileLarge = window.matchMedia('(min-width: 543px)');
	const updateisMobileLarge = (media: MediaQueryListEvent | MediaQueryList) => store.commit('updateConfig', { isMobileLarge: media.matches });
	updateisMobileLarge(mediaQueryisMobileLarge);
	mediaQueryisMobileLarge.onchange = updateisMobileLarge;
}
