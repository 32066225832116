import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import ShowMoreContainer from '@lmt-rpb/ShowMoreContainer/ShowMoreContainer.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import roomTypes, { type RoomTypeValue } from '@/js/data/room-types';
import { computed, watch, onMounted } from 'vue';
import { useStore } from '@/components/common/store';
import FilterPrice from '@lmt-rpb/FilterPrice/FilterPrice.vue';

type Props = {
	isHotelPage?: boolean;
	isChecked?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomTypeFilter',
  props: {
    isHotelPage: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false }
  },
  emits: ['update:selectedFilters'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const store = useStore();

const sortedRoomTypes = computed(() => {
	// only sort on hotelpage
	if (!props.isHotelPage) {
		return roomTypes;
	}
	// don't sort if nothing is active
	if (activeFilters.value.size === 0) {
		return roomTypes;
	}

	return roomTypes.slice().sort((a, b) => {
		// sort first based on availability
		if (activeFilters.value.has(a.value)) {
			if (activeFilters.value.has(b.value)) {
				return 0;
			} else {
				return -1;
			}
		}
		if (activeFilters.value.has(b.value)) {
			return 1;
		}
		// if neither is active then sort the selected ones before the others
		if (selectedFilters.value.includes(a.value)) {
			if (selectedFilters.value.includes(b.value)) {
				return 0;
			}
			return -1;
		}
		return 1;
	});
});

const visibleRoomTypes = computed(() => sortedRoomTypes.value.slice(0, 5));
const hiddenRoomTypes = computed(() => sortedRoomTypes.value.slice(5));

const selectedFilters = computed({
	get() {
		return store.state.searchMask.roomTypes;
	},
	set(value) {
		store.commit('searchMask/updateFormData', { roomTypes: value });
		emitSelectedFilters(value as RoomTypeValue[]);
	},
});

const emit = __emit;

const emitSelectedFilters = (value: RoomTypeValue[]) => {
	emit('update:selectedFilters', value);
};

watch(selectedFilters, () => {
	emitSelectedFilters(selectedFilters.value as RoomTypeValue[]);
});
const priceMap = computed(() => store.state.searchMask.room.priceMap);
const activeFilters = computed(() => store.state.searchMask.room.enabledFilters);
const isFetchLoading = computed(() => store.state.searchMask.room.isFetchLoading);

const isCheckboxDisabled = function (checkboxValue: RoomTypeValue) {
	if (!props.isHotelPage) {
		return false;
	}
	if (isFetchLoading.value) {
		return true;
	}
	return !activeFilters.value.has(checkboxValue);
};

onMounted(() => {
	if (selectedFilters.value) emitSelectedFilters(selectedFilters.value as RoomTypeValue[]);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FilterType, { class: "room-type-filter" }, {
    default: _withCtx(() => [
      _createVNode(ShowMoreContainer, {
        "item-count": _unref(roomTypes).length
      }, {
        visibleContent: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleRoomTypes.value, (roomType) => {
            return (_openBlock(), _createElementBlock("li", {
              key: roomType.id,
              class: "room-type-filter__list-item"
            }, [
              _createVNode(Checkbox, {
                modelValue: selectedFilters.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedFilters).value = $event)),
                "custom-value": roomType.value,
                label: roomType.label,
                disabled: isCheckboxDisabled(roomType.value)
              }, null, 8 /* PROPS */, ["modelValue", "custom-value", "label", "disabled"]),
              (_ctx.isHotelPage)
                ? (_openBlock(), _createBlock(FilterPrice, {
                    key: 0,
                    "is-loading": isFetchLoading.value,
                    value: priceMap.value.get(roomType.value)
                  }, null, 8 /* PROPS */, ["is-loading", "value"]))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        hiddenContent: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hiddenRoomTypes.value, (roomType) => {
            return (_openBlock(), _createElementBlock("li", {
              key: roomType.id,
              class: "room-type-filter__list-item"
            }, [
              _createVNode(Checkbox, {
                modelValue: selectedFilters.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedFilters).value = $event)),
                "custom-value": roomType.value,
                label: roomType.label,
                disabled: isCheckboxDisabled(roomType.value)
              }, null, 8 /* PROPS */, ["modelValue", "custom-value", "label", "disabled"]),
              (_ctx.isHotelPage)
                ? (_openBlock(), _createBlock(FilterPrice, {
                    key: 0,
                    "is-loading": isFetchLoading.value,
                    value: priceMap.value.get(roomType.value)
                  }, null, 8 /* PROPS */, ["is-loading", "value"]))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["item-count"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})