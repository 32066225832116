import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "counter",
  "aria-live": "polite"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "counter__controls" }
const _hoisted_4 = ["aria-labelledby"]

import { computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';

interface Props {
	min?: number,
	max?: number,
	pluralLabel: string,
	modelValue: number,
	adultDecreaseLabel?: string,
	adultIncreaseLabel?: string,
	childDecreaseLabel?: string,
	childIncreaseLabel?: string,
	isAdult?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Counter',
  props: {
    min: { default: 1 },
    max: { default: 4 },
    pluralLabel: { default: '' },
    modelValue: {},
    adultDecreaseLabel: { default: 'Einen Erwachsenen entfernen' },
    adultIncreaseLabel: { default: 'Einen Erwachsenen hinzufügen' },
    childDecreaseLabel: { default: 'Ein Kind entfernen' },
    childIncreaseLabel: { default: 'Ein Kind hinzufügen' },
    isAdult: { type: Boolean, default: true }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const uuid = uuidv4();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newCount) {
		emit('update:modelValue', newCount);
	}
});

const increase = (): void => {
	model.value = Math.max(props.min, Math.min(props.modelValue + 1, props.max));
};

const decrease = (): void => {
	model.value = Math.max(props.min, Math.min(props.modelValue - 1, props.max));
};

const labels = computed(() => ({
  decrease: props.isAdult ? props.adultDecreaseLabel : props.childDecreaseLabel,
  increase: props.isAdult ? props.adultIncreaseLabel : props.childIncreaseLabel
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "counter__label",
      id: `counter-display-${_unref(uuid)}`
    }, _toDisplayString(props.pluralLabel), 9 /* TEXT, PROPS */, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(RoundedButton, {
        class: "counter__button is-decrease",
        disabled: model.value === _ctx.min,
        onClick: decrease,
        onKeydown: _withKeys(_withModifiers(decrease, ["prevent"]), ["enter"]),
        "aria-label": labels.value.decrease
      }, {
        default: _withCtx(() => [
          _createVNode(BaseIcon, {
            name: "minus",
            class: "counter__icon"
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onKeydown", "aria-label"]),
      _createElementVNode("div", {
        class: "counter__value",
        "aria-labelledby": `counter-value-${_unref(uuid)}`
      }, _toDisplayString(model.value), 9 /* TEXT, PROPS */, _hoisted_4),
      _createVNode(RoundedButton, {
        class: "counter__button is-increase",
        disabled: model.value === _ctx.max,
        onClick: increase,
        onKeydown: _withKeys(_withModifiers(increase, ["prevent"]), ["enter"]),
        "aria-label": labels.value.increase
      }, {
        default: _withCtx(() => [
          _createVNode(BaseIcon, {
            name: "plus",
            class: "counter__icon"
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onKeydown", "aria-label"])
    ])
  ]))
}
}

})