import { isBestPackageOfferForHotelList } from './typeUtils';
import { ItemType, ItemTypeWithAccommodationOffer } from '@/interfaces/hotel-list-types/hotel-list-types';

export enum TravelType {
	Accommodation = 'Accommodation',
	Package = 'Package',
}

export type Offer = ItemType

export const extractTravelTypeFromOffer = (offer: ItemType) => {
	if (isBestPackageOfferForHotelList(offer)) {
		return offer.PackageOffer;
	}
	return (offer as ItemTypeWithAccommodationOffer).AccommodationOffer;
};

export function getTravelType(type: 'hotel' | 'package') {
	return type === 'hotel' ? TravelType.Accommodation : TravelType.Package;
}
