import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "show-more-container__list" }

import TransitionExpand from '@lmt-rpb/TransitionExpand/TransitionExpand.vue';
import { ref, computed } from 'vue';

type Props = {
	itemCount: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowMoreContainer',
  props: {
    itemCount: {}
  },
  setup(__props: any) {

const props = __props;
const showMore = ref(false);
const handleShowMore = () => {
	showMore.value = !showMore.value;
};
const buttonText = computed(() => (showMore.value ? 'Weniger anzeigen' : `Alle ${props.itemCount} anzeigen`));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("menu", _hoisted_1, [
      _renderSlot(_ctx.$slots, "visibleContent"),
      _createVNode(TransitionExpand, { "show-content": showMore.value }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "hiddenContent")
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["show-content"])
    ]),
    _createElementVNode("button", {
      class: "show-more-container__show-more",
      onClick: handleShowMore
    }, _toDisplayString(buttonText.value), 1 /* TEXT */)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})