<template>
	<div class="filter-radio-button-list">
		<FilterRadioButton
			v-for="item in items"
			:id="id"
			:key="`${item.boldLabel ?? ''}${item.label}`"
			v-model="model"
			:label="item.label"
			:bold-label="item.boldLabel"
			:value="item.value"
			:disabled="item.disabled"
		/>
	</div>
</template>
<script setup lang="ts" generic="T extends string | number | undefined">
import FilterRadioButton from '@lmt-rpb/FilterRadioButton/FilterRadioButton.vue';

type Props = {
	id: string;
	items: Array<{ label: string; value: T; boldLabel?: string, disabled?: boolean }>
}

withDefaults(defineProps<Props>(), {
	id: 'default',
	items: () => [],
});

const model = defineModel<T>();
</script>

<style lang="scss" scoped>
.filter-radio-button-list {
	display: flex;
	flex-direction: column;
	row-gap: 1.6rem;
}
</style>
