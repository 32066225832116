import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "internal-navigation-link" }
const _hoisted_2 = ["data-id", "href", "aria-current"]

import { scrollToAnchorWithDynamicOffset } from '@/js/utils/utils';
import { computed, inject, onBeforeMount } from 'vue';
import { type InternalNavigationBarProvide, NavigationBarProvideKey } from '../provideTypes';

export interface InternalNavigationLinkProps {
	anchorId: string;
	href?: string;
	label?: string;
	gaAttributes?: Record<string, string | number>;
}

export default /*@__PURE__*/_defineComponent({
  ...{
	inheritAttrs: false,
},
  __name: 'InternalNavigationLink',
  props: {
    anchorId: {},
    href: {},
    label: {},
    gaAttributes: {}
  },
  setup(__props: any) {

const props = __props;



const injectData = inject<InternalNavigationBarProvide>(NavigationBarProvideKey);

const isAriaCurrent = computed(() => {
	return injectData?.activeLink.value === props.anchorId;
});

onBeforeMount(() => {
	if (injectData) {
		injectData.addLinkToObserved(props.anchorId as string);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", _mergeProps({
      "data-id": _ctx.anchorId,
      href: _ctx.href,
      class: "internal-navigation-link__link",
      "aria-current": isAriaCurrent.value
    }, { ..._ctx.gaAttributes, ..._ctx.$attrs }, {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(scrollToAnchorWithDynamicOffset)($event)), ["prevent"]))
    }), _toDisplayString(_ctx.label), 17 /* TEXT, FULL_PROPS */, _hoisted_2)
  ]))
}
}

})