import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grouped-price-info" }
const _hoisted_2 = { class: "grouped-price-info__price-container" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "grouped-price-info__subtext" }

import { computed } from 'vue';
import type { ClientCurrency } from '@global-js/constants';
import BasePrice from '@lmt-rpb/Atoms/BasePrice/BasePrice.vue';
import type { HistoricalPriceData } from '@/interfaces/common';
import DiscountPrice from '../DiscountPrice/DiscountPrice.vue';
import { useStore } from '@/components/common/store';
import { useTravelers } from '../../../composables/useTravelers';


interface Props {
	currency: ClientCurrency;
	totalPrice: number;
	subtext?: string;
	prefix?: string;
	perPersonPrice?: number,
	perPersonText?:	string,
	discount?: HistoricalPriceData,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupedPriceInfo',
  props: {
    currency: {},
    totalPrice: {},
    subtext: { default: '' },
    prefix: { default: '' },
    perPersonPrice: {},
    perPersonText: { default: 'p.P.' },
    discount: { default: undefined }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();
const storeTravelers = computed(() => ({
	adult: store.state.searchMask.travelers.adult,
	children: store.state.searchMask.travelers.children
}));

const { travelersLabel } = useTravelers(storeTravelers);

const prefixText = computed(() => props.prefix ? props.prefix : travelersLabel.value);

const withDiscount = computed(() => props.discount && !!props.discount?.referencePriceTotal?.avgPrice && !!props.discount?.relativeDiscountPercent?.toAvgPrice);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "discount", {
      currency: _ctx.currency,
      discount: _ctx.discount
    }, () => [
      (withDiscount.value)
        ? (_openBlock(), _createBlock(DiscountPrice, {
            key: 0,
            currency: _ctx.currency,
            "average-price": _ctx.discount.referencePriceTotal?.avgPrice,
            discount: _ctx.discount.relativeDiscountPercent?.toAvgPrice ?? 0,
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"]))
          }, {
            tooltip: _withCtx(() => [
              _renderSlot(_ctx.$slots, "tooltip", {
                currency: _ctx.currency,
                discount: _ctx.discount,
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"]))
              })
            ]),
            _: 3 /* FORWARDED */
          }, 8 /* PROPS */, ["currency", "average-price", "discount"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "grouped-price-info__prefix",
        textContent: _toDisplayString(prefixText.value)
      }, null, 8 /* PROPS */, _hoisted_3),
      _createVNode(BasePrice, {
        price: _ctx.totalPrice,
        currency: _ctx.currency,
        theme: "accent",
        class: "grouped-price-info__price"
      }, null, 8 /* PROPS */, ["price", "currency"])
    ]),
    _createElementVNode("span", _hoisted_4, [
      (_ctx.perPersonPrice)
        ? (_openBlock(), _createBlock(BasePrice, {
            key: 0,
            class: "grouped-price-info__per-person",
            prefix: _ctx.perPersonText,
            currency: _ctx.currency,
            "show-decimals": "",
            price: _ctx.perPersonPrice
          }, null, 8 /* PROPS */, ["prefix", "currency", "price"]))
        : _renderSlot(_ctx.$slots, "subtext", {
            key: 1,
            currency: _ctx.currency,
            subtext: _ctx.subtext
          }, () => [
            _createTextVNode(_toDisplayString(_ctx.subtext), 1 /* TEXT */)
          ])
    ])
  ]))
}
}

})