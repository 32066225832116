import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-modal__content" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "base-modal__header-slot" }
const _hoisted_4 = { class: "base-modal__main" }
const _hoisted_5 = { class: "base-modal__footer" }

import { ref, onMounted, computed } from 'vue';
import Modal from '@/js/modules/modal';
import CloseButton from '@lmt-rpb/Atoms/CloseButton/CloseButton.vue';

interface Props {
	headerTitle?: string;
	mediaQuery?: MediaQueryList | null;
	fullHeight?: boolean;
	modalCloseCallback?: () => void;
	size?: 'small';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseModal',
  props: {
    headerTitle: {},
    mediaQuery: { default: null },
    fullHeight: { type: Boolean, default: false },
    modalCloseCallback: { type: Function, default: () => {} },
    size: {}
  },
  emits: ['close'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const modalRef = ref<HTMLDialogElement | null>(null);
let modalInstance: Modal | null = null;

const emits = __emit;
const props = __props;

const openModal = () => {
	modalInstance?.openModal();
};

const closeModal = () => {
	modalInstance?.closeModal();
	emits('close');
};

const blockClassModifiers = computed(() => ({
	[`base-modal--${props.size}`]: !!props.size,
	'base-modal--full-height': props.fullHeight,
}));

__expose({
	modalRef,
	openModal,
	closeModal,
});

onMounted(() => {
	if (modalRef.value) {
		modalInstance = new Modal(modalRef.value, props?.mediaQuery, props.modalCloseCallback);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "modalRef",
    ref: modalRef,
    class: _normalizeClass(['base-modal', blockClassModifiers.value])
  }, [
    _createElementVNode("div", {
      class: "base-modal__backdrop",
      onClick: _withModifiers(closeModal, ["stop"])
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "base-modal__header",
        onClick: _withModifiers(closeModal, ["stop"])
      }, [
        _createElementVNode("span", {
          class: "base-modal__header-title",
          textContent: _toDisplayString(_ctx.headerTitle)
        }, null, 8 /* PROPS */, _hoisted_2),
        _createVNode(CloseButton, {
          class: "base-modal__close-btn",
          onClick: _withModifiers(closeModal, ["stop"])
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps({ closeModal: closeModal, openModal: openModal })))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ closeModal: closeModal, openModal: openModal })))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "footer", _normalizeProps(_guardReactiveProps({ closeModal: closeModal, openModal: openModal })))
      ])
    ])
  ], 2 /* CLASS */))
}
}

})