<template>
	<div class="filter-type">
		<div
			class="filter-type__header"
			@click="toggleDropdown"
		></div>
		<div class="filter-type__body--desktop">
			<slot />
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	icon?: string;
	title?: string;
	initialOpen?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	icon: undefined,
	title: 'Initial title',
	initialOpen: true,
});

const emit = defineEmits(['FilterType:MobileHeaderOpened']);

const { isDesktop } = storeToRefs(useBreakpointStore());

const dropdownOpen = ref(props.initialOpen);
const toggleDropdown = function () {
	if (isDesktop.value) return;
	dropdownOpen.value = !dropdownOpen.value;
	if (dropdownOpen.value) {
		emit('FilterType:MobileHeaderOpened');
	}
};
</script>

<style lang="scss" scoped>
.filter-type {
	&__title {
		color: $color-filter-type-header-text;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
	}

	&__header {
		align-items: center;
		column-gap: 0.8rem;
		margin-block: 0 0.8rem;
		padding: 0;
		width: 100%;
		margin-bottom: 1.6rem;

		&.desktop {
			background-color: initial;
			box-shadow: none;
			padding: 0;
			margin-block: 0 1.6rem;
			cursor: default;

			.filter-type__title {
				font-size: $font-medium-2;
				font-weight: $font-weight-semibold;
				line-height: 2.724rem;
			}
		}
	}

	&__title-icon {
		width: 3rem;
		height: 3rem;
		fill: $color-filter-type-collapse-icon;
	}

	&__chevron {
		width: 1.8rem;
		height: 0.9rem;
		transform: rotate(180deg);
		transition: transform 0.1s ease-out;
		vertical-align: middle;
		fill: $color-filter-type-collapse-icon;
		margin-left: auto;

		&.toggled {
			transform: rotate(360deg);
		}
	}

	&__body--mobile {
		overflow: hidden;
		position: relative;
		padding: 0;

		&::before {
			content: '';
			display: block;
			height: 0.8rem;
		}
	}
}
</style>
