import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, isRef as _isRef, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "operator-filter__not-found"
}

import { ref, computed, watch } from 'vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { useStore } from '@/components/common/store';
import { useFilterArray } from '../../composables/filterArray';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	disabled?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OperatorFilter',
  props: {
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();
const {isDesktop} = storeToRefs(useBreakpointStore());
const selectedOptions = computed(() => (store.state.searchMask.operatorTypes as string[]) ?? []);
const proxy = ref<string[]>(selectedOptions.value ?? []);
const checkboxOptionList = computed(() => store.state.types.operator ?? []);
const { filteredItems, notFoundMessage, filterExpression, resetFilter } = useFilterArray(checkboxOptionList, ['Name']);
const selectedOptionsConcat = computed(() => {
	if (props.disabled) return '';
	if (!checkboxOptionList.value.length === 0) return '';
	return checkboxOptionList.value
		.filter((option) => selectedOptions.value.includes(option.Code))
		.map((option) => option.Name)
		.join(', ');
});

const checkboxModel = computed({
	get() {
		return isDesktop.value ? selectedOptions.value : proxy.value;
	},
	set(value: string[]) {
		if (isDesktop.value) {
			store.commit('searchMask/updateFormData', {
				operatorTypes: value,
			});
		} else {
			proxy.value = value;
		}
	},
});
const clearSelectedOptions = () => {
	checkboxModel.value = [];
};
watch(selectedOptions, (newVal) => {
	if (!isDesktop.value) return;
	if (proxy.value.length === newVal.length && newVal.every((value) => proxy.value.includes(value))) return;
	proxy.value = newVal;
});

function onApply() {
	if (!isDesktop.value) {
		store.commit('searchMask/updateFormData', {
			operatorTypes: proxy.value,
		});
	}
}
function onAbort() {
	if (!isDesktop.value) {
		proxy.value = selectedOptions.value;
	}
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownFilterType, {
    ref: "dropdown",
    "search-term": _unref(filterExpression),
    "onUpdate:searchTerm": _cache[1] || (_cache[1] = ($event: any) => (_isRef(filterExpression) ? (filterExpression).value = $event : null)),
    class: _normalizeClass(["operator-filter", { 'operator-filter--disabled': _ctx.disabled }]),
    disabled: _ctx.disabled,
    title: "Veranstalter",
    icon: "sphere",
    "selected-value": selectedOptionsConcat.value,
    "apply-button": "OK",
    "abort-button": "Abbrechen",
    placeholder: _ctx.disabled ? 'Keine Treffer' : 'Beliebig',
    "with-clear-button": checkboxModel.value.length > 0,
    "with-menu-header": false,
    "modal-header": "Veranstalter",
    "show-toggle-icon": false,
    "show-buttons": "",
    searchable: "",
    "onDropdownFilterType:apply": onApply,
    "onDropdownFilterType:abort": onAbort,
    "onDropdownFilterType:clear": clearSelectedOptions
  }, {
    default: _withCtx(() => [
      (_unref(notFoundMessage))
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_unref(notFoundMessage)), 1 /* TEXT */))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(filteredItems), (option) => {
            return (_openBlock(), _createBlock(Checkbox, {
              key: option.Code,
              modelValue: checkboxModel.value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((checkboxModel).value = $event)),
                _unref(resetFilter)
              ],
              class: "operator-filter__checkbox-item",
              label: option.Name,
              "custom-value": option.Code,
              disabled: props.disabled
            }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value", "disabled", "onUpdate:modelValue"]))
          }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["search-term", "class", "disabled", "selected-value", "placeholder", "with-clear-button"]))
}
}

})