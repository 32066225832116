import { NDASH } from '@global-js/constants';

export type DateInput = Date | string | number | undefined | null;

/**
 * Adds the given days to todays date at midnight and
 * returns the timestamp as string.
 * Usecase: mobile calendar for selecting. eg. document.querySelector('[data-date="1652652000000"]')
 *
 * @param {number} days
 * @param {string} format 'timestamp' | 'dashed-date'
 * @returns {string} timestamp eg. 1652652000000 or dashed date eg. 2022-04-17
 */
export const addDaysToToday = (days: number, format: 'timestamp' | 'dashed-date' = 'timestamp'): string => {
	const todayAtMidnight = new Date(new Date().setHours(0, 0, 0, 0));

	if (format === 'dashed-date') {
		const offSetDate = todayAtMidnight.setDate(todayAtMidnight.getDate() + days + 1); // +1 is necessary to get the correct date
		return new Date(offSetDate).toISOString().slice(0, 10);
	}

	const offSetDate = todayAtMidnight.setDate(todayAtMidnight.getDate() + days);
	return offSetDate.toString();
};

export function formatDateWeekdayPoint(dateParam: DateInput) {
	const weekday = formatDate(dateParam, { weekday: 'short' });
	const date = formatDate(dateParam, { month: '2-digit', day: '2-digit' });

	return `${weekday}. ${date}`;
}

export function convertNumberToTime(value: number | undefined | null) {
	if (value === null || value === undefined) return '';
	if (value < 10) {
		return `0${value}:00`;
	}
	if (value === 24) {
		return '23:59';
	}
	return `${value}:00`;
}
export function convertToDateIfNumber(date: Date) {
	return Number(date) ? dashedDate(new Date(Number(date))) : date;
}

export function dateParts(source: string | number | Date): any[] {
	const date = new Date(source);
	const [y, m, d] = [date.getFullYear(), date.getMonth(), date.getDate()];
	const month = `0${m + 1}`.slice(-2);
	const day = `0${d}`.slice(-2);

	return [y, month, day];
}

export function dashedDate(source: string | number | Date): string {
	const parts = dateParts(source);
	return parts.join('-');
}

export function getToday(): string {
	return new Date().toISOString().split('T')[0];
}

export const convertDate = (value: string | number): Date => {
	if (typeof value === 'string' && !Date.parse(value)) {
		const dateTime = value.replace(' ', 'T');

		return new Date(dateTime);
	}

	return new Date(value);
};

export const convertDateToTime = (value: string | null | undefined): string => {
	if (value === null || value === undefined) return '';
	let time = '';
	const dateSplit = value.split(' ');
	if (dateSplit.length === 2) {
		const timeSplit = dateSplit[1].split(':');
		if (timeSplit.length === 3 || timeSplit.length === 2) {
			time = timeSplit[0] + ':' + timeSplit[1];
		}
	}
	if (dateSplit.length >= 4) {
		const timeSplit = dateSplit[4].split(':');
		if (timeSplit.length === 3 || timeSplit.length === 2) {
			time = timeSplit[0] + ':' + timeSplit[1];
		}
	}
	return time;
};

export const now = (formatNumber?: boolean): Date | number => {
	const date = new Date();

	if (formatNumber) {
		return date.setHours(0, 0, 0, 0);
	}

	return date;
};

export const formatDate = (
	date: DateInput,
	format: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit' },
	locale = 'de-AT'
): string => {
	if (!date) {
		return '';
	}

	const intl = new Intl.DateTimeFormat(locale, format);
	const d = date instanceof Date ? date : convertDate(date);

	try {
		return intl.format(d);
	} catch {
		return '';
	}
};

export const formatDateLong = (date: string | Date | undefined) =>
	date
		? formatDate(date, {
				weekday: 'long',
				month: '2-digit',
				day: '2-digit',
				year: '2-digit',
			}).replace(',', '')
		: '.';

export const formatDateWeekday = (date: DateInput): string => {
	if (!date) {
		return '';
	}
	const dateWeekday = date && formatDate(new Date(date), { weekday: 'short' });

	const dateFormatted = (date && formatDate(new Date(date), { month: '2-digit', day: '2-digit' })) || 'Beliebig';
	return `${dateWeekday} ${dateFormatted}`;
};

export const formatDateWithWeekdayAndYear = (date: DateInput): string => {
	if (!date) {
		return '';
	}
	const dateWeekday = date && formatDate(new Date(date), { weekday: 'short' });
	const dateFormatted = (date && formatDate(new Date(date), { month: '2-digit', day: '2-digit', year: '2-digit' })) || 'Beliebig';
	return `${dateWeekday}. ${dateFormatted}`;
};

export const formatDateInterval = (from: DateInput, to: DateInput, inklusiveYear = false) => {
	const fromDate = inklusiveYear ? formatDateWithWeekdayAndYear(from) : formatDateWeekday(from);
	const toDate = inklusiveYear ? formatDateWithWeekdayAndYear(to) : formatDateWeekday(to);
	return `${fromDate} ${NDASH} ${toDate}`;
};

export const dateRange = (from: Date | number, to: Date | number): Date[] => {
	const dates = [];
	// convert back to date
	const f = new Date(from);
	const t = new Date(to);

	while (f <= t) {
		dates.push(new Date(f));
		f.setDate(f.getDate() + 1);
	}

	return dates;
};

export const dateDiff = (aDate: number | string | Date, bDate: number | string | Date, type = 'day'): number => {
	const a = new Date(aDate);
	const b = new Date(bDate);

	if (type === 'day') {
		const date1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const date2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

		return (date2 - date1) / (1000 * 60 * 60 * 24); // ms / day;
	}

	// month diff
	const yearDiff = b.getFullYear() - a.getFullYear();
	return b.getMonth() - a.getMonth() + yearDiff * 12;
};

export const offsetDate = (date: Date | string | number, offsetDay: number): Date => {
	const d = new Date(date);
	d.setDate(d.getDate() + Number(offsetDay));

	return d;
};

/**
 * Converts ISO time to relative time indication
 * @param {string} time ISO time format string, e.g. '2020-01-20 12:41:19'
 * @return {string} relative time indication, e.g. 'vor 2 Tagen'
 */
export const relativeTime = (time: string): string => {
	const timeSplit: any[] = time.split(/[- :]/);
	timeSplit[1]--;
	const fixedDate: any = new Date(Date.UTC(timeSplit[0], timeSplit[1]--, timeSplit[2], timeSplit[3], timeSplit[4], timeSplit[5]));
	try {
		fixedDate.toISOString();
	} catch (error) {
		return '';
	}
	const trustPilotDate: number = Date.parse(fixedDate) / 1000;
	let relativeDate: string,
		nowDate: any = new Date(),
		newDate: number;

	nowDate = Date.parse(nowDate) / 1000;
	newDate = nowDate - trustPilotDate;
	if (newDate <= 60 * 59) {
		newDate = Math.floor(newDate / 60);
		relativeDate = newDate <= 1 ? '1 Minute' : newDate + ' Minuten';
	} else if (newDate <= 60 * 60 * 23) {
		newDate = Math.floor(newDate / (60 * 60));
		relativeDate = newDate <= 1 ? '1 Stunde' : newDate + ' Stunden';
	} else {
		newDate = Math.floor(newDate / (60 * 60 * 24));
		relativeDate = newDate <= 1 ? '1 Tag' : newDate + ' Tagen';
	}
	return newDate ? 'vor ' + relativeDate : '';
};

/**
 * Converts the given date to Weekday DD MMM & if the domain is AT changes 'Jan' to 'Jän'.
 * @param {string} date
 * @param domain
 * @return {string} domain
 */
export const convertDateHelper = (date: string, domain: string): string => {
	const convertedDate = new Date(date);
	const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
	const months = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
	if (domain === 'AT') {
		months[0] = 'Jän';
	}
	return days[convertedDate.getDay()] + ' ' + convertedDate.getDate() + '. ' + months[convertedDate.getMonth()];
};
