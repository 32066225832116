import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "checkout-text-field" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "checkout-text-field__explanation"
}
const _hoisted_5 = { class: "checkout-text-field__container" }
const _hoisted_6 = ["id", "type", "name", "placeholder", "value", "disabled", "required", "enterkeyhint"]

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { firstLetterUppercase } from '@utils/utils';
import { computed, ref } from 'vue';
import { z } from 'zod';
import { CheckoutValidationState } from '@/components/common/store/checkout';

interface Props {
	label: string,
	fieldName: string,
	disabled?: boolean,
	fieldType?: string,
	labelText?: string,
	requiredField?: boolean,
	uniqueKey?: string | number
	placeholder?: string,
	errorMessage?: string,
	capitalizeFirstWord?: boolean,
	validFromParentValidation?: boolean,
	hideError?: boolean,
	enterkeyhint?: string,
	validationSchema?: any,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutTextField',
  props: /*@__PURE__*/_mergeModels({
    label: {},
    fieldName: {},
    disabled: { type: Boolean, default: false },
    fieldType: { default: 'text' },
    labelText: { default: '' },
    requiredField: { type: Boolean, default: false },
    uniqueKey: { default: '' },
    placeholder: { default: '' },
    errorMessage: { default: '' },
    capitalizeFirstWord: { type: Boolean, default: false },
    validFromParentValidation: { type: Boolean, default: undefined },
    hideError: { type: Boolean, default: false },
    enterkeyhint: { default: 'next' },
    validationSchema: { default: z.any() }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {

const props = __props;

const modelValue = _useModel<string>(__props, "modelValue");

const nativeInput = ref<HTMLInputElement | null>(null);

const computedFieldName = computed((): string => {
	if (props.uniqueKey) return `${props.fieldName}-${props.uniqueKey}`;
	return props.fieldName;
});

// Init field in store
CheckoutValidationState.value[computedFieldName.value] = {
	valid: false, validated: false, hasBackendError: false
};

const valid = computed((): boolean => {
	if (typeof props.validFromParentValidation !== 'undefined') {
		CheckoutValidationState.value[computedFieldName.value].valid = props.validFromParentValidation;
		CheckoutValidationState.value[computedFieldName.value].hasBackendError = false;
		return props.validFromParentValidation;
	}

	if (CheckoutValidationState.value[computedFieldName.value].hasBackendError) {
		return false;
	}
	return CheckoutValidationState.value[computedFieldName.value].valid;
});

const fieldInvalid = computed(() => !valid.value && validated.value && !props.disabled);
const actualLabel = computed(() => (fieldInvalid.value ? props.errorMessage : props.label));

const validated = computed((): boolean => CheckoutValidationState.value[computedFieldName.value].validated);

const inputValidator = (inputValue: string): void => {
	if (props.capitalizeFirstWord) {
		// eslint-disable-next-line no-param-reassign
		inputValue = firstLetterUppercase(inputValue);
	}

	// if the validation happens in the parent validation inside the component is disabled
	if (typeof props.validFromParentValidation === 'undefined') {
		const validationResult = props.validationSchema.safeParse(inputValue);
		CheckoutValidationState.value[computedFieldName.value].valid = validationResult.success;
		if (CheckoutValidationState.value[computedFieldName.value].hasBackendError) {
			// Reset backend error flag if the input is valid
			CheckoutValidationState.value[computedFieldName.value].hasBackendError = !validationResult.success;
		}
	}

	modelValue.value = inputValue;
};

const inputHandler = (event: Event): void => {
	const inputValue = (event.target as HTMLInputElement).value;
	inputValidator(inputValue);
};

const blurHandler = (event: Event): void => {
	CheckoutValidationState.value[computedFieldName.value].validated = true;
	inputHandler(event);
};

__expose({ inputValidator, nativeInput });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["checkout-text-field__label", { 'checkout-text-field__label--invalid': fieldInvalid.value }]),
      for: computedFieldName.value
    }, [
      _createTextVNode(_toDisplayString(actualLabel.value), 1 /* TEXT */),
      (_ctx.requiredField && !fieldInvalid.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
        : _createCommentVNode("v-if", true)
    ], 10 /* CLASS, PROPS */, _hoisted_2),
    (_ctx.labelText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.labelText), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("input", {
        id: computedFieldName.value,
        ref_key: "nativeInput",
        ref: nativeInput,
        class: _normalizeClass([{ 'error' : !valid.value && validated.value && !_ctx.disabled}, "checkout-text-field__container-input"]),
        type: _ctx.fieldType,
        name: computedFieldName.value,
        placeholder: _ctx.placeholder,
        value: modelValue.value,
        disabled: _ctx.disabled,
        required: _ctx.requiredField,
        enterkeyhint: _ctx.enterkeyhint,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (inputHandler($event))),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (inputHandler($event))),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (blurHandler($event)))
      }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_6),
      (_ctx.requiredField || _ctx.disabled)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.disabled)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (!valid.value && validated.value)
                    ? (_openBlock(), _createBlock(BaseIcon, {
                        key: 0,
                        name: "exclamationMarkFill",
                        class: "checkout-text-field__exclamation"
                      }))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}
}

})