import axios, { AxiosRequestConfig, CancelTokenSource, isAxiosError, isCancel } from 'axios';
import { objectToQuery } from './transform';
import { useStore } from '../store';

const store = useStore();

const calls: Record<string, CancelTokenSource> = {};

const MAX_RETRIES = 5;
const RETRY_DELAY = 2000; // 2 second

const retryRequest = async <T>(config: AxiosRequestConfig, requestType: string, retries: number, error: Error, logNetworkErrors: boolean): Promise<T> => {
	if (retries >= MAX_RETRIES) {
		throw error;
	}

	await new Promise((resolve) => { setTimeout(resolve, (RETRY_DELAY)); });
	// eslint-disable-next-line no-use-before-define
	return makeRequest(config, requestType, retries + 1, logNetworkErrors);
};

const makeRequest = async <T>(config: AxiosRequestConfig = {}, requestType = 'unnamed', retries = 1, logNetworkErrors = false): Promise<T> => {
	const method = config.method ?? 'post';
	const target = method === 'get' && config.data ? `${config.url}?${objectToQuery(config.data)}` : config.url;

	if (calls[requestType]) {
		calls[requestType].cancel();
	}
	calls[requestType] = axios.CancelToken.source();

	try {
		const response = await axios({
			method,
			url: target,
			baseURL: config.baseURL ?? store.state.config.apiBase,
			data: config.data,
			headers: {
				'Content-Type': 'application/json'
			},
			cancelToken: calls[requestType].token,
		});
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error) && error.message === 'Network Error') {
			if (logNetworkErrors) {
				console.error('Network Error in', requestType);
			}
			return retryRequest(config, requestType, retries, error, logNetworkErrors);
		}
		// rethrow error for error handling in components
		throw error;
	}
};

export const cancelRequestIncludesPattern = (cancelTokenPattern: string) => {
	Object.entries(calls).forEach((call) => {
		if (call[0].includes(cancelTokenPattern)) {
			call[1].cancel();
		}
	});
};

export const requestCancelable = async <T>(config: AxiosRequestConfig = {}, requestType = 'unnamed', retries = 1): Promise<T | null> => {
	try {
		return await makeRequest<T>(config, requestType, retries);
	} catch (e) {
		if ((isAxiosError(e) && !isCancel(e)) || (!isAxiosError(e) && e instanceof Error)) {
			throw e;
		}
	}
	return null;
};

export default makeRequest;
