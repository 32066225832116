import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import FilterHeader from '@lmt-rpb/FilterHeader/FilterHeader.vue';
import type { FontAwesomeIcon } from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';

type Props = {
	title?: string;
	withDivider?: boolean;
	icon?: FontAwesomeIcon;
	isChecked?: boolean;
	deleteButtonString?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterContainer',
  props: {
    title: { default: undefined },
    withDivider: { type: Boolean, default: true },
    icon: { default: undefined },
    isChecked: { type: Boolean, default: false },
    deleteButtonString: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const resetInput = (value: string): void => {
	store.dispatch('resetItem', value);

	if (props.deleteButtonString === 'directFlight') {
		store.dispatch('resetItem', 'flight');
	}
};

const handleDelete = (value: string): void => {
	resetInput(value);
	EventBus.$emit('SearchMask:MobileSubmit');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filter-container", { 'filter-container--with-divider': _ctx.withDivider }])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(FilterHeader, {
          key: 0,
          title: _ctx.title,
          icon: _ctx.icon,
          "delete-button-string": _ctx.deleteButtonString,
          "is-checked": _ctx.isChecked,
          onDelete: handleDelete
        }, null, 8 /* PROPS */, ["title", "icon", "delete-button-string", "is-checked"]))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}
}

})