<template>
	<div
		class="search-form"
		:style="[showBackground ? bgImages : { backgroundImage: 'none' }]"
		:class="{
			'show-background': showBackground,
		}"
	>
		<Loading
			v-if="redirecting"
			class="search-form__loading"
		/>
		<div class="search-form__search">
			<SearchBarCore
				ref="main"
				class="search-form__search-bar"
				:variant="searchBarVariant"
				:show-submit-button
				@search-submit="dynamicSubmit"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import Loading from '@lmt-rpb/Loading/Loading.vue';
import type { SearchFormDataType, BackgroundImages } from '@interfaces/search-form';
import { objectToQuery, formToParams } from '@services/transform';
import { EventBus } from '@global-js/event-bus';
import { isOfferlistPage, showOfferlist } from '@utils/utils';
import { getBasePath } from '@utils/environmentUtils';
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from '@/components/common/store';
import * as searchHistoryService from '@/components/common/services/localStorage/searchHistoryService';
import type { ClientType } from '@/interfaces/common';
import ModalManager from '@/js/modules/modalManager';
import SearchBarCore from '@lmt-rpb/Organisms/SearchBarCore/SearchBarCore.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	client?: ClientType;
	images?: BackgroundImages | undefined;
	searchBarVariant?: 'standalone' | 'inline';
	showSubmitButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	client: 'at',
	images: undefined,
	searchBarVariant: 'standalone',
	showSubmitButton: true,
});

const store = useStore();

const showBackground = ref(false);
const redirecting = ref(false);

const { isSmallMobile, isDesktop } = storeToRefs(useBreakpointStore());
const baseUrl = computed((): string => store.state.config.baseUrl);
const pageType = computed((): string => store.state.config.pageType);

const formData = computed((): SearchFormDataType => store.state.searchMask);

const locationType = computed((): string => store.state.config.locationType);

const targetUrl = computed((): string => {
	let url = store.state.config.searchUrl; // default url
	if (formData.value.destination && formData.value.destination.url) {
		url = `${baseUrl.value}${formData.value.destination.url}`;
	} else {
		url = `${baseUrl.value}/region/`;
	}

	return url;
});

const basePath = getBasePath(props.client) + '/img/search-form';

const getBackgroundStyleProp = (images: BackgroundImages, backgroundSettings: string): Record<string, string> => {
	const { mobile, tablet, desktop } = images;

	if (isDesktop.value) {
		return { background: `url('${desktop}') ${backgroundSettings}` };
	}
	if (isSmallMobile.value) {
		return { background: `url('${mobile}') ${backgroundSettings}` };
	}
	return { background: `url('${tablet}') ${backgroundSettings}` };
};

const handleFallbackImages = (backgroundSettings: string): Record<string, string> => {
	const images = {
		desktop: `${basePath.value}/main-image-desktop.jpg`,
		tablet: `${basePath.value}/main-image-tablet.jpg`,
		mobile: `${basePath.value}/main-image-mobile.jpg`,
	};

	return getBackgroundStyleProp(images, backgroundSettings);
};

const bgImages = computed((): Record<string, string> => {
	const backgroundPositionX = isSmallMobile.value ? '20%' : 'center';
	const backgroundSettings = `${backgroundPositionX} 70%/cover no-repeat`;

	if (!props.images) return handleFallbackImages(backgroundSettings);
	const { mobile, tablet, desktop } = props.images;

	// In case bg images are missing after edev cloning
	// TODO: remove after edevs are gone
	if (!desktop || !tablet || !mobile) return handleFallbackImages(backgroundSettings);

	return getBackgroundStyleProp(props.images, backgroundSettings);
});

const selectedDestinationChanged = computed(
	(): boolean => JSON.stringify(formData.value.destination) !== JSON.stringify(store.state.proxies?.initialDestination)
);

const onSubmit = (noLoadingSpinner: boolean = false): void => {
	searchHistoryService.update();
	const body = store.getters['searchMask/submitData'];
	const params = formToParams(body, 'search');

	if (locationType.value === 'COUNTRY' && params.rid) {
		delete params.rid;
	}
	if (locationType.value === 'TOPREGION' && params.cyid) {
		delete params.cyid;
	}

	const query = objectToQuery(params);

	const searchTerm = formData.value.searchTerm;
	const travelType = formData.value.onlyHotel ? 'hotel' : 'package';
	let url = '';

	url = `${targetUrl.value}?${query}` + (searchTerm ? `&term=${encodeURIComponent(searchTerm)}` : '');

	// When redirecting to our own hotel pages, add the ibe parameter
	if (url.indexOf('ibe=') === -1 && url.indexOf('/hotel/') !== -1) {
		url += url.indexOf('?') !== -1 ? '&' : '?';
		url += 'ibe=' + travelType;
	}

	if (!store.state.config.redirectOnSubmit) {
		console.log('redirect: ', url);
	} else if (noLoadingSpinner) {
		redirectTo(url);
	} else {
		document.body.style.opacity = '0.3';
		redirecting.value = true;
		redirectTo(url);
	}
};

function redirectTo(url: string) {
	window.location.href = url;
	// necessary so redirect works on chrome modal
	ModalManager.redirectTo = url;
}

const handlePopState = ({ state }: PopStateEvent): void => {
	EventBus.$emit('window:popstate', state);
};

const handleKeydown = ({ key }: KeyboardEvent): void => {
	if (!key) {
		return;
	}

	const keyName = key.toLowerCase();

	if (['enter', 'escape'].includes(keyName)) {
		EventBus.$emit(`keydown.${keyName}`);
	}
};

const handlePageRedirect = (): void => {
	redirecting.value = false;
	document.body.style.opacity = '1';
};

const dynamicSubmit = (): void => {
	if (isOfferlistPage() && !selectedDestinationChanged.value) {
		EventBus.$emit('SearchMask:MobileSubmit');

		if (pageType.value === 'hotelPage') {
			if (isDesktop.value) {
				showOfferlist('#offerlist', 50, 1000);
				setTimeout(() => {
					document.body.classList.add('page-header--hidden');
				}, 2000);
			} else {
				showOfferlist('.offers__list', 200, 1000);
				setTimeout(() => {
					document.body.classList.add('page-header--hidden');
				}, 200);
			}
		} else if (pageType.value === 'hotelList') {
			showOfferlist('.hotel-list', 150, 1000);
		} else if (pageType.value === 'regionList') {
			showOfferlist('.region-list', 150, 1000);
		}
	} else {
		onSubmit();
	}
};

onBeforeMount(() => {
	window.addEventListener('popstate', handlePopState);
	document.addEventListener('keydown', handleKeydown);

	// Prevents endless loading spinner if user clicks back fast from ibe on >= iOS 13
	window.addEventListener('pageshow', handlePageRedirect);

	EventBus.$on('search:submit', (buttonSearch = false) => {
		// redirectFired boolean to hide the second loader
		if (!store.state.searchMask.redirectFired || buttonSearch) {
			onSubmit(buttonSearch);
		}
		if (!buttonSearch) {
			// dont set this value when buttonSearch. When Browser back it wouldn't fire anymore.
			store.state.searchMask.redirectFired = true;
		}
	});
});

onMounted(() => {
	if (pageType.value === 'homePage') {
		showBackground.value = true;
	}
	store.dispatch('updateProxies', { initialDestination: formData.value.destination });
});

onBeforeUnmount((): void => {
	window.removeEventListener('popstate', handlePopState);
	document.removeEventListener('keydown', handleKeydown);
	window.removeEventListener('pageshow', handlePageRedirect);
	EventBus.$off('search:submit', onSubmit);
});

defineExpose({
	dynamicSubmit,
});
</script>

<style lang="scss" scoped>
.search-form {
	color: black;
	position: relative;
	scrollbar-color: $color-primary transparent;
	scrollbar-width: thin;
	-webkit-tap-highlight-color: transparent;

	&__search-bar {
		max-width: 45rem;
	}

	::-webkit-scrollbar {
		width: 1.2rem;
	}

	::-webkit-scrollbar-thumb {
		border-radius: $border-radius-small;
		background-color: $color-primary;
	}

	&__search {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;

		&-bar {
			margin: 0 auto;

			@include media-query-up($breakpoint-extralarge) {
				max-width: 1440px;
			}
		}
	}

	&__upper {
		width: 100%;
		display: flex;
		min-height: 13.4rem;
		justify-content: center;
		align-items: flex-end;
		margin: 1.6rem 0;

		@media (min-width: $breakpoint-verysmall) {
			margin-top: 3rem;
		}

		@media (min-width: $breakpoint-small) {
			margin-top: unset;
			margin-bottom: 2rem;
		}

		@media (min-width: $breakpoint-extralarge) {
			margin-bottom: 6rem;
		}
	}

	// is home page
	&.show-background {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		height: 55rem;
		padding: 2rem 1.6rem;

		@include media-query-up($breakpoint-extralarge) {
			justify-content: center;
			align-items: center;
			height: 32rem;
		}
	}

	.search-form__loading {
		position: absolute;
		top: 50%;
		z-index: 100;
		transform: translateY(-31%);
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
	}

	.search-form__theme {
		position: relative;
		min-height: 6.3rem;
	}
}

.slide-fade-enter-active {
	transition: opacity 0.5s;

	@include media-query-up($breakpoint-extralarge) {
		transition: opacity 0.3s;
		transition-delay: 0.2s;
	}
}

.slide-fade-enter-from {
	opacity: 0;
}
</style>
