import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed, toRef, useSlots } from 'vue';
import type { OfferStatusCheck } from '@/interfaces/offer';
import { useOfferStatus } from '../../composables/offerStatus';

type Props = {
	status?: OfferStatusCheck,
	barClasses?: string,
	absoluteBar?: boolean,
	interactionOnRequest?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutInfoBoxStatus',
  props: {
    status: {},
    barClasses: {},
    absoluteBar: { type: Boolean },
    interactionOnRequest: { type: Boolean, default: false }
  },
  emits: ['on-request-action'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const slots = useSlots();

const {
	isOfferAvailable,
	isOfferOnRequest,
	offerCanBeChecked
} = useOfferStatus(toRef(props, 'status'));

const getStatusDetails = computed(() => {
	let title = 'Angebot nicht auf Verfügbarkeit/Preis prüfbar';
	let icon;

	if (isOfferAvailable.value) {
		title = 'Angebot verfügbar';
		icon = 'check';
	} else if (isOfferOnRequest.value) {
		title = 'Angebot auf Anfrage buchbar';
		icon = props.interactionOnRequest ? 'infoFill' : undefined;
	}

	return { title, icon };
});

const onBarClick = () => {
	if (props.interactionOnRequest && isOfferOnRequest.value) {
		emit('on-request-action');
	}
};

const infoBoxClasses = computed(() => ({
	'info-box-status--check-unable': offerCanBeChecked.value,
	'info-box-status--wrapper': Boolean(slots?.default),
	'info-box-status--on-request': isOfferOnRequest.value
}));

const infoBarClasses = computed(() => ({
	[`${props.barClasses}`]: props.barClasses,
	'info-box-status__bar--absolute': props.absoluteBar,
	'info-box-status__bar--interactive': props.interactionOnRequest
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['info-box-status', infoBoxClasses.value])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['info-box-status__bar', infoBarClasses.value]),
      onClick: _withModifiers(onBarClick, ["stop"])
    }, [
      _createElementVNode("span", {
        class: "info-box-status__title",
        textContent: _toDisplayString(getStatusDetails.value.title)
      }, null, 8 /* PROPS */, _hoisted_1),
      (getStatusDetails.value.icon)
        ? (_openBlock(), _createBlock(BaseIcon, {
            key: 0,
            name: getStatusDetails.value.icon,
            class: "info-box-status__icon"
          }, null, 8 /* PROPS */, ["name"]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}
}

})