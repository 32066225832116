<template>
	<div class="travel-type-switch">
		<ChipBase
			v-model="offerType"
			:label="LABELS.PACKAGE"
			:icon-name="'package'"
			:as="'radio'"
			value="PACKAGE"
		>
			<template #icon>
				<PackageIcon />
			</template>
		</ChipBase>
		<ChipBase
			v-model="offerType"
			:label="LABELS.HOTEL_ONLY"
			:icon-name="'room2'"
			value="HOTEL_ONLY"
			:as="'radio'"
		>
			<template #icon>
				<FaIcon :icon="['fa-solid', 'fa-bed']" />
			</template>
		</ChipBase>
	</div>
</template>
<script setup lang="ts">
import ChipBase from '@lmt-rpb/Atoms/ChipBase/ChipBase.vue';
import { useStore } from '@components/common/store/index';
import { computed } from 'vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import PackageIcon from './internals/PackageIcon.vue';
import { useTranslations } from '@helper/composables/translations';

const store = useStore();
const { translate } = useTranslations();

const LABELS = computed(() => ({
	PACKAGE: translate('searchBarMain.travelType.package'),
	HOTEL_ONLY: translate('searchBarMain.travelType.hotelOnly'),
}));

const offerType = computed({
	get() {
		return store.state.searchMask.onlyHotel ? 'HOTEL_ONLY' : 'PACKAGE';
	},
	set(newValue) {
		const onlyHotel = newValue === 'HOTEL_ONLY';
		store.commit('searchMask/updateFormData', { onlyHotel });
	}
});

</script>
<style scoped lang="scss">
.travel-type-switch {
	display: flex;
	gap: 0.8rem;

	& > * {
		flex-shrink: 0;
	}
}
</style>
