import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, unref as _unref, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "no-offers-found" }
const _hoisted_2 = { class: "no-offers-found__inner-wrap" }

import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoOffersFound',
  emits: ['OpenFilters', 'OpenSearch'],
  setup(__props, { emit: __emit }) {

const { isDesktop } = storeToRefs(useBreakpointStore());

const emit = __emit;

function openSearch() {
	emit('OpenSearch');
}

function openFilters() {
	emit('OpenFilters');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "no-offers-found__empty" }, [
      _createElementVNode("span", { class: "no-offers-found__empty-title" }, "Ihre Suche ergab leider keine Ergebnisse.")
    ], -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "no-offers-found__empty-desc",
        onClick: _withModifiers(openSearch, ["prevent"])
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("p", null, "Bitte ändern Sie Ihre Angaben (z.B. den Reisetermin) oder schränken Sie Ihre Suche weniger ein.", -1 /* HOISTED */)
      ])),
      (!_unref(isDesktop))
        ? (_openBlock(), _createBlock(RoundedButton, _mergeProps({
            key: 0,
            theme: 'outlined',
            class: "no-offers-found__button"
          }, _ctx.$attrs, {
            onClick: _withModifiers(openFilters, ["prevent"])
          }), {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Filter öffnen ")
            ])),
            _: 1 /* STABLE */
          }, 16 /* FULL_PROPS */))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}
}

})