import type { PaymentAttributes, PaymentTypesKey } from '@/interfaces/checkout/checkoutTypes';

interface CountLabel {
	singular: string;
	plural: string;
}

const DEFAULT_DURATION_FILTER: ReadonlyArray<number> = [1, 14];
const TRAVEL_DURATION_EXACT: Readonly<string> = 'exact';
const ANY = 'Any';

const HOTEL_LABELS: Readonly<CountLabel> = {
	singular: 'Hotel',
	plural: 'Hotels',
};

export const MAX_FAVORITE_HOTELS_LIMIT = 15;
export const MAX_COOKIE_SIZE_LIMIT = 2000;

const OFFER_IS_AVAILABLE_STATUS = ['Available', 'ToConfirm'] as const;
const OFFER_CAN_BE_CHECKED_STATUS = ['Undefined', 'CheckUnable'] as const;
const OFFER_IS_ON_REQUEST_STATUS = 'OnRequest' as const;

const NBSP = '\u00A0';
const NDASH = '\u2013';
export const MOST_POPULAR_REGION_ID = 40047;

const FLEX_ACCEPTED_VALUES = ['1', '2'] as const;

const OFFER_INCLUDE_TYPES = {
	railandfly: 'Zug zum Flug (innerhalb Deutschlands)',
	transfer: 'Inkl. Transfer vor Ort',
	rentalcar: 'Mietwagen inkl.',
} as { [key: string]: string };

const PAYMENT_TYPES_CONSTANTS = {
	CREDIT_CARD: 'Kreditkarte',
	SEPA: 'DirectDebitInternational',
};

export const NO_OFFER_TITLE = 'Für diese Suche gibt es keine passenden Angebote';
export const NO_OFFER_MESSAGE_PACKAGE =
	'Passen Sie die Suche an, z.B. den Termin, oder suchen Sie ohne Flug. Keine Sorge, es gibt noch viele weitere Angebote!';
export const NO_OFFER_MESSAGE_NO_PACKAGE =
	'Passen Sie die Suche an, z.B. den Termin, oder suchen Sie mit Flug. Keine Sorge, es gibt noch viele weitere Angebote!';
export const BUTTON_TEXT_ALL_OFFERS = 'Alle Angebote';
export const BUTTON_TEXT_CHANGE_SEARCH = 'Sucheinstellungen ändern';
export const LINK_TEXT_WITHOUT_FLIGHT = 'Angebote ohne Flug anzeigen';
export const LINK_TEXT_WITH_FLIGHT = 'Angebote mit Flug anzeigen';
export const TOTAL_PRICE_TEXT = 'Gesamtpreis ab';

const PAYMENT_TYPES = {
	AX: { label: 'American Express', img: 'AX', type: 'CreditCard' },
	VI: { label: 'Visa', img: 'VI', type: 'CreditCard' },
	MC: { label: 'Mastercard', img: 'MC', type: 'CreditCard' },
	BankTransfer: { label: 'Banküberweisung', img: 'BankTransfer' },
	DirectDebitInternational: { label: 'SEPA-Lastschrift', img: 'sepa' },
	PayPal: { label: 'PayPal', img: 'paypal' },
} as const satisfies Record<PaymentTypesKey, PaymentAttributes>;

const timeZoneOffsetInHours = 11;
const timeZoneOffsetInMs = timeZoneOffsetInHours * 60 * 60 * 1000;

const CHECKOUT_OTHER_CUSTOMER_ID = 99;

export {
	CountLabel,
	DEFAULT_DURATION_FILTER,
	ANY,
	HOTEL_LABELS,
	NBSP,
	NDASH,
	OFFER_IS_AVAILABLE_STATUS,
	OFFER_IS_ON_REQUEST_STATUS,
	OFFER_CAN_BE_CHECKED_STATUS,
	OFFER_INCLUDE_TYPES,
	PAYMENT_TYPES,
	timeZoneOffsetInHours,
	timeZoneOffsetInMs,
	CHECKOUT_OTHER_CUSTOMER_ID,
	FLEX_ACCEPTED_VALUES,
	TRAVEL_DURATION_EXACT,
	PAYMENT_TYPES_CONSTANTS,
};

export const pageLocations = ['unverifiedOffer', 'verifiedOffer', 'checkoutInfoBox', 'checkoutSummary', 'thankyouPage'] as const;

export type PageLocation = (typeof pageLocations)[number];

export const CLIENT_DOMAIN_CODES = {
	at: 'at',
	com: 'com',
	lmat: 'lmat',
	ch: 'ch',
	kuat: 'kuat',
	tbde: 'tbde',
} as const;

export type WebClient = keyof typeof CLIENT_DOMAIN_CODES;

export const WEB_CLIENTS = {
	at: 'restplatzboerse.at',
	com: 'restplatzboerse.com',
	lmat: 'lastminute.at',
	ch: 'restplatzboerse.ch',
	kuat: 'kuoni.at',
	tbde: 'touristikboerse.de',
} as const satisfies Record<WebClient, string>;

export const WEB_CLIENTS_NAMES = {
	at: 'Restplatzbörse',
	ch: 'Restplatzbörse',
	com: 'Restplatzbörse',
	lmat: 'Lastminute',
	kuat: 'Kuoni',
	tbde: 'Touristikbörse',
} as const satisfies Record<WebClient, string>;

export type WebClientName = (typeof WEB_CLIENTS_NAMES)[WebClient];

export type WebClientHost = (typeof WEB_CLIENTS)[WebClient];

export const CLIENT_SHORT_CODES = {
	at: 'rpb_at',
	com: 'rpb_com',
	lmat: 'lm_at',
	ch: 'rpb_ch',
	kuat: 'kuo_at',
	tbde: 'tb_de',
} as const satisfies Record<WebClient, string>;

export type ClientCodes = (typeof CLIENT_SHORT_CODES)[WebClient];

export const CURRENCIES = {
	EUR: 'EUR',
	CHF: 'CHF',
} as const;

export type ClientCurrency = keyof typeof CURRENCIES;

export const GERMAN_CLIENTS: Array<WebClient> = ['com', 'tbde'] as const;
export const SWISS_CLIENTS: Array<WebClient> = ['ch'] as const;
export const AUSTRIAN_CLIENTS: Array<WebClient> = ['at', 'lmat', 'kuat'] as const;

export const CLIENT_COUNTRIES = {
	GERMANY: 'Germany',
	AUSTRIA: 'Austria',
	SWITZERLAND: 'Switzerland',
} as const;

export const CLIENT_COUNTRIES_IN_GERMAN = {
	GERMANY: 'Deutschland',
	AUSTRIA: 'Österreich',
	SWITZERLAND: 'Schweiz',
	SLOVAKIA: 'Slowkei',
	SLOVENIA: 'Slowenien',
	HUNGARY: 'Ungarn',
	NETHERLANDS: 'Niederlande',
	BELGIUM: 'Belgien',
	LUXEMBOURG: 'Luxemburg',
	FRANCE: 'Frankreich',
	ITALY: 'Italien',
	EUROPE: 'Europa',
} as const;

export type GERMAN_COUNTRY_NAMES =
	| 'Deutschland'
	| 'Österreich'
	| 'Schweiz'
	| 'Slowakei'
	| 'Slowenien'
	| 'Ungarn'
	| 'Niederlande'
	| 'Belgien'
	| 'Luxemburg'
	| 'Frankreich'
	| 'Italien'
	| 'Europa';

export type ClientCountry = (typeof CLIENT_COUNTRIES)[keyof typeof CLIENT_COUNTRIES];

export const AUSTRIAN_AIRPORTS = 'VIE,SZG,GRZ,LNZ,INN,KLU,MUC,FRA,FDH,FMM,NUE,STR,ZRH,BSL,ACH,BTS,LJU,BUD';
export const AUSTRIAN_DEFAULT_AIRPORTS = 'GRZ,INN,LNZ,SZG,VIE';

export const SWISS_AIRPORTS = 'ZRH,BSL,BRN,GVA,LUG,ACH,MUC,FRA,FDH,FMM,FKB,STR,INN';
export const SWISS_DEFAULT_AIRPORTS = 'ACH,BRN,BSL,GVA,ZRH';

export const GERMAN_AIRPORTS =
	'BER,BRE,DTM,DRS,DUS,ERF,FRA,HHN,FDH,HAM,HAJ,FKB,KSF,CGN,LEJ,LBC,FMM,MUC,FMO,NRN,NUE,PAD,RLG,SCN,STR,AMS,BSL,BRN,BRU,GVA,GRZ,INN,LNZ,LUX,SZG,SXB,VIE,ZRH';
export const GERMAN_DEFAULT_AIRPORTS = 'BER,BRE,CGN,DRS,DUS,ERF,FDH,FKB,FMM,FMO,FRA,HAJ,HAM,LEJ,MUC,NUE,PAD,STR';

export const AUSTRIAN_AIRPORTS_FULL = ['GRZ', 'INN', 'KLU', 'LNZ', 'SZG', 'VIE'];
export const GERMAN_AIRPORTS_FULL = [
	'BRE', // Bremen
	'BER', // Berlin
	'CGN', // Cologne/Bonn
	'DRS', // Dresden
	'DTM', // Dortmund
	'DUS', // Düsseldorf
	'ERF', // Erfurt
	'FDH', // Friedrichshafen
	'FKB', // Karlsruhe/Baden-Baden
	'FMM', // Memmingen
	'FMO', // Münster/Osnabrück
	'FRA', // Frankfurt
	'HAM', // Hamburg
	'HAJ', // Hanover
	'HHN', // Frankfurt-Hahn
	'KSF', // Kassel
	'LBC', // Lübeck
	'LEJ', // Leipzig/Halle
	'MUC', // Munich
	'NRN', // Weeze
	'NUE', // Nuremberg
	'PAD', // Paderborn
	'RLG', // Rostock
	'SCN', // Saarbrücken
	'STR', // Stuttgart
];
export const SWISS_AIRPORTS_FULL = ['BRN', 'BSL', 'GVA', 'ZRH'];

export const EUROPE_AIRPORTS_FULL = [
	'AMS', // Amsterdam
	'BLL', // Billund
	'BRQ', // Brno
	'BRU', // Brussels
	'BTS', // Bratislava
	'BUD', // Budapest
	'CRL', // Charleroi
	'EIN', // Eindhoven
	'GRQ', // Groningen
	'KRK', // Krakow
	'LJU', // Ljubljana
	'LUX', // Luxembourg
	'MST', // Maastricht
	'MXP', // Milan
	'PRG', // Prague
	'SXB', // Strasbourg
	'WAW', // Warsaw
];

export const RATING_MAX_VALUE = 6;
// https://beholdr.github.io/maska/v3/#/tokens?id=default-tokens
export const MASKA_IBAN_MASK = '@@## **** **** **** **** **** **** ****';

export const OFFER_BOX_TELEPORT = {
	HOTEL_INFOS: 'hotel-infos__teleport-offer-box',
	HOTEL_DESCRIPTION: 'hotel-page-main-content__teleport-description-offer-box',
} as const;

export const MINIMUM_HOTEL_RATING = 4.2;

export const NUMBER_OF_OFFER_RESULTS = 10;
