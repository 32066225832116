import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "direct-flight-filter" }
const _hoisted_2 = { class: "direct-flight-filter__input-container" }
const _hoisted_3 = { class: "direct-flight-filter__tooltip" }

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import { EventBus } from '@global-js/event-bus';
import { computed, onBeforeMount, onMounted, watch } from 'vue';
import { useStore } from '@/components/common/store';

interface Props {
	title?: string;
	disabled?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DirectFlightFilter',
  props: {
    title: { default: '' },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:isChecked'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const store = useStore();
const emit = __emit;

const isChecked = computed(() => {
	const defaultTimes = { from: 0, to: 24 };
	const isCustomTime = (time: { from: number; to: number }) => time.from !== defaultTimes.from || time.to !== defaultTimes.to;

	return directFlight.value || isCustomTime(departureTime.value) || isCustomTime(arrivalTime.value);
});

const directFlight = computed({
	get() {
		if (store.state.searchMask) {
			return store.state.searchMask.directFlight;
		}
		return false;
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', { directFlight: value });
	},
});

const departureTime = computed(() => ({
	from: store.state.searchMask.minDepartureTime ?? 0,
	to: store.state.searchMask.maxDepartureTime ?? 24,
}));

const arrivalTime = computed(() => ({
	from: store.state.searchMask.minArrivalTime ?? 0,
	to: store.state.searchMask.maxArrivalTime ?? 24,
}));

const emitChecked = () => {
	emit('update:isChecked', isChecked.value);
};

watch(isChecked, () => {
	emitChecked();
});

const resetInput = (): void => {
	store.dispatch('resetItem', 'directFlight');
	store.dispatch('resetItem', 'flight');
};

const maybeResetInput = (isHotelOnly: boolean): void => {
	if (isHotelOnly) resetInput();
};

onMounted(() => {
	if (props.disabled) resetInput();
	if (isChecked.value) emitChecked();
	EventBus.$on('HotelOnly:Change', maybeResetInput);
});

onBeforeMount(() => {
	EventBus.$off('HotelOnly:Change', maybeResetInput);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Checkbox, {
        modelValue: directFlight.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((directFlight).value = $event)),
        disabled: _ctx.disabled,
        class: "direct-flight-filter__checkbox",
        label: "Nur Direktflüge anzeigen",
        onChange: emitChecked
      }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(InfoTooltip, {
          placement: "top",
          "modifier-class": "info-tooltip__body"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Es werden nur Angebote angezeigt, die mit hoher Wahrscheinlichkeit eine direkte Flugverbindung ohne Umsteigen beinhalten. ")
          ])),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}
}

})