<template>
	<div
		v-if="headerProps.link"
		class="component-header"
		:class="{ 'component-header--no-subtitle': !subtitle }"
	>
		<div
			v-if="title"
			class="component-header__title-row"
		>
			<h2 class="component-header__title">
				<a
					class="component-header__title-link"
					:href="headerProps.link"
					:title="headerProps.titleAttribute"
				>{{ title }}</a
				>
			</h2>
			<a
				:href="headerProps.link"
				class="component-header__more"
				:title="headerProps.titleAttribute"
			>{{ headerProps.moreText }}</a
			>
		</div>
		<h3
			v-if="subtitle && title"
			class="component-header__subtitle"
		>
			{{ subtitle }}
		</h3>
	</div>
	<div
		v-else-if="!headerProps.link && title"
		class="component-header"
		:class="{ 'component-header--no-subtitle': !subtitle }"
	>
		<div
			v-if="title"
			class="component-header__title-row"
		>
			<h2 class="component-header__title">
				{{ title }}
			</h2>
		</div>
		<h3
			v-if="subtitle && title"
			class="component-header__subtitle"
		>
			{{ subtitle }}
		</h3>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { HeaderProps } from './componentHeaderTypes';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	headerProps: HeaderProps;
}
const props = defineProps<Props>();

const { isDesktop } = storeToRefs(useBreakpointStore());

const getResponsiveOrDesktop = (desktop: string | undefined, mobile: string | undefined) => (!isDesktop.value && mobile ? mobile : desktop);

const title = computed<string | undefined>(() => getResponsiveOrDesktop(props.headerProps.title, props.headerProps.mobileTitle));

const subtitle = computed<string | undefined>(() => getResponsiveOrDesktop(props.headerProps.subtitle, props.headerProps.mobileSubtitle));
</script>

<style lang="scss" scoped>
.component-header {
	margin: 0 auto $component-header-bottom-margin-mobile;
	padding-left: $padding-normal;
	padding-right: $padding-normal;

	&--no-subtitle {
		margin-bottom: $component-header-bottom-margin-mobile-no-subtitle;
	}
}

.component-header__title-row {
	display: flex;
	justify-content: space-between;
	align-items: end;
}

.component-header__title {
	margin: 0;
	padding: 0;
	color: $color-black;
	text-align: left;
	font-size: $font-large-4;
	font-weight: $font-weight-semibold;

	@include lineHeightCrop(1.4);
}

.component-header__title-link {
	text-decoration: none;
}

.component-header__more {
	font-size: $font-small-1;
	color: $color-link;
	float: right;
	text-decoration: none;
	line-height: 1.7;
}

.component-header__subtitle {
	margin: 0;
	padding: 0;
	font-size: $font-small-1;
	color: $color-text-light;
	text-align: left;
	font-weight: normal;
}

@include media-query-up($breakpoint-small) {
	.component-header {
		margin: 0 auto $component-header-bottom-margin-desktop;

		&--no-subtitle {
			margin-bottom: $component-header-bottom-margin-desktop-no-subtitle;
		}

		&__title {
			font-size: $font-large-1;
		}

		&__more {
			line-height: 2.3;
		}
	}
}

@include media-query-up($breakpoint-scroller + 1px) {
	.component-header {
		margin: 0 auto $component-header-bottom-margin-desktop;

		&--no-subtitle {
			margin-bottom: $component-header-bottom-margin-desktop-no-subtitle;
		}
	}
}

@include media-query-up($breakpoint-extralarge) {
	.component-header {
		margin: 0 auto $component-header-bottom-margin-desktop;

		&--no-subtitle {
			margin-bottom: $component-header-bottom-margin-desktop-no-subtitle;
		}
	}
}

@include media-query-up($breakpoint-verylarge) {
	.component-header {
		padding-left: 0;
		padding-right: 0;
	}
}
</style>
