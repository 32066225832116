<template>
	<div class="destination-field">
		<Autocomplete
			v-model="destination"
			:url="suggestUrl"
			:error-duration="maxTravelDurationError"
			icon="search"
			label="Reiseziel, Hotel, Ort, ..."
		/>
	</div>
</template>

<script lang="ts" setup>
import Autocomplete from '@lmt-rpb/Autocomplete/Autocomplete.vue';
import type { SuggestionDataType } from '@interfaces/search-form';
import { cloneDeep } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';

const store = useStore();

const destination = computed({
	get() {
		return store.state.searchMask.destination;
	},
	set(value: SuggestionDataType | null) {
		store.commit('searchMask/updateFormData', {
			destination: value,
			filter: '',
		});
	}
});

const destinationValue = ref<SuggestionDataType | null>(destination.value && cloneDeep(destination.value));

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const suggestUrl = computed((): string => {
	const { fluxApi, suggestions } = store.state.config;

	return `${fluxApi}${suggestions}`;
});


watch(destination, () => {
	destinationValue.value = destination.value && cloneDeep(destination.value);
});

defineExpose({
	destinationValue,
});

</script>

<style lang="scss" scoped>
.destination-field {
	$loader-size: 3rem;

	.modal__content .form-field {
		height: 4.5rem;

		.autocomplete__icon {
			flex-shrink: 2;
		}
	}

	.destination-field__autocomplete {
		&.autocomplete--is-open > .autocomplete__list {
			max-height: 35rem;
		}
	}

	:deep(.form-field__icon) {
		flex-shrink: 0;
		width: 2.5rem;
		height: 2.5rem;
		fill: $color-primary;
	}

	.destination-field__modal {
		@include media-query-up($breakpoint-small) {
			padding-right: 0;
			padding-left: 0;
		}

		:deep(.modal__body) {
			padding: 1rem;
		}

		:deep(.autocomplete__dropdown) {
			@include max-width(65rem);

			.dropdown__field::after {
				display: none;
			}

			.dropdown__box {
				@include media-query-up($breakpoint-large) {
					.destination-field__modal .dropdown__box {
						left: 50%;
						width: 100%;
					}
				}

				& {
					margin-top: -0.1rem;
					margin-bottom: 2rem;
				}
			}

			.autocomplete__item-header {
				margin-top: 1rem;
				margin-bottom: 0.5rem;
			}

			.autocomplete__item {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}

			.autocomplete__item-list {
				padding: 0;
			}

			.form-field__input {
				font-size: $font-large-4;
			}

			.form-field__input:disabled {
				background-color: initial;
			}
		}
	}

	:deep(.dropdown__box) {
		@media screen and (min-width: $breakpoint-extralarge) {
			width: 120%;
			border-top-right-radius: $border-radius-small;
		}
	}

	:deep(.dropdown__inner) {
		@media screen and (min-width: $breakpoint-extralarge) {
			border-top-right-radius: $border-radius-small;
		}
	}
}

</style>
