<template>
	<div class="direct-flight-filter">
		<div class="direct-flight-filter__input-container">
			<Checkbox
				v-model="directFlight"
				:disabled="disabled"
				class="direct-flight-filter__checkbox"
				label="Nur Direktflüge anzeigen"
				@change="emitChecked"
			/>
			<span class="direct-flight-filter__tooltip">
				<InfoTooltip
					placement="top"
					modifier-class="info-tooltip__body"
				>
					Es werden nur Angebote angezeigt, die mit hoher Wahrscheinlichkeit eine direkte Flugverbindung ohne Umsteigen
					beinhalten.
				</InfoTooltip>
			</span>
		</div>
	</div>
</template>

<script lang="ts" setup>
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import { EventBus } from '@global-js/event-bus';
import { computed, onBeforeMount, onMounted, watch } from 'vue';
import { useStore } from '@/components/common/store';

interface Props {
	title?: string;
	disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	title: '',
	disabled: false,
});

const store = useStore();
const emit = defineEmits(['update:isChecked']);

const isChecked = computed(() => {
	const defaultTimes = { from: 0, to: 24 };
	const isCustomTime = (time: { from: number; to: number }) => time.from !== defaultTimes.from || time.to !== defaultTimes.to;

	return directFlight.value || isCustomTime(departureTime.value) || isCustomTime(arrivalTime.value);
});

const directFlight = computed({
	get() {
		if (store.state.searchMask) {
			return store.state.searchMask.directFlight;
		}
		return false;
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', { directFlight: value });
	},
});

const departureTime = computed(() => ({
	from: store.state.searchMask.minDepartureTime ?? 0,
	to: store.state.searchMask.maxDepartureTime ?? 24,
}));

const arrivalTime = computed(() => ({
	from: store.state.searchMask.minArrivalTime ?? 0,
	to: store.state.searchMask.maxArrivalTime ?? 24,
}));

const emitChecked = () => {
	emit('update:isChecked', isChecked.value);
};

watch(isChecked, () => {
	emitChecked();
});

const resetInput = (): void => {
	store.dispatch('resetItem', 'directFlight');
	store.dispatch('resetItem', 'flight');
};

const maybeResetInput = (isHotelOnly: boolean): void => {
	if (isHotelOnly) resetInput();
};

onMounted(() => {
	if (props.disabled) resetInput();
	if (isChecked.value) emitChecked();
	EventBus.$on('HotelOnly:Change', maybeResetInput);
});

onBeforeMount(() => {
	EventBus.$off('HotelOnly:Change', maybeResetInput);
});
</script>

<style lang="scss" scoped>
.direct-flight-filter {
	&__header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		column-gap: 0.8rem;
		margin-bottom: 1.6rem;
		padding: 0.8rem 2.4rem;
	}

	&__input-container {
		padding: 2.4rem 0 0;
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 1.6rem;
		margin-left: 0.8rem;

		:deep(.checkbox .checkbox__label) {
			font-weight: $font-weight-regular;
			font-size: $font-small-1;
		}
	}

	&__title {
		color: $color-filter-type-header-text;
		font-size: $font-medium-2;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
	}

	&__title-icon {
		width: 3rem;
		height: 3rem;
		fill: $color-primary;
	}

	&__checkbox {
		box-shadow: none;
	}

	&__tooltip {
		margin-left: 1rem;
		padding-top: 0.4rem;

		:deep(.info-tooltip__body) {
			font-size: $font-small-1;
			left: -2rem !important;
			right: 35% !important;
			max-width: 31rem !important;
		}

		:deep(.info-tooltip__arrow) {
			left: 2rem !important;
		}

		:deep(.info-tooltip__icon) {
			overflow: visible;
			opacity: 1;
			width: 1.6rem;
			height: 1.6rem;
			fill: $color-primary;
			cursor: pointer;
		}

		:deep(.info-tooltip__icon-container) {
			height: fit-content;
			width: fit-content;
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.direct-flight-filter {
		&__header {
			background-color: initial;
			box-shadow: none;
			padding: 0;
		}

		&__input-container {
			padding: 0.8rem 0;
		}

		&__info-bubble {
			left: 0;
		}
	}
}
</style>
