import type { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import { getFluxApiUrl } from '@utils/utils';
import axios from 'axios';

export const get = async (hotels: number[]): Promise<FavoritedHotel[]> => {
	const url = '/v1/hotel-list';
	const baseURL = getFluxApiUrl();
	const response = await axios({
		method: 'POST',
		url: baseURL + url,
		headers: {
			'content-type': 'application/json',
		},
		data: hotels,
	});
	return response.data;
};
