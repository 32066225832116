import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "destination-field" }

import Autocomplete from '@lmt-rpb/Autocomplete/Autocomplete.vue';
import type { SuggestionDataType } from '@interfaces/search-form';
import { cloneDeep } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'DestinationField',
  setup(__props, { expose: __expose }) {

const store = useStore();

const destination = computed({
	get() {
		return store.state.searchMask.destination;
	},
	set(value: SuggestionDataType | null) {
		store.commit('searchMask/updateFormData', {
			destination: value,
			filter: '',
		});
	}
});

const destinationValue = ref<SuggestionDataType | null>(destination.value && cloneDeep(destination.value));

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const suggestUrl = computed((): string => {
	const { fluxApi, suggestions } = store.state.config;

	return `${fluxApi}${suggestions}`;
});


watch(destination, () => {
	destinationValue.value = destination.value && cloneDeep(destination.value);
});

__expose({
	destinationValue,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Autocomplete, {
      modelValue: destination.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((destination).value = $event)),
      url: suggestUrl.value,
      "error-duration": maxTravelDurationError.value,
      icon: "search",
      label: "Reiseziel, Hotel, Ort, ..."
    }, null, 8 /* PROPS */, ["modelValue", "url", "error-duration"])
  ]))
}
}

})