import type { MutableStrings, FlattenedObject } from './typeUtils';

export function flatMap<T = unknown>(list: { [x: string]: unknown }[]): T {
	return list.reduce((prev, curr) => ({ ...prev, ...curr }), {}) as T;
}

export function hasValue(o: string | number | boolean | undefined | unknown): boolean {
	if (typeof o === 'string') {
		return o.trim().length > 0;
	}
	if (typeof o === 'number') {
		return !Number.isNaN(o);
	}
	if (typeof o === 'object' && o !== null) {
		return JSON.stringify(o) !== '{}';
	}
	if (typeof o === 'boolean') {
		return true;
	}
	return false;
}

export function mergeMaps<K = string, V = unknown>(...maps: Array<Map<K, V>>): Map<K, V> {
	return new Map(maps.flatMap((m) => Array.from(m.entries())));
}

export function filterObjectArray<T>(item: T, fieldsToFilterBy: Array<keyof T>, filterExpression: string): boolean {
	return fieldsToFilterBy.some((field) => item[field]?.toString().toLowerCase().includes(filterExpression.toLowerCase()));
}

export function filterDuplicates<T>(array: T[]): T[] {
	//  Set ist nur unique für primitive Datentypen und die Reihenfolge NICHT deterministisch
	return Array.from(new Set(array));
}

/**
 * Returns an array of keys/props that have a falsy value [false, 0, '', null, undefined, NaN]
 * @param object
 * @returns Array of keys/props that have a falsy value [false, 0, '', null, undefined, NaN]
 */
export function getFalsyKeys<T extends Record<string, T[keyof T]>>(object: T): Array<keyof T> {
	return (
		Object.entries(object)
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			.filter(([_, value]) => !value)
			.map(([key]) => key)
	);
}

export function deepOverride<T extends Record<string, T[keyof T]>, Q = MutableStrings<T>>(base: T, overrides: Partial<Q>): T {
	if (typeof base !== 'object' || base === null) return base;
	if (typeof overrides !== 'object' || overrides === null) return base;

	const result = { ...base } as T;
	for (const key in overrides) {
		const override = overrides[key];
		const baseValue = base[key];

		if (
			override &&
			typeof override === 'object' &&
			!Array.isArray(override) &&
			baseValue &&
			typeof baseValue === 'object' &&
			!Array.isArray(baseValue)
		) {
			result[key] = deepOverride(baseValue as T, override as Partial<Q>) as T[Extract<keyof Q, string>];
		} else if (override !== undefined) {
			result[key] = override as T[Extract<keyof Q, string>];
		}
	}

	return result as T;
}

function flattenHelper<Obj extends Record<keyof Obj, Obj[keyof Obj]>>(obj: Obj, prefix = '', notation = '.'): Array<[string, unknown]> {
	return Object.entries(obj).flatMap(([key, value]) => {
		const newKey = prefix ? `${prefix}${notation}${key}` : key;
		if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
			return flattenHelper(value, newKey);
		}
		return [[newKey, value]];
	});
}

/**
 *
 * @param obj An object to flatten
 * @param prefix Prefix to add to the keys
 * @param notation Delimiter to use for the keys
 * @returns
 */
export const flattenObjectInNotation = <Obj extends Record<keyof Obj, Obj[keyof Obj]>>(obj: Obj): FlattenedObject<Obj> => {
	const entries = flattenHelper(obj);
	return Object.fromEntries(entries) as FlattenedObject<Obj>;
};
