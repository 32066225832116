import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

type Props = {
	buttonText?: string;
	isChecked?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteButtonFilter',
  props: {
    buttonText: { default: 'Löschen' },
    isChecked: { type: Boolean }
  },
  emits: ['delete'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const handleClick = () => {
	emit('delete');
};

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("button", {
    class: "delete-button",
    type: "button",
    onClick: handleClick
  }, _toDisplayString(_ctx.buttonText), 513 /* TEXT, NEED_PATCH */)), [
    [_vShow, _ctx.isChecked]
  ])
}
}

})