import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "component-header__title-row"
}
const _hoisted_2 = { class: "component-header__title" }
const _hoisted_3 = ["href", "title"]
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = {
  key: 1,
  class: "component-header__subtitle"
}
const _hoisted_6 = {
  key: 0,
  class: "component-header__title-row"
}
const _hoisted_7 = { class: "component-header__title" }
const _hoisted_8 = {
  key: 1,
  class: "component-header__subtitle"
}

import { computed } from 'vue';
import type { HeaderProps } from './componentHeaderTypes';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	headerProps: HeaderProps;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ComponentHeader',
  props: {
    headerProps: {}
  },
  setup(__props: any) {

const props = __props;

const { isDesktop } = storeToRefs(useBreakpointStore());

const getResponsiveOrDesktop = (desktop: string | undefined, mobile: string | undefined) => (!isDesktop.value && mobile ? mobile : desktop);

const title = computed<string | undefined>(() => getResponsiveOrDesktop(props.headerProps.title, props.headerProps.mobileTitle));

const subtitle = computed<string | undefined>(() => getResponsiveOrDesktop(props.headerProps.subtitle, props.headerProps.mobileSubtitle));

return (_ctx: any,_cache: any) => {
  return (_ctx.headerProps.link)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["component-header", { 'component-header--no-subtitle': !subtitle.value }])
      }, [
        (title.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("h2", _hoisted_2, [
                _createElementVNode("a", {
                  class: "component-header__title-link",
                  href: _ctx.headerProps.link,
                  title: _ctx.headerProps.titleAttribute
                }, _toDisplayString(title.value), 9 /* TEXT, PROPS */, _hoisted_3)
              ]),
              _createElementVNode("a", {
                href: _ctx.headerProps.link,
                class: "component-header__more",
                title: _ctx.headerProps.titleAttribute
              }, _toDisplayString(_ctx.headerProps.moreText), 9 /* TEXT, PROPS */, _hoisted_4)
            ]))
          : _createCommentVNode("v-if", true),
        (subtitle.value && title.value)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(subtitle.value), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */))
    : (!_ctx.headerProps.link && title.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["component-header", { 'component-header--no-subtitle': !subtitle.value }])
        }, [
          (title.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("h2", _hoisted_7, _toDisplayString(title.value), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          (subtitle.value && title.value)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_8, _toDisplayString(subtitle.value), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
}
}

})