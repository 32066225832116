<template>
	<div
		v-if="feature"
		class="flex-feature"
		:class="{ 'flex-feature-offerlist': !hasHotelListStyle }"
		@mouseover="showTooltip = true"
		@mouseleave="showTooltip = false"
	>
		<div class="flex-feature__shield-container">
			<FaIcon :icon="['fa-kit', 'fa-shield']" />
		</div>
		<div class="flex-feature__title">
			<span>{{ feature.title }}</span>
			<InfoTooltip
				class="flex-feature__tooltip"
				:show-tooltip-parent="showTooltip"
			>
				{{ feature.description }}
			</InfoTooltip>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { SingleFeature } from '@/interfaces/common';
import InfoTooltip from '../InfoTooltip/InfoTooltip.vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';

interface Props {
	feature: SingleFeature;
	hasHotelListStyle?: boolean;
	showShield?: boolean;
}

withDefaults(defineProps<Props>(), {
	hasHotelListStyle: false,
	showShield: true,
});

const showTooltip = ref(false);
</script>

<style lang="scss" scoped>
.flex-feature {
	display: flex;
	color: $color-green-text;
	font-weight: $font-weight-semibold;
	margin-top: 0.6rem;

	&__description {
		list-style: none;
		margin: 0;
		padding: 4px 8px;
	}

	&__tooltip {
		margin-left: 0.5rem;
		display: inline-flex;
	}

	&__shield-container {
		fill: $color-green-text;
		width: 2rem;
		height: 1.8rem;
		margin-right: 0.9rem;

		i {
			flex-shrink: 0;
			fill: $color-green-text;
			width: 2rem;
			height: 1.8rem;
			text-align: center;
		}
	}
}
</style>
