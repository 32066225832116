import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "offer-duration__time-container" }
const _hoisted_2 = { class: "offer-duration__time-box" }
const _hoisted_3 = { class: "offer-duration__time-zone" }
const _hoisted_4 = { class: "offer-duration__time-box" }
const _hoisted_5 = { class: "offer-duration__time-zone" }
const _hoisted_6 = {
  key: 0,
  class: "offer-duration__warning"
}

import Calendar from '@lmt-rpb/Calendar/Calendar.vue';
import { dateDiff, formatDate, offsetDate } from '@utils/dateUtils';
import { computed, ref, watch } from 'vue';
import { EventBus } from '@global-js/event-bus';
import type { SelectionType } from '@/components/common/types';
import { useStore } from '@/components/common/store';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

const maxDurationErrorMessage = 'Die gewählte Dauer kann nicht länger als 56 Tage sein. Bitte ändern Sie die Reisedaten.';


export default /*@__PURE__*/_defineComponent({
  __name: 'OfferDurationField',
  setup(__props, { expose: __expose }) {

const store = useStore();

const maxTravelDurationError = computed({
	get() {
		return store.state.searchMask.maxTravelDurationError;
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			maxTravelDurationError: value,
		});
	},
});

const calendar = ref<InstanceType<typeof Calendar> | null>(null);

const okFirstTime = ref(true);
const isDirty = ref(false);

const { isDesktop } = storeToRefs(useBreakpointStore());

const offerDuration = computed({
	get() {
		return store.state.searchMask.offerDuration as SelectionType;
	},
	set(value: SelectionType) {
		if (!isDirty.value) {
			isDirty.value = JSON.stringify(value) !== JSON.stringify(store.state.searchMask.offerDuration);
		}
		store.commit('searchMask/updateFormData', { offerDuration: value });
	},
});

const selection = ref<SelectionType>(offerDuration.value);

const travelDuration = computed({
	get() {
		return store.state.searchMask.travelDuration as number[];
	},
	set(value: number[]) {
		store.commit('searchMask/updateFormData', { travelDuration: value });
	},
});

const isExact = computed({
	get() {
		return store.state.searchMask.isTravelDurationExactSelected;
	},
	set(newValue: boolean) {
		store.commit('searchMask/updateFormData', { isTravelDurationExactSelected: newValue });
	},
});

const selectionDiffers = computed((): boolean => {
	const { from: offerFrom, to: offerTo } = offerDuration.value;
	const { from: selectionFrom, to: selectionTo } = selection.value;

	return offerFrom !== selectionFrom || offerTo !== selectionTo;
});

const selectedDatesLabel = computed((): string => {
	if (!selection.value.from) {
		return '';
	}
	const from = formatDate(new Date(selection.value.from));
	const to = (selection.value.to && formatDate(new Date(selection.value.to))) || 'Beliebig';

	return `${from} - ${to}`;
});

const extendSelectedDatesLabel = computed((): { from: string; to: string } => {
	let [from, to] = selectedDatesLabel.value.split(' - ');

	if (to !== 'Beliebig') {
		to += new Date(selection.value.to).getFullYear();
	}

	from += new Date(selection.value.from).getFullYear();
	if (from === '1970') {
		from = '';
		to = '';
	}
	return { from, to };
});

const checkDateRange = (selectionValues: { from: number; to: number }) => {
	if (selectionValues.from === 0 || selectionValues.to === 0) {
		return;
	}

	const dayDifference = dateDiff(selectionValues.to, selectionValues.from);
	if (isExact.value) {
		maxTravelDurationError.value = Math.abs(dayDifference) > 56;
	}
};

const closeDropdown = () => {
	if (isDesktop.value) {
		// If dropdown closes, always set calendarDateChanged to false
		store.commit('updateCalendarDateChanged', false);
	}
};

const updateTravelDuration = () => {
	const totalDiffInDay = dateDiff(offerDuration.value.from, offerDuration.value.to);

	if (totalDiffInDay < travelDuration.value[0] || isExact.value) {
		travelDuration.value = [totalDiffInDay];
		isExact.value = true;
	}

	maxTravelDurationError.value = travelDuration.value[0] > 56;
};

const handleTravelDuration = () => {
	if (okFirstTime.value && travelDuration.value[0] <= 56 && isDirty.value) {
		EventBus.$emit('OfferDurationField:OpenTravelDuration');
	}
	okFirstTime.value = false;
	updateTravelDuration();
};

const onOk = () => {
	// Clearing relative offerDuration if user changes the offerDuration via the calendar

	store.commit('searchMask/updateFormData', { offerDurationRelative: { relativeFrom: '', relativeTo: '' }, filter: '' });

	if (!selection.value.from) {
		selection.value.from = offerDuration.value.from;
	}

	if (selection.value.from && !selection.value.to) {
		// If offer duration to is 'Beliebig', a date 4 weeks in the future is choosen
		selection.value.to = offsetDate(selection.value.from, 28).getTime();
	}

	offerDuration.value = selection.value;
	handleTravelDuration();

	EventBus.$emit('Calendar:Scroll');
};

const onCancel = () => {
	selection.value = offerDuration.value;
	checkDateRange(offerDuration.value);
	closeDropdown();
};

const onBeforeClose = () => {
	if (!isDesktop.value) {
		selection.value = offerDuration.value;
		maxTravelDurationError.value = false;
	} else if (!maxTravelDurationError.value) {
		if (selectionDiffers.value && calendar.value) {
			calendar.value.showDismiss = !calendar.value.showConfirm;
		} else {
			selection.value = offerDuration.value;
			closeDropdown();
		}
		EventBus.$emit('Calendar:Scroll');
	}
};

const onClear = () => {
	if (!maxTravelDurationError.value) {
		offerDuration.value = { from: 0, to: 0 };
		EventBus.$emit('Calendar:Scroll');
	}
};

watch(
	() => offerDuration.value,
	() => {
		selection.value = offerDuration.value;
	},
	{ immediate: true }
);

watch(
	() => isDesktop.value,
	() => {
		onBeforeClose();
	}
);

__expose({
	selectedDatesLabel,
	offerDuration,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["offer-duration", { 'duration-error': maxTravelDurationError.value }])
  }, [
    _createVNode(DropdownFilterType, {
      title: "Zeitraum",
      "with-menu-header": false,
      icon: "calendar",
      class: "offer-duration__dropdown",
      "selected-value": selectedDatesLabel.value,
      "cancel-button-text": "Verwerfen",
      "ok-button-text": "Übernehmen",
      "show-buttons": true,
      "show-toggle-icon": false,
      "emit-on-ok": true,
      "button-disabled": maxTravelDurationError.value,
      "has-error": maxTravelDurationError.value,
      "apply-button-text": 'Übernehmen',
      "onDropdownFilterType:apply": onOk,
      "onDropdownFilterType:clear": onClear,
      "onDropdownFilterType:abort": onCancel
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "offer-duration__time-title" }, "Früheste Anreise", -1 /* HOISTED */)),
            _createElementVNode("p", _hoisted_3, _toDisplayString(extendSelectedDatesLabel.value.from), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "offer-duration__time-title" }, "Späteste Abreise", -1 /* HOISTED */)),
            _createElementVNode("p", _hoisted_5, _toDisplayString(extendSelectedDatesLabel.value.to), 1 /* TEXT */)
          ])
        ]),
        _createVNode(Calendar, {
          ref_key: "calendar",
          ref: calendar,
          modelValue: selection.value,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((selection).value = $event)),
            checkDateRange
          ],
          "max-months": 24,
          horizontal: _unref(isDesktop),
          "is-exact": isExact.value,
          "onCalendar:AlertOkClick": onOk,
          "onCalendar:AlertCancelClick": onCancel
        }, null, 8 /* PROPS */, ["modelValue", "horizontal", "is-exact"]),
        (maxTravelDurationError.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(maxDurationErrorMessage)))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["selected-value", "button-disabled", "has-error"])
  ], 2 /* CLASS */))
}
}

})