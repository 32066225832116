import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "filter-tag-list__list"
}
const _hoisted_2 = { class: "filter-tag-list__collapse" }
const _hoisted_3 = { class: "filter-tag-list__reset-all" }
const _hoisted_4 = { class: "filter-tag-list__collapse-text" }

import FilterTag from '@lmt-rpb/FilterTag/FilterTag.vue';
import { groupBy } from '@utils/utils';
import type { SearchFormDataType } from '@interfaces/search-form';
import Collapse from '@lmt-rpb/Collapse/Collapse.vue';
import { FLEX_ACCEPTED_VALUES } from '@global-js/constants';
import { computed, onMounted, ref } from 'vue';
import { EventBus } from '@global-js/event-bus';
import { useTranslations } from '@helper/composables/translations';
import { boardTypes, roomTypes, transferTypes, roomViews, mostPopularTypes, additionalCheckboxFilterTypes } from '@/js/data/index';

import { useStore } from '@/components/common/store';
import type { AdditionalCheckboxFilters, MostPopularFilters } from '@/interfaces/checkbox-filter-types';
import { formatPrice } from '@/js/utils/priceUtils';
import { isHotelPage, isHotelList } from '@/js/utils/searchFilterUtils';
import FilterAnalytics from '@/js/modules/analytics/FilterAnalytics';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	hasResults?: boolean;
	emitAnalytics?: boolean;
}

const maxItems = 4;


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterTagList',
  props: {
    hasResults: { type: Boolean },
    emitAnalytics: { type: Boolean, default: true }
  },
  setup(__props: any) {



const isCollapseOpen = ref(false);
const isMounted = ref(false);
const store = useStore();

const { translate } = useTranslations();

const toggleCollapse = () => {
	isCollapseOpen.value = !isCollapseOpen.value;
};

const { isSmallMobile, isDesktop } = storeToRefs(useBreakpointStore());
const formData = computed((): SearchFormDataType => store.state.searchMask as SearchFormDataType);

const isHotelPageView = isHotelPage();
const isHotelListView = isHotelList();

const currencyType = computed(() => store.getters.getCurrencyType);

const locationIdName = computed((): 'RegionID' | 'CityID' =>
	store.getters.locationType === 'COUNTRY' || store.getters.locationType === 'REGIONGROUP' ? 'RegionID' : 'CityID'
);

const destinationTypes = computed(() => store.state.types.destination);

const getDestinationTypeName = computed((): string => {
	const { destinationTypes: destination } = formData.value;
	const { destinationTypeName } = formData.value;
	let destName = '';

	if (destinationTypeName) {
		return destinationTypeName;
	}

	// if destinationTypes is set via paramsToForm then only the id is known and it is an array with 1 value
	if (Array.isArray(destination) && destination[0] && destinationTypes.value.length) {
		const foundType = destinationTypes.value.find((type) => type[locationIdName.value] === destination[0]);

		if (foundType) {
			destName = foundType.Name;
		}
	}
	store.commit('searchMask/updateFormData', { destinationTypeName: destName });
	return destName;
});

const items = computed(() => {
	const {
		boardTypes: boards,
		roomTypes: rooms,
		directFlight,
		roomViews: views,
		transferTypes: transfers,
		onlyHotel,
		hotelCategory,
		minMeanRecommendationRate,
		maxPrice,
		operatorTypes,
		mostPopularFilters: mostPopular,
		additionalCheckboxFilters,
		maxArrivalTime,
		minArrivalTime,
		maxDepartureTime,
		minDepartureTime,
		flex,
		deal,
	} = formData.value;
	const types = groupBy(
		[...boardTypes, ...roomTypes, ...roomViews, ...transferTypes, ...mostPopularTypes, ...additionalCheckboxFilterTypes],
		'value'
	);
	let filterItems: { label: string; value: string | boolean; type: string }[] = [];

	const boardItems = boards.map((item) => {
		const { label, value } = types[item][0];
		return { label, value, type: 'boardTypes' };
	});

	const roomItems = rooms.map((item) => {
		const { label, value } = types[item][0];
		return { label, value, type: 'roomTypes' };
	});

	const viewItems = views.length > 0 ? [{ label: 'Meerblick', value: 'FullOcean', type: 'roomViews' }] : [];

	const transferItems = transfers.map((item) => {
		const { label, value } = types[item][0];
		return { label, value, type: 'transferTypes' };
	});

	const mostPopularArray = [];
	if (!isHotelPageView && mostPopular?.hotelAttributes && mostPopular?.hotelAttributes.length > 0) {
		mostPopularArray.push(...mostPopular.hotelAttributes);
	}
	if (!isHotelPageView && mostPopular?.ratingAttributes && mostPopular?.ratingAttributes.length > 0) {
		mostPopularArray.push(...mostPopular.ratingAttributes);
	}
	const mostPopularItems = mostPopularArray.map((item) => {
		const { label, value } = types[item][0];
		let { attrType } = types[item][0];
		attrType = attrType.charAt(0).toLowerCase() + attrType.slice(1);
		return {
			label,
			value,
			attrType,
			type: 'mostPopularFilters',
		};
	});

	const additionalCheckboxFiltersArray = [];
	if (!isHotelPageView && additionalCheckboxFilters?.hotelAttributes && additionalCheckboxFilters?.hotelAttributes.length > 0) {
		additionalCheckboxFiltersArray.push(...additionalCheckboxFilters.hotelAttributes);
	}
	if (!isHotelPageView && additionalCheckboxFilters?.ratingAttributes && additionalCheckboxFilters?.ratingAttributes.length > 0) {
		additionalCheckboxFiltersArray.push(...additionalCheckboxFilters.ratingAttributes);
	}
	const additionalCheckboxFiltersItems = additionalCheckboxFiltersArray.map((item) => {
		const { label, value } = types[item][0];
		let { attrType } = types[item][0];
		attrType = attrType.charAt(0).toLowerCase() + attrType.slice(1);
		return {
			label,
			value,
			attrType,
			type: 'additionalCheckboxFilters',
		};
	});

	filterItems = [...boardItems, ...roomItems, ...viewItems, ...mostPopularItems, ...additionalCheckboxFiltersItems];

	if (!onlyHotel) {
		filterItems = filterItems.concat(transferItems);
	}

	if (directFlight && !onlyHotel) {
		filterItems.push({ label: 'Direktflug', value: true, type: 'directFlight' });
	}

	if (!isHotelPageView && hotelCategory) {
		filterItems.push({ label: `Hotelkategorie ${hotelCategory}`, value: true, type: 'hotelCategory' });
	}

	if (!isHotelPageView && minMeanRecommendationRate) {
		filterItems.push({ label: `Weiterempfehlungsrate ${minMeanRecommendationRate}%`, value: true, type: 'minMeanRecommendationRate' });
	}

	if (!isHotelPageView && maxPrice && maxPrice !== 1600) {
		filterItems.push({ label: `Reisepreis bis ${formatPrice(maxPrice, currencyType.value)}`, value: true, type: 'maxPrice' });
	}

	if (FLEX_ACCEPTED_VALUES.includes(flex as (typeof FLEX_ACCEPTED_VALUES)[number])) {
		filterItems.push({ label: 'Flexibel buchen', value: true, type: 'flex' });
	}
	if (deal && isHotelListView) {
		filterItems.push({ label: translate('filtersSection.dealsOnly.title'), value: true, type: 'deal' });
	}

	if (operatorTypes && operatorTypes.length > 0) {
		filterItems.push({ label: 'Veranstalter', value: true, type: 'operatorTypes' });
	}

	if (!isHotelPageView && destinationTypes.value && getDestinationTypeName.value) {
		filterItems.push({ label: `${getDestinationTypeName.value}`, value: true, type: 'destinationTypes' });
	}

	if (!store.state.searchMask.onlyHotel && (minArrivalTime > 0 || minDepartureTime > 0 || maxDepartureTime < 24 || maxArrivalTime < 24)) {
		filterItems.push({ label: 'Flugzeiten', value: true, type: 'flight' });
	}

	return filterItems;
});

const shouldCollapse = computed(() => {
	return items.value.length > maxItems;
});

const getItems = computed(() => {
	if (isSmallMobile.value) {
		return items.value.slice(0, maxItems);
	}
	return items.value;
});

const getCollapsedItems = computed(() => {
	return items.value.filter((item) => getItems.value.indexOf(item) === -1);
});

const onTagMounted = (label: string, type?: string) => {
	if (!isMounted.value || !__props.emitAnalytics) return;
	if (type === 'destinationTypes') {
		label = locationIdName.value === 'RegionID' ? 'Region' : 'Ort';
	}
	FilterAnalytics.createInstance(label).fireFilterSelected();
};

const resetAll = (): void => {
	store.dispatch('resetFormData');
};

const resetFilter = (item: { label: string; value: string | boolean; type: string; attrType?: string }): void => {
	type FormDataKey = keyof typeof formData.value;
	if (['boardTypes', 'roomTypes', 'roomViews', 'transferTypes'].indexOf(item.type) !== -1) {
		// update stored value without this item
		const stored = formData.value[item.type as FormDataKey] as string[];

		const updated = item.type === 'roomViews' ? [] : stored.filter((storedItem: string) => storedItem !== item.value);
		store.dispatch('setFormData', {
			...formData.value,
			[item.type]: updated,
		});
	} else if (['mostPopularFilters'].indexOf(item.type) !== -1 && item.attrType) {
		const stored = formData.value[item.type as FormDataKey] as MostPopularFilters;
		type StoredDataKey = keyof typeof stored;
		const storedFilter = stored ? stored[item.attrType as StoredDataKey] : [];
		const updated = storedFilter?.filter((storedItem: string) => storedItem !== item.value);
		const storedMostPopular = formData.value.mostPopularFilters;
		store.dispatch('setFormData', {
			...formData.value,
			[item.type]: {
				...storedMostPopular,
				[item.attrType]: updated,
			},
		});
	} else if (['additionalCheckboxFilters'].indexOf(item.type) !== -1 && item.attrType) {
		const stored = formData.value[item.type as FormDataKey] as AdditionalCheckboxFilters;
		type StoredDataKey = keyof typeof stored;
		const storedFilter = stored ? stored[item.attrType as StoredDataKey] : [];
		const updated = storedFilter?.filter((storedItem: string) => storedItem !== item.value);
		const storedAdditionalCheckboxFilters = formData.value.additionalCheckboxFilters;
		store.dispatch('setFormData', {
			...formData.value,
			[item.type]: {
				...storedAdditionalCheckboxFilters,
				[item.attrType]: updated,
			},
		});
	} else {
		// reset stored value by init state
		store.dispatch('resetItem', item.type);

		// if type is destinationTypes we also need to reset the saved name
		if (['destinationTypes'].indexOf(item.type) !== -1) {
			store.dispatch('resetItem', 'destinationTypeName');
		} else if (['travelDuration'].indexOf(item.type) !== -1) {
			store.dispatch('resetItem', 'isTravelDurationExactSelected');
		}
	}
	// update filters
	EventBus.$emit('SearchMask:MobileSubmit');
};

onMounted(() => {
	isMounted.value = true;
});

return (_ctx: any,_cache: any) => {
  return (items.value.length)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass({
			'filter-tag-list': true,
			'filter-tag-list--no-results': !_ctx.hasResults,
		})
      }, [
        (items.value.length)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getItems.value, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.type + item.label,
                    class: "filter-tag-list__item"
                  }, [
                    _createVNode(FilterTag, {
                      label: item.label,
                      onClick: ($event: any) => (resetFilter(item)),
                      onVnodeMounted: ($event: any) => (onTagMounted(item.label, item.type))
                    }, null, 8 /* PROPS */, ["label", "onClick", "onVnodeMounted"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */)),
                _createElementVNode("li", _hoisted_3, [
                  (_unref(isDesktop))
                    ? (_openBlock(), _createBlock(FilterTag, {
                        key: 0,
                        label: "Alle Filter löschen",
                        cancelable: false,
                        class: "filter-tag-list__item filter-tag-list__reset-all",
                        onClick: resetAll
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                (_unref(isSmallMobile) && shouldCollapse.value)
                  ? (_openBlock(), _createBlock(Collapse, {
                      key: 0,
                      "hide-arrow-icon": true,
                      open: isCollapseOpen.value,
                      duration: 100,
                      "onCollapse:Toggle": toggleCollapse
                    }, {
                      icon: _withCtx(() => [
                        _createElementVNode("p", _hoisted_4, _toDisplayString(isCollapseOpen.value ? 'Weniger' : 'Mehr'), 1 /* TEXT */),
                        (_openBlock(), _createElementBlock("svg", {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 27.563 15.267",
                          class: _normalizeClass(["collapse__icon--small", { 'collapse__icon--small-open': isCollapseOpen.value }])
                        }, _cache[0] || (_cache[0] = [
                          _createElementVNode("path", { d: "M24.907 14.798L13.782 3.673 2.657 14.798a1.511 1.511 0 01-2.188 0 1.511 1.511 0 010-2.188L12.719.422a1.548 1.548 0 012.125 0l12.25 12.188a1.54714964 1.54714964 0 01-2.188 2.188z" }, null, -1 /* HOISTED */)
                        ]), 2 /* CLASS */))
                      ]),
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getCollapsedItems.value, (item, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: item.type + item.label + index,
                            class: "filter-tag-list__item"
                          }, [
                            _createVNode(FilterTag, {
                              label: item.label,
                              onClick: ($event: any) => (resetFilter(item)),
                              onVnodeMounted: ($event: any) => (onTagMounted(item.label, item.type))
                            }, null, 8 /* PROPS */, ["label", "onClick", "onVnodeMounted"])
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["open"]))
                  : _createCommentVNode("v-if", true)
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}
}

})