import { requestCancelable } from '@/components/common/services/request';
import type { UpsellingRoom, UpsellingRoomResponse } from '@/interfaces/search-form';
import { useStore } from '@/components/common/store';
import type { ApiDataTypes, ApiDataTypesKeys } from '@/components/common/types';
import { roomTypes } from '@/js/data';
import type { RoomTypeValue } from '@/js/data/room-types';
import { createPriceMap } from './priceMapService';

const url = '/v1/best-offer-for-room-type';
const store = useStore();
const apiData = (override?: any, exclude?: ApiDataTypesKeys[]): ApiDataTypes => store.getters['searchMask/apiData'](override, exclude);

const isTypeOfferUpsellingRoom = (isOfferUpsellingBoard: UpsellingRoomResponse | []): isOfferUpsellingBoard is UpsellingRoomResponse => {
	return typeof (isOfferUpsellingBoard as any).Status === 'string';
};

export const getOfferUpsellingRoom = async () => {
	const hotelOnly = store.state.searchMask.onlyHotel;
	const exclude = hotelOnly ? ['DepartureAirport', 'DirectFlight', 'DepartureTime', 'ArrivalTime'] : [];
	// Removing params which are at the moment specific to the hotellist request
	exclude.push(
		'Regions',
		'Cities',
		'MaxPrice',
		'SortOrder',
		'HotelCategory',
		'MinMeanRecommendationRate',
		'RatingAttributes',
		'HotelAttributes',
		'Countries'
	);
	const bodyParams = {
		travelType: hotelOnly ? 'Accommodation' : 'Package',
	};
	const body = apiData(bodyParams, exclude);

	const response = await requestCancelable<UpsellingRoomResponse>(
		{
			url,
			data: body,
		},
		'best-offer-for-room-type'
	);

	let result: UpsellingRoom[] = [];

	if (!response || (isTypeOfferUpsellingRoom(response) && response.Status !== 'OK')) {
		throw new Error('Best Offer For Room Type Response Invalid: ' + JSON.stringify(response));
	} else if (response && isTypeOfferUpsellingRoom(response)) {
		result = response.Offers;
	}
	return result;
};

export async function updateRoomTypePrices() {
	await store.commit('searchMask/updateRoom', {
		enabledFilters: new Set(roomTypes.map((room) => room.value)),
		isFetchLoading: true,
		priceMap: new Map(),
		fetchError: null,
	});

	try {
		const result = await getOfferUpsellingRoom();
		const { priceMap, enabledFilters } = createPriceMap<UpsellingRoom>(result, (response) => response.Room.Type as RoomTypeValue);

		await store.commit('searchMask/updateRoom', {
			enabledFilters: enabledFilters as Set<RoomTypeValue>,
			priceMap,
			isFetchLoading: false,
			fetchError: null,
		});
	} catch (error) {
		await store.commit('searchMask/updateRoom', {
			enabledFilters: new Set(roomTypes.map((room) => room.value)),
			isFetchLoading: false,
			priceMap: new Map(),
			fetchError: error as Error,
		});
	}
}
