import type { SearchFormDataType } from '@/interfaces/search-form';
import type { TourOperatorInventoryResponse } from '@/interfaces/api/v1-touroperator-inventory';
import request from '@components/common/services/request';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { useStore } from '../store';
import type { ApiDataTypesKeys } from '../types';

// NOTE: Temporary excludes for the inventory requests till API also sends items with count 0
const TEMPORARY_EXCLUDES: ApiDataTypesKeys[] = [
	'BoardTypes',
	'RoomTypes',
	'HotelCategory',
	'MinMeanRecommendationRate',
	'MaxPrice',
	'RatingAttributes',
	'HotelAttributes',
	'TourOperatorCodes',
];

const HOTEL_EXCLUDES = ['DepartureAirport', 'DirectFlight', 'Transfer'] as const;
const COMMON_EXCLUDES = ['DefiniteStartDate', 'TourOperatorCodes', 'SortOrder'] as const;

export const fetchTourOperatorInventory = (bodyParams: Record<string, any>, excludes: ApiDataTypesKeys[] = []): void => {
	const store = useStore();
	const {
		touroperatorInventory: { packages, hotels },
	} = store.state.config;
	const formData: SearchFormDataType = store.state.searchMask;
	const apiData = store.getters['searchMask/apiData'];
	const travelType = formData.onlyHotel ? 'hotel' : 'package';
	const url = formData.onlyHotel ? hotels : packages;
	const exclude: ApiDataTypesKeys[] = [
		...(travelType === 'hotel' ? HOTEL_EXCLUDES : []),
		...COMMON_EXCLUDES,
		...excludes,
		...TEMPORARY_EXCLUDES,
	];

	const body = apiData(bodyParams, exclude);

	request<TourOperatorInventoryResponse>({ url, data: body }, 'tourOperatorOfferList')
		.then((resp) => {
			if (!resp) {
				return;
			}
			store.dispatch('updateTypes', {
				operator: resp.TourOperators,
			});
		})
		.catch((axiosError: AxiosError) => {
			if (!axios.isCancel(axiosError)) {
				console.error('Touroperator: ', axiosError);
			}
		})
		.finally(() => {
			store.dispatch('updateTourOperatorLoading', false);
		});
};

/* export const fetchTourOperatorInventory = (): Promise<TourOperatorInventoryResponse> => {
	const store = useStore();
	const {
		touroperatorInventory: { packages, hotels },
	} = store.state.config;
	const formData: SearchFormDataType = store.state.searchMask;
	const apiData = store.getters['searchMask/apiData'];
	const travelType = formData.onlyHotel ? 'hotel' : 'package';
	const url = formData.onlyHotel ? hotels : packages;
	const exclude: ApiDataTypesKeys[] = [...(travelType === 'hotel' ? HOTEL_EXCLUDES : []), ...COMMON_EXCLUDES, ...TEMPORARY_EXCLUDES];

	const body = apiData({}, exclude);

	return request<TourOperatorInventoryResponse>({ url, data: body }, 'tourOperatorOfferList');
}; */
