import { getDefaultCurrency } from '@utils/environmentUtils';
import type { Price } from '@/interfaces/checkout/offer-data';
import type { Pricing } from '@/interfaces/common';
import { getTotalPrice } from '@/js/utils/priceUtils';
interface TypedResponse {
	Room?: {
		Type: string;
	};
	Board?: {
		Type: string;
	};
	Pricing: Pricing;
}

export function createPriceMap<T extends TypedResponse>(
	responses: T[],
	getType: (response: T) => string
): { priceMap: Map<string, Price>; enabledFilters: Set<string> } {
	const priceMap = new Map<string, Price>();
	const enabledFilters = new Set<string>();

	responses.forEach((responseObject) => {
		const type = getType(responseObject);
		const totalPrice = getTotalPrice(responseObject.Pricing);
		if (priceMap.has(type)) {
			const storedPrice = priceMap.get(type)!;
			if (totalPrice < storedPrice.Amount) {
				priceMap.set(type, {
					Amount: totalPrice,
					Currency: getDefaultCurrency(),
				});
			}
		} else {
			priceMap.set(type, {
				Amount: totalPrice,
				Currency: getDefaultCurrency(),
			});
		}
		enabledFilters.add(type);
	});

	return { priceMap, enabledFilters };
}
