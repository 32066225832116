<template>
	<dialog
		ref="modalRef"
		:class="['base-modal', blockClassModifiers]"
	>
		<div
			class="base-modal__backdrop"
			@click.stop="closeModal"
		/>
		<div class="base-modal__content">
			<div
				class="base-modal__header"
				@click.stop="closeModal"
			>
				<span
					class="base-modal__header-title"
					v-text="headerTitle"
				/>
				<CloseButton
					class="base-modal__close-btn"
					@click.stop="closeModal"
				/>
			</div>
			<div class="base-modal__header-slot">
				<slot
					v-bind="{ closeModal, openModal }"
					name="header"
				/>
			</div>
			<div class="base-modal__main">
				<slot v-bind="{ closeModal, openModal }" />
			</div>
			<div class="base-modal__footer">
				<slot
					v-bind="{ closeModal, openModal }"
					name="footer"
				/>
			</div>
		</div>
	</dialog>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import Modal from '@/js/modules/modal';
import CloseButton from '@lmt-rpb/Atoms/CloseButton/CloseButton.vue';

const modalRef = ref<HTMLDialogElement | null>(null);
let modalInstance: Modal | null = null;

interface Props {
	headerTitle?: string;
	mediaQuery?: MediaQueryList | null;
	fullHeight?: boolean;
	modalCloseCallback?: () => void;
	size?: 'small';
}

const emits = defineEmits(['close']);
const props = withDefaults(defineProps<Props>(), {
	mediaQuery: null,
	fullHeight: false,
	modalCloseCallback: () => {},
});

const openModal = () => {
	modalInstance?.openModal();
};

const closeModal = () => {
	modalInstance?.closeModal();
	emits('close');
};

const blockClassModifiers = computed(() => ({
	[`base-modal--${props.size}`]: !!props.size,
	'base-modal--full-height': props.fullHeight,
}));

defineExpose({
	modalRef,
	openModal,
	closeModal,
});

onMounted(() => {
	if (modalRef.value) {
		modalInstance = new Modal(modalRef.value, props?.mediaQuery, props.modalCloseCallback);
	}
});
</script>

<style lang="scss" scoped>
.base-modal {
	height: 100dvh;
	max-height: 100dvh;
	width: 100vw;
	max-width: 100vw;
	padding: 0;
	background-color: transparent;
	border: none;
	overflow: hidden;

	&__backdrop {
		opacity: 0;
		position: fixed;
		z-index: -1;
		inset: 0;
		background-color: rgba($color-text, 0.8);
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: stretch;
		height: 100%;
		min-width: 100%;
		background-color: $color-white;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $color-link;
		color: $color-white;
		font-size: $font-small-1;
		cursor: pointer;
		box-shadow: $box-shadow;
		padding: 0.8rem 1.6rem;
		z-index: 1;

		&-slot {
			padding: 0.8rem 1.6rem;

			&:empty {
				display: none;
			}

			&:not(:empty) {
				border-bottom: 0.1rem solid $color-border;
			}
		}
	}

	&__main {
		height: 100%;
		padding: 1.6rem;
		overflow: hidden auto;
	}

	&__header-title {
		font-weight: $font-weight-semibold;
	}

	&__footer {
		padding: 1.6rem;

		&:empty {
			display: none;
		}

		&:not(:empty) {
			position: sticky;
			bottom: 0;
			left: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			column-gap: 0.8rem;
			background-color: $color-white;
			box-shadow: $box-shadow;
		}
	}
}

@media (min-width: $breakpoint-small) {
	.base-modal {
		&__header {
			padding: 1.6rem 2.4rem;
			font-size: $font-medium-2;
		}

		&__close-btn {
			height: 1.6rem;
		}

		&__footer {
			padding: 1.6rem 2.4rem;
		}

		&__main {
			padding: 2.4rem;
		}

		&__header-slot {
			padding: 0.8rem 2.4rem;
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.base-modal {
		&__backdrop {
			opacity: 1;
		}

		&__content {
			margin: 0 auto;
			min-width: 102.4rem;
			max-width: 102.4rem;
			border-radius: $border-radius-small;
			height: max-content;
			max-height: min(calc(100% - 16rem), 92rem);
			overflow: hidden;
			min-height: 34rem;
		}

		&--small {
			.base-modal__content {
				height: fit-content;
				min-width: auto;
				width: 51.2rem;
			}
		}

		&--full-height {
			.base-modal__content {
				height: 100%;
			}
		}

		&[open] {
			display: flex;
			align-items: center;
		}
	}
}
</style>
