<template>
	<component
		:is="component"
		:class="['base-price', blockClassModifiers]"
		:aria-label="ariaLabel"
	>
		<span
			v-if="prefix"
			class="base-price__prefix"
			v-text="prefix + NBSP"
		></span>
		<span v-text="formattedPrice.amount"></span>
		<span
			class="base-price__symbol"
			v-text="NBSP + formattedPrice.symbol"
		></span>
	</component>
</template>

<script setup lang="ts">
import { NBSP, type ClientCurrency } from '@global-js/constants';
import { formatPrice, separatePrice } from '@utils/priceUtils';
import { getDefaultCurrency } from '@utils/environmentUtils';
import { computed } from 'vue';

interface Props {
	strikeThrough?: boolean;
	price: number;
	currency?: ClientCurrency;
	showDecimals?: boolean;
	theme?: 'primary' | 'accent' | 'inherit';
	prefix?: string;
}

const props = withDefaults(defineProps<Props>(), {
	currency: getDefaultCurrency() || 'EUR',
	theme: 'inherit',
	showDecimals: false,
	strikeThrough: false,
	prefix: '',
});

const formattedPrice = computed(() => separatePrice(formatPrice(props.price, props.currency, props.showDecimals)));
const component = computed(() => (props.strikeThrough ? 'del' : 'ins'));
const ariaLabel = computed(() => `${props.prefix}${NBSP}${formattedPrice.value.amount}${NBSP}${formattedPrice.value.symbol}`);

const isLongCurrency = computed(() => ['CHF'].includes(props.currency));

const blockClassModifiers = computed(() => ({
	[`base-price--${props.theme}`]: !!props.theme,
	'base-price--strike-through': !!props.strikeThrough,
	'base-price--long-currency': isLongCurrency.value,
}));
</script>

<style lang="scss">
.base-price {
	line-height: 3.4rem;
	white-space: nowrap;
	text-decoration: none;
	text-align: right;

	&,
	& > * {
		font-family: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-size: inherit;
	}

	// theme
	&--primary {
		color: $color-link;
	}

	&--accent {
		color: $color-price-accent;
	}

	// strike-through
	&--strike-through {
		text-decoration: line-through;
	}

	&--long-currency {
		.base-price__symbol {
			font-family: $font-family-condensed;
		}
	}
}
</style>
