import { determineClient } from '@utils/environmentUtils';
import rpbAT from '../../../../storybook/src/locales/translations/rpbAT';
import rpbCH from '../../../../storybook/src/locales/translations/rpbCH';
import rpbDE from '../../../../storybook/src/locales/translations/rpbDE';
import kuoAT from '../../../../storybook/src/locales/translations/kuoAT';
import lmtAT from '../../../../storybook/src/locales/translations/lmtAT';
import tbDE from '../../../../storybook/src/locales/translations/tbDE';
import type { ClientType } from '@/interfaces/common';

const clientLocaleMap = {
	com: 'de_DE',
	at: 'de_AT',
	ch: 'de_CH',
	kuat: 'de_AT',
	lmat: 'de_AT',
	tbde: 'de_DE',
} as const satisfies Record<ClientType, string>;

export type LocalesCodes = (typeof clientLocaleMap)[keyof typeof clientLocaleMap];

const clientLocaleMessages = {
	com: rpbDE,
	at: rpbAT,
	ch: rpbCH,
	kuat: kuoAT,
	lmat: lmtAT,
	tbde: tbDE,
} as const satisfies Record<ClientType, Record<string, unknown>>;

export const determineLocales = (clientLocale = determineClient()) =>
	({
		locale: clientLocaleMap[clientLocale],
		messages: {
			[clientLocaleMap[clientLocale]]: clientLocaleMessages[clientLocale],
		} as Record<LocalesCodes, (typeof clientLocaleMessages)[ClientType]>,
	}) as const;
