<template>
	<div class="list">
		<div class="list__header">
			<slot name="header" />
		</div>
		<div class="list__body">
			<slot name="content" />
		</div>
		<div class="list__footer">
			<slot name="footer" />
		</div>
	</div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.list {
	display: flex;
	flex-direction: column;
	height: 100%;

	.list__body {
		height: 100%;
		overflow: scroll;
	}
}

@include media-query-up ($breakpoint-extralarge) {
	.list {
		max-height: 38rem;
		height: auto;

		.list__body {
			height: auto;
		}
	}
}
</style>
