<template>
	<Checkbox
		v-model="dealFilter"
		class="deals-only-filter"
		:label
	/>
</template>
<script lang="ts" setup>
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed } from 'vue';
import { useStore } from '@/components/common/store';

type Props = {
	label?: string;
}
const { label = 'Angebote mit Preissenkung' } = defineProps<Props>();

const store = useStore();

const dealFilter = computed({
	get() {
		return Boolean(store.state.searchMask.deal);
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', {
			deal: value,
		});
	},
});

</script>
