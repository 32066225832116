import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex-feature__shield-container" }
const _hoisted_2 = { class: "flex-feature__title" }

import { ref } from 'vue';
import type { SingleFeature } from '@/interfaces/common';
import InfoTooltip from '../InfoTooltip/InfoTooltip.vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';

interface Props {
	feature: SingleFeature;
	hasHotelListStyle?: boolean;
	showShield?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FlexFeature',
  props: {
    feature: {},
    hasHotelListStyle: { type: Boolean, default: false },
    showShield: { type: Boolean, default: true }
  },
  setup(__props: any) {



const showTooltip = ref(false);

return (_ctx: any,_cache: any) => {
  return (_ctx.feature)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["flex-feature", { 'flex-feature-offerlist': !_ctx.hasHotelListStyle }]),
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => (showTooltip.value = true)),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (showTooltip.value = false))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(FaIcon, { icon: ['fa-kit', 'fa-shield'] })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.feature.title), 1 /* TEXT */),
          _createVNode(InfoTooltip, {
            class: "flex-feature__tooltip",
            "show-tooltip-parent": showTooltip.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.feature.description), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["show-tooltip-parent"])
        ])
      ], 34 /* CLASS, NEED_HYDRATION */))
    : _createCommentVNode("v-if", true)
}
}

})