import { z } from 'zod';
import { isValidIBAN, isValidBIC } from 'ibantools';

export const noSchema = z.any();
export const stringRequiredSchema = z.string().trim().min(1);
export const emailSchema = z.string().trim().email();
export const bicRequiredSchema = z
	.string()
	.trim()
	.pipe(z.custom<string>((bic) => (typeof bic === 'string' ? isValidBIC(bic.replace(/\s/g, '')) : false)));
export const ibanRequiredSchema = z
	.string()
	.trim()
	.pipe(z.custom<string>((iban) => (typeof iban === 'string' ? isValidIBAN(iban.replace(/\s/g, '')) : false)));
