<template>
	<div class="grouped-price-info">
		<slot
			name="discount"
			:currency
			:discount
		>
			<DiscountPrice
				v-if="withDiscount"
				:currency
				:average-price="discount.referencePriceTotal?.avgPrice"
				:discount="discount.relativeDiscountPercent?.toAvgPrice ?? 0"
				@click.stop.prevent
			>
				<template #tooltip>
					<slot
						name="tooltip"
						:currency
						:discount
						@click.stop.prevent
					/>
				</template>
			</DiscountPrice>
		</slot>
		<div class="grouped-price-info__price-container">
			<span
				class="grouped-price-info__prefix"
				v-text="prefixText"
			/>
			<BasePrice
				:price="totalPrice"
				:currency
				theme="accent"
				class="grouped-price-info__price"
			/>
		</div>
		<span
			class="grouped-price-info__subtext"
		>
			<BasePrice
				v-if="perPersonPrice"
				class="grouped-price-info__per-person"
				:prefix="perPersonText"
				:currency
				show-decimals
				:price="perPersonPrice"
			/>
			<slot
				v-else
				name="subtext"
				:currency
				:subtext
			>
				{{ subtext }}
			</slot>
		</span>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ClientCurrency } from '@global-js/constants';
import BasePrice from '@lmt-rpb/Atoms/BasePrice/BasePrice.vue';
import type { HistoricalPriceData } from '@/interfaces/common';
import DiscountPrice from '../DiscountPrice/DiscountPrice.vue';
import { useStore } from '@/components/common/store';
import { useTravelers } from '../../../composables/useTravelers';


interface Props {
	currency: ClientCurrency;
	totalPrice: number;
	subtext?: string;
	prefix?: string;
	perPersonPrice?: number,
	perPersonText?:	string,
	discount?: HistoricalPriceData,
}

const props = withDefaults(defineProps<Props>(), {
	perPersonText: 'p.P.',
	prefix: '',
	subtext: '',
	discount: undefined,
});

const store = useStore();
const storeTravelers = computed(() => ({
	adult: store.state.searchMask.travelers.adult,
	children: store.state.searchMask.travelers.children
}));

const { travelersLabel } = useTravelers(storeTravelers);

const prefixText = computed(() => props.prefix ? props.prefix : travelersLabel.value);

const withDiscount = computed(() => props.discount && !!props.discount?.referencePriceTotal?.avgPrice && !!props.discount?.relativeDiscountPercent?.toAvgPrice);

</script>

<style lang="scss" scoped>
.grouped-price-info {
	width: max-content;
	display: flex;
	align-items: end;
	flex-direction: column;

	&__prefix {
		font-size: $font-small-3;
		color: $color-black;
		margin-right: 0.8rem;
	}

	&__price-container{
		width: max-content;
	}

	&__price {
		font-size: $font-medium-1;
		line-height: 1.3;
		font-weight: $font-weight-semibold;
	}

	&__subtext {
		font-size: $font-small-3;
		color: $color-placeholder-text;
		margin: 0;

		&:empty {
			opacity: 0;
			min-height: 2rem;
		}
	}

	@include media-query-up($breakpoint-small) {
		&__price {
			font-size: $font-large-4;
			line-height: 1.3;
		}

		&__prefix {
			font-size: $font-small-2;
		}

		&__subtext {
			font-size: $font-small-2;
		}
	}
}
</style>
