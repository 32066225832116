import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-type" }
const _hoisted_2 = { class: "filter-type__body--desktop" }

import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	icon?: string;
	title?: string;
	initialOpen?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterType',
  props: {
    icon: { default: undefined },
    title: { default: 'Initial title' },
    initialOpen: { type: Boolean, default: true }
  },
  emits: ['FilterType:MobileHeaderOpened'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { isDesktop } = storeToRefs(useBreakpointStore());

const dropdownOpen = ref(props.initialOpen);
const toggleDropdown = function () {
	if (isDesktop.value) return;
	dropdownOpen.value = !dropdownOpen.value;
	if (dropdownOpen.value) {
		emit('FilterType:MobileHeaderOpened');
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "filter-type__header",
      onClick: toggleDropdown
    }),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})