import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flight-details" }
const _hoisted_2 = { class: "flight-details__wrap" }
const _hoisted_3 = { class: "flight-details__header" }
const _hoisted_4 = { class: "flight-details__legal-text" }

import CheckoutFlightDetailsSegmentList from '@lmt-rpb/CheckoutFlightDetailsSegmentList/CheckoutFlightDetailsSegmentList.vue';
import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import { useTranslations } from '@helper/composables/translations';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutFlightDetails',
  props: {
    inboundFlight: {},
    outboundFlight: {}
  },
  setup(__props: any) {



const { translate } = useTranslations();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_unref(translate)('flightDetail.header')), 1 /* TEXT */),
      _createVNode(CheckoutFlightDetailsSegmentList, {
        flights: _ctx.outboundFlight,
        "is-outbound": true
      }, null, 8 /* PROPS */, ["flights"]),
      _createVNode(CheckoutFlightDetailsSegmentList, {
        flights: _ctx.inboundFlight,
        "is-outbound": false
      }, null, 8 /* PROPS */, ["flights"])
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(translate)('flightDetail.legalText')), 1 /* TEXT */)
  ]))
}
}

})