import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "checkout-payment-info" }
const _hoisted_2 = {
  key: 0,
  class: "checkout-payment-info__headline"
}
const _hoisted_3 = { class: "checkout-payment-info__content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "checkout-payment-info__chf-info"
}

import { toRef } from 'vue';
import { usePaymentInfo } from '../../composables/usePaymentInfo';
import { PriceInformation, SimplePrice } from '@/interfaces/checkout/offer-data';

interface Props {
    priceInfo: PriceInformation;
    flexStornoAmount?: SimplePrice;
    startDate?: string;
    hidePaymentInfo?: string;
    hideHeadline?: boolean;
}

const paymentHeadline = 'Zahlungsmodalitäten für Zahlung der Reise:';
const chfInfoText = 'Der Vertrag wird in EURO geschlossen. Die Angabe in CHF dient lediglich Ihrer Information und ist ein Circa-Wert.';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutPaymentInfo',
  props: {
    priceInfo: {},
    flexStornoAmount: {},
    startDate: {},
    hidePaymentInfo: {},
    hideHeadline: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const priceInfoRef = toRef(props, 'priceInfo');
const flexStornoRef = toRef(props, 'flexStornoAmount');
const startDateRef = toRef(props, 'startDate');
const hidePaymentInfoRef = toRef(props, 'hidePaymentInfo');

const {
    depositAmount,
    finalPaymentConverted,
    formattedDepositText,
    formattedFinalPaymentText,
    shouldShowFallback,
} = usePaymentInfo(
    priceInfoRef,
    flexStornoRef,
    startDateRef,
    hidePaymentInfoRef
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((!_unref(shouldShowFallback) && !_ctx.hideHeadline))
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(paymentHeadline)))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      (_unref(depositAmount) && _ctx.priceInfo?.FinalPayment)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "checkout-payment-info__payment-due-text",
            innerHTML: _unref(formattedDepositText)
          }, null, 8 /* PROPS */, _hoisted_4))
        : _createCommentVNode("v-if", true),
      _createElementVNode("p", {
        class: "checkout-payment-info__payment-due-text",
        innerHTML: _unref(formattedFinalPaymentText)
      }, null, 8 /* PROPS */, _hoisted_5),
      (_unref(finalPaymentConverted) && !_unref(shouldShowFallback))
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(chfInfoText)))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}
}

})