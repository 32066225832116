<template>
	<button
		v-show="isChecked"
		class="delete-button"
		type="button"
		@click="handleClick"
	>
		{{ buttonText }}
	</button>
</template>

<script lang="ts" setup>
type Props = {
	buttonText?: string;
	isChecked?: boolean;
};

withDefaults(defineProps<Props>(), { buttonText: 'Löschen' });

const emit = defineEmits(['delete']);

const handleClick = () => {
	emit('delete');
};
</script>

<style lang="scss" scoped>
.delete-button {
	background: none;
	border: none;
	color: $color-primary;
	font-size: $font-small-2;
	font-weight: $font-weight-semibold;
	cursor: pointer;
	margin-left: auto;
}
</style>
