<template>
	<svg width="58" height="16" viewBox="0 0 58 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M37.6455 9.71618V10.2162H38.1455H45.7025H46.2025V9.71618V4.3183C46.2025 3.9973 46.4611 3.73873 46.7821 3.73873H54.3391C55.851 3.73873 57.0778 4.96555 57.0778 6.47745V14.0345C57.0778 14.3555 56.8193 14.6141 56.4983 14.6141C56.1773 14.6141 55.9187 14.3555 55.9187 14.0345V12.9549V12.4549H55.4187H47.8617H46.7821H38.1455H37.6455V12.9549V14.0345C37.6455 14.3555 37.3869 14.6141 37.0659 14.6141C36.7449 14.6141 36.4863 14.3555 36.4863 14.0345V1.07958C36.4863 0.758578 36.7449 0.5 37.0659 0.5C37.3869 0.5 37.6455 0.758578 37.6455 1.07958V9.71618ZM41.924 3.73873C42.5072 3.73873 43.0665 3.9704 43.4789 4.38278C43.8913 4.79516 44.1229 5.35447 44.1229 5.93766C44.1229 6.52086 43.8913 7.08017 43.4789 7.49255C43.0665 7.90493 42.5072 8.1366 41.924 8.1366C41.3408 8.1366 40.7815 7.90493 40.3691 7.49255C39.9567 7.08017 39.7251 6.52086 39.7251 5.93766C39.7251 5.35447 39.9567 4.79516 40.3691 4.38278C40.7815 3.9704 41.3408 3.73873 41.924 3.73873Z"
			fill="currentcolor" stroke="currentcolor" />
		<path
			d="M12.6781 3.0713L12.8846 3.14876L13.0811 3.04845L16.5762 1.26378L16.5764 1.26369C17.113 0.989418 17.7097 0.846158 18.3123 0.846158H20.2488C20.8114 0.846158 21.199 1.40877 21.0022 1.93084L21.002 1.93134C20.429 3.4563 19.3018 4.70882 17.8458 5.43848C17.8457 5.43856 17.8456 5.43863 17.8454 5.43871L8.6391 10.0402L8.63903 10.0402C8.55797 10.0807 8.469 10.1021 8.3802 10.1021H3.73466C3.5677 10.1021 3.40567 10.0282 3.29423 9.89858C3.29419 9.89853 3.29415 9.89848 3.2941 9.89844L0.828359 7.02117L0.827649 7.02034C0.813728 7.00417 0.814245 6.97429 0.842447 6.95972C0.8426 6.95964 0.842753 6.95956 0.842906 6.95948L1.92731 6.41728L1.92731 6.41729L1.92941 6.41623C2.08025 6.33992 2.26287 6.33556 2.42019 6.40609L2.42143 6.40664L4.38491 7.28042L4.603 7.37747L4.81559 7.26891L8.13866 5.57195L8.91299 5.17653L8.18177 4.70615L3.22612 1.5182C3.22611 1.51819 3.22609 1.51819 3.22608 1.51818C3.21645 1.51197 3.21338 1.50692 3.21174 1.50356C3.20941 1.49879 3.20745 1.49139 3.20793 1.4822C3.20842 1.47302 3.21115 1.46578 3.21409 1.4611C3.21617 1.4578 3.22 1.45279 3.23062 1.44733C3.2308 1.44724 3.23098 1.44715 3.23116 1.44706L4.77768 0.67549L4.77919 0.67473C5.18892 0.468574 5.66815 0.444003 6.10364 0.605454C6.10389 0.605545 6.10414 0.605637 6.10438 0.605729L12.6781 3.0713ZM1.07958 15.5C0.758578 15.5 0.5 15.2414 0.5 14.9204C0.5 14.5994 0.758578 14.3408 1.07958 14.3408H20.5119C20.8329 14.3408 21.0915 14.5994 21.0915 14.9204C21.0915 15.2414 20.8329 15.5 20.5119 15.5H1.07958Z"
			fill="currentcolor" stroke="currentcolor" />
		<path
			d="M28.9613 4.2214C28.9613 3.8769 28.683 3.59857 28.3384 3.59857C27.9939 3.59857 27.7156 3.8769 27.7156 4.2214V7.02415H24.9129C24.5684 7.02415 24.29 7.30248 24.29 7.64698C24.29 7.99148 24.5684 8.26981 24.9129 8.26981H27.7156V11.0726C27.7156 11.4171 27.9939 11.6954 28.3384 11.6954C28.683 11.6954 28.9613 11.4171 28.9613 11.0726V8.26981H31.764C32.1085 8.26981 32.3869 7.99148 32.3869 7.64698C32.3869 7.30248 32.1085 7.02415 31.764 7.02415H28.9613V4.2214Z"
			fill="currentcolor" stroke="currentcolor" />
	</svg>
</template>
