import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';

interface Props {
	direction?: 'height' | 'width';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpandTransition',
  props: {
    direction: { default: 'height' }
  },
  setup(__props: any) {

const props = __props;

const isHtmlELement = (element: Element | null): element is HTMLElement => element instanceof HTMLElement;
const isHeight = computed(() => props.direction === 'height');
const maxProp = computed(() => isHeight.value ? 'maxHeight' : 'maxWidth');
const dimension = computed(() => isHeight.value ? 'scrollHeight' : 'scrollWidth');

const setElementStyle = <T extends HTMLElement>(element: T, size: string | number, overflow: string, maxPropValue: 'maxWidth' | 'maxHeight') => {
	element.style.overflow = overflow;
	element.style[maxPropValue] = String(size);
	if (isHeight.value) {
		element.offsetHeight;
	} else {
		element.offsetWidth;
	}
};

const enter = (element: Element) => {
	const size = element[dimension.value];
	if (!isHtmlELement(element)) return;
	setElementStyle(element, 0, 'hidden', maxProp.value);

	element.style[maxProp.value] = `${size + 5}px`;
};

const afterEnter = (element: Element) => {
	if (!isHtmlELement(element)) return;
	setElementStyle(element, '', '', maxProp.value);
};

const leave = (element: Element) => {
	const size = element[dimension.value];
	if (!isHtmlELement(element)) return;
	setElementStyle(element, `${size}px`, 'hidden', maxProp.value);

	element.style[maxProp.value] = '0';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "expand",
    onEnter: enter,
    onAfterEnter: afterEnter,
    onLeave: leave
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})