import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "form-field__label" }
const _hoisted_2 = {
  key: 0,
  class: "form-field__label-text"
}
const _hoisted_3 = {
  key: 0,
  class: "form-field__label-text--small-text"
}
const _hoisted_4 = ["value", "disabled", "placeholder", "readonly", "aria-label"]

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';

interface Props {
	selected?: string;
	readonly?: boolean;
	showModal?: boolean;
	label?: string;
	icon: string;
	placeholder?: string;
	board?: string | boolean;
	open?: boolean;
	allowClear?: boolean;
	showToggleIcon?: boolean;
	preventMousedown?: boolean;
	disabled?: boolean;
	preventCloseOpen?: boolean;
	customClearIcon?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormField',
  props: {
    selected: { default: '' },
    readonly: { type: Boolean, default: true },
    showModal: { type: Boolean, default: false },
    label: { default: '' },
    icon: {},
    placeholder: { default: 'Beliebig' },
    board: { type: [String, Boolean], default: false },
    open: { type: Boolean, default: false },
    allowClear: { type: Boolean, default: false },
    showToggleIcon: { type: Boolean, default: true },
    preventMousedown: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    preventCloseOpen: { type: Boolean },
    customClearIcon: { default: undefined }
  },
  emits: ['update:selected', 'FormField:Focus', 'FormField:Blur', 'FormField:Clear', 'toggle', 'key:enter'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const model = computed({
	get() {
		return props.selected;
	},
	set(newValue) {
		emit('update:selected', newValue);
	},
});

const isFocused = ref(false);

const isClosing = ref(false);

const input = ref<HTMLInputElement>();

const fieldClasses = computed(
	(): Record<string, any> => ({
		'is-readonly': props.readonly,
		'is-focused': isFocused.value,
		'is-modal-closing': isClosing.value,
		'is-modal-open': props.showModal,
	})
);

const onFocus = (): void => {
	isFocused.value = true;
	emit('FormField:Focus');
};

const onBlur = (): void => {
	isFocused.value = false;
	emit('FormField:Blur');
};

const mousedownHandler = (event: MouseEvent): void => {
	if (props.preventMousedown) {
		event.preventDefault();
	}
};

onMounted(() => {
	input.value = getCurrentInstance()?.proxy?.$refs.input as HTMLInputElement;
});

watch(
	() => props.showModal,
	() => {
		if (!props.showModal) {
			setTimeout(() => {
				isClosing.value = false;
			}, 300);
			isClosing.value = true;
		}
	}
);

__expose({
	input,
	model,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: ["form-field", fieldClasses.value]
  }, _ctx.$attrs, { onMousedown: mousedownHandler }), [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(BaseIcon, {
          key: 0,
          name: _ctx.icon,
          class: "form-field__icon"
        }, null, 8 /* PROPS */, ["name"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("label", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1 /* TEXT */),
            (_ctx.board)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (mind.)"))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("input", {
        ref_key: "input",
        ref: input,
        value: model.value,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (model.value = $event.target.value)),
        type: "text",
        class: "form-field__input",
        "data-lpignore": "true",
        maxlength: "100",
        disabled: _ctx.disabled,
        tabindex: -1,
        placeholder: _ctx.placeholder,
        readonly: _ctx.readonly,
        "aria-label": model.value,
        onFocus: onFocus,
        onBlur: onBlur,
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (emit('key:enter')), ["stop"]), ["enter"]))
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)
    ]),
    (_ctx.allowClear && model.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "form-field__close",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('FormField:Clear')), ["stop"]))
        }, [
          _createVNode(BaseIcon, {
            name: _ctx.customClearIcon ?? 'close',
            class: "form-field__clear-icon"
          }, null, 8 /* PROPS */, ["name"])
        ]))
      : (_ctx.showToggleIcon)
        ? (_openBlock(), _createBlock(BaseIcon, {
            key: 2,
            name: "chevron",
            class: _normalizeClass(["form-field__chevron-icon", { 'form-field__chevron-icon--open': _ctx.open }]),
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('toggle', $event)), ["stop"]))
          }, null, 8 /* PROPS */, ["class"]))
        : _createCommentVNode("v-if", true)
  ], 16 /* FULL_PROPS */))
}
}

})