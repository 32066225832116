import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "gallery-modal__header" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { class: "gallery-modal__body" }
const _hoisted_4 = { class: "swiper-wrapper" }
const _hoisted_5 = ["alt"]
const _hoisted_6 = { class: "gallery-modal__content-wrapper" }
const _hoisted_7 = {
  key: 1,
  class: "gallery-modal__video-wrapper"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  class: "gallery-modal__copyright"
}
const _hoisted_10 = { class: "swiper-wrapper" }
const _hoisted_11 = ["alt"]
const _hoisted_12 = ["src", "alt"]
const _hoisted_13 = ["src", "alt"]

import { getWebsiteLogoPath, getWebsiteName } from '@utils/environmentUtils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import {
	computed, nextTick, ref, watch, onBeforeUnmount,
	MaybeRef, unref, useTemplateRef,
} from 'vue';
import ImageWithPlaceholder from '@lmt-rpb/ImageWithPlaceholder/ImageWithPlaceholder.vue';
import { Swiper } from 'swiper/bundle';
import type { ClientType } from '@/interfaces/common';
import { GalleryElement } from '@/interfaces/components/galleryElement';
import 'swiper/scss';

type Props = {
	elements: GalleryElement[],
	index?: number,
	modelValue?: boolean,
	client?: ClientType,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GalleryModal',
  props: /*@__PURE__*/_mergeModels({
    elements: {},
    index: { default: 0 },
    modelValue: { type: Boolean, default: false },
    client: { default: 'at' }
  }, {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['slide', 'update:modelValue', 'close'], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = _useModel<boolean>(__props, "modelValue");
const mainSlideRefs = useTemplateRef('mainSlideRefs');

const altTextForLogo = getWebsiteName(props.client);

const modalLogo = computed((): string => `${getWebsiteLogoPath(props.client)}/img/logo.svg`);

const mainGalleryElement = useTemplateRef('mainGalleryElement');
const thumbnailGalleryElement = useTemplateRef('thumbnailGalleryElement');

const animate = (galleryRef: MaybeRef<InstanceType<Swiper>>, button: 'next' | 'prev') => {
	if (unref(galleryRef)) {
		const buttonElement = unref(galleryRef).navigation[`${button}El`];
		buttonElement.style.animationName = '';
		buttonElement.offsetWidth;
		buttonElement.style.animationName = 'hotel-gallery-arrow-bounce';
	}
};

function formatCopyrightString(text: string) {
	if (text.includes('©')) {
		return text;
	}
	if (text.includes('&copy;')) {
		return text.replace('&copy;', '©');
	}
	return `© ${text}`;
}

const getVideoSrc = (element: GalleryElement) => {
	if (element.type === 'youtube' || element.type === 'ytube') {
		return `https://www.youtube.com/embed/${element.content}?rel=0`;
	}
	if (element.type === 'vimeo') {
		return `https://player.vimeo.com/video/${element.content}`;
	}
	return element.content;
};

const slideNext = () => {
	animate(mainGallery, 'next');
	mainGallery.value?.slideNext();
};

const slidePrev = () => {
	animate(mainGallery, 'prev');
	mainGallery.value?.slidePrev();
};

const slideNextThumb = () => {
	animate(thumbGallery, 'next');
	mainGallery.value?.slideNext();
};

const slidePrevThumb = () => {
	animate(thumbGallery, 'prev');
	mainGallery.value?.slidePrev();
};

const close = () => {
	stopVideo(activeIndex.value);
	emit('close');
};

const handleKeyup = (event: KeyboardEvent) => {
	if (event.key === 'ArrowRight') {
		slideNext();
	}
	if (event.key === 'ArrowLeft') {
		slidePrev();
	}
	if (event.key === 'Escape') {
		close();
		window.removeEventListener('keyup', handleKeyup);
	}
};

const mainGallery = ref<InstanceType<Swiper>>(null);
const thumbGallery = ref<InstanceType<Swiper>>(null);

const activeIndex = ref(mainGallery.value?.activeIndex ?? 0);
const elementCount = computed(() => props.elements.length)

watch(() => model.value, () => {
	if (model.value) {
		nextTick(() => { // one tick delay
			document.body.style.overflow = 'hidden';
			if (!thumbGallery.value) {
				thumbGallery.value = new Swiper(thumbnailGalleryElement.value, {
					slidesPerView: 3,
					freeMode: true,
					spaceBetween: 10,
					watchSlidesProgress: true,
					breakpoints: {
						544: {
							slidesPerView: 6,
						},
						992: {
							slidesPerView: 10,
						},
						1300: {
							slidesPerView: 12,
						}
					},
					navigation: {
						nextEl: thumbnailGalleryElement.value?.querySelector('.gallery-modal__thumb-gallery-next'),
						prevEl: thumbnailGalleryElement.value?.querySelector('.gallery-modal__thumb-gallery-prev'),
					},
				});
			}
			if (!mainGallery.value) {
				mainGallery.value = new Swiper(mainGalleryElement.value, {
					slidesPerView: 1,
					centeredSlides: true,
					navigation: {
						nextEl: mainGalleryElement.value?.querySelector('.gallery-modal__main-gallery-next'),
						prevEl: mainGalleryElement.value?.querySelector('.gallery-modal__main-gallery-prev'),
					},
					thumbs: {
						swiper: thumbGallery.value,
					},
					on: {
						activeIndexChange(swiper: Swiper) {
							activeIndex.value = swiper.activeIndex;
						},
					}
				});
			}
			if (mainGallery.value && props.index !== mainGallery.value?.activeIndex) {
				mainGallery.value.slideTo(props.index, 0, false);
			}
		});
		window.addEventListener('keyup', handleKeyup);
	} else {
		document.body.style.overflow = 'auto';
		window.removeEventListener('keyup', handleKeyup);
	}
});

function stopVideo(index: number) {
	if (!mainSlideRefs.value) {
		return;
	}
	const embedElement = mainSlideRefs.value[index].querySelector('embed');
	if (embedElement) {
		embedElement.src = embedElement.src;
	}
}

watch(activeIndex, (_, oldVal) => {
	if (!mainSlideRefs.value) {
		return;
	}
	const embedElement = mainSlideRefs.value[oldVal].querySelector('embed');
	if (embedElement) {
		embedElement.src = embedElement.src;
	}
});

onBeforeUnmount(() => {
	document.body.style.overflow = 'auto';
	window.removeEventListener('keyup', handleKeyup);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["gallery-modal", {'is-visible': model.value}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "gallery-modal__logo",
        alt: _unref(altTextForLogo),
        src: modalLogo.value
      }, null, 8 /* PROPS */, _hoisted_2),
      _createVNode(RoundedButton, {
        class: "gallery-modal__close-button",
        theme: "outlined",
        "aria-label": "Close",
        onClick: close
      }, {
        default: _withCtx(() => [
          _createVNode(BaseIcon, {
            name: "close",
            class: "gallery-modal__close-icon"
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        ref_key: "mainGalleryElement",
        ref: mainGalleryElement,
        class: "swiper gallery-modal__main-slides"
      }, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              ref_for: true,
              ref_key: "mainSlideRefs",
              ref: mainSlideRefs,
              class: "swiper-slide",
              alt: element.alt
            }, [
              _createElementVNode("div", _hoisted_6, [
                (element.type === 'image')
                  ? (_openBlock(), _createBlock(ImageWithPlaceholder, {
                      key: 0,
                      src: element.content,
                      alt: `${element.alt} - ${index}`,
                      class: "gallery-modal__image",
                      title: `© GIATA GmbH 1996 - ${new Date().getFullYear()}`
                    }, null, 8 /* PROPS */, ["src", "alt", "title"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("embed", {
                        class: "gallery-modal__video",
                        src: getVideoSrc(element)
                      }, null, 8 /* PROPS */, _hoisted_8)
                    ])),
                (element.copyright)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(formatCopyrightString(element.copyright)), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ])
            ], 8 /* PROPS */, _hoisted_5))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["gallery-modal__main-gallery-prev swiper-button-prev", { 'hidden': activeIndex.value === 0 }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => {animate(mainGallery.value, 'prev');})
        }, [
          _createVNode(BaseIcon, { name: "chevronLeft" })
        ], 2 /* CLASS */),
        _createElementVNode("div", {
          class: _normalizeClass(["gallery-modal__main-gallery-next swiper-button-next", { 'hidden': activeIndex.value === _ctx.elements.length - 1 }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => {animate(mainGallery.value, 'next');})
        }, [
          _createVNode(BaseIcon, { name: "chevronRight" })
        ], 2 /* CLASS */)
      ], 512 /* NEED_PATCH */),
      (_ctx.elements)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref_key: "thumbnailGalleryElement",
            ref: thumbnailGalleryElement,
            class: "swiper gallery-modal__thumb-slides"
          }, [
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "swiper-slide",
                  alt: element.alt
                }, [
                  (element.type === 'image')
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: element.preview,
                        alt: `${element.alt} - ${index}`,
                        class: _normalizeClass(["gallery-modal__thumb-image", { 'gallery-modal__thumb-image--active': index === activeIndex.value}])
                      }, null, 10 /* CLASS, PROPS */, _hoisted_12))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["gallery-modal__thumb-video-preview", { 'gallery-modal__thumb-video-preview--active': index === activeIndex.value}])
                      }, [
                        _createElementVNode("img", {
                          class: _normalizeClass(["gallery-modal__thumb-image", { 'gallery-modal__thumb-image--active': index === activeIndex.value}]),
                          src: element.preview,
                          alt: `${element.alt} - ${index}`
                        }, null, 10 /* CLASS, PROPS */, _hoisted_13),
                        _cache[2] || (_cache[2] = _createElementVNode("img", {
                          src: "/fileadmin/2/restplatzboerse/all/img/playbutton.svg",
                          class: "gallery-modal__thumb-video-icon"
                        }, null, -1 /* HOISTED */))
                      ], 2 /* CLASS */))
                ], 8 /* PROPS */, _hoisted_11))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["gallery-modal__thumb-gallery-prev swiper-button-prev", { 'hidden': activeIndex.value === 0 }]),
              onClick: slidePrevThumb
            }, [
              _createVNode(BaseIcon, { name: "chevronLeft" })
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["gallery-modal__thumb-gallery-next swiper-button-next", { 'hidden': activeIndex.value === _ctx.elements.length - 1 }]),
              onClick: slideNextThumb
            }, [
              _createVNode(BaseIcon, { name: "chevronRight" })
            ], 2 /* CLASS */)
          ], 512 /* NEED_PATCH */))
        : _createCommentVNode("v-if", true)
    ])
  ], 2 /* CLASS */))
}
}

})