import { ref, onMounted, onUnmounted } from 'vue';

export function useGalleryListeners(imageSelector: string, callback: (index: number) => void) {
	const imageElements = ref<Array<HTMLElement>>([]);
	const clickListeners: Array<() => void> = [];
	const keyupListeners: Array<(event: KeyboardEvent) => void> = [];
	const imageListenersController = new AbortController();

	const createListener = (index: number): [() => void, (event: KeyboardEvent) => void] => {
		const clickListener = () => {
			callback(index);
		};
		const keyupListener = (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				callback(index);
			}
		};

		clickListeners.push(clickListener);
		keyupListeners.push(keyupListener);
		return [clickListener, keyupListener];
	};

	onMounted(() => {
		imageElements.value = Array.from(document.querySelectorAll(imageSelector));
		imageElements.value.forEach((element, index) => {
			const [clickListener, keyupListener] = createListener(index);
			element.addEventListener('click', clickListener, { signal: imageListenersController.signal });
			element.addEventListener('keyup', keyupListener,  { signal: imageListenersController.signal });
		});
	});

	onUnmounted(() => {
		imageListenersController.abort();
	});
}
