import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkout-info-flight-details" }
const _hoisted_2 = { class: "checkout-info-button checkout-info-flight-details__button" }

import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import FlightData from '@lmt-rpb/FlightData/FlightData.vue';
import CheckoutFlightDetails from '@lmt-rpb/CheckoutFlightDetails/CheckoutFlightDetails.vue';
import BaseModal from '@lmt-rpb/BaseModal/BaseModal.vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { ref } from 'vue';

type Props = {
	outbound: FlightSegment[];
	inbound: FlightSegment[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutInfoFlightDetails',
  props: {
    outbound: {},
    inbound: {}
  },
  setup(__props: any) {



const modalRef = ref<InstanceType<typeof BaseModal>>();
const openModal = (): void => {
	modalRef.value?.openModal();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FlightData, {
      "flight-segment": _ctx.outbound,
      outbound: true
    }, null, 8 /* PROPS */, ["flight-segment"]),
    _createVNode(FlightData, {
      "flight-segment": _ctx.inbound,
      outbound: false
    }, null, 8 /* PROPS */, ["flight-segment"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: "checkout-info-button__button",
        onClick: openModal
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "checkout-info-button__button-text" }, "Flugdetails anzeigen", -1 /* HOISTED */)),
        _createVNode(FaIcon, {
          icon: ['fa-solid', 'fa-chevron-right'],
          class: "checkout-info-button__button-arrow"
        })
      ]),
      _createVNode(BaseModal, {
        ref_key: "modalRef",
        ref: modalRef,
        "header-title": "Flugdetails"
      }, {
        default: _withCtx(() => [
          _createVNode(CheckoutFlightDetails, {
            "inbound-flight": _ctx.inbound,
            "outbound-flight": _ctx.outbound
          }, null, 8 /* PROPS */, ["inbound-flight", "outbound-flight"])
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)
    ])
  ]))
}
}

})