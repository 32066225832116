<template>
	<transition
		name="expand"
		@enter="enter"
		@after-enter="afterEnter"
		@leave="leave"
	>
		<div
			v-show="showContent"
			class="transition-expand"
		>
			<slot></slot>
		</div>
	</transition>
</template>

<script lang="ts" setup>
// source: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/

interface Props {
	showContent: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	showContent: false,
});

const forceRepaintTriggerAnimation = (element: HTMLElement, height: string): void => {
	void getComputedStyle(element).height;
	requestAnimationFrame(() => {
		element.style.height = height;
	});
};

const setElementStyles = (element: HTMLElement, styles: Partial<CSSStyleDeclaration>): void => {
	Object.assign(element.style, styles);
};

const enter = (element: HTMLElement): void => {
	const width = getComputedStyle(element).width;

	setElementStyles(element, {
		width,
		position: 'absolute',
		visibility: 'hidden',
		height: 'auto',
	});

	const height = getComputedStyle(element).height;

	setElementStyles(element, {
		width: '',
		position: '',
		visibility: '',
		height: '0',
	});

	forceRepaintTriggerAnimation(element, height);
};

const afterEnter = (element: HTMLElement): void => {
	element.style.height = 'auto';
};

const leave = (element: HTMLElement): void => {
	const height = getComputedStyle(element).height;
	element.style.height = height;
	forceRepaintTriggerAnimation(element, '0');
};
</script>

<style lang="scss" scoped>
.transition-expand {
	display: flow-root;
}

.expand-enter-active,
.expand-leave-active {
	transition: height 0.5s ease-in-out;
	overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
	height: 0;
}
</style>
