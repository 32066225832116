import type { StoreOptions } from 'vuex';
import { createStore } from 'vuex';
import { getFluxIbeUrl, deepMerge, getFluxApiUrl, determinePageType, getStornoValueByFlexCode } from '@utils/utils';
import { offsetDate } from '@utils/dateUtils';
import { determineClient, getBaseUrl, getDefaultCurrency, getIbeBaseUrl, getLocaleString } from '@utils/environmentUtils';
import type { SearchFormDataType, Config, Items, RootState, Proxies } from '@interfaces/search-form';
import type { ApiDataTypes, Types } from '@components/common/types/index';
import { DEFAULT_DURATION_FILTER } from '@global-js/constants';
import { EventBus } from '@global-js/event-bus';
import { boardTypes, roomTypes, transferTypes } from '@/js/data/index';
import type { MergedOfferItemData } from '@/interfaces/offer';
import { convertNumberToTime, dashedDate } from '@/js/utils/dateUtils';
import { defaultAirportCodes } from '@/js/data/airports';
import searchMask from './search-mask';
import checkout from './checkout';
import { paramsToForm, getTravelDurationByQuery } from '../services/transform';
import { getAllEnabled as getAllEnabledURLParam } from '../services/url';
import { initBreakpoints } from './breakpoints';
import type { MappedActions, MappedGetters, MappedMutations, MergedActions, MergedMutations, MergedState } from '../types/vuex';

const { protocol, hostname } = window.location;
const ibeBaseUrl = getIbeBaseUrl();

const storeSetup = {
	state() {
		return {
			updated: false,
			items: {
				departure: [],
			},
			bestOfferLoading: false,
			types: {
				board: boardTypes,
				room: roomTypes,
				transfer: transferTypes,
				operator: [],
				destination: [],
			},
			config: {
				storeInitialized: false,
				listenToBus: false,
				isDesktop: true,
				isMinTablet: true,
				isMinTabletLandscape: true,
				isMobileLarge: false,
				redirectOnSubmit: true,
				pageType: determinePageType(),
				ibeBaseUrl,
				apiBase: getFluxIbeUrl(),
				fluxApi: getFluxApiUrl(),
				suggestions: '/v1/search/suggest',
				searchUrl: `${protocol}//${hostname}`,
				ibeUrl: getIbeBaseUrl(),
				baseUrl: getBaseUrl(),
				alterFlights: '/v1/verify-package-offer',
				phone: '',
				phoneLink: '',
				locationType: '',
				client: '',
				offers: {
					packages: '/v1/package-offers',
					hotels: '/v1/hotel-offers',
				},
				offerCalendar: {
					package: '/v1/best-package-offer-for-departure-date',
					hotel: '/v1/best-accommodation-offer-for-check-in-date',
				},
				hotelBoxes: {
					package: '/v1/hotel-boxes',
					hotel: '/v1/hotel-boxes',
				},
				hotelList: {
					packages: '/v1/best-package-offer-for-hotel-list',
					hotels: '/v1/best-accommodation-offer-for-hotel-list',
				},
				regionList: '/v1/best-offer-for-region',
				touroperatorInventory: {
					packages: '/v1/package-touroperator-inventory',
					hotels: '/v1/accommodation-touroperator-inventory',
				},
				geoInventory: {
					packages: '/v1/package-geo-inventory',
					hotels: '/v1/accommodation-geo-inventory',
				},
				geoLocation: {
					packages: '/v1/best-package-offer-for-geo-location',
					hotels: '/v1/best-accommodation-offer-for-geo-location',
				},
				hotelOnly: false,
			},
			bestOffer: null,
			calendarDateChanged: false,
			tourOperatorLoading: true,
			destinationFilterLoading: true,
			proxies: {
				initialDestination: null,
			},
		};
	},

	getters: {
		getBoardByValue: () => (value: string) => boardTypes.find((type: { value: string }) => type.value === value),

		getCurrencyType: (state) => getDefaultCurrency(state.config.client),

		filter: (state) => state.searchMask.filter,

		getRoomByValue: () => (value: string) => roomTypes.find((type: { value: string }) => type.value === value),

		locationType: (state): string => state.config.locationType,

		submitData: (state) => (compState: SearchFormDataType) => {
			// duration is set to MaxDuration or defaults to DEFAULT_DURATION_FILTER[1]
			const duration = Math.max(...(compState.travelDuration || DEFAULT_DURATION_FILTER));
			return deepMerge(compState, {
				...(compState.departure.length
					? compState.departure
					: {
							departure: state.items.departure,
						}),
				...(!compState.filter
					? {}
					: {
							offerDuration: {
								from: compState.filter,
								to: offsetDate(compState.filter, duration),
							},
						}),
			});
		},

		apiData:
			() =>
			(data: SearchFormDataType): ApiDataTypes => {
				const RatingAttributes: string[] = [];
				const HotelAttributes: string[] = [];
				const defaultAirports = defaultAirportCodes[determineClient(window.location.href)].split(',');

				RatingAttributes.push(...data.mostPopularFilters.ratingAttributes);
				HotelAttributes.push(...data.mostPopularFilters.hotelAttributes);
				RatingAttributes.push(...data.additionalCheckboxFilters.ratingAttributes);

				return {
					CurrencyCode: getLocaleString('currency'),
					RoomViews: data.roomViews,
					HotelIffCode: data.hotelId,
					StartDate: data.offerDuration.from && dashedDate(data.offerDuration.from),
					DepartureAirport: !data.departure || !data.departure.length ? defaultAirports : data.departure,
					EndDate: data.offerDuration.to && dashedDate(data.offerDuration.to),
					Adults: data.travelers.adult,
					Children: data.travelers.children,
					MinDuration: data.travelDuration ? data.travelDuration[0] : DEFAULT_DURATION_FILTER[0], // default for 'Beliebig'
					MaxDuration: data.travelDuration ? data.travelDuration[1] || data.travelDuration?.[0] : DEFAULT_DURATION_FILTER[1], // default for 'Beliebig'
					BoardTypes: data.boardTypes,
					RoomTypes: data.roomTypes,
					Transfer: data.transferTypes,
					DirectFlight: data.directFlight,
					DefiniteStartDate: !!data.filter, // DefiniteStartDate only if filter is set in best-price component
					Countries: data.locationId,
					Regions: data.rid && data.rid.length > 0 ? data.rid : data.destinationTypes,
					GeoAreaFilter: data.geoAreaFilter && {
						LowerLeft: {
							Latitude: data.geoAreaFilter.lowerLeft.latitude,
							Longitude: data.geoAreaFilter.lowerLeft.longitude,
						},
						UpperRight: {
							Latitude: data.geoAreaFilter.upperRight.latitude,
							Longitude: data.geoAreaFilter.upperRight.longitude,
						},
					},
					RegionGroups: data.regionGroupIds,
					Cities: data.cyid && data.cyid.length > 0 ? data.cyid : data.destinationTypes,
					NumberOfResults: data.numberOfResults,
					SortOrder: data.sortingPricePopular ? 'Popular' : 'Price',
					HotelCategory: data.hotelCategory,
					MinMeanRecommendationRate: data.minMeanRecommendationRate,
					MaxPrice: data.maxPrice === 1600 ? null : data.maxPrice, // if maxPrice is the default of 1600 then we don't want a filter on price
					RatingAttributes,
					HotelAttributes,
					TourOperatorCodes: data.operatorTypes,
					TravelType: data.travelType,
					Storno: getStornoValueByFlexCode(data.flex),
					DealsOnly: !data.deal ? undefined : data.deal,
					DepartureTime:
						data.minDepartureTime || data.maxDepartureTime
							? {
									MinTime: convertNumberToTime(data.minDepartureTime) ?? '00:00',
									MaxTime: convertNumberToTime(data.maxDepartureTime) ?? '23:59',
								}
							: undefined,
					ArrivalTime:
						data.minArrivalTime || data.maxArrivalTime
							? {
									MinTime: convertNumberToTime(data.minArrivalTime) ?? '00:00',
									MaxTime: convertNumberToTime(data.maxArrivalTime) ?? '23:59',
								}
							: undefined,
				};
			},
	},

	modules: {
		searchMask,
		checkout,
	},

	actions: {
		initStore({ dispatch }) {
			const pageInfoSelector: HTMLElement | null = document.querySelector(
				'#search-landing-page, #hotel-list-data, #region-list-data, #typo-data, #region-seasons'
			);
			const globalInfo = (document.querySelector('#global-info') as HTMLElement)?.dataset;

			let initConfigValues = {
				storeInitialized: true,
				phone: globalInfo?.phone,
				phoneLink: globalInfo?.phoneLink,
				client: determineClient(window.location.href),
			};

			const seasonData = (document.querySelector('#season-data') as HTMLElement)?.dataset;

			if (pageInfoSelector) {
				const searchMaskData = { ...pageInfoSelector?.dataset };
				const query = getAllEnabledURLParam();
				let hotelData;
				if (typeof searchMaskData?.hoteldata === 'string') {
					hotelData = JSON.parse(searchMaskData.hoteldata);
					Object.assign(searchMaskData, {
						rgid: hotelData.Location.Region.TtRid,
						cyid: hotelData.Location.City.TtCyid,
						aid: hotelData.Hotel.IffCode.toString(),
						destinationName: hotelData.Hotel.Name,
					});
				}
				const travelDuration = getTravelDurationByQuery(query);

				let termin = searchMaskData.DepartureDate ?? searchMaskData.termin;
				let ruecktermin = searchMaskData.ReturnDate ?? searchMaskData.ruecktermin;

				const pageType = this.state.config.pageType;
				if (pageType === 'themePage') {
					termin = searchMaskData?.offerDuration?.from ? dashedDate(searchMaskData.offerDuration.from) : undefined;
					ruecktermin = searchMaskData?.offerDuration?.to ? dashedDate(searchMaskData.offerDuration.to) : undefined;
				}
				const hotelOnly =
					['hotel', 'Accommodation', 'Hotel'].includes(
						query.ibe ?? searchMaskData.TravelType ?? searchMaskData.travelType ?? seasonData?.ibe
					) || searchMaskData.onlyHotel === true;
				initConfigValues = {
					...initConfigValues,
					...{
						apiBase: searchMaskData.apiBase,
						hotelOnly,
						rgid: searchMaskData.rgid, // regiongroup ~= country?
						rid: searchMaskData.rid, // region
						cyid: searchMaskData.cyid, // city
						aid: searchMaskData.aid, // hotel
						termin: termin ?? seasonData?.ddate,
						ruecktermin: termin ?? seasonData?.rdate,
						locationType: searchMaskData.locationType,
						destinationName: searchMaskData.destinationName,
						country: searchMaskData.countryCode,
						displayMode: searchMaskData.displaymode,
					},
				};

				dispatch('initItems', {
					...{ departure: searchMaskData.departure },
					...(searchMaskData.region ? { region: searchMaskData.region } : {}),
				});

				dispatch('initFormData', {
					...{
						adult: searchMaskData.adult,
						aid: searchMaskData.aid,
						ddate: termin ?? seasonData?.ddate,
						depap: searchMaskData.depap,
						dur: travelDuration ?? seasonData?.dur,
						ibe: hotelOnly ? 'hotel' : 'package',
						rdate: ruecktermin ?? seasonData?.rdate,
						board: searchMaskData.board,
						room: searchMaskData.room,
						dfl: searchMaskData.dfl,
						filter: searchMaskData.filter,
						coname: searchMaskData.countryName,
						trname: searchMaskData.topRegionName,
						cyname: searchMaskData.cityName,
						hotelname: searchMaskData.destinationName,
						srtHot: '101',
					},
					...query,
				});

				EventBus.$emit('Store:Initialized');
			}

			type testkey = keyof typeof initConfigValues;
			Object.keys(initConfigValues).forEach(
				(key) => initConfigValues[key as testkey] === undefined && delete initConfigValues[key as testkey]
			);
			dispatch('initConfig', initConfigValues);
		},

		initConfig({ commit }, data: Partial<Config>) {
			if (Object.keys(data).length) {
				commit('updateConfig', data);
			}
		},

		initItems({ commit, getters }, data: Partial<Items>) {
			const parsed = Object.keys(data).reduce((acc, key) => {
				let values = data[key];
				// if comma separated list, split it
				if (/[,]/g.test(data[key]) && !Array.isArray(data[key])) {
					// split + trim
					values = data[key].split(',').map((value: string) => value.trim());
				}

				return { ...acc, [key]: values };
			}, {} as Items);

			if (Object.keys(parsed).length) {
				commit('updateItem', parsed);
			}
		},

		initFormData({ dispatch }, data) {
			if (data.rid && this.state.config.locationType === 'TOPREGION') {
				data.rid = undefined;
			}
			if (data.cyid && this.state.config.locationType === 'COUNTRY') {
				data.cyid = undefined;
			}
			const updated = paramsToForm(data);

			if (Object.keys(updated).length) {
				dispatch('setFormData', updated);
			}
		},

		updateTypes({ commit }, data: Partial<Types>) {
			if (Object.keys(data).length) {
				commit('UPDATE_TYPES', data);
			}
		},

		updateTourOperatorLoading({ commit }, data: boolean) {
			commit('UPDATE_TOUR_OPERATOR_LOADING', data);
		},

		updateDestinationFilterLoading({ commit }, data: boolean) {
			commit('UPDATE_DESTINATION_FILTER_LOADING', data);
		},
		updateProxies({ commit }, data: Partial<Proxies>): void {
			commit('UPDATE_PROXIES', data);
		},
	},

	mutations: {
		updateConfig(state, update: Partial<Config>) {
			state.config = { ...state.config, ...update };
		},

		UPDATE_TYPES(state, update: Partial<Types>) {
			state.types = { ...state.types, ...update };
		},

		updateItem(state, update: Partial<Items>) {
			state.items = { ...state.items, ...update };
		},

		updateBestOffer(state, update: Partial<MergedOfferItemData>) {
			state.bestOffer = update;
		},

		updateCalendarDateChanged(state, update: boolean) {
			state.calendarDateChanged = update;
		},

		UPDATE_TOUR_OPERATOR_LOADING(state, update: boolean) {
			state.tourOperatorLoading = update;
		},

		UPDATE_DESTINATION_FILTER_LOADING(state, update: boolean) {
			state.destinationFilterLoading = update;
		},

		UPDATE_PROXIES(state, update: Partial<Proxies>): void {
			state.proxies = { ...state.proxies, ...update };
		},
	},
} satisfies StoreOptions<RootState>;

type StoreSetupOptions = typeof storeSetup;
const $store = createStore(storeSetup) as unknown as {
	state: MergedState<StoreSetupOptions>;
	getters: MappedGetters<StoreSetupOptions>;
	dispatch: <L extends MergedActions<StoreSetupOptions>>(key: L, ...args: MappedActions<StoreSetupOptions, L>) => void;
	commit: <L extends MergedMutations<StoreSetupOptions>>(key: L, ...args: MappedMutations<StoreSetupOptions, L>) => void;
};
export type InitializedStore = typeof $store;
initBreakpoints($store);

// set singleton
global.$store = global.$store || $store;
export default global.$store;

export const useStore = () => $store;

$store.dispatch('initStore');
