import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { computed } from 'vue';

interface Props {
	inheritStyles?: boolean;
	remSize?: number;
	color?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CloseButton',
  props: {
    inheritStyles: { type: Boolean, default: false },
    remSize: { default: 2 },
    color: { default: 'currentcolor' }
  },
  setup(__props: any) {


const blockClassModifiers = computed(() => ({
	'close-button--inherit-styles': __props.inheritStyles,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    "aria-label": "Close",
    class: _normalizeClass(['close-button', blockClassModifiers.value])
  }, [
    _createVNode(FaIcon, {
      class: "close-button__icon",
      icon: ['fa-solid', 'fa-xmark'],
      style: _normalizeStyle({ fontSize: `${_ctx.remSize}rem`, color: _ctx.color })
    }, null, 8 /* PROPS */, ["style"])
  ], 2 /* CLASS */))
}
}

})