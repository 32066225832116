import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "travel-type-switch" }

import ChipBase from '@lmt-rpb/Atoms/ChipBase/ChipBase.vue';
import { useStore } from '@components/common/store/index';
import { computed } from 'vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import PackageIcon from './internals/PackageIcon.vue';
import { useTranslations } from '@helper/composables/translations';


export default /*@__PURE__*/_defineComponent({
  __name: 'TravelTypeSwitch',
  setup(__props) {

const store = useStore();
const { translate } = useTranslations();

const LABELS = computed(() => ({
	PACKAGE: translate('searchBarMain.travelType.package'),
	HOTEL_ONLY: translate('searchBarMain.travelType.hotelOnly'),
}));

const offerType = computed({
	get() {
		return store.state.searchMask.onlyHotel ? 'HOTEL_ONLY' : 'PACKAGE';
	},
	set(newValue) {
		const onlyHotel = newValue === 'HOTEL_ONLY';
		store.commit('searchMask/updateFormData', { onlyHotel });
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ChipBase, {
      modelValue: offerType.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((offerType).value = $event)),
      label: LABELS.value.PACKAGE,
      "icon-name": 'package',
      as: 'radio',
      value: "PACKAGE"
    }, {
      icon: _withCtx(() => [
        _createVNode(PackageIcon)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "label"]),
    _createVNode(ChipBase, {
      modelValue: offerType.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((offerType).value = $event)),
      label: LABELS.value.HOTEL_ONLY,
      "icon-name": 'room2',
      value: "HOTEL_ONLY",
      as: 'radio'
    }, {
      icon: _withCtx(() => [
        _createVNode(FaIcon, { icon: ['fa-solid', 'fa-bed'] })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "label"])
  ]))
}
}

})