import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "airport-filter__not-found"
}
const _hoisted_2 = {
  key: 1,
  id: "airport-container",
  class: "airport-filter"
}
const _hoisted_3 = {
  key: 0,
  class: "airport-filter__selected-options"
}
const _hoisted_4 = { class: "airport-filter__selected-options--thin" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "airport-filter__checkbox-label" }
const _hoisted_7 = { class: "airport-filter__checkbox-label--strong" }

import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import CheckBox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from '@/components/common/store';
import { type ClientType } from '@/interfaces/common';
import { type Airport, findAirportByKey, getMappedAirportOptions } from '../../composables/airportOptions';
import TransitionExpand from '@lmt-rpb/TransitionExpand/TransitionExpand.vue';
import type { GERMAN_COUNTRY_NAMES } from '@global-js/constants';
import ChipBase from '@lmt-rpb/Atoms/ChipBase/ChipBase.vue';

type Props = {
	disabled?: boolean;
	client?: ClientType;
	resetInsteadOfCancel?: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'AirportFilter',
  props: {
    disabled: { type: Boolean },
    client: { default: 'at' },
    resetInsteadOfCancel: { type: Boolean }
  },
  setup(__props: any) {

function removeSelection(value: string) {
	selectedOptionsProxy.value = selectedOptionsProxy.value.filter((val) => val !== value);
}
const props = __props;

const dropdown = ref<InstanceType<typeof DropdownFilterType>>();
const focusOut = () => {
	dropdown.value?.modal?.input?.blur();
	dropdown.value?.formField?.input?.blur();
	isFocused.value = false;
};
const focusIn = () => (isFocused.value = true);

const selectAirportInCountry = (airport: Airport, country: GERMAN_COUNTRY_NAMES) => {
	if (!selectedOptionsProxy.value.some((a) => airport.value === a)) {
		//expand if its selected
		toggleCountry(country, true);
	}
	clearTerm();
};
const toggleCountry = (country: GERMAN_COUNTRY_NAMES, value = !(expanded.value.get(country) ?? false)) => {
	expanded.value.set(country, value);
};

const expanded = ref(
	new Map(
		Array.from(getMappedAirportOptions(props.client)).map(([country], index) => {
			return [country, index === 0];
		})
	)
);

const isFocused = ref(false);

const store = useStore();
const sortedAirportsByCountry = getMappedAirportOptions(props.client);

const filteredItems = computed(() => {
	const t = filterExpression.value.toLowerCase().trim();
	return Array.from(sortedAirportsByCountry.entries())
		.map(([country, entries]) => [
			country,
			entries.filter(({ label, value }) => {
				return label.toLowerCase().startsWith(t) || value.toLowerCase().startsWith(t);
			}),
		])
		.filter(([, entries]) => entries.length > 0) as [GERMAN_COUNTRY_NAMES, Airport[]][];
});
const notFoundMessage = computed(() => (filteredItems.value.length === 0 ? 'Für deine Suche gibt es leider keine passenden Filter.' : ''));
const filterExpression = ref('');
const selectedOptions = computed(() => store.state.searchMask?.departure ?? []);

const selectedOptionsProxy = ref(selectedOptions.value);
const applyChanges = () => {
	manageCountryListOpenCloseState();
	store.commit('searchMask/updateFormData', { departure: selectedOptionsProxy.value });
};
const cancel = () => {
	selectedOptionsProxy.value = store.state.searchMask?.departure ?? [];
	manageCountryListOpenCloseState();
};

const clearTerm = () => (filterExpression.value = '');
const clearSelectedOptions = () => {
	selectedOptionsProxy.value = [];
};

const selectedAirports = computed(() => {
	const airports = Array.from(sortedAirportsByCountry.values()).flatMap((entries) => entries);
	return selectedOptionsProxy.value
		.map((entry) => airports.find((a) => a.value === entry))
		.filter((airport): airport is Airport => airport !== undefined);
});

const selectedOptionsConcat = computed(() => {
	return selectedOptions.value
		.map((key) => {
			const airport = findAirportByKey(key);
			return airport ? airport.label : key;
		})
		.join(', ');
});
const manageCountryListOpenCloseState = () => {
	const firstCountry = Array.from(expanded.value.keys())[0];
	Array.from(expanded.value.keys()).forEach((country) => {
		if (country !== firstCountry) {
			const countryEntries = sortedAirportsByCountry.get(country) || [];
			const hasSelectedAirports = countryEntries.some((airport) => selectedOptionsProxy.value.includes(airport.value));
			toggleCountry(country, hasSelectedAirports);
		}
	});
};

onMounted(() => {
	manageCountryListOpenCloseState();
});

watch(selectedOptions, () => {
	selectedOptionsProxy.value = selectedOptions.value;
	manageCountryListOpenCloseState();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownFilterType, {
    ref_key: "dropdown",
    ref: dropdown,
    "search-term": filterExpression.value,
    "onUpdate:searchTerm": _cache[1] || (_cache[1] = ($event: any) => ((filterExpression).value = $event)),
    class: "airport-filter",
    title: "Abflughafen",
    icon: "flightStart",
    "modal-title": "Flughafen",
    "apply-button-text": "Übernehmen",
    "cancel-button-text": "Zurücksetzen",
    "reset-instead-of-cancel": true,
    "selected-value": selectedOptionsConcat.value,
    "with-menu-header": false,
    "button-disabled": !!notFoundMessage.value,
    "with-cancel-button": true,
    "show-buttons": "",
    searchable: "",
    "emit-on-ok": "",
    "is-focused": isFocused.value,
    "show-toggle-icon": false,
    "onDropdownFilterType:apply": applyChanges,
    "onDropdownFilterType:abort": cancel,
    "onDropdownFilterType:clear": clearSelectedOptions,
    "onDropdownFilterType:focusedIn": focusIn,
    "onDropdownFilterType:focusedOut": focusOut
  }, {
    default: _withCtx(() => [
      (notFoundMessage.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(notFoundMessage.value), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (selectedAirports.value.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedAirports.value, ({ label, value }) => {
                    return (_openBlock(), _createBlock(ChipBase, {
                      key: value,
                      as: 'div',
                      "with-delete": true,
                      thin: true,
                      onClick: _withModifiers(() => removeSelection(value), ["stop"]),
                      onDeleteClick: _withModifiers(() => removeSelection(value), ["stop"])
                    }, {
                      label: _withCtx(() => [
                        _createTextVNode(_toDisplayString(label) + " ", 1 /* TEXT */),
                        _createElementVNode("span", _hoisted_4, "(" + _toDisplayString(value) + ")", 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "onDeleteClick"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", {
              class: _normalizeClass(["airport-filter-country-container", { 'has-selection': selectedAirports.value.length }]),
              onScroll: focusOut
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredItems.value, ([country, entries]) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: country,
                  class: _normalizeClass(["airport-filter-country", { 'filter-active': filterExpression.value }])
                }, [
                  (!filterExpression.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "airport-filter-country-name",
                        onClick: ($event: any) => (toggleCountry(country))
                      }, [
                        _createElementVNode("span", null, _toDisplayString(country), 1 /* TEXT */),
                        _createElementVNode("i", {
                          class: _normalizeClass(["airport-filter-country-name__icon fa-solid fa-lg", { ['fa-chevron-' + ((expanded.value.get(country) ?? false) ? 'up' : 'down')]: true }])
                        }, null, 2 /* CLASS */)
                      ], 8 /* PROPS */, _hoisted_5))
                    : _createCommentVNode("v-if", true),
                  _createVNode(TransitionExpand, {
                    "show-content": expanded.value.get(country) || !!filterExpression.value
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entries, (airport, index) => {
                        return (_openBlock(), _createBlock(CheckBox, {
                          key: airport.value,
                          modelValue: selectedOptionsProxy.value,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOptionsProxy).value = $event)),
                          label: airport.label,
                          "custom-value": airport.value,
                          class: _normalizeClass(["airport-filter__checkbox", { 'first-item': index === 0 }]),
                          onClick: ($event: any) => (selectAirportInCountry(airport, country))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_6, [
                              _createElementVNode("b", _hoisted_7, _toDisplayString(airport.label), 1 /* TEXT */),
                              _createTextVNode("  (" + _toDisplayString(airport.value) + ") ", 1 /* TEXT */)
                            ])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "label", "custom-value", "class", "onClick"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["show-content"])
                ], 2 /* CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ], 34 /* CLASS, NEED_HYDRATION */)
          ]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["search-term", "selected-value", "button-disabled", "is-focused"]))
}
}

})