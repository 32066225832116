<template>
	<div
		class="travel-duration-field"
		:class="{ 'duration-error': maxTravelDurationError }"
	>
		<DropdownFilterType
			ref="dropdownFilterType"
			title="Dauer"
			:with-menu-header="false"
			icon="clock"
			:selected-value="durationLabel"
			cancel-button-text="Verwerfen"
			ok-button-text="Übernehmen"
			:show-buttons="true"
			:emit-on-ok="true"
			:button-disabled="maxTravelDurationError"
			:has-error="maxTravelDurationError"
			:apply-button-text="'Übernehmen'"
			:show-toggle-icon="false"
			@DropdownFilterType:apply="onOk"
			@DropdownFilterType:clear="onCancel"
			@DropdownFilterType:abort="onCancel"
		>
			<TravelDurationContent
				v-model="travelDurationModel"
				:offer-duration-diff="totalDiffInDay"
				:error-message="maxTravelDurationError ? maxDurationErrorMessage : ''"
				@TravelDurationContent:PillChanged="handleDurationError"
			/>
		</DropdownFilterType>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { pluralize } from '@utils/utils';
import { dateDiff, offsetDate } from '@utils/dateUtils';
import { useStore } from '@/components/common/store';
import { EventBus } from '@global-js/event-bus';
import { TRAVEL_DURATION_EXACT } from '@global-js/constants';
import TravelDurationContent from './TravelDurationContent.vue';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { useBreakpointStore } from 'src/store/breakpointsStore';
import { storeToRefs } from 'pinia';

const maxDurationErrorMessage = 'Die gewählte Dauer kann nicht länger als 56 Tage sein. Bitte ändern Sie die Reisedaten.';

const store = useStore();

const { isDesktop } = storeToRefs(useBreakpointStore());

const dropdownFilterType = ref<InstanceType<typeof DropdownFilterType>>();

const selection = ref({
	isExactSelected: false,
	travelDuration: [] as number[],
});

const maxTravelDurationError = computed({
	get() {
		return store.state.searchMask.maxTravelDurationError;
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			maxTravelDurationError: value,
		});
	},
});

const offerDuration = computed({
	get() {
		const dur = store.state.searchMask.offerDuration;
		return {
			from: dur.from || 0,
			to: dur.to || 0,
		};
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			offerDuration: value,
			offerDurationRelative: { relativeFrom: '', relativeTo: '' },
		});
	},
});

const totalDiffInDay = computed((): number => dateDiff(offerDuration.value.from, offerDuration.value.to));

const isExact = computed({
	get() {
		return store.state.searchMask.isTravelDurationExactSelected;
	},
	set(newValue: boolean) {
		store.commit('searchMask/updateFormData', { isTravelDurationExactSelected: newValue });
		selection.value.isExactSelected = newValue;
	},
});

const travelDuration = computed({
	get() {
		return store.state.searchMask.travelDuration || [];
	},
	set(newValue: number[]) {
		store.commit('searchMask/updateFormData', { travelDuration: newValue });
		selection.value.travelDuration = newValue;
	},
});

const travelDurationModel = computed({
	get() {
		return {
			exact: selection.value.isExactSelected,
			duration: selection.value.travelDuration,
		};
	},
	set(newValue: { exact: boolean; duration: number[] }) {
		selection.value = {
			isExactSelected: newValue.exact,
			travelDuration: newValue.duration,
		};
	},
});

const getDaysDurationLabel = (duration: number[]) => {
	let result: string;
	if (duration.length === 1) {
		result = pluralize(Number(duration[0]), 'Tag', 'Tage');
	} else {
		result = `${duration.join('-')} Tage`;
	}
	return result;
};

const durationLabel = computed((): string => {
	let result = '';
	const duration = travelDurationModel.value.duration;
	const isBeliebig = duration.length === 0 || (duration[0] === 1 && duration[1] === 14);
	if (travelDurationModel.value.exact) {
		result = 'Exakt';
	} else if (!isBeliebig) {
		result = getDaysDurationLabel(duration);
	}
	return result;
});

const updateOfferDuration = (): void => {
	const offerFrom = offerDuration.value.from!;
	const min = travelDuration.value[0];
	if (totalDiffInDay.value < min) {
		const max = Math.max(...travelDuration.value);
		offerDuration.value = {
			from: offerDuration.value.from,
			to: offsetDate(offerFrom, max).getTime(),
		};
	}
};
const onOk = () => {
	if (maxTravelDurationError.value) {
		return;
	}
	isExact.value = selection.value.isExactSelected;
	travelDuration.value = selection.value.travelDuration;
	EventBus.$emit('TravelDuration:Changed');
	updateOfferDuration();
	store.commit('searchMask/updateFormData', { filter: '' });
};

const onCancel = () => {
	selection.value = {
		travelDuration: travelDuration.value,
		isExactSelected: isExact.value,
	};
	maxTravelDurationError.value = travelDuration.value[0] > 56 && isExact.value;
};

const handleDurationError = (pillValue: string, travelDurationDays: number) => {
	if (pillValue !== '') {
		maxTravelDurationError.value = pillValue === TRAVEL_DURATION_EXACT && travelDurationDays > 56;
	}
};

watch(travelDuration, (newValue) => {
	travelDuration.value = newValue;
});

watch(isExact, (newValue) => {
	isExact.value = newValue;
});

watch(isDesktop, () => {
	onCancel();
});

const openModalOrDropdown = () => {
	if (!maxTravelDurationError.value) {
		if (isDesktop.value && !dropdownFilterType.value?.desktopField?.open) {
			dropdownFilterType.value?.desktopField?.toggleDropdown();
		} else {
			setTimeout(() => dropdownFilterType.value?.open(), 500);
		}
	}
};
// we need this unfortunately because of multiple instances
const listenToBus = computed({
	get: () => store.state.config.listenToBus,
	set: (value: boolean) => store.commit('updateConfig', { listenToBus: value }),
});
onMounted(() => {
	if (!listenToBus.value) {
		listenToBus.value = true;
		EventBus.$on('OfferDurationField:OpenTravelDuration', openModalOrDropdown);
	}
	selection.value = {
		isExactSelected: isExact.value,
		travelDuration: travelDuration.value,
	};
});

onBeforeUnmount(() => {
	EventBus.$off('OfferDurationField:OpenTravelDuration', openModalOrDropdown);
	listenToBus.value = false;
});

defineExpose({
	selection,
	durationLabel,
});
</script>

<style lang="scss" scoped>
:deep() {
	.dropdown__container,
	.dropdown__inner {
		overflow: visible !important;
	}

	.dropdown__box {
		left: -13.1rem !important; // (box-width - parent element)/2 = (448px - 186px)/2 = -131px
		&:has(.inner-dropdown.is-open) {
			overflow: visible !important;
		}
	}

	.inner-dropdown__inner {
		z-index: 15;
	}
}

.travel-duration-content {
	height: 100vh;
}

:deep(.list .list__body) {
	overflow: initial;
}

:deep(.dropdown__box) {
	min-width: 45rem;
	transform: none;
	left: 0;

	&:has(.inner-dropdown.is-open) {
		overflow: visible !important;
	}
}

.travel-duration-field {
	:deep(.dropdown.is-open .dropdown__box) {
		width: 45rem;
		max-width: 45rem;
		overflow: hidden;
	}

	:deep(.form-field__icon) {
		@include visible-from($breakpoint-verysmall);

		flex-shrink: 0;
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 1rem;
		fill: $color-primary;
	}

	&.duration-error .is-open {
		:deep(.travel-duration-field__field) {
			border-color: $color-warning;
		}

		:deep(.dropdown__inner) {
			border-color: $color-warning;
		}

		:deep(.form-field.travel-duration-field__field::after) {
			border-color: $color-warning;
		}
	}

	.travel-duration-field__dropdown {
		// Hacky Firefox fix for range slider not going fully right
		:deep(.list__body) {
			overflow: initial;
		}

		:deep(.dropdown__inner) {
			overflow: initial;
		}
	}

	:deep(.travel-duration-field__field) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.travel-duration-modal {
	.travel-duration-modal__header {
		@include max-width(65rem);

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 3rem 1.5rem;
	}
}
</style>
