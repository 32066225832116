import type { Commit } from 'vuex';
import { deepMerge } from '@utils/utils';
import { dateDiff, offsetDate } from '@utils/dateUtils';
import type { FilterTypeState, RootState, SearchFormDataType } from '@interfaces/search-form';
import { DEFAULT_DURATION_FILTER } from '@global-js/constants';
import type { TravelType } from '@/interfaces/common';
import type { ApiDataTypesKeys } from '../types';
import type { BoardTypeValue } from '@/js/data/board-types';
import type { RoomTypeValue } from '@/js/data/room-types';

const today = new Date().setHours(0, 0, 0, 0);
const defaultOfferDurationStart = offsetDate(today, 1).getTime();
const defaultOfferDurationEnd = offsetDate(defaultOfferDurationStart, 45).getTime();

const defaultState: SearchFormDataType = {
	onlyHotel: false,
	sorting: false,
	directFlight: false,
	sortingPricePopular: true,
	isTravelDurationExactSelected: false,
	roomViews: [],
	destination: null,
	departure: [],
	travelDuration: [7],
	offerDuration: { from: defaultOfferDurationStart, to: defaultOfferDurationEnd },
	offerDurationRelative: { relativeFrom: '', relativeTo: '' },
	roomTypes: [],
	boardTypes: [],
	transferTypes: [],
	operatorTypes: [],
	destinationTypes: [],
	destinationTypeName: '',
	hotelCategory: 0,
	minMeanRecommendationRate: 0,
	maxPrice: 1600,
	minDepartureTime: 0,
	maxDepartureTime: 24,
	minArrivalTime: 0,
	maxArrivalTime: 24,
	rid: [],
	cyid: [],
	mostPopularFilters: { ratingAttributes: [], hotelAttributes: [] },
	additionalCheckboxFilters: { ratingAttributes: [] },
	travelers: {
		adult: 2,
		children: [],
	},
	searchTerm: '',
	filter: '',
	redirectFired: false,
	selectedGeoInventory: {
		RegionID: undefined,
		CityID: undefined,
		Name: '',
		Count: 0,
	},
	availableHotelCount: 0,
	maxTravelDurationError: false,
	airlines: [],
	flex: null,
	deal: false,
	board: {
		enabledFilters: new Set(),
		priceMap: new Map(),
		isFetchLoading: false,
		fetchError: null,
	},
	room: {
		enabledFilters: new Set(),
		priceMap: new Map(),
		isFetchLoading: false,
		fetchError: null,
	},
};

let savedState = defaultState; // copy of state for diff change detect
let savedCurrentState: SearchFormDataType;

export default {
	namespaced: true,
	state: () => ({ ...defaultState }),
	actions: {
		saveCurrentState: {
			handler({ state }: { state: SearchFormDataType }): void {
				savedCurrentState = JSON.parse(JSON.stringify(state));
			},
		},
		rollbackToSavedCurrentState: {
			handler({ commit }: { commit: Commit }): void {
				commit('updateFormData', savedCurrentState);
			},
		},
		resetFormData: {
			root: true,
			handler({ commit }: { commit: Commit }): void {
				const resetState = JSON.parse(JSON.stringify(defaultState));
				// Preserve Search Mask Settings
				(['destination', 'onlyHotel', 'offerDuration', 'travelers', 'sortingPricePopular'] as (keyof SearchFormDataType)[]).forEach(
					(key) => {
						resetState[key] = (window.$store.state.searchMask as SearchFormDataType)[key] as never;
					}
				);

				// Set travel duration to 'Beliebig'
				resetState.travelDuration = [...DEFAULT_DURATION_FILTER];

				// Initialize board
				resetState.board.priceMap = new Map();
				resetState.board.enabledFilters = new Set();

				// Initialize room
				resetState.room.priceMap = new Map();
				resetState.room.enabledFilters = new Set();

				commit('updateFormData', resetState);
			},
		},

		resetItem: {
			root: true,
			handler({ commit, state }: { commit: Commit; state: SearchFormDataType }, key: string): void {
				const resetState = JSON.parse(JSON.stringify(defaultState));

				if (key === 'travelDuration') {
					// Set travel duration to 'Beliebig'
					resetState.travelDuration = DEFAULT_DURATION_FILTER;
				}

				if (key === 'flight') {
					commit('updateFormData', {
						...state,
						minArrivalTime: 0,
						maxArrivalTime: 24,
						minDepartureTime: 0,
						maxDepartureTime: 24,
					});
					return;
				}

				if (key === 'roomtype') {
					commit('updateFormData', {
						...state,
						roomTypes: [],
					});
					return;
				}

				if (key === 'customerRating') {
					commit('updateFormData', {
						...state,
						additionalCheckboxFilters: {
							...state.additionalCheckboxFilters,
							ratingAttributes: [],
						},
					});
					return;
				}

				if (key === 'price') {
					commit('updateFormData', {
						...state,
						maxPrice: 1600,
					});
					return;
				}

				commit('updateFormData', {
					...state,
					[key]: (resetState as { [key: string]: any })[key],
				});
			},
		},

		setFormData: {
			root: true,
			handler({ commit, state }: { commit: Commit; state: SearchFormDataType }, data: SearchFormDataType): void {
				data.travelers = {
					...state.travelers,
					...data.travelers,
				};

				data.offerDuration = {
					...state.offerDuration,
					...data.offerDuration,
				};

				commit('setFormData', data);
			},
		},
	},

	getters: {
		travelType: (state: SearchFormDataType): string => (state.onlyHotel ? 'hotel' : 'package'),
		hasDealsFilter: (state: SearchFormDataType) => state.deal,
		travelTypeApiResponse: (state: SearchFormDataType): TravelType => (state.onlyHotel ? 'Accommodation' : 'Package'),
		submitData: (state: SearchFormDataType, getters: any, rootState: RootState, rootGetters: any) => rootGetters.submitData(state),
		flex: (state: SearchFormDataType): boolean => state.flex === '1',
		locationType: (state: SearchFormDataType, getters: any, rootState: RootState, rootGetters: any): string => rootGetters.locationType,

		apiData:
			(state: SearchFormDataType, getters: any, rootState: RootState, rootGetters: any) =>
			(override: Partial<SearchFormDataType>, exclude?: ApiDataTypesKeys[]) => {
				let data = override ? deepMerge(getters.submitData, override) : getters.submitData;
				data = rootGetters.apiData(data);

				if (exclude && exclude.length) {
					exclude.forEach((key) => {
						delete data[key];
					});
				}

				return data;
			},
	},

	mutations: {
		setFormData(state: SearchFormDataType, update: SearchFormDataType): void {
			const {
				offerDuration: { from, to },
			} = update;
			let fixed = update;
			// Check against zero (both are 0 if no offer duration is selected, e.g. on homepage initially)
			if (from !== 0 && to !== 0) {
				const travelDurationArray = update.travelDuration || state.travelDuration;

				let start = from;
				let end = to;
				// check if start date is not number or in the past
				// this check needs to be in front of the isTravelDurationValid check
				if (typeof start !== 'number' || !start || start <= today) {
					start = defaultOfferDurationStart;
				}

				const offerDurationInDays = dateDiff(start!, end!);

				// Check if travel duration or one of the travel duration range fits into offer duration
				const minTravelDuration = Math.min(...(travelDurationArray || [0]));
				const isTravelDurationValid = minTravelDuration <= offerDurationInDays;

				// check if end date is not number, smaller than today or start date and check if offer duration is smaller than travel duration
				if (typeof end !== 'number' || !end || end <= today || end < start || !isTravelDurationValid) {
					end = offsetDate(start, 45).getTime();
				}

				fixed = { ...update, offerDuration: { from: start, to: end } };
			}

			Object.keys(fixed).forEach((key: string) => {
				state[key] = fixed[key as keyof SearchFormDataType];
			});

			savedState = JSON.parse(JSON.stringify(state));
		},

		updateFormData(state: SearchFormDataType, update: Partial<SearchFormDataType>): void {
			Object.keys(update).forEach((key: string) => {
				if (key === 'destinationTypes') {
					if (this.getters.locationType === 'COUNTRY' || this.getters.locationType === 'REGIONGROUP') {
						state.rid = update[key];

						state[key] = update[key];
					}
					if (this.getters.locationType === 'TOPREGION') {
						state.cyid = update[key];

						state[key] = update[key];
					}
				} else {
					// If travelDuration is set to 'Beliebig', update savedState.travelDuration with the current values.
					// Otherwise you will be redirected to the same site on regionPages.
					if (
						update.travelDuration &&
						update.travelDuration[0] === DEFAULT_DURATION_FILTER[0] &&
						update.travelDuration[1] === DEFAULT_DURATION_FILTER[1]
					) {
						const currentDuration = $store.state.searchMask.travelDuration;

						if (currentDuration && currentDuration.length) {
							savedState.travelDuration = currentDuration;
						}
					}

					state[key] = update[key];
				}
			});
		},

		updateBoard(state: SearchFormDataType, update: FilterTypeState<BoardTypeValue>) {
			state.board = { ...update };
		},

		updateRoom(state: SearchFormDataType, update: FilterTypeState<RoomTypeValue>) {
			state.room = { ...update };
		},

		updateFlex(state: SearchFormDataType, update: boolean) {
			state.flex = update ? '1' : null;
		},

		getFlex(state: SearchFormDataType) {
			return state.flex || null;
		},
		updateAvailableHotelCount(state: SearchFormDataType, update: number): void {
			state.availableHotelCount = update;
		},
	},
};
