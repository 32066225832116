<template>
	<section
		v-if="storeLoaded"
		class="quickpill-list rpb_main-content--no-sidepadding"
	>
		<div class="quickpill-list__container">
			<ComponentHeader :header-props="headerProps" />
			<ul class="quickpill-list__pills">
				<QuickPill
					v-for="(pill, index) in limitedList"
					:key="pill.destination"
					:destination="pill.destination"
					:price="pill.price"
					:currency="currency"
					:url="pill.url"
					:title-tag="pill.titleTag"
					:link-target="pill.linkTarget"
					@click="emit('pill-click', index)"
				/>
				<li v-if="showButton && !showAll">
					<button
						class="quickpill-list__button"
						@click="showAll = true"
					>
						<h3 class="quickpill-list__headline">
							{{ expandText }}
						</h3>
					</button>
				</li>
			</ul>
		</div>
	</section>
</template>

<script lang="ts" setup>
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import QuickPill from '@lmt-rpb/QuickPill/QuickPill.vue';
import { addTopMarginToFirstElement } from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import { computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from '@/components/common/store';
import type { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import type { QuickPillType } from './types';

const store = useStore();

interface Props {
	headerProps: HeaderProps;
	pills: QuickPillType[];
	limit?: number;
	expandText?: string;
	enableEventToggling?: boolean;
	currency?: string;
}

const props = withDefaults(defineProps<Props>(), {
	limit: 7,
	expandText: 'Mehr anzeigen',
	enableEventToggling: false,
	currency: 'EUR',
});

const emit = defineEmits<{
	(event: 'pill-click', index: number): void;
}>();

const showAll = ref(false);

const storeLoaded = ref(false);

const limitedList = computed((): QuickPillType[] => (showAll.value ? props.pills : props.pills.slice(0, props.limit)));

const showButton = computed((): boolean => props.pills.length > props.limit);

const isStoreInitialized = computed((): boolean => store?.state?.config?.storeInitialized);

const addMarginTop = (): void => {
	addTopMarginToFirstElement(getCurrentInstance()?.proxy?.$el as HTMLElement);
};

const showAllPills = () => {
	showAll.value = true;
};

onBeforeMount(() => {
	// Workaround for initializing quickpills before store
	if (!isStoreInitialized.value) {
		storeLoaded.value = true;
		addMarginTop();
	} else {
		storeLoaded.value = true;
	}

	if (props.enableEventToggling) {
		EventBus.$on('Destination:OpenQuickPills', showAllPills);
	}
});

onMounted(() => {
	if (storeLoaded.value) {
		addMarginTop();
	}
});

onBeforeUnmount(() => {
	EventBus.$off('Store:Initialized', () => {
		storeLoaded.value = false;
	});

	if (props.enableEventToggling) {
		EventBus.$off('Destination:OpenQuickPills', showAllPills);
	}
});
</script>

<style lang="scss" scoped>
.quickpill-list {
	min-height: 21.8rem;

	&--margin-top {
		margin-top: $vgrid-mobile-first-element-top-margin;
	}

	&__container {
		margin: auto;
		max-width: 144rem;
		padding-bottom: $vgrid-mobile-large;
	}
}

ul {
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		width: 100%;
	}
}

.quickpill-list__pills {
	text-align: center;
	margin-top: -0.8rem;
}

.quickpill-list__headline {
	font-size: 2.1rem;
	font-weight: $font-weight-bold;
	font-family: $font-family;
	color: $color-primary;
	margin: 0.5rem 0.8rem 0.3rem 0.2rem;
	padding: 0;
	background: $color-page-background;
	display: inline-block;
	transition: all 0.3s ease;
	white-space: nowrap;

	@include textEllipsis(auto);

	vertical-align: middle;
	line-height: 1.3;
	flex: 1 1 auto;
	text-align: left;
}

.quickpill-list__button {
	position: relative;
	width: 100%;
	margin: 0 0.3rem;
	text-decoration: none;
	border: none;
	border-bottom: 0.1rem dashed $color-lime-light;
	box-sizing: border-box;
	text-align: center;
	background: $color-page-background;
	padding: 0.5rem 1rem 0.8rem;
	transform: translate(0, 0);
	transition: all 0.3s ease;
	display: flex;

	&:hover,
	&:active,
	&:focus {
		outline: 0;
		background: $color-light-gray;
		box-shadow: 0 0.1rem 0 0 $color-primary-l6;
		cursor: pointer;

		.quickpill-list__headline {
			color: $color-primary-l1;
			background: $color-light-gray;
			outline: 0;
		}
	}
}

.quickpill-list__icon-arrow {
	fill: $color-primary;
	height: 1.7rem;
	width: auto;
	margin: 0.2rem 0 0;
	align-self: center;
}

/* Mobile landscape */
@media (min-width: $breakpoint-verysmall) {
	.quickpill-list__headline {
		font-size: $font-large-3;
		margin: 0.3rem 0 0.3rem 0.2rem;
	}
}

/* Tablet portrait */
@media (min-width: $breakpoint-small) {
	.quickpill-list {
		&--margin-top {
			margin-top: $vgrid-desktop-first-element-top-margin;
		}

		&__container {
			padding-bottom: $vgrid-desktop-large;
		}
	}

	.quickpill-list__headline {
		font-size: 3.5rem;
		font-weight: $font-weight-bold;
		color: $color-primary;
		margin: 0;
		outline: 0;
		padding: 0.4rem 1.8rem 0;
		transition: all 0.3s ease;
		max-width: 60rem; // Fix for too long region names.

		&:hover,
		&:active,
		&:focus {
			overflow: visible;
			background: $color-page-background;
		}
	}

	.quickpill-list__button {
		width: auto;
		margin: 1.4rem 0.5rem 0;
		padding: 0 1.8rem 0.7rem;
		display: inline-block;
		border-radius: $border-radius-button;
		border-bottom: 0.1rem solid $color-primary-l4;
		background: $color-page-background;
		box-shadow: 0 -0.1rem 0 0 $color-primary-l6;

		&:hover,
		&:active,
		&:focus {
			overflow: visible;
			background: #fff;
			border-bottom: 0.1rem solid $color-primary-l1;
			box-shadow: 0 -0.1rem 0 0 $color-primary-l6;

			.quickpill-list__headline {
				color: $color-primary-l1;
				background: #fff;
				outline: 0;
				transform: translate(0.1rem, -0.1rem);
			}
		}
	}

	.quickpill-list__icon-arrow {
		display: none;
	}

	ul > li {
		width: auto;
	}
}

@media (min-width: $breakpoint-small) {
	.quickpill-list {
		min-height: 24.8rem;
	}
}

/* Medium Screens */
@media (min-width: $breakpoint-medium) {
	.quickpill-list__headline {
		max-width: none;
	}

	.quickpill-list__button {
		display: inline-block;
	}
}

@media (min-width: $breakpoint-extralarge) {
	.quickpill-list {
		min-height: 17.4rem;
	}
}
</style>
