<template>
	<div
		:class="['filter-row', containerClasses]"
		role="radio"
		:aria-checked="isSelected"
		:aria-label="label"
		@click="updateModel"
		@keyup.enter="updateModel"
	>
		<p class="filter-row__label">
			<b
				v-if="boldLabel"
				class="filter-row__bold-label"
			>
				{{ boldLabel }}
			</b>
			{{ label }}
		</p>
	</div>
</template>

<script lang="ts" setup generic="T extends string | number | undefined">
import { computed } from 'vue';

interface Props {
	id: string;
	value: T;
	disabled?: boolean;
	label?: string;
	boldLabel?: string;
}

const props = withDefaults(defineProps<Props>(), {
	id: 'default',
	disabled: false,
	label: '',
	boldLabel: undefined,
});

const containerClasses = computed(() => {
	return {
		'filter-row--disabled': props.disabled,
		'filter-row--selected': isSelected.value,
	};
});

const model = defineModel<T>();
const isSelected = computed(() => model.value === props.value);
const updateModel = () => {
	if (props.disabled) return;
	model.value = props.value;
};
</script>

<style lang="scss" scoped>
.filter-row {
	padding: 0.8rem 0.8rem 0.8rem 1.6rem;
	cursor: pointer;

	&:hover {
		background-color: $color-hover-light;
	}

	&:focus,
	&:focus-visible {
		color: $color-primary-l1;
		outline: none;
	}

	&__label {
		font-size: $font-small-1;
		line-height: $line-height-text;
		font-weight: $font-weight-regular;
		cursor: inherit;
		margin-bottom: 0;
		max-width: 23rem;
	}

	&__bold-label {
		font-weight: $font-weight-semibold;
		cursor: inherit;
	}

	&--selected {
		background-color: $color-destination-filter-selected;

		&:hover,
		&:focus,
		&:focus-visible {
			background-color: $color-destination-filter-selected;
		}

		.filter-row__label {
			color: $dropdown-active-color;
		}
	}

	&--disabled {
		color: $color-state-disabled;
		cursor: default;
		pointer-events: none;
	}
}
</style>
