<template>
	<div>
		<div class="flight-details__segment">
			<div class="flight-details__info">
				<div class="flight-details__flight">
					<div class="flight-details__departure">
						<div class="flight-details__timeline-start"></div>
						<p class="flight-details__time flight-time">{{ getTime(segment.Departure) }}</p>
						<p class="flight-city">
							{{ segment.Departure.Airport }}
							<span class="flight-city__code"> ({{ segment.Departure.AirportCode }}) </span>
						</p>
					</div>
					<div class="flight-details__airline">
						<div
							class="flight-details__timeline-track"
							:class="{ 'flight-details__timeline-track--verified': segment.Departure.Date }"
						></div>
						<span v-if="segment.Airline">
							{{ segment.Airline }}<span v-if="segment.FlightNumber"> ({{ segment.FlightNumber }})</span>,&nbsp;</span
						>
						<span>{{ segment.CabinClass }}</span>
					</div>
					<div class="flight-details__arrival">
						<div class="flight-details__timeline-end"></div>
						<p class="flight-details__time flight-time">
							{{ getTime(segment.Arrival) }}
							<span
								v-if="updateDate"
								class="update-date"
							>
								+1 Tag
							</span>
						</p>
						<p class="flight-city">
							{{ segment.Arrival.Airport }}
							<span class="flight-city__code"> ({{ segment.Arrival.AirportCode }}) </span>
						</p>
					</div>
				</div>
				<div class="flight-details__baggage">
					<div>
						<FaIcon
							:icon="['fa-solid', 'fa-suitcase']"
							name="briefcase"
							class="icon"
						/>
						Freigepäck pro Person
					</div>
					<span class="flight-details__baggage-tooltip">
						{{ getAllowanceText(segment.BaggageAllowance) }}
						<InfoTooltip placement="bottom">
							{{ getTooltipText(segment.BaggageAllowance) }}
						</InfoTooltip>
					</span>
				</div>
			</div>
		</div>
		<div
			v-if="segment.StopOver"
			class="flight-details__stopover"
		>
			{{ convertSeconds(segment.StopOver.StopoverDuration) }} Aufenthalt
		</div>
		<div
			v-if="updateDate"
			class="flight-details__newdate"
		>
			{{ updateDate }}
		</div>
	</div>
</template>

<script lang="ts" setup>
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { convertSeconds } from '@utils/utils';
import { formatDate, formatDateLong } from '@utils/dateUtils';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import type { BaggageAllowance, Juncture } from '@/interfaces/common';
import type { AirportData } from '@/interfaces/offer';
import { computed } from 'vue';
interface Props {
	segment: FlightSegment;
	isRight?: boolean;
}

const props = defineProps<Props>();
const isAiportData = (aiport: Juncture | AirportData): aiport is AirportData => 'time' in aiport;

const getAllowanceText = (baggage: BaggageAllowance | undefined): string => {
	if (!baggage || !(baggage.MaxWeight || baggage.MaxBags)) {
		return 'Gepäck inklusive';
	}
	const { MaxBags, MaxWeight } = baggage;
	if (MaxBags && MaxWeight) {
		return baggage.MaxBags + ' x ' + baggage.MaxWeight + 'kg inklusive';
	}

	if (MaxWeight) {
		return '1 x ' + baggage.MaxWeight + 'kg inklusive';
	}

	return MaxBags + ' Gepäck inkl.';
};

const getTooltipText = (baggae: BaggageAllowance | undefined) => {
	if (!baggae || !baggae.MaxWeight) {
		return 'Der Veranstalter liefert uns leider keine Informationen zum Freigepäck. Dieses beträgt ca. 20kg und kann je nach Airline auch weniger oder mehr betragen.';
	}
	return 'Bitte beachten Sie, dass die Angaben zu Freigepäckmengen in Ausnahmefällen abweichen können.';
};

const updateDate = computed((): string | null => {
	if (props.segment.StopOver && props.segment.Departure.Date) {
		const departureDate = new Date(props.segment.Departure.Date).toDateString();
		const stopoverEndDate = new Date(props.segment.StopOver.StopoverEnd).toDateString();
		if (departureDate !== stopoverEndDate) {
			return formatDateLong(props.segment.StopOver.StopoverEnd);
		}
	}
	return null;
});

const getTime = (airport: Juncture | AirportData): string => {
	let time = '';
	if (isAiportData(airport)) {
		if (airport.time) {
			time = airport.time;
		}
	}

	if (!time && airport.Date) {
		time = `${formatDate(airport.Date, { hour: '2-digit', minute: '2-digit' })}`;
	}

	return time;
};
</script>

<style lang="scss" scoped>
.flight-details {
	&__info {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		gap: 1.6rem 0;

		div {
			display: flex;
		}
	}

	&__flight {
		display: flex;
		flex-direction: column;

		.flight-time {
			font-size: $font-small-1;
			font-weight: $font-weight-semibold;
			margin-bottom: 0;
			white-space: nowrap;
		}

		.flight-city {
			font-size: $font-small-1;
			font-weight: $font-weight-semibold;
			margin: 0;
		}
	}

	&__timeline-start,
	&__timeline-end,
	&__timeline-airline {
		align-self: stretch;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-inline-end: 0.7rem;
	}

	&__timeline-start,
	&__timeline-end {
		padding-top: 0.9rem;

		&::before {
			background: $color-black;
			border-radius: 100%;
			content: '';
			display: block;
			height: 0.7rem;
			width: 0.7rem;
		}
	}

	&__airline {
		span {
			font-size: $font-small-2;
			padding: 1.6rem 0;
			line-height: $line-height-text;
			font-weight: $font-weight-regular;
		}
	}

	&__timeline-track {
		display: flex;
		inset-inline-start: 0.3rem;
		position: relative;
		padding-inline-end: 3rem;
		margin-top: -0.5rem;
		margin-bottom: -0.8rem;
		min-height: 5.3rem;

		&::after {
			background-color: $color-black;
			content: '';
			position: relative;
			width: 0.125rem;
		}

		&--verified {
			padding-inline-end: 7rem;
		}
	}

	&__timeline-end {
		&::before {
			background: $color-white;
			border: 1px solid $color-black;
		}
	}

	&__time {
		margin-right: 1.6rem;
	}

	&__baggage {
		flex-direction: column;
		font-weight: $font-weight-semibold;
		font-size: $font-small-2;

		div {
			align-items: center;
			margin-left: -0.8rem;
			line-height: 1.6;
		}

		.icon {
			font-size: $font-small-1;
			margin-right: 0.8rem;
		}

		&-tooltip {
			margin: 0.8rem 0 0 1.4rem;
			font-weight: $font-weight-regular;

			:deep(.info-tooltip) {
				margin-left: 0.8rem;
				color: $color-primary;
			}
		}
	}

	&__departure,
	&__arrival {
		align-items: baseline;
	}

	.flight-city__code {
		font-weight: $font-weight-regular;
	}

	&__newdate {
		padding: 1.6rem 1.6rem 0;
		font-size: $font-small-1;
		font-weight: $font-weight-regular;
	}

	.update-date {
		font-size: $font-small-3;
		vertical-align: super;
	}

	&__stopover {
		margin-top: 1.6rem;
		margin-left: 0.8rem;
		padding: 0.8rem 1.6rem;
		background-color: $color-light-gray;
		border: 0.1rem solid $color-search-mask-border;
		max-width: fit-content;
		border-radius: $border-radius-small;
		font-size: $font-small-2;
		font-weight: $font-weight-semibold;
	}

	&__segment {
		display: flex;
		flex-direction: column;
		margin-top: 1.6rem;
		margin-left: 1rem;
	}
}

:deep(#tooltip) {
	max-width: 48.2rem;
	max-height: 11.5rem;

	@media (max-width: $breakpoint-verysmall - 1px) {
		max-width: $breakpoint-verysmall;
	}

	@media (min-width: $breakpoint-verysmall) and (max-width: $breakpoint-small - 1px) {
		max-width: calc($breakpoint-small / 2);
	}
}

@media (min-width: $breakpoint-verysmall) {
	.flight-details {
		&__info {
			flex-direction: row;
		}

		&__baggage {
			div {
				margin-left: 0;
			}

			&-tooltip {
				margin-left: 2.4rem;
			}
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.flight-details {
		&__stopover {
			font-size: $font-small-1;
		}

		&__baggage {
			font-size: $font-small-1;
		}

		&__flight {
			.flight-city {
				font-size: $font-medium-3;
				line-height: 1.27;
			}
		}
	}
}
</style>
