import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const watchBreakpoint = (breakpoint: string) => {
	const _matches = ref(false);
	const mQ = window.matchMedia(`(min-width: ${breakpoint})`);
	const queryWatcher = (media: MediaQueryListEvent | MediaQueryList) => {
		_matches.value = media.matches;
	};
	queryWatcher(mQ);
	mQ.onchange = queryWatcher;
	return _matches;
};
export const STORE_BREAKPOINTS = {
	MOBILE_LARGE: '543px',
	TABLET: '768px',
	TABLET_LANDSCAPE: '992px',
	DESKTOP: '1300px',
} as const;

export const useBreakpointStore = defineStore('breakpoints', () => {
	const _isDesktop = watchBreakpoint(STORE_BREAKPOINTS.DESKTOP);
	const _isMinTablet = watchBreakpoint(STORE_BREAKPOINTS.TABLET);
	const _isMinTabletLandscape = watchBreakpoint(STORE_BREAKPOINTS.TABLET_LANDSCAPE);
	const _isMobileLarge = watchBreakpoint(STORE_BREAKPOINTS.MOBILE_LARGE);

	const isDesktop = computed(() => _isDesktop.value);
	const isMinTablet = computed(() => _isMinTablet.value);
	const isMinTabletLandscape = computed(() => _isMinTabletLandscape.value);
	const isMobileLarge = computed(() => _isMobileLarge.value);
	const isSmallMobile = computed(() => !_isMobileLarge.value);
	const isMobile = computed(() => !_isMinTablet.value);

	return { isMinTablet, isMinTabletLandscape, isMobileLarge, isSmallMobile, isMobile, isDesktop };
});
