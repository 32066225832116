import { getIbeBaseUrl, getBaseUrl } from '@utils/environmentUtils';
import { amadeusImageGroup } from '@/js/utils/utils';
import { getSearchParams } from '@/js/modules/updateUrls';
import type { PersistedHotelItem, LastSeenHotel, TimeStampedLastSeenHotel } from '@/interfaces/localStorage/last-seen-hotel';
import * as hotelListService from './hotelListService';
import Cookies from 'js-cookie';

// CODE TO BE REMOVED
const deleteLegacyCookie = () => {
	const cookieExists = Boolean(Cookies.get('rpb_lastSeenHotels'));
	const parts = location.hostname.split('.');
	const domainEnd = parts[parts.length - 1];
	const client = parts[parts.length - 2];
	if (!cookieExists) return;
	Cookies.set('rpb_lastSeenHotels', 'void(0)', {
		expires: -180,
		domain: `.${client}.${domainEnd}`,
		secure: true,
		sameSite: 'strict',
	});
};
// END OF CODE TO BE REMOVED

/**
 * Adds the hotel link & image to the hotel object.
 * @param array list
 * @return Array of hotels.
 */
const addInfo = (list: PersistedHotelItem[]): LastSeenHotel[] => {
	const today = new Date();

	return list.map(function (hotel) {
		const rawParams = hotel.urlparams,
			params = new URLSearchParams(rawParams),
			rgid = params.get('rgid');

		let deeplink: string,
			image: string,
			paramsString = '';

		if (rawParams) {
			// If ddate is today or older, don't add ddate & rdate to urlparams.
			const departureDate = new Date(params.get('ddate') as string);

			if (rawParams && today >= departureDate) {
				params.delete('ddate');
				params.delete('rdate');
			}
		}

		paramsString = rawParams ? `?${params.toString()}` : '';

		// If rgid matches one of the following we link to the amadeus IBE
		// Kreuzfahrten rgid=40042
		// Rundreisen rgid=40045
		// Additionally checking the aid if we don't receive an rgid.
		// If the aid has a length of 5 or more and starts with 1000 it is most likely one of the above.
		if (rgid === '40042' || rgid === '40045' || (hotel.id.length > 5 && hotel.id.substr(0, 4) === '1000')) {
			deeplink = getIbeBaseUrl() + `/offer${paramsString}`;
			image = 'https://de.images.traveltainment.eu/images/content/booking_thumbs_s_gr/';
			image += `${amadeusImageGroup(hotel.id)}/THB_999_H${hotel.id}.jpg`;
		} else {
			deeplink = getBaseUrl() + `/hotel/${hotel.id}/${paramsString}`;
			image = `${process.env.fluxImagesUrl}/hotel/${hotel.id}/1/s`;
		}

		const { id, region, name, category } = hotel;

		return {
			id,
			url: deeplink,
			image,
			title: name,
			hotelRating: category,
			region,
		};
	});
};

/**
 * Takes parameters of current hotel and adds it to persistence storage if it's not already
 *
 * @param  {} newHotel parameters of the current hotel
 */
export const processLastSeenHotelData = (newHotel: PersistedHotelItem): void => {
	deleteLegacyCookie();
	const currentStorage: PersistedHotelItem[] = [];
	let newPersistenceData: PersistedHotelItem[] = [];
	let hotelIndex = -1;

	const { items: lastSeenHotels } = loadLocalStorageHotel();

	if (!currentStorage) {
		newPersistenceData.push(newHotel);
	} else {
		newPersistenceData = lastSeenHotels;
		lastSeenHotels.forEach(function (hotel: PersistedHotelItem, index: number) {
			if (hotel.id === newHotel.id) {
				hotelIndex = index;
			}
		});
		if (hotelIndex > -1) {
			newPersistenceData.splice(hotelIndex, 1);
		}
		newPersistenceData.unshift(newHotel);
	}

	if (newPersistenceData.length > 4) {
		newPersistenceData.splice(4, 1);
	}
	saveLastSeenHotels(newPersistenceData);
};

/**
 * Adds the hotel on pageload to the last seen hotels persistence storage.
 *
 */
export const addToLastSeenHotelsPersistence = (): void => {
	const typoDataElement = document.getElementById('typo-data');
	if (!typoDataElement) return;
	if (!typoDataElement.dataset.hoteldata) return;

	const parsedData = JSON.parse(typoDataElement.dataset.hoteldata);
	const hotelInfo = {
		id: parsedData.Hotel.IffCode.toString(),
		name: parsedData.Hotel.Name,
		region: parsedData.Location.Region.Name,
		category: parsedData.Hotel.Category,
		urlparams: getSearchParams(),
	};

	processLastSeenHotelData(hotelInfo);
};

export function loadLocalStorageHotel(): TimeStampedLastSeenHotel {
	deleteLegacyCookie();
	const lastSeenHotels = window.localStorage.getItem('rpb_lastSeenHotels');

	if (!lastSeenHotels) {
		return { updatedAt: 0, createdAt: 0, items: [] };
	}
	const state = JSON.parse(lastSeenHotels) as TimeStampedLastSeenHotel;

	return { ...state, items: state.items.slice(0, 4) };
}

export async function editPersistedLastSeenHotels(lastSeenHotels: PersistedHotelItem[]) {
	let lastSeenHotel: LastSeenHotel[] = [];
	if (lastSeenHotels && lastSeenHotels.length > 0) {
		const enabledHotels = await filterEnabledHotels(lastSeenHotels);
		lastSeenHotel = addInfo(enabledHotels);
	}
	return lastSeenHotel;
}

async function filterEnabledHotels(list: PersistedHotelItem[]) {
	const enabledHotels = await hotelListService.get(list.map((hotel) => Number(hotel.id)));
	const newList = list.filter((hotel) => enabledHotels.find((e) => e.IffCode.toString() === hotel.id));
	if (newList.length !== list.length) {
		saveLastSeenHotels(newList);
	}
	return newList;
}

function saveLastSeenHotels(newLastSeen: PersistedHotelItem[]): void {
	const payload: TimeStampedLastSeenHotel = {
		updatedAt: Date.now(),
		createdAt: Date.now(),
		items: newLastSeen,
	};
	window.localStorage.setItem('rpb_lastSeenHotels', JSON.stringify(payload));
}
