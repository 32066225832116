import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { type IconNames, icons } from './svg';

interface Props {
	name: IconNames;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseIcon',
  props: {
    name: {}
  },
  setup(__props: any) {

/* eslint-disable global-require */


const iconComponent = computed(() => icons[__props.name]);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(iconComponent.value), { class: "base-icon" }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})