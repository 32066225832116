import { deepOverride } from '@/js/utils/objectUtils';
import base, { type BaseStructure } from './base';

const austrianWordings: BaseStructure = {
	...deepOverride(base, {
		filtersSection: {
			dealsOnly: {
				title: 'Deals',
			},
		},
	}),
} as const;
export default austrianWordings;
