<template>
	<div
		v-if="mapsKey"
		class="map-preview-and-modal"
	>
		<dialog
			ref="mapModalDiv"
			class="map-preview-and-modal__modal"
		>
			<div
				v-if="modalOpened && userCentrics.consentGiven"
				class="map-preview-and-modal__map-container"
			>
				<MapWithOffers
					:id="title"
					ref="mapWithOffers"
					:lat="lat"
					:lng="lng"
					:northwest="northwest"
					:southeast="southeast"
					:hotel-request-body="hotelRequestBody"
					:is-hotel-page="isHotelPage"
					:hotel-to-show-iff-code="hotelToShowIffCode"
					:hotel-to-show="hotelToShow"
				/>
			</div>
			<div
				v-else-if="modalOpened && !userCentrics.consentGiven"
				class="map-preview-and-modal__place-holder"
			>
				<div
					id="map-usercentrics"
					class="map-preview-and-modal__usercentrics"
				>
					<div class="map-preview-and-modal__usercentrics-content">
						<!-- User Centrics replace Container-->
					</div>
				</div>
			</div>
			<div
				v-if="showUcSecondLayer"
				class="map-preview-and-modal__uc-layer"
			></div>
			<button
				class="map-preview-and-modal__close-btn"
				aria-label="Vollbild-Karte schließen"
				@click.prevent="closeModal"
			>
				<BaseIcon
					name="close"
					class="map-preview-and-modal__close-icon"
				/>
			</button>
		</dialog>

		<slot :load-fullscreen="loadFullscreen">
			<div
				id="map-preview"
				class="map-preview-and-modal__preview"
				@click.prevent="loadFullscreen()"
			>
				<button
					class="map-preview-and-modal__fullscreen-btn"
					aria-label="Vollbild-Karte anzeigen"
				>
					<BaseIcon
						name="fullscreen"
						class="map-preview-and-modal__fullscreen-icon"
					/>
				</button>
				<picture
					id="static-map-container"
					class="map-preview-and-modal__static-map"
				>
					<source
						v-if="staticMaps.staticgmap_992"
						media="(min-width: 544px)"
						:data-srcset="staticMaps.staticgmap_992"
					/>
					<source
						v-if="staticMaps.staticgmap_544"
						:media="staticMaps.staticgmap_420 ? '(min-width: 420px)' : ''"
						:data-srcset="staticMaps.staticgmap_544"
					/>
					<source
						v-if="staticMaps.staticgmap_420"
						:data-srcset="staticMaps.staticgmap_420"
					/>
					<img
						ga-data-event_category="UX"
						ga-data-event_action="Map"
						:ga-data-event_label="gaEventLabel"
						:data-src="staticMaps.staticgmap_544 || staticMaps.staticgmap_420"
						src="data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw=="
						:alt="`Google Map von ${title}`"
						class="rpb_lazy map-preview-and-modal__static-map-img"
					/>
				</picture>
			</div>
		</slot>
		<span
			v-if="showNote"
			class="map-preview-and-modal__note"
		>
			(Positionsangaben ohne Gewähr)
		</span>
	</div>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue';
import { ref, onMounted, onUnmounted, reactive, onBeforeMount, nextTick, watch } from 'vue';
import type { StaticMaps, MarkerData } from '@interfaces/google-maps';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import MapWithOffers from '@lmt-rpb/MapWithOffers/MapWithOffers.vue';
import Modal from '@/js/modules/modal';
import UserCentrics from '@/js/modules/UserCentrics';
import type { ApiDataTypes } from '@/components/common/types';
import type { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { EventBus } from '@global-js/event-bus';
import { useBreakpointStore } from 'src/store/breakpointsStore';
import { storeToRefs } from 'pinia';

interface Props {
	title: string;
	lat: number;
	lng: number;
	zoom?: number;
	staticMaps: StaticMaps;
	mapsKey: string;
	marker?: boolean;
	customMarkerData?: Array<MarkerData>;
	gaEventLabel?: string;
	showNote?: boolean;
	northwest: { lat: number; lng: number } | null;
	southeast: { lat: number; lng: number } | null;
	hotelRequestBody: ApiDataTypes;
	hotelToShow?: ItemType | null;
	hotelToShowIffCode?: number;
	isHotelPage?: boolean;
}

withDefaults(defineProps<Props>(), {
	zoom: 11,
	marker: true,
	customMarkerData: () => [],
	gaEventLabel: 'Hotelseite',
	showNote: true,
	hotelToShow: undefined,
	hotelToShowIffCode: undefined,
	isHotelPage: false,
});

const { isDesktop } = storeToRefs(useBreakpointStore());
const mapModalDiv = ref<HTMLDialogElement>();
const modal: Ref<Modal | null> = ref(null);
const userCentrics = reactive(new UserCentrics('Google Maps'));
const modalOpened = ref(false);
const showUcSecondLayer = ref(false);
let moreInfoButton: Element | null = null;
let observer: MutationObserver;

const mapWithOffers: Ref<InstanceType<typeof MapWithOffers> | null> = ref(null);
const eventsController = new AbortController();

const initializeMap = () => {
	if (!mapWithOffers.value) return;
	mapWithOffers.value.openDefaultInfoWindow();
};

const loadFullscreen = () => {
	modal.value?.openModal();
	modalOpened.value = true;

	if (!userCentrics.consentGiven) {
		handleUserCentrics();
	} else {
		initializeMap();
	}
};

watch(
	() => userCentrics.consentGiven,
	(newValue, oldValue) => {
		if (newValue && !oldValue && modalOpened.value) {
			if (mapWithOffers.value) {
				mapWithOffers.value.$forceUpdate();
				initializeMap();
			}
		}
	}
);

const clickEvent = async () => {
	if (!window.UC_UI) {
		return;
	}
	showUcSecondLayer.value = true;
	// Small delay to ensure container is mounted
	await nextTick(() => {
		const ucContainer = document.querySelector('.map-preview-and-modal__uc-layer');
		const ucRoot = document.querySelector('#usercentrics-root');
		if (!ucContainer || !ucRoot) {
			return;
		}
		// Store original position info
		const originalParent = ucRoot.parentElement;
		const originalNextSibling = ucRoot.nextSibling;

		// Store references for cleanup
		ucContainer.setAttribute('data-uc-parent', originalParent?.id || 'body');
		if (originalNextSibling instanceof Element) {
			ucContainer.setAttribute('data-uc-next-sibling', originalNextSibling.id || '');
		}

		// Move UC to our modal layer
		ucContainer.appendChild(ucRoot);

		// Show the second layer
		window.UC_UI.showSecondLayer();
	});
};

function handleUserCentrics() {
	userCentrics.init();
	userCentrics.blockElement({ S1pcEj_jZX: '#map-usercentrics' });

	// Handle the more info button separately
	observer = new MutationObserver(() => {
		moreInfoButton = document.querySelector('.uc-embedding-more-info');
		if (!moreInfoButton) {
			return;
		}
		moreInfoButton.addEventListener('click', clickEvent, { signal: eventsController.signal });
	});

	observer.observe(document.body, {
		childList: true,
		subtree: true,
	});
}

const closeModal = () => {
	modalOpened.value = false;
	showUcSecondLayer.value = false;
	remountedUserCentrics();
	modal.value?.closeModal();
};

const closeModalKey = () => {
	modalOpened.value = false;
	showUcSecondLayer.value = false;
};

const remountedUserCentrics = () => {
	const ucContainer = document.querySelector('.map-preview-and-modal__uc-layer');
	const ucRoot = document.querySelector('#usercentrics-root');

	if (ucContainer && ucRoot) {
		const parentId = ucContainer.getAttribute('data-uc-parent');
		const nextSiblingId = ucContainer.getAttribute('data-uc-next-sibling');

		// Get the original parent (fallback to body if not found)
		const originalParent = parentId && parentId !== 'body' ? document.getElementById(parentId) : document.body;

		// Get the original next sibling
		const nextSibling = nextSiblingId ? document.getElementById(nextSiblingId) : null;

		if (originalParent) {
			if (nextSibling) {
				originalParent.insertBefore(ucRoot, nextSibling);
			} else {
				originalParent.appendChild(ucRoot);
			}
		}
	}

	if (window.UC_UI) {
		window.UC_UI.closeCMP();
	}

	showUcSecondLayer.value = false;
	moreInfoButton?.removeEventListener('click', clickEvent);
};

const handleCloseModalKey = (event: KeyboardEvent | PopStateEvent) => {
	if (event instanceof KeyboardEvent && event.key === 'Escape' && modalOpened.value) {
		event.preventDefault();
		event.stopPropagation();
		closeModal();
	}
	if (event instanceof PopStateEvent && modalOpened.value) {
		event.preventDefault();
		// needed for mobile (android / ios)
		if (!userCentrics.consentGiven || !isDesktop.value) {
			closeModalKey();
			remountedUserCentrics();
		} else {
			closeModal();
		}
	}
};

onBeforeMount(() => {
	EventBus.$on('hotel:openMap', loadFullscreen);
	if (!userCentrics.consentGiven) {
		handleUserCentrics();
	}

	// Use capture phase to ensure our handler runs first
	document.addEventListener('keydown', handleCloseModalKey, { signal: eventsController.signal, capture: true });
	window.addEventListener('popstate', handleCloseModalKey, { signal: eventsController.signal, capture: true });
});

onMounted(() => {
	if (mapModalDiv.value) {
		modal.value = new Modal(mapModalDiv.value);
	}
});

onUnmounted(() => {
	closeModal();
	if (observer) {
		observer.disconnect();
	}
	EventBus.$off('hotel:openMap', loadFullscreen);
	eventsController.abort();
});
</script>

<style lang="scss" scoped>
.map-preview-and-modal {
	&__modal {
		padding: 0;
		margin: 0;
		border: none;
		max-width: none;
		max-height: none;
		height: 100vh;
		width: 100vw;

		// user centric styling
		:deep(.uc-embedding-more-info) {
			width: 100%;
			background-color: $color-medium-gray;
			transition: width 0.3s ease;

			@media (min-width: $breakpoint-verysmall) {
				width: 23rem;
			}
		}

		:deep(.uc-embedding-accept) {
			width: 100%;
			transition: width 0.3s ease;

			@media (min-width: $breakpoint-verysmall) {
				width: 17rem;
			}
		}

		:deep(.uc-embedding-wrapper h3) {
			margin: 2.8rem 0;
			padding-top: 0 !important;
			padding-bottom: 2.8rem !important;
			line-height: 3rem;
			font-size: 1.8rem;
			font-weight: 700;
			font-family: $font-family;
			transition: all 0.3s ease;

			@media (min-width: $breakpoint-small) {
				margin: 3.2rem 0;
				padding: initial;
			}
		}

		:deep(.uc-embedding-1) {
			max-width: 60rem;
		}

		:deep(.description-text) {
			font-size: 1.4rem;
			font-weight: 400;
			font-family: $font-family !important;
			text-align: left;
			padding: 0 1rem;
			transition: padding 0.3s ease;

			@media (min-width: $breakpoint-small) {
				padding: 0;
			}
		}

		:deep(.uc-embedding-buttons) {
			margin: 2rem 0;
			padding: 0 1rem;
			transition: all 0.3s ease;

			@media (min-width: $breakpoint-small) {
				margin: 2.7rem 0;
				padding: 0;
			}
		}

		:deep(.uc-embedding-wrapper > span) {
			margin-bottom: 1.6rem !important;
			transition: margin 0.3s ease;

			@media (min-width: $breakpoint-small) {
				margin-bottom: 2.4rem !important;
			}
		}

		:deep(.uc-embedding-container) {
			position: fixed !important;
			top: 50% !important;
			left: 50% !important;
			transform: translate(-50%, -50%) !important;
			overflow: auto !important;
			padding: 1rem !important;
			border-radius: 0.4rem !important;
			width: 100% !important;
			height: 100vh !important;
			max-height: 100vh !important;
			background-color: $color-white !important;
			transition: all 0.3s ease;

			@media (min-width: $breakpoint-small) {
				padding: 1rem !important;
			}
		}

		:deep(.uc-embedding-wrapper) {
			width: 92% !important;
			background: $color-white !important;
			font-family: $font-family !important;
			box-shadow: 0.3rem 0.6rem 1.4rem 0 $color-dark-gray-t2 !important;
			max-width: 92% !important;
			transition: all 0.3s ease;

			@media (min-width: $breakpoint-verysmall) {
				width: 95% !important;
				max-width: 60rem !important;
			}
		}
	}

	&__place-holder {
		height: 26.8rem;
		min-width: 30rem;
	}

	&__fullscreen-btn {
		z-index: 2;
		border: none;
		user-select: none;
		height: 4rem;
		width: 4rem;
		overflow: hidden;
		background: none $color-white;
		padding: 0;
		text-transform: none;
		appearance: none;
		position: absolute;
		box-shadow: #0000004d 0 0.1rem 0.4rem -0.1rem;
		border-radius: 0.2rem;
		top: 0;
		right: 1rem;
		margin: 2.6rem 1rem;
	}

	&__preview {
		position: relative;
		overflow: hidden;
		height: 100%;
		fill: $color-dark-gray;

		&:hover {
			fill: $color-black;
		}
	}

	&__fullscreen-icon {
		width: 3rem;
		height: 100%;
	}

	&__loading {
		margin: auto;
		height: 100% !important;
	}

	&__static-map {
		height: 100%;
		width: 100%;
	}

	&__static-map-img {
		height: 100%;
		object-fit: contain;
		width: 100%;
		opacity: 1;
		z-index: 1;
		position: sticky;
		cursor: pointer;
	}

	&__close-btn {
		background-color: $color-white;
		border: none;
		border-radius: 0.2rem;
		height: 4rem;
		width: 4rem;
		margin-top: 0.6rem;
		margin-left: 0.1rem;
		position: absolute;
		top: 0.4rem;
		right: 1rem;
		box-shadow: #0000004d 0 0.1rem 0.4rem -0.1rem;

		&:hover {
			path {
				fill: $color-black;
			}
		}
	}

	&__close-icon {
		margin-top: 0.4rem;
		margin-left: 0;
		width: 1.8rem;
		fill: $color-dark-gray;

		&:hover {
			fill: $color-black;
		}
	}

	&__note {
		padding-left: 0.5rem;
		padding-bottom: 3rem;
		font-size: $font-small-3;
		line-height: 2.3;
		left: 0.7rem;
		position: relative;
	}

	@media (min-width: $breakpoint-verylarge) {
		&__note {
			padding: 0;
		}
	}

	&__map-container {
		position: relative;
		width: 100%;
		height: 100%;
	}
}
</style>
