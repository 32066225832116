import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = { class: "search-modal__header--title" }
const _hoisted_2 = {
  key: 0,
  class: "search-modal__filter-input-container"
}
const _hoisted_3 = { class: "search-modal__footer" }
const _hoisted_4 = {
  key: 1,
  class: "search-modal__button-row"
}
const _hoisted_5 = ["disabled"]

import { ref, onMounted, computed } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import Modal from '@/js/modules/modal';
import DropdownClearButton from '@lmt-rpb/DropdownClearButton/DropdownClearButton.vue';

type Props = {
	header: string;
	applyButton?: string;
	cancelButton?: string;
	resetInsteadOfCancel?: boolean;
	searchable?: boolean;
	withClearButton?: boolean;
	inputClearable?: boolean;
	isFocused?: boolean;
	buttonDisabled?: boolean;
	clearOnOpen?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchModal',
  props: /*@__PURE__*/_mergeModels({
    header: {},
    applyButton: {},
    cancelButton: {},
    resetInsteadOfCancel: { type: Boolean },
    searchable: { type: Boolean },
    withClearButton: { type: Boolean },
    inputClearable: { type: Boolean },
    isFocused: { type: Boolean },
    buttonDisabled: { type: Boolean },
    clearOnOpen: { type: Boolean, default: true }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels([
	'SearchModal:close',
	'SearchModal:applyChanges',
	'SearchModal:cancel',
	'SearchModal:clear',
	'SearchModal:clearInput',
	'SearchModal:focusedIn',
	'SearchModal:focusedOut',
], ["update:modelValue"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

const modalRef = ref<HTMLDialogElement | null>(null);
let modalInstance: Modal | null = null;

const input = ref<HTMLInputElement>();

const props = __props;

const emit = __emit;
const filterExpression = _useModel<string>(__props, "modelValue");

onMounted(() => {
	modalInstance = new Modal(modalRef.value!, window.matchMedia('(min-width: 1300px)'), () => {
		emit('SearchModal:close');
	});
	if (window.innerWidth > 1300) {
		modalInstance?.closeModal();
	}
});

const actualPlaceholder = computed(() => (props.isFocused ? '' : 'Suchen'));

const showButtonRow = computed(() => props.applyButton || props.cancelButton);

const clearInput = () => {
	if (!props.inputClearable) return;
	filterExpression.value = '';
};

const openModal = () => {
	if (window.innerWidth <= 1300) {
		modalInstance?.openModal();
		if (props.clearOnOpen) {
			filterExpression.value = '';
		}
	}
};

const handleApply = () => {
	if (props.buttonDisabled) {
		return;
	}
	modalInstance?.closeModal();
	emit('SearchModal:applyChanges');
};

const evaluateCloseOrReset = () => {
	if (props.resetInsteadOfCancel) {
		emit('SearchModal:clear');
	} else {
		handleClose();
	}
};

const handleClose = () => {
	modalInstance?.closeModal();
	emit('SearchModal:close');
};

__expose({ openModal, handleClose, input });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "modalRef",
    ref: modalRef,
    class: "search-modal"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["search-modal__content", { 'search-modal__content--searchable': _ctx.searchable }])
    }, [
      _createElementVNode("div", {
        class: "search-modal__header",
        onClick: handleClose
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.header), 1 /* TEXT */),
        _createVNode(BaseIcon, {
          name: "close",
          class: "search-modal__header--close"
        })
      ]),
      (_ctx.searchable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              ref_key: "input",
              ref: input,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterExpression).value = $event)),
              class: "search-modal__filter-input",
              type: "text",
              placeholder: "Suchen",
              autofocus: "",
              onFocusin: _cache[1] || (_cache[1] = () => emit('SearchModal:focusedIn')),
              onFocusout: _cache[2] || (_cache[2] = () => emit('SearchModal:focusedOut'))
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, filterExpression.value]
            ]),
            _createVNode(BaseIcon, {
              class: "search-modal__filter-input-icon",
              name: "search"
            }),
            (_ctx.inputClearable && filterExpression.value?.length)
              ? (_openBlock(), _createBlock(BaseIcon, {
                  key: 0,
                  class: "search-modal__filter-input-clear-icon",
                  name: "clearSearch",
                  onClick: clearInput
                }))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: _normalizeClass(["search-modal__main", { 'is-focused': _ctx.isFocused }])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2 /* CLASS */),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.withClearButton)
          ? (_openBlock(), _createBlock(DropdownClearButton, {
              key: 0,
              class: "search-modal__clear-button",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('SearchModal:clear')))
            }))
          : _createCommentVNode("v-if", true),
        (showButtonRow.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "footer", _normalizeProps(_guardReactiveProps({ evaluateCloseOrReset: evaluateCloseOrReset, handleApply: handleApply })), () => [
                (_ctx.cancelButton)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: _normalizeClass(["search-modal__button search-modal__button--cancel", { 'reset-styling': props.resetInsteadOfCancel }]),
                      type: "button",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (evaluateCloseOrReset()))
                    }, _toDisplayString(_ctx.cancelButton), 3 /* TEXT, CLASS */))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("button", {
                  disabled: _ctx.buttonDisabled,
                  class: _normalizeClass(["search-modal__button search-modal__button--apply", { 'is-disabled': _ctx.buttonDisabled }]),
                  type: "button",
                  onClick: handleApply
                }, _toDisplayString(_ctx.applyButton), 11 /* TEXT, CLASS, PROPS */, _hoisted_5)
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ], 2 /* CLASS */)
  ], 512 /* NEED_PATCH */))
}
}

})