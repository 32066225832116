<template>
	<section class="flight-details">
		<div class="flight-details__wrap">
			<h2 class="flight-details__header">{{ translate('flightDetail.header') }}</h2>
			<CheckoutFlightDetailsSegmentList
				:flights="outboundFlight"
				:is-outbound="true"
			/>
			<CheckoutFlightDetailsSegmentList
				:flights="inboundFlight"
				:is-outbound="false"
			/>
		</div>

		<div class="flight-details__legal-text">
			{{ translate('flightDetail.legalText') }}
		</div>
	</section>
</template>

<script lang="ts" setup>
import CheckoutFlightDetailsSegmentList from '@lmt-rpb/CheckoutFlightDetailsSegmentList/CheckoutFlightDetailsSegmentList.vue';
import type { FlightSegment } from '@/interfaces/checkout/offer-data';
import { useTranslations } from '@helper/composables/translations';

defineProps<{
	inboundFlight: FlightSegment[];
	outboundFlight: FlightSegment[];
}>();

const { translate } = useTranslations();
</script>

<style lang="scss" scoped>
.flight-details {
	background-color: $color-page-background;

	&__wrap {
		display: flex;
		flex-direction: column;
	}

	&__header {
		font-family: $font-family;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		padding: 0 0 1.6rem;
		text-align: center;
	}

	&__legal-text {
		margin: 0 auto;
		margin-top: 3.2rem;
		font-size: $font-small-2;
	}
}

@media (min-width: $breakpoint-small) {
	.flight-details {
		&__header {
			text-align: left;
		}

		&__wrap {
			max-width: 65rem;
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.flight-details {
		padding: 0 3.2rem;

		&__header {
			font-size: $font-medium-2;
		}

		&__legal-text {
			line-height: 3rem;
		}
	}
}
</style>
