<template>
	<InfoTooltip :disabled="!withTooltip">
		<template #custom>
			<span class="discount-price">
				<span class="discount-price__tag">
					{{ discountPrice }}
				</span>
				<BasePrice
					:currency
					:price="averagePrice"
					strike-through
					class="discount-price__price"
					theme="primary"
				/>
				<BaseIcon
					v-if="withTooltip"
					class="discount-price__icon"
					:name="'infoOutline'"
					width="16"
					height="16"
				/>
			</span>
		</template>
		<template #default>
			<slot
				v-if="withTooltip"
				name="tooltip"
			>
				{{ tooltip }}
			</slot>
		</template>
	</InfoTooltip>
</template>
<script setup lang="ts">
import { computed, useSlots } from 'vue';
import type { ClientCurrency } from '@global-js/constants';
import BasePrice from '@lmt-rpb/Atoms/BasePrice/BasePrice.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';

interface Props {
	discount: number;
	currency: ClientCurrency;
	averagePrice?: number;
	tooltip?: string;
}
const props = withDefaults(defineProps<Props>(), {
	tooltip: '',
	averagePrice: undefined,
	currency: 'EUR',
});

const slots = useSlots();
const discountPrice = computed(() => `${props.discount ? '-' : ''}${props.discount}%`);
const withTooltip = computed(() => !!props.tooltip || !!slots?.tooltip);
</script>
<style lang="scss" scoped>
.discount-price {
	display: inline-flex;
	align-items: center;
	gap: 0.8rem;
	font-size: $font-small-3;

	&__price {
		font-weight: $font-weight-semibold;
	}

	&__tag {
		border-radius: 0.3rem;
		display: inline-block;
		color: $color-white;
		padding: 0.2rem 0.4rem;
		background-color: $color-link;
		vertical-align: middle;
		font-size: $font-small-4;
		font-weight: $font-weight-bold;
	}

	&__icon * {
		fill: $color-link !important;
	}

	&__tooltip {
		display: flex;
		align-items: center;
	}

	@include media-query-up($breakpoint-small) {
		font-size: $font-small-2;

		&__tag {
			font-size: $font-small-3;
		}
	}

	@include media-query-up($breakpoint-large) {
		font-size: $font-small-1;

		&__tag {
			font-size: $font-small-2;
		}
	}
}
</style>
