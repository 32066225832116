import type GaItemList from './GaItemList';
import type { EventNames, GtagCommands } from './Ga4';

const consentStatusExists = () => (window.dataLayer as GtagCommands[]).map((d) => String(d.event)).includes('consent_status');
export default abstract class AnalyticsEvent<GaItem = GaItemList> {
	items: GaItem;

	static analyticsEventListener: { analyticsEvent: AnalyticsEvent; eventString: EventNames }[] = [];

	constructor(items: GaItem) {
		this.items = items;
	}

	protected fireGoogleAnalyticsEventOrAddListener(event: EventNames): void {
		if (!window.gtag) {
			console.log('fire GoogleAnalyticsEvent: ', event, '\n with following attributes:', this.items);
		} else if (consentStatusExists()) {
			this.fireGoogleAnalyticsEvent(event);
		} else {
			this.addToDataLayerListener(event);
		}
	}

	protected fireGoogleAnalyticsEvent(event: EventNames): void {
		window.gtag('event', event, this.items);
	}

	fireGoogleAnalyticsEventByListener(item: GtagCommands, listener: { analyticsEvent: AnalyticsEvent; eventString: EventNames }) {
		if (String(item.event) === 'consent_status') {
			const index = AnalyticsEvent.analyticsEventListener.findIndex(
				(l) => l.analyticsEvent === listener.analyticsEvent && l.eventString === listener.eventString
			);
			if (index > -1) {
				AnalyticsEvent.analyticsEventListener.splice(index, 1);
			}
			this.fireGoogleAnalyticsEvent(listener.eventString);
		}
	}

	private addToDataLayerListener(eventString: EventNames): void {
		AnalyticsEvent.analyticsEventListener.push({ analyticsEvent: this as AnalyticsEvent<GaItemList>, eventString });
	}

	public getItems() {
		return this.items;
	}
}

window.dataLayer = window.dataLayer || [];
window.dataLayer.push = function (item: GtagCommands) {
	Array.prototype.push.call(this, item);
	AnalyticsEvent.analyticsEventListener.forEach((listener) => listener.analyticsEvent.fireGoogleAnalyticsEventByListener(item, listener));
};
